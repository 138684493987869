import {
  AuthenticationService,
  UserManagementService, UnitService, UserService,
  ArrayUtil
} from "@75f/portal-ui-components";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

interface orgData {
  orgName: string;
  orgId: string;
  sites: [];
}

@Component({
  selector: "fs-user-management-layout",
  templateUrl: "./user-management-layout.component.html",
  styleUrls: ["./user-management-layout.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class UserManagementLayoutComponent implements OnInit, OnDestroy {
  userMangementTypes: any[] = [
    "allUser",
    "supportAccount",
    "partner",
    "organizationManager",
    "facilityManager",
    "installer",
    "endUser",
    "unassignedUser",
  ];
  isAccordian: boolean = false;
  hasAction: boolean = true;
  showAddUser: boolean;
  subscriptions: any = {};
  menu: Array<any> = [];
  userName: any;
  sites = [];
  orgList:any [];
  tempOrgList: any[];
  assignedOrgs = [];
  userId: any;
  open: boolean = false;
  expandedOrg: number[] = [];
  searchFieldInput: string = '';
  constructor(
    public authService: AuthenticationService,
    public userMangementService: UserManagementService,
    public unitService: UnitService,
    public userService: UserService,
    private analyticsService: AnalyticsService
  ) {}
  /**
   * 'ngOnInit()' method will call the getUsers method and will display the sites and
   * user details after verifying the site Id and Email Id .
   */
  ngOnInit() {
    this.userMangementService.userChanged$.subscribe(() => {
      this.getUsers();
    });


    this.userMangementService.getUserOrganizations(this.authService.getUser()
    ? this.authService.getUser().userId
    : null, {
      getSites: 'false'
    }).toPromise().then((res)=>{
      this.assignedOrgs =  res.organizations
    },err=>{

    })
    this.getUsers();
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }

  /**
   * getUsers() method will first store the sites of the user in an array(sites) and then will check for the
   * Facility manager and Installer roles in the site and store them in array.
   */
  getUsers() {
    const self = this;
    let userId = this.authService.getUser()
      ? this.authService.getUser().userId
      : null;
    this.userId = userId;
    self.subscriptions["getBuildings"] = self.userService
      .getUserSitesOfFacilisight(userId)
      .subscribe((_res) => {
        self.sites = _res["sites"] || [];
        self.sites = self.sites.map((site)=>{
          site['orgManager'] = this.assignedOrgs.filter(org=>org.orgId == site.orgId).length > 0;
          return site;
        })
        self.orgList = this.setOrgWithSites(_res["sites"]);
        this.userData(userId);
        if(!this.tempOrgList) {
          this.tempOrgList = this.orgList?.slice();
        }
      });
  }
/* Added setOrgWithSite is to create orgnaization hierarchy with sites inside orgnisation object */
  setOrgWithSites(data){
    let orgList = [];
    let orgnames = []
    data?.map(site => {
        if (!orgnames.includes(site.orgName)) {
            let org = {};
            org["orgName"] = site.orgName;
            org["orgId"] = site.orgId;
            org["sites"] = [];
            orgnames.push(site.orgName)
            orgList.push(org)
            org["sites"].push(site);
        } else {
          orgList[orgnames.indexOf(site.orgName)]["sites"].push(site);
        }
    });
    /*sorting the sites inside each organisation in ascending alphabetical order*/
    orgList?.map((org)=>{
      org.sites = org.sites?.sort((a:any, b:any) => (a.siteName || '').toLowerCase().localeCompare((b.siteName || '').toLowerCase()));
    })
    /*sorting the orgnisations in ascending alphabetical order*/
    orgList = orgList?.sort((a:any, b:any) => (a.orgName || '').toLowerCase().localeCompare((b.orgName || '').toLowerCase()));
    return orgList;
  }
  /*Added below method for checking the expanded org and adding into array */
  onaccordionClick(ind){
    const currentIndex = this.expandedOrg.indexOf(ind);

    if (currentIndex === -1) {
      this.expandedOrg.push(ind);
    } else {
      this.expandedOrg.splice(currentIndex, 1);
    }
  }
  /*Added below method for checking the expanded org and hiding hr element */
  isOrgExpanded(index: number): boolean {
    return this.expandedOrg.includes(index);
  }

  userData(userId) {
    const self = this;
    let currentSiteIndex = 0;
    for (let site of this.sites) {
      site["users"] = [];
      self.userMangementService
        .getUserBySite(site.siteId)
        .subscribe((res) => {
          let users = [];
          if (res.users) {
            if (res.users["facilityManager"]) {
              res.users["facilityManager"].forEach((user) => {
                this.setFacilityManagerData(user, site, userId);
              });
              users = users.concat(res.users["facilityManager"]);
            }

            if (res.users["installer"]) {
              res.users["installer"].forEach((user) => {
                this.setInstallerData(user, site, userId);
              });
              users = users.concat(res.users["installer"]);
            }
            users = this.checkForOccupantUserData(res, users, userId);
          }
          // Hide add user button based highest user role for user.
          site["showAddUser"] = this.checkUserRole(userId, users);
          site["users"] = users;
          // Updating the tempOrgList,once the last API call is done.
          this.updateOrgList(currentSiteIndex++);
        }, error => {
          // Updating the tempOrgList,if the last API call throws error.
          this.updateOrgList(currentSiteIndex++);
        });
    }
  }

  updateOrgList(currentSiteIndex) {
    if (currentSiteIndex == this.sites.length - 1) {
      if (this.tempOrgList) {
        if (this.searchFieldInput) { // If search text is present, then filter the building list based on the search text.
          this.onSiteSearch(this.searchFieldInput);
        } else {
          this.tempOrgList = ArrayUtil.clone(this.orgList);
        }
      }
    }
  }

  checkForOccupantUserData(res, users, userId) {
    if (res.users["occupant"]) {
      res.users["occupant"].forEach(occupantUser => {
        this.setOccupantUserData(occupantUser, userId)
      });
      users = users.concat(res.users["occupant"]);
    }
    return users;
  }

  trackByOrgId(index: number, org: any) {
    return org.orgId;
  }

  trackBySiteId(index: number, site: any) {
    return site.siteId;
  }

  setOccupantUserData(user, userId) {
    user["role"] = "Occupant";
    user["roleCode"] = "occupant";
    user["isEdit"] = true;
    user["loggedUser"] = userId == user.userId;
    if (user.userPreferences != null) {
      user["unitArray"] = user.userPreferences.temperatureUnit;
    } else {
      user["unitArray"] = this.unitService.temperatureUnitListArray[1];
    }
  }

  setFacilityManagerData(user, site, userId) {
    user["role"] =
      (user.isPrimary ? "Primary" : "Secondary") + " Manager";
    user["roleCode"] = "facilityManager";
    user["isEdit"] = (site.isPrimary || site['orgManager'])
      ? !user.isPrimary
      : false;
    user["loggedUser"] = userId == user.userId;
    user["unitArray"] = this.formatUnits(user);
  }

  setInstallerData(user, site, userId) {
    user["role"] =
      (user.isPrimary ? "Primary" : "Secondary") + " Installer";
    user["roleCode"] = "installer";
    user["isEdit"] = !user.isPrimary;
    user["loggedUser"] = userId == user.userId;
    user["unitArray"] = this.formatUnits(user);
  }

  formatUnits(res) {
    let unit
    if (res.userPreferences != null) {
      unit = res.userPreferences.temperatureUnit + ',';
      unit += res.userPreferences.energyConsumptionUnit + ',';
      unit += res.userPreferences.airflowVolumeUnit + ',';
      unit += res.userPreferences.airPressureUnit + ',';
      unit += res.userPreferences.waterPressureUnit + ',';
      unit += res.userPreferences.waterFlowUnit
    } else {
      let localUnit = {
        temperatureUnit: this.unitService.temperatureUnitListArray[1],
        energyConsumptionUnit: this.unitService.energyConsumptionUnitListArray[0],
        airflowVolumeUnit: this.unitService.airflowVolumeUnitListArray[0],
        airPressureUnit: this.unitService.airPressureUnitListArray[0],
        waterPressureUnit: this.unitService.waterPressureUnitListArray[0],
        waterFlowUnit: this.unitService.waterFlowUnitListArray[0]
      }
      unit = localUnit.temperatureUnit + ',';
      unit += localUnit.energyConsumptionUnit + ',';
      unit += localUnit.airflowVolumeUnit + ',';
      unit += localUnit.airPressureUnit + ',';
      unit += localUnit.waterPressureUnit + ',';
      unit += localUnit.waterFlowUnit;
    }

    return unit;
  }

  /**
 *This method searches the searchtext in the building list and filters the building list based on the search text.
 */
  onSiteSearch(searchtext) {
    this.searchFieldInput = searchtext; // Storing the search text in a variable.
    let filteredSites = [];
    this.tempOrgList = [];
    const searchTextLowerCase = searchtext?.toLowerCase();

    for (const org of this.orgList) {
      const orgNameCheck = org.orgName.toLowerCase().includes(searchTextLowerCase);
      const siteNameCheck = org.sites.some(site => site.siteName.toLowerCase().includes(searchTextLowerCase));

      if (orgNameCheck || siteNameCheck) {
        const filteredOrg = { ...org, sites: [] };

        if (orgNameCheck) {
          filteredOrg.sites = org.sites;
          filteredSites = filteredSites.concat(org.sites);
          this.tempOrgList.push(filteredOrg);
        } else {
          for (const site of org.sites) {
            if (site.siteName.toLowerCase().includes(searchTextLowerCase)) {
              filteredOrg.sites.push(site);
              filteredSites.push(site);
            }
          }
          this.tempOrgList.push(filteredOrg);
        }
      }
    }
  }

  /**
   *This method clears the search text and displays the original building list.
   */
  clearFilterSearch() {
    this.tempOrgList = ArrayUtil.clone(this.orgList);
    this.searchFieldInput = '';
  }

  /**
   * 'ngOnDestroy()' method will clear the data.
   */
  ngOnDestroy() {
    const self = this;
    Object.keys(self.subscriptions).forEach((e) => {
      self.subscriptions[e].unsubscribe();
    });
  }

  //Check the user Highest role and return;
  checkUserRole(userId, users) {
    const filterUsersRoles = users.filter(_user => _user.userId == userId && _user.isReadOnly !== 1 && _user.roleCode !== 'occupant');
    return filterUsersRoles?.length >= 1;
  }
}
