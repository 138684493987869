import { ConfigurationService } from '@75f/portal-ui-components';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fs-easy-street-share-info',
  templateUrl: './easy-street-share-info.component.html',
  styleUrls: ['./easy-street-share-info.component.scss']
})
export class EasyStreetShareInfoComponent implements OnInit {

  isExcellentScore: boolean;
  platform: string;
  badgeType: string;

  constructor(
    public configService: ConfigurationService,
    public dialogRef: MatDialogRef<EasyStreetShareInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.isExcellentScore = this.data.isExcellentScore ?? false;
    this.badgeType = this.isExcellentScore ? "Excellent" : "Good";
    this.platform = this.data.platform;
  }

  // Function to share the badge and copied on social media platforms
  proceedWithShare() {

    const url = this.isExcellentScore
      ? this.configService.getConfig('easyStreetShareUrl') +'/EasyStreetBadgeExcellent.jpg'
      : this.configService.getConfig('easyStreetShareUrl')+ '/EasyStreetBadgeGood.jpg';

    const message = `🎉 Excited to share that I’ve been awarded the Smart Building Installation - ${this.badgeType} Badge! This achievement reflects my dedication to maintaining top-tier standards in smart building innovation and exceptional performance.`;

    navigator.clipboard.writeText(message);

    let shareUrl = '';

    switch (this.platform) {
      case 'twitter':
        const fullMessage = `${message}\n${url}`;
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(fullMessage)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(message)}`;
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank');
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
