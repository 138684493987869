import { Injectable } from '@angular/core';
import { AuthenticationService, ConfigurationService, FeatureToggleService } from '@75f/portal-ui-components';
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public pageLoadStart: any;
  public currentPage: any;
  public isAnalyticsEnabled: boolean;
  user: any;

  constructor(private configService: ConfigurationService,
    private featureToggleService: FeatureToggleService,
    private authService: AuthenticationService) {

    this.featureToggleService.flagChange.subscribe((flags) => {
      this.setFlags(flags);
    })
  }

  setUser() {
    this.user = this.authService.getUser();
    const email = this.user.emailId;
    this.user = {
      firstName: this.user.firstName,
      id: this.user.userId,
      email: email.substring(0, email.indexOf('@'))
    };
    this.setUserId();
    this.logUserLogin();
  }

  setUserId() {
    gtag('set', 'user_properties', {
      'user_Id': `${this.user.email}(${this.user.id})`,
      'user_name': this.user.firstName
    });
  }

  logUserLogin() {
    console.log("user",this.user)
    gtag('event', 'user_login', {
      "username": `${this.user.firstName}:${this.user.id}`,
      "user_Id": this.user.id,
      "emailId": this.user.email,
      "firstName": this.user.firstName // This matches the Event Parameter in GA4
    });
  }


  useFeatureToggles() {
    let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags) => {
      this.setFlags(flags);
      this.attachSrcToHTML();
      this.setUser();
      sub.unsubscribe();
    });
    this.featureToggleService.getFlags();
  }

  setFlags(flags) {
    this.isAnalyticsEnabled = flags.hasOwnProperty('google-analytics') ? flags['google-analytics'].value : false;
  }


  /* Google Analytics Configuration */
  addAnalyticsScript() {
    this.useFeatureToggles();
  }

  attachSrcToHTML() {
    if (this.isAnalyticsEnabled) {
      let analyticsKey = this.configService.getConfig('analyticsConfig').analyticsKey;
      let gtagScript: HTMLScriptElement = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = this.configService.getConfig('analyticsConfig').analyticsUrl + analyticsKey;
      document.head.prepend(gtagScript);
      gtag('config', analyticsKey, { 'site_speed_sample_rate': 100 });
    }

  }

  /* Google Analytics log Page View */
  pageView(path) {
    this.currentPage = path;
    if (this.isAnalyticsEnabled) {
      gtag('event', 'page_view', {
        page_path: path,
        // 'metricX': 300
      })
    }
  }

  /* Google Analytics log Event  */
  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    if (this.isAnalyticsEnabled) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue
      })
    }
  }

  /* Google Analytics log Page Load Time  */
  pageLoadTime(loadTime) {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'timing_complete', {
        'name': 'Avg. Page load Time',
        'value': loadTime,
        'event_category': this.currentPage
      });
    }
  }

  /* Google Analytics log Exception  */
  logException(error, isFatal) {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'exception', {
        'description': error,
        'fatal': isFatal
      });
    }
  }

  setPageLoadStartTime(time) {
    this.pageLoadStart = time;
  }

  getPageLoadStartTime() {
    return this.pageLoadStart;
  }
 
  // New method to track search events
  logSearchEvent(query: string, category: string = 'Search') {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'search', {
        eventCategory: category,
        eventAction: 'search_performed',
        eventLabel: query
      });
    }
  }
 
  logDownloadEvent(fileName: string, fileType: string) {
    if (this.isAnalyticsEnabled) {
      gtag('event', 'download', {
        eventCategory: 'File Download',
        eventAction: 'file_downloaded',
        eventLabel: fileName, 
        eventValue: 1,
        file_type: fileType
      });
    }
  }
}