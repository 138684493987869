import { Component, ViewEncapsulation, HostListener, Inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  MessageService, LoaderService, HttpCancelService,
  AuthenticationService, HelperService, PubNubService, FeatureToggleService
} from '@75f/portal-ui-components';
import { CCUALERTService } from './shared/_services/ccuAlert.service';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({ selector: 'app', templateUrl: './app.component.html', styleUrls: ['./app.component.scss'], encapsulation: ViewEncapsulation.None })
export class AppComponent {
  message: any = {};
  state: Boolean = false;
  subscription = {};
  loaderState: any;
  addChatSupport: any;
  scriptUrl: any;
  constructor(
    @Inject('WINDOW') public window: any,
    public router: Router,
    private loaderService: LoaderService,
    public messageService: MessageService,
    public pubNubService: PubNubService,
    private helperService: HelperService,
    public ccuAlertService: CCUALERTService,
    public authService: AuthenticationService,
    public httpCancelService: HttpCancelService,
    private analyticsService: AnalyticsService,
    private featureToggleService: FeatureToggleService
  ) {
    this.subscription['getMessage'] = this.messageService.getMessage().subscribe(message => this.message = message);
    this.loaderState = this.loaderService.getState().subscribe(state => this.state = state);


    this.router.events.subscribe(event => {
   
      if (event instanceof NavigationStart) {
        this.analyticsService.setPageLoadStartTime(Date.now());
        if (event.url.split('/').length < 3) {
          let url;
          let completeUrl = event.url.split('?');
          if (completeUrl.length > 1) {
            url = completeUrl[0].split('/')[1];
          }
          else {
            url = event.url.split('/')[1];
          }

          localStorage.setItem('url', url);
          this.pubNubService.unsubscribe();
        }
        else {
          let completeUrl = event.url.split('?');
          if (completeUrl.length > 0) {
            localStorage.setItem('url', completeUrl[0]);
          }
        }
      }
    });

    this.subscription['flags'] = this.featureToggleService.flagChange.subscribe((flags)=>{
      this.setFlags(flags);
  });
  this.useFeatureToggles();
    
  }
  // <!-- Start of 75thoughts Zendesk Widget script -->
  // <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=9f7a82b6-af93-455e-86fc-3ce842e1b346"> </script>
  // <!-- End of 75thoughts Zendesk Widget script -->
  addAnalytics(){
      this.analyticsService.addAnalyticsScript();
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
          if(this.analyticsService.isAnalyticsEnabled) {
            this.analyticsService.pageView(event.urlAfterRedirects);
          }
      })
  }

  useFeatureToggles() {
    let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
        this.setFlags(flags);
        sub.unsubscribe();
        
    });
    this.featureToggleService.getFlags();
  }

  setFlags(flags) {
      this.addChatSupport  = flags.hasOwnProperty('zendesk-chat-icon') ? flags['zendesk-chat-icon'].value: false;
      this.scriptUrl  = flags.hasOwnProperty('script-url') ? flags['script-url'].value: null;
      this.addChartScriptOrRemove();
  }

  addChartScriptOrRemove() {
    
    if(this.addChatSupport) {
      if (!this.isScriptLoaded(this.scriptUrl)) {
        this.loadScript(this.scriptUrl);
      }
    } else {
      this.removeScript(this.scriptUrl)
    }
  }

  //Check scriprt is already loaded
  isScriptLoaded(url) {
    let script:any = document.getElementById('ze-snippet');
    return script
  }

  //Remove the script on feature toggle disable
  removeScript(url) {
    let script:any = document.getElementById('ze-snippet');
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  //Add script on feature toggle enable
  loadScript(url) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet'
    script.src = url;
    script.onload = () => {
      this.applyZendeskStyles(); // Apply styles only after script is loaded
    };
    document.body.appendChild(script);
  }

  applyZendeskStyles() {
    const interval = setInterval(() => {
      const zendesklauncher = document.getElementById('launcher') as HTMLIFrameElement;
      if (zendesklauncher) {
        const zendeskBody = zendesklauncher.contentWindow.document.body;
        zendeskBody.classList.add('stylesUpdated');
        zendeskBody.style.cssText = 'width: 60px !important; overflow: hidden !important; bottom: -1px; position: fixed; right: 2px;';
        clearInterval(interval);
      }
    }, 1000); // Check every 500ms until the element is found
  }
  

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    let navigationExists = this.router.getCurrentNavigation();
    if (!navigationExists) { localStorage.removeItem('url'); }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    let navigationExists = this.router.getCurrentNavigation();
    if (!navigationExists) { localStorage.removeItem('url'); }
  }

  ngOnDestroy() {
    Object.keys(this.subscription).forEach(e => {
      this.subscription[e].unsubscribe();
    });

    this.featureToggleService.close();
    
  }

  enableSidebar(url) {
    if (!(url.indexOf("dashboard") > -1 || url == "")) {
      let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));

      if (buildingDetails) {

        let siteName = buildingDetails.siteId;
        siteName = this.helperService.stripHaystackTypeMapping(buildingDetails.siteId).split(' ')[1];
        if ((<HTMLElement>document.getElementsByClassName('fs-sidebar')[0])) {
          (<HTMLElement>document.getElementsByClassName('fs-sidebar')[0]).style.display = 'block';
        }
        (<HTMLElement>document.getElementsByClassName('fa-bars')[0]).style.display = 'block';
        (<HTMLElement>document.getElementsByClassName('fs-global-topbar__userbuliding--name')[0]).innerText = siteName;
        (<HTMLElement>document.getElementsByClassName('fs-global-topbar__userbuliding')[0]).style.display = 'block';
      }


    }
  }


  ngOnInit(): void {

    this.httpCancelService.cancelPendingRequests();

    // Once the application receives an sso token, it needs to make a call to get the bearer token,
    // and since the entire application is reloaded, hence this method would get called
    this.authService.handleAuthCallback().subscribe(_ => {
      if (this.authService.isLoggedIn()) {

        if (this.authService.isTokenExpired()) {
          this.authService.appName = 'facilisight';
          this.authService.logout().then(()=>{}).catch(()=>{
          
          });
        } else {
          this.navigateToLanding();
        }

      } else {
        this.authService.login();
      }

    });
  }
  
  navigateToLanding() {
    this.authService.fetchLoggedInUserDetails().subscribe(_ => {
      if (localStorage.getItem('url')) {
        this.router.navigate([localStorage.getItem('url')]).then(() => {
          this.ccuAlertService.refreshAlertCountEventTrigger();
        }).catch(()=>{
          
        });
      }
      else {
        this.router.navigate(['/landing']).then(() => {
          this.ccuAlertService.refreshAlertCountEventTrigger();
        }).catch(()=>{
          
        });
      }

      this.featureToggleService.devCycleInit().then(()=>{}).catch(()=>{});
      this.featureToggleService.initalizedSubject.subscribe(()=>{
        this.addAnalytics();
      });
    });
  }
}
