import {
    Component,
    OnInit,
    SimpleChange,
    SimpleChanges,
    HostListener,
    OnChanges,
    ViewChild,
    OnDestroy,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil, map, debounceTime, distinctUntilChanged ,first} from 'rxjs/operators';
import { Subject, Observable, Subscription, forkJoin, interval,firstValueFrom, lastValueFrom } from 'rxjs';
import { HeatmapHeaderComponent } from '../heatmap-header/heatmap-header.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    PucSystemVavComfortIndexGraphWidget, PucSystemDabComfortIndexGraphWidget, ObjectUtil,
    DateUtil, PucUserIntentTempSliderValueChangedIds, PucSliderInputOutputData,
    PucUserLimitDataSources, SliderUserIntentService, MessageService,
    LoaderService,ExportService,GraphService, SiteService,
    VacationsService, HelperService, PubNubService,RuntimeGraphService, HeatMapToolTipService, 
    DeviceHelperService, CustomHeatmapService, HeatmapConnectService, SchedulerService, 
    ArrayUtil, UserService, HeatmapService,UnitService,ConfigurationService, AuthenticationService, NamedScheduleService, SchedulerEditModalComponent, IntrinsicSchedulesComponent, FeatureToggleService, SummaryTableHelperService,
    systemProfileTagsArray, systemProfileOaoTagsArray, systemProfileFilterTags, systemProfileFilterOAOTags, AlertService, HeatmapFilterService
    ,zoneProfile_vav, zoneProfile_dab, zoneProfile_cpu_hyperstat, zoneProfile_cpu_non_hyperstat,systemprofileWidgets,zoneProfile_hpu_non_hyperstat,vavDabAdvancedAhuV2EquipGraphics,
    AnalyticsLayoutService, ZoneProfile_2pipe_non_hyperstat, zoneProfile_pipe4, zoneProfile_emr, zoneProfile_pid, zoneProfile_sse, zoneProfile_sense, zoneProfile_monitoring, zoneProfile_vrv, zoneReconfig_ti, zoneProfile_bpos,
    zoneProfile_otn, zoneProfile_dualDuct, zoneProfile_series, zoneProfile_parallel, zoneProfile_hyperstathpu, zoneProfile_hyperstatPipe2, NotesService, zoneProfile_hyperstatCpu_specific, zoneProfile_hyperstatSplit_specific,zoneProfile_chilledBeam, PointSummaryListComponent,zoneProfile_Mystat_pipe2,
    systemprofileEquipGraphics, scheduleGroupText, SchedulerForceTrimModalComponent,zoneProfile_hsplit_cpu_widgets,
    dayIndexs,
    weekDays,
    weekEnd,
    weeks,
    vav_terminal,
    CustomGraphicsListComponent,
    AnalyticsListComponent,
    zoneProfile_hs_pipe2,
    CommonMethodService,CommonService,SortOrderComponent
} from '@75f/portal-ui-components';
import { MatSelect } from '@angular/material/select';
import { CCUALERTService } from '@/shared/_services';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ProfileWidgets, UserIntent } from '@/shared/_models';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { environment } from 'src/environments/environment';
import { ActionType, ChatCommand, ChatEventsService, WidgetResponse } from '@75f/chat-ui';
interface CustomGlobalHeatmapDashboard {
    id: string,
    ownerUserId: string,
    siteRef: string,
    isPublished: boolean,
    updatedDateTime: string,
    globalDashboardId: string,
    entityDashboards: {}
}
@Component({
    selector: 'fs-heatmap-layout',
    templateUrl: './heatmap-layout.component.html',
    styleUrls: ['./heatmap-layout.component.scss'],
})
export class HeatmapLayoutComponent implements OnInit,AfterViewInit , OnChanges, OnDestroy {
    advanceAhuSpPoint: Array<any> = [];
    advanceAhuSpPointReadpoints: Array<any> = [];
    showlastUpdatedToggleEnabled: boolean = false;
    oaoDomainNameCheck:boolean = false;
    checkForAdvanceAhuProfiles = ["vavAdvancedHybridAhuV2", "dabAdvancedHybridAhuV2"];
    checkForVavDabSystemExternalProfiles = ["vavExternalAHUController","dabExternalAHUController"];
    openedSchedulerEditModal = false;
    systemOperationModePoint:any;
    newSchedulerOnNamedSchedule: any;
    zonehasDomainName:any;
    ranges;
    checkForEventType:any;
    equipGraphicSystemProfile:any
    equipGraphicsProfiles:any;
    zendesklauncher: any;
    cmVersion;
    occStatusValues: string[] = ['Occupied', 'Unoccupied', 'Tenant Override'];
    OccStatus:any;
    userTempPreference:any;
    systemLevelEquipGraphics: boolean = false;
    humidifier:boolean;
    hsCpuWidgetsData: Array<any> = [];
    deHumidifier:boolean;
    customWidgetDataForExport = {};
    isDomainIntegrated: boolean = false;
    highlightCCU = '';
    userPrefData = {
        ccuOrder:[]
    };
    checkForHyperSense:any;
    OaoShow: boolean;
    screenfloors:any=[];
    vacationSkeletonLoader : boolean;
    hideDisplayUIBtuText:boolean =false;
    hideDisplayUIModbusText:boolean = false;
    showModbus:boolean;
    modbusType:string;
    hideDisplayUIText:boolean =false;
    overlayHeight;
    skeletonContentLoaded: boolean;
    isTIZone: boolean = false;
    showModbusEmr: boolean;
    showBtu: boolean;
    showEmr = false;
    btuMeterType: string;
    showEquipGraphics:any;
    emrMeterType: string
    btuTagsProfile = {
        profileType: 'modbus',
        profile: {
            name: 'BTU METER'
        }
    }
    heatingDesriedTempChangeHolder = [];
    coolingDesriedTempChangeHolder = [];
    desiredCoolingTempHolder;
    desiredHeatingTempHolder;
    settingType: any;
    desiredTemp: any;
    schedulerOccupancyLegend = [
		{ name: 'Occupied', color: 'rgba(250, 135, 51, 0.5)', button: false },
		{ name: 'Unoccupied', color: 'rgba(208, 227, 157, 0.5)', button: false },
		{ name: 'edit schedule', color: '', button: true },
	];
    onChangeDesiredTempSubject = new Subject();

    public subscriptionCollection: Array<any> = new Array<any>();
    public highlightRoomsSubscriptionCollection: Array<any> = new Array<any>();
    public getUserSettingDataSubscriptionCollection: any = {};
    public getCCUSettingDataSubscriptionCollection: Array<any> = new Array<any>();
    public getCCUSettingDataChannelSubscriptionCollection: Array<any> = new Array<any>();

    zonePriorityId = "zone_priority";
    scheduleTypeId = "schedule_type";

    public zoneVacations: Array<any>;
    private unsubscribe: Subject<void> = new Subject();
    public checkZoneDeadSubscription: Subscription = new Subscription();
    public checkZoneDeadHisPointSubscriptions: Subscription[] = [];
    public checkIfOAOIsPairedSubscription: Subscription = new Subscription();
    private getEntitySubscription: Subscription = new Subscription();
    version: any;
    dataForExport = {};
    checkWidgestLoaded = {};
    systemReconfigurationSettings: any;
    equipGraphicData
    noteUploadOpen: boolean = false;
    @ViewChild(HeatmapHeaderComponent, { static: false }) header: HeatmapHeaderComponent;
    @ViewChild('intrinsicRef', { static: false }) IntrinsicSchedulesComponent: IntrinsicSchedulesComponent;
    intrinsicScheduleData = {};
    allZonesVacationArr = {};
    conflictMsg: string;
    refs: any = {
        siteRef: undefined,
        floorRef: undefined,
        ccuName: undefined,
        zoneName: undefined,
        equipRef: undefined,
        roomRef: undefined,
        ccuRef: undefined,
        gateway: undefined,
        ccuEquipRef: undefined
    };


    buildingLimit: any = {
        min: undefined,
        max: undefined
    }
    siteTz: string;
    currentTemperatureRef: any = [];
    profiles: any;
    profileCheckFlag: Boolean = false;
    profileObj: any = {
        profileType: undefined,
        profile: undefined,
    };
    headerUserIntent: boolean = false;
    currentRateInput: any;
    energyReadingInput: any;
    energyMeterStatus: any;
    piLoopstatus: any;
    buildings: any = {
        buildings: null,
        buildingDetails: [],
        floors: [],
        floorsMaster: [],
        ccus: [],
        floorCcu: [],
        rooms: {},
        equips: {},
        zones: [],
        schedule: []
    };
    zone: any = {
        equipId: undefined,
        name: undefined,
        priority: undefined,
    };
    modal: any = {
        room: {
            active: false,
            fixed: false,
            width: 60,
            left: 0,
        },
        system: {
            active: false,
            fixed: false,
            width: 60,
            left: 0,
        }
    };
    singleFloor = false;
    ccuColors: any = this.heatmapService.ccuColors;
    temperatureColors = [
        {
            color: '#304369',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#fcaf17',
            coolingDesiredColor: '#d9e7f4',
            temp: -6
        },
        {
            color: '#92bed4',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: -4
        },
        {
            color: '#d9e7f4',
            zoneNameColor: '#4f4f4f',
            currentTempColor: '#4f4f4f',
            partitionColor: '#4f4f4f',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: -2
        },
        {
            color: '#d0e39d',
            zoneNameColor: '#4f4f4f',
            currentTempColor: '#4f4f4f',
            partitionColor: '#4f4f4f',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: 0
        },
        {
            color: '#fcaf17',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#304369',
            temp: 2
        },
        {
            color: '#f6892e',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#b02c25',
            coolingDesiredColor: '#d9e7f4',
            temp: 4
        },
        {
            color: '#b02c25',
            zoneNameColor: '#ffffff',
            currentTempColor: '#ffffff',
            partitionColor: '#ffffff',
            heatingDesiredColor: '#fcaf17',
            coolingDesiredColor: '#d9e7f4',
            temp: 6
        }
    ];
    heatingDeadBandRoomRefList: any;
    coolingDeadBandRoomRefList: any;
    arrZoneList = [];
    zoneSettings: any = UserIntent.zoneSettings;
    priorityArray: any = [];
    options: any = UserIntent.options;
    accordionHeaderStyle: any = {
        firstLevel: {
            'font-size': '1rem',
            'font-weight': '700',
            //'color': '#E24301',
            'text-transform': 'capitalize',
        },
        secondLevel: {
            'font-size': '0.8rem',
            'margin': '2px 0',
            'font-weight': '500',
            'color': '#231f20',
            'text-transform': 'capitalize',
        },
    };
    sliderInput: PucSliderInputOutputData = {
        currentTemp: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        coolingDeadband: undefined,
        heatingDeadband: undefined,
    };
    multipleModuleInput: any = {
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingDeadband: 2,
        coolingDeadband: 2,
    };
    activeTile: string;
    mousePos: any;
    xCords: any = [];
    ccuEquips: any = [];
    isFloorPlanLoaded: Boolean = false;
    isHeaderFixed: Boolean = false;
    displayRangeSlider: Boolean = undefined;
    pointIdSet: any = new Set();
    selected: any;
    buildingMaster: any;
    widgets: any;
    systemWidgets: any;
    defaultMinHumidity: number;
    defaultMaxHumidity: number;
    defaultCompensateHumidity: number;
    defaultDemandResponse: number;
    desiredCIValue;
    desiredCISliderValue: any;
    humdityValues: any;

    ccuSettingObj: any = [];
    modeList = ['OFF', 'AUTO', 'COOL ONLY', 'HEAT ONLY'];
    rangeSliderHack = {
        2: 5,
        3: 4,
        4: 3,
        5: 2,
    };
    ccuSetting: any = {
        master: null,
        systemMode: null,
        status: null,
        desiredComfortIndex: 5,
        compensateHumidity: null,
        demandResponse: null,
        minHumidity: null,
        maxHumidity: null,
        equipStatus: null,
        occStatus: null,
        occupancyStatus: null,
        oao: null,
        smartPostpurge: null,
        smartPrepurge: null,
        demandResponseActivation:null,
        enhancedVentilation: null,

    };
    roomObj: any;
    schedulerUnitCheck:any
    onSystemMode: boolean = false;
    avgCurrentTemp: any = 0;
    coolingDeadbandArray: any = [];
    heatingDeadbandArray: any = [];
    heatUserLimitArray: any = [];
    coolUserLimitArray: any = [];
    multiModuleProfile: boolean = false;
    multiModuleProfileHasHPU: boolean = false;
    multiModuleProfileHasCPU: boolean = false;
    multiModuleZoneStatus: any = [];
    zonePriorityVal: any;
    oldZonePriorityVal: any;
    scheduleTypeVal: any;
    oldscheduleTypeVal: any;
    multiModuleZonePriorityList: Map<string, any> = new Map();
    multiModuleScheduleTypeList: Map<string, any> = new Map();
    zonePriorityCount: number = 0;
    zoneScheduleCount: number = 0;
    hoverEnterTimer:any;
    average = (array) => array.reduce((a, b) => a + b) / array.length;

    modelStyle: any = {
        display: 'none'
    };
    siteNamemodalShow;
    modalStyleClickedFlag;
    modelStyleOnClicked: any = {
        display: 'none'
    };

    siteNameModalStyle: any = {
        display: 'none'
    }
    siteNameModalStyleClickedFlag;
    siteNameModalStyleOnClicked: any = {
        display: 'none'
    }
    active = null;
    ccuOrZone = { type: '', actionUsed: '' }
    oaoArcOptions: any = UserIntent.oaoArcOptions;
    oaoArcValue: number = 0;
    zoneChange = false;
    ahumode = [];
    ccusWithPairedZones;
    sideMenu;
    subscriptions: any = {};
    cancelChanges: any[] = [];

    //In case graph-wrapper widht isn't caught , we'll use this one
    graphWidth: any;

    zoneId = "";
    defaultLimits: any = {
        desiredTempHeating: undefined,
        desiredTempCooling: undefined
    };
    schSliderInputData;

    private heatCoolLimitsHisData = new Map<string, any>();
    public ccuListByFloors = [];

    @ViewChild('CompensateHumidity', { static: false }) CompensateHumidity: any;
    @ViewChild('DemandResponse', { static: false }) DemandResponse: any;
    deadbandScale: any = [];

    showHyperStatCpu:any;
    selectedCCu : any;
    selectedZone : any;
    searchText = '';
    searchMatchingZones = [];
    tempBuildingScheduleInfo: any;
    //trim sch array holder
    forceTrimArray = [];
    systemReconfigProfileName: any;

    delayChanges = new Subject();
    autoRefreshSelection: any;
    autoRefreshSub: any;
    allEquips: Array<any> = [];
    allDevices: Array<any> = [];
    allNodes: Array<any> = [];

    lazyLoadFloors=[];
    showLazyLoader: boolean;
    singleFloorFlagStyle: boolean;
    selectedFilter
    defaultFilter;
    floorheight: number = 2;
    hoverZoneData;
    selectedFloorRef: any;
    shapeId = '';
    systemPoints = [];
    concatQuery = '';
    allFLoor: any = [];
    allDetails: boolean = false;
    ccuForLayout: any = [];
    allCcus: any = [];

    @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
    zonePreDefinedWidgetsOpenState: boolean = true;
    zoneAlertsAccordionOpenState: boolean = true;
    zoneTunersOpenState: boolean = false;
    zoneSettingsOpenState: boolean = true;
    zoneReconfigurationOpenState: boolean = false;
    CCUPreDefinedWidgetsOpenState: boolean = true;
    CCUAlertsAccordionOpenState:boolean = true;
    systemTunersOpenState: boolean = false;
    CCUSettingsOpenState: boolean = true;
    ccuPointSummaryOpenState: boolean = true;
    zonePointSummaryOpenState: boolean = true;
    CCUreconfigurationOpenState: boolean = false;
    remoteAccessOpenState: boolean = false;
    CCULogsOpenState: boolean = false;
    initialFilter: boolean = true;
    hoverdOrClickEventFalg:any;
  
    userPreferenceData: any = {
      userId: "",
      heatmapPreferences: {
        lastSelectedFilter: {},
        defaultFilter: {},
  
        CCUHeatmapPreferences: {
          preDefinedWidgets: true,
          CCUSettings: true,
          systemEquipGraphics:true,
          pointSummary:true,
          analytics:true
        },
  
        zoneHeatmapPreferences: {
          preDefinedWidgets: true,
          zoneSettings: true,
          accordionSectionSelction: true,
          pointSummary:true,
          analytics:true
        },
      },
    };
    showSchedulerOccupancy: boolean = true;
    private schedulerEditDialogRef: MatDialogRef<SchedulerEditModalComponent>;
    checkHyperStatProfile: any;
    messagingSubscribe: any;
    userUpdatedValue: boolean = false;
    tempZoneSettingsHolder: any;
    overrideCancelled: boolean;
    summaryTableOpen = false;
    autoRefreshSubject = new Subject();
    siteName = '';
    hideCCUHeader: boolean = false;
    isVofm = false;
    timeSeriesinteval;
    buildingDataTempHolder: any;
    isModbus: boolean;
    operationModePoint: any = [];
    hideSummaryTableSection: boolean = false;
    isPartner: boolean = false;
    multiFloorSelected: boolean = false;
    compareFloorChanges: any = [];
    monitoringProfile: boolean = false;
    enableDownLoad: boolean = false;
    zoneTuners: Array<any> = [];
    tunerDataChanged = false;
    tuners: Array<any> = [];
    allowTunersAccess: boolean = false;
    certificationLevel: any;
    isFilter: boolean = false;
    notesList = [];
    filteredNotesList = [];

    // Feature toggles
    showSummaryTables: boolean = false;
    showAIDashboard; boolean = false;
    showOtaCommands:any;
    show75FChatButton: boolean;
    iframeSrc: any;
    dispProgress:any;
    ccuId: any;
    showRemoteAccess: boolean = false;
    modbusEquipId: any;
    systemReconfigTitle: string = '';

    // Chat UI
    autoAddedSummaryTables: string[] = [];
    aiDashboardData: any;
    terminalEquipGraphics:any;

    zoneHovercheckFlag: boolean;
    ccuHoverCheckFlag: boolean;
    monitoringEquipParameteres: { };
    @ViewChild('heatmapView', { static: false }) heatmapView: ElementRef;
    openMenu: boolean = false;
    showPointSummary: boolean;
    isdomainName: boolean = false;
    scheduleGroupLabel: any = scheduleGroupText;
    profileReconfigCheck: boolean;
    groupingOptions: any = [
        { id: '1', displayName: 'EVERYDAY', class: 'everyday', enum: 'EVERYDAY', value: 3, disabled: false},
        { id: '2', displayName: 'WEEKDAY + WEEKEND', class: 'weekday-weekend', enum: 'WEEKDAY_WEEKEND', value: 2, disabled: false}, 
        { id: '3', displayName: 'WEEKDAY + SATURDAY + SUNDAY', class: 'weekday-sat-sun', enum: 'WEEKDAY_SATURDAY_SUNDAY',  value: 1, disabled: false} , 
        { id: '4', displayName: '7 DAY', class: 'sevendays', enum: '7_DAY', value: 0,  disabled: false }
      ];
    enableScheduleGroup: boolean = false;
    compareCcuVersion: any;
    ccuAnalyticsOpenState: boolean = true;
    zoneAnalyticsOpenState: boolean = true;
    entitiesList: any;
    showNotesAccordion: boolean = true;
    modalPopupOpened:boolean = false;

     // scroll to notes section
     expandNotesSection : Boolean = false;

    enableViewAssetsSystemProfile: boolean = false;
    taggedFilesListSystemProfile: any;
    showQRCode: any;
    versionText: string;
    homeAppVersion: string;
    remoteAccessAppVersion: string;
    backnetAppVersion: string;
    isDownloadExcelPending: boolean = false;
    clearExcelInterval: any;
    fileUpload: boolean;
    query = "(room or ccu or floor or equip or device or schedule or schedulable or scheduleType or vacation or (building and occupancy) or (schedulable and default) or (building and limit and (min or max))) and siteRef==@";
    widgetData:any;
    zoneWidgetDataLoaded = false;
    showCustomGraphics: boolean = false;
    onGraphicListChangedEvent: any; 
    showPredefinedEquipGraphic: any = {};
    graphicsLoaded: boolean = false;
    piAnalogSensorsDmDomainNames = [
        'voltageInput_ai1',
        'pressureSensor_ai1',
        'differentialAirPressureSensor_025_ai1',
        'airFlowSensor_ai1',
        'zoneHumidity_ai1',
        'zoneCo2_ai1',
        'zoneCo_ai1',
        'zoneNo2_ai1',
        'currentTx10_ai1',
        'currentTx20_ai1',
        'currentTx50_ai1',
        'genericIonSensorPoint_ai1'
      ];
      
    piAnalog2SensorsDmDomainNames = [
        'voltageInput_ai2',
        'pressureSensor_ai2',
        'differentialPressureSensor_025_ai2',
        'airFlowSensor_ai2',
        'zoneHumidity_ai2',
        'zoneCo2_ai2',
        'zoneCo_ai2',
        'zoneNo2_ai2',
        'currentTx10_ai2',
        'currentTx20_ai2',
        'currentTx50_ai2'
      ];
      
    thermistorSensorDmDomainNames = [
        'externalAirTempSensor',
        'airTempSensor100kOhms',
        'genericAlarmNC',
        'genericAlarmNO'
      ];
      
    nativeSensorDMDomainNames = [
        'currentTemp',
        'zoneHumidity',
        'zoneCo2',
        'zoneCo',
        'zoneNo',
        'pressureSensor',
        'zoneSound',
        'zoneOccupancy',
        'zoneIlluminance',
        'zoneCo2e',
        'zoneUvi',
        'zonePm25',
        'zonePm10'
      ];
    sensorType:any;
    sensorType2:any
    reconfig: boolean = false;
    allowModbusRearrange: boolean = false;
    connectModuleOaoIspaired:boolean;
    connectModulePaired:boolean = false;
    isConnectModuleOaoIsPaired:boolean;
    constructor(
        public sanitizer: DomSanitizer,
        public siteService: SiteService,
        public helperService: HelperService,
        public loaderService: LoaderService,
        public graphService: GraphService,
        public pubNubService: PubNubService,
        public deviceHelper: DeviceHelperService,
        public sliderUserIntentService: SliderUserIntentService,
        public heatMapToolTipService: HeatMapToolTipService,
        private vacationsService: VacationsService,
        public runTimeGraphsService: RuntimeGraphService,
        public el: ElementRef,
        public messageService: MessageService,
        public exportData: ExportService,
        public dialog: MatDialog,
        public dom: DomSanitizer,
        public customHeatmapService: CustomHeatmapService,
        public heatmapConnect: HeatmapConnectService,
        public schedulerService: SchedulerService,
        public userPref: UserService,
        public heatmapService: HeatmapService,
        private ccuAlertService: CCUALERTService,
        public configService: ConfigurationService,
        public unitService : UnitService,
        private authService: AuthenticationService,
        public namedScheduleService: NamedScheduleService,
        private analyticsService: AnalyticsService,
        private featureToggleService: FeatureToggleService,
        private summaryTableHelperService: SummaryTableHelperService,
        public alertService: AlertService,
        private filterSrvc: HeatmapFilterService,
        public notesService: NotesService,
        private chatEventsService: ChatEventsService,
        private analyticsLayoutService: AnalyticsLayoutService,
        private cdr: ChangeDetectorRef,
        private commonMethodService : CommonMethodService,
        private commonService: CommonService
    ) {

        this.subscriptions['popUpCancelClick'] = this.helperService.popUpCancelClick.subscribe(() => {
            this.overrideCancelled = true;
            this.getUserSettingData('');
        });
        
        this.subscriptions['updateEntitySubscriptions'] = this.pubNubService.updateEntityForNamedSch.pipe(
            debounceTime(1000),
          ).subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataWhenNamedSchApply();
            }
        });

        this.subscriptions['chatEvents'] = this.chatEventsService.chatEvent$.subscribe((command: ChatCommand) => {
            const widgetCommand = command as WidgetResponse;
            if (command.actionType == ActionType.ADD_SUMMARY_TABLE) {
                this.autoAddedSummaryTables = [widgetCommand.visualizationId];
            } else if (command.actionType == ActionType.GENERATE_DASHBOARD) {
                this.aiDashboardData = widgetCommand.data;
            } else if (command.actionType == ActionType.APPLY_HEATMAP_OVERLAY) {
                if (widgetCommand.default) {
                    this.selectedFilter = { filterId: widgetCommand.visualizationId, defaultInd: true }
                }
                else {
                    let filter = this.filterSrvc.filtersList.find(f => f.filterId == widgetCommand.visualizationId);
                    if (filter) {
                        this.setFilterPoints(ObjectUtil.deepClone(filter)).then(() => {})
                        .catch((error) => console.error("Failed to set filter via chat event", error));
                    }
                }
                this.heatmapView?.nativeElement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            }
          })

        this.onChangeDesiredTempSubject.pipe(
            debounceTime(0), distinctUntilChanged()).subscribe((val: any) => {
                this.handleSliderChanges()
            })

        this.delayChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
        ).subscribe((res: any) => {
            if (res.key == 'reConfig' && res.val != null) {
                this.getSystemReconfigurationData(res.val);
            } else {
           

                if (this.multiModuleProfile) {
                    const response = ObjectUtil.deepClone(res.val);
                    const zoneSettings = ObjectUtil.deepClone(this.tempZoneSettingsHolder);
                    for(const keys of this.profiles) {
                        delete (response[keys['profile']['name']]?.lastUpdated?.val);
                        if (zoneSettings != null) {
                            delete (zoneSettings[keys['profile']['name']]?.lastUpdated?.val);
                            //In modbus profile the will be no schedule points so it iss breaking so kept profile check
                            if(this.profileCheckFlag) {
                                delete (zoneSettings["zoneSchedule"]["floorName"])
                                delete (zoneSettings["zoneSchedule"]["scheduleType"])
                                delete (zoneSettings["zoneSchedule"]["zoneName"])
                            }                       
                        }
                       
                    }

                    if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                        this.zoneSettings = ObjectUtil.deepClone(res.val);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                        this.zoneSettingsFunction();
                    } else {
                        this.updateLastedValues(res);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                    }
                    
                } else {
                    const response = ObjectUtil.deepClone(res.val);
                    delete (response?.lastUpdated?.val);
                    const zoneSettings = ObjectUtil.deepClone(this.tempZoneSettingsHolder);
                    if (zoneSettings != null) {
                        delete (zoneSettings?.lastUpdated?.val);
                        //In modbus profile the will be no schedule points so it iss breaking so kept profile check
                        if(this.profileCheckFlag) {
                            delete (zoneSettings["zoneSchedule"]["floorName"])
                            delete (zoneSettings["zoneSchedule"]["scheduleType"])
                            delete (zoneSettings["zoneSchedule"]["zoneName"])
                        }
                    }
                    
                    if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                        this.zoneSettings = ObjectUtil.deepClone(res.val);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                        this.zoneSettingsFunction();
                    } else {
                        this.updateLastedValues(res);
                        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res.val);
                    }
                }
            }
        });

        this.summaryTableHelperService.openPopup.subscribe((res: any) => {
            if (res.type == 'zone') {
                let ccu = this.ccusWithPairedZones.find(c=>c.pairedZoneList.indexOf(res.zone.id)>-1);
                let ccuName = {'ccuName' : ccu?.CCUName || ''};
                res['zoneData'] = ccuName;
                ccu ? res['ccuId'] = ccu.id : '';
                this.zoneClicked(res, true);
            } else if (res.type == 'system') {
                this.ccuClickFromSummaryTable(res);
            }
        })
        this.isPartner = environment.partner;
    }

    async ccuClickFromSummaryTable(ccuObj) {
        let ccuData = {
            "ccuId": ccuObj.ccuId,
            "$event": {
                "isTrusted": true
            }
        }
        await this.getSystemSettingsOnClick(ccuData, true);
    }


    setUserUpdatedValue() {
        this.userUpdatedValue = true;
        setTimeout(()=>{
            this.userUpdatedValue = false;
        },5000)
    }

    zoneSettingsFunction() {
        this.handleHumidityDehumidity()
        this.handleZonePriority();
        this.handleZoneSchedule();
        this.heartBeathandle();
        this.setTerminalGraphicValues(this.zoneSettings);
        this.overrideCancelled = false;
        if (this.zoneSettings?.zoneSpecialSchedule) {
            this.zoneSettings.zoneSpecialSchedule = [].concat(this.zoneSettings.zoneSpecialSchedule);
        }
        if (this.zoneSettings?.zoneVacation) {
            this.zoneVacations = [].concat(this.zoneSettings.zoneVacation);
            this.vacationSkeletonLoader = false;
        } else {
            this.vacationSkeletonLoader = false;
        }
        this.zoneUserIntentSettings();
        this.getCcuVersion();
    }

    ngAfterViewInit(){
        
        const interval = setInterval(() => {
            this.zendesklauncher = document.getElementById('launcher') as HTMLIFrameElement;
            if (this.zendesklauncher && !this.isFilter) {
                this.zendesklauncher.style.right = '75px';
                clearInterval(interval);
            }
        }, 500); // Check every 500ms until the element is found

        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

    getBuildingRef(buildingId) {
        let selectedBuildingId = this.stripHayStackTags(buildingId);
        return selectedBuildingId.split(' ')[0];
    }

    stripHayStackTags(dataInput) {
        return this.helperService.stripHaystackTypeMapping(dataInput);
    }

    commaSeparatedPointIds = (a, b) => { return a + ',' + b };

    pointIDOrPointIDsWithComma = (buildingObj, roomRef, tagsList: any) => {
        let returnPointIds = this.helperService.getPointIdbyTags(buildingObj, tagsList, roomRef);
        if (returnPointIds) {
            if (returnPointIds.length > 0) {
                return returnPointIds.join();
            }
            else if (returnPointIds.length == 0) {
                return undefined;
            }
            else {
                return returnPointIds;
            }
        }
    };

    findMaxValuefromCommaSeparated = (input) => {
        let output = input.split(',');
        output.pop();
        if (output.length > 0) {
            output = output.reduce((prev, curr) => {
                return Math.max(Number(prev), Number(curr))
            });
        }
        return output.toString();
    };

    intializeDate() {
        let tz: string = this.siteService.getIANATimeZone(this.siteTz);
        this.selected = {
            startDate: dayjs().tz(tz).startOf('day'),
            endDate: dayjs(Date.now()).tz(tz),
            timeZone: tz
        }
        this.heatMapToolTipService.selectedDateRange = this.selected;
    }

    ngOnChanges(changes: SimpleChanges) {
        const floors: SimpleChange = changes.floors;
        this.buildings.floors = floors.currentValue;
    }

    async ngOnInit() {
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        if (buildingDetails) {
            this.siteName = buildingDetails.siteName;
            this.refs.siteRef = buildingDetails.siteId;
            this.getBuildingDetails(this.getBuildingRef(buildingDetails.siteId));
            this.floorheight = Math.round((window.screen.height - 74)/500);
            this.getBuildingFloors(this.getBuildingRef(buildingDetails.siteId));
        }
        
        try {
            await this.getUserPreferencesData(true);
        } catch(e) {
            this.filterChangeHandle(null, false);
        }
        this.userPreferenceData.userId = this.authService.getUser() ? this.authService.getUser().userId : null;
        let userPreference = JSON.parse(localStorage.getItem('user_preference'));
        this.isVofm = this.authService.isUserViewOnly();
        this.userTempPreference = userPreference.temperatureUnit;
        this.schedulerUnitCheck = this.userTempPreference
        this.ranges=this.helperService.fetchRanges();
        this.vacationSkeletonLoader = true
        this.loaderService.active(true);
        this.helperService.clearData();
        this.heatMapToolTipService.clearTooltipData();
        this.defaultMinHumidity = 0;
        this.defaultMaxHumidity = 0;
        this.defaultCompensateHumidity = 0;
        this.defaultDemandResponse = 0;
        this.humdityValues = new Array(101).fill(0).map((x, i) => i);
        this.subscriptions['cancelledChangeSubscriptions'] = this.helperService.cancelledChange.subscribe((param: any) => {
            this.cancelChanges = ArrayUtil.clone(param.list || []);
            this.revertChangedValues(param.list);
        });

        this.subscriptions['vacationAddedSubscriptions'] = this.pubNubService.vacationAdded.pipe(debounceTime(1000)).subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataForVacationEdit(addedVacId);
            }
        });

        this.ccuAlertService.refreshAlertCountEventTrigger();
        
        this.filterSrvc.closepopups.subscribe((val) => {
            this.isFilter = val;
        });

        this.subscriptions['flags'] = this.featureToggleService.flagChange.subscribe((flags)=>{
            this.setFlags(flags);
        });

        this.useFeatureToggles();    
    }

    useFeatureToggles() {
        
        let sub = this.featureToggleService.featureFlagsSubject.pipe(takeUntil(this.unsubscribe)).subscribe((flags)=>{
            this.setFlags(flags);
            
        });
        this.featureToggleService.getFlags();
    }

    setFlags(flags) {
        this.showSummaryTables = flags.hasOwnProperty('summary-table') ? flags['summary-table'].value: false;
        this.showOtaCommands = flags.hasOwnProperty('ota-commands-certificates') ? flags['ota-commands-certificates'].value: false;
        this.show75FChatButton = flags.hasOwnProperty('show-75fchat-button') ? flags['show-75fchat-button'].value: false;
        this.showAIDashboard = flags.hasOwnProperty('show-ai-dashboard') ? flags['show-ai-dashboard'].value: false;
        this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value: false;
        this.showPointSummary = flags.hasOwnProperty('point-summary') ? flags['point-summary'].value : false;
        this.compareCcuVersion = flags.hasOwnProperty('ccu-version') ? flags['ccu-version'].value : '2.4.12';
        this.fileUpload = flags.hasOwnProperty('file-upload') ? flags['file-upload'].value : false;
        this.showQRCode = flags.hasOwnProperty('qr-share') ? flags['qr-share'].value : false;
        this.showCustomGraphics = flags.hasOwnProperty('custom-graphics') ? flags['custom-graphics'].value : false;
        this.reconfig = flags.hasOwnProperty('reconfiguration') ? flags['reconfiguration'].value : false;
        this.allowModbusRearrange = flags.hasOwnProperty('modbus-rearrange') ? flags['modbus-rearrange'].value : false;
    }

    clickMenu() {
        this.openMenu = !this.openMenu;
    }

    openPointSummaryList() {
        const dialogRef = this.dialog.open(PointSummaryListComponent, {
            panelClass: 'pointsummary-padding-dialog',
        });

        dialogRef.componentInstance.siteId = this.refs.siteRef;
        dialogRef.componentInstance.siteNameRef = this.siteName;
        dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
        });
    }

    openGraphicsList() {
        const dialogRef = this.dialog.open(CustomGraphicsListComponent, {
            panelClass: 'pointsummary-padding-dialog',
        });
        dialogRef.componentInstance.siteId = this.refs.siteRef;
        dialogRef.componentInstance.siteNameRef = this.siteName;
        dialogRef.componentInstance.graphicsFrom = 'global';
        dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
        });
    }

    handleSliderChanges() {
        if (this.coolingDesriedTempChangeHolder.length > 0 && this.heatingDesriedTempChangeHolder.length > 0) {
            let coolingChanged = false;
            let heatingChanged = false;
            let coolingVal = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (coolingVal.temp != this.desiredCoolingTempHolder) coolingChanged = true;

            let heatingVal = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (heatingVal.temp != this.desiredHeatingTempHolder) heatingChanged = true;

            if (heatingChanged && coolingChanged) {
                //bothchanged
                this.onChangeDesiredTemp([{
                    name: heatingVal.type,
                    value: heatingVal.temp
                }, {
                    name: coolingVal.type,
                    value: coolingVal.temp
                }])
            } else if (heatingChanged) {
                this.onChangeDesiredTemp([{
                    name: heatingVal.type,
                    value: heatingVal.temp
                }]);
            } else {
                this.onChangeDesiredTemp([{
                    name: coolingVal.type,
                    value: coolingVal.temp
                }]);
            }
        }
        else if (this.coolingDesriedTempChangeHolder.length > 0) {
            let val = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredCoolingTempHolder) return;
            this.onChangeDesiredTemp([{
                name: val.type,
                value: val.temp
            }])
        }
        else if (this.heatingDesriedTempChangeHolder.length > 0) {
            let val = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredHeatingTempHolder) return;
            this.onChangeDesiredTemp([{
                name: val.type,
                value: val.temp
            }])
        }
    }

    displayLabel(value) {
        switch (value) {
            case 5:
                return 'Full comfort'
            case 4:
                return 'Partial comfort '
            case 3:
                return 'Partial economy'
            case 2:
                return 'Full economy'
        }
    }

    multiModuleZonePriority() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].zonePriority
                && this.zoneSettings[profile.profile.name].zonePriority.hasOwnProperty('val')
                && ['vav', 'dab', 'ti', 'series', 'parallel','otn', 'bpos','chilledBeam'].includes(profile['profileType'])
                && ['vav', 'dab', 'ti', 'series', 'parallel', 'vrv','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'zonePriorityValue': this.zoneSettings[profile.profile.name].zonePriority.val }
                this.multiModuleZonePriorityList.set(name, data);
            }
        })
    }

    multiModuleZoneSchedule() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].scheduleType
                && this.zoneSettings[profile.profile.name].scheduleType.hasOwnProperty('val')
                && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti','otn', 'bpos', 'dualDuct', 'series', 'parallel','vrv','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'scheduleTypeValue': this.zoneSettings[profile.profile.name].scheduleType.val }
                this.multiModuleScheduleTypeList.set(name, data);
            }
        })
    }

    getMultiScheduleTypeVal() {
        if (this.profiles.length && this.zoneScheduleCount) {
            if ((this.zoneScheduleCount == this.multiModuleScheduleTypeList.size)) {
                const scheduleTypeValues = Array.from(this.multiModuleScheduleTypeList).map(([key, value]) => value);
                this.scheduleTypeVal = scheduleTypeValues
                    .reduce((max, p) => Number(p.scheduleTypeValue) > max ? Number(p.scheduleTypeValue) : max, Number(scheduleTypeValues[0].scheduleTypeValue)).toString();
            }
        }
    }

    getMultiZonePriorityVal() {
        if (this.profiles.length && this.zonePriorityCount) {
            if ((this.zonePriorityCount == this.multiModuleZonePriorityList.size)) {
                const zonePriorityValues = Array.from(this.multiModuleZonePriorityList).map(([key, value]) => value);
                this.zonePriorityVal = zonePriorityValues
                    .reduce((max, p) => Number(p.zonePriorityValue) > max ? Number(p.zonePriorityValue) : max, Number(zonePriorityValues[0].zonePriorityValue)).toString();
            }
        }
    }

    zoneUserIntentSettings() {
        if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
            if (this.zoneSettings && this.profileObj && this.profileObj.profile && this.profileObj.profile.name) {
                this.headerUserIntent = true;
                if (this.profileObj.profileType == 'emr') {
                    this.currentRateInput = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].currentRate : '';
                    this.energyReadingInput = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].energyReading : '';
                }
                if (this.profileObj.profileType == 'emr' || this.profileObj.profileType == 'pid') {
                    this.energyMeterStatus = this.zoneSettings[this.profileObj.profile.name] ? this.zoneSettings[this.profileObj.profile.name].equipStatusMessage : '';
                }

            }
            if (this.profileObj.profileType == 'ti') {
                this.isTIZone = true;
            }
            else {
                this.isTIZone = false;
            }
        }

        this.validateValuesAndUpdateSlider();
    }

 

    validateValuesAndUpdateSlider() {
        if (this.multiModuleProfile) {
            let dataReceived = false;
            let dataperprofile = 1;
            if (this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin.val
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax.val
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating.val
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling.val
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.val
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.val
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.val
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.val
            ) {
                if (this.multiModuleZoneStatus.length == this.profiles.length) {
                    this.profiles.forEach(profile => {
                        if (!(this.zoneSettings && this.zoneSettings[profile.profile.name]
                            && this.zoneSettings[profile.profile.name].currentTemp
                            && this.zoneSettings[profile.profile.name].currentTemp.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].heatingDeadband
                            && this.zoneSettings[profile.profile.name].heatingDeadband.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].coolingDeadband
                            && this.zoneSettings[profile.profile.name].coolingDeadband.hasOwnProperty('val')
                        )) {
                            dataperprofile = 0;
                        }
                        dataReceived = (dataperprofile == 0) ? false : true;

                        if (
                            (
                                this.zoneSettings?.[profile.profile.name]
                                && this.zoneSettings?.[profile.profile.name]?.currentTemp
                                && this.zoneSettings?.[profile.profile.name]?.heatingDeadband
                                && this.zoneSettings?.[profile.profile.name]?.coolingDeadband
                            )
                            &&
                            (
                                this.zoneSettings?.[profile.profile.name]?.currentTemp.error
                                || this.zoneSettings?.[profile.profile.name]?.heatingDeadband.error
                                || this.zoneSettings?.[profile.profile.name]?.coolingDeadband.error
                            )
                        ) {
                            this.displayRangeSlider = false;
                        }
                    });

                    if (dataReceived) {
                        this.calculateAvgCurrentTemp();
                        if (this.zoneSettings['coolingDeadband'] && this.zoneSettings['heatingDeadband']) {
                            this.findHighestDeadbands();
                        }

                        this.updateSliderInput();
                        this.displayRangeSlider = true;
                    }
                }

            }
            else {
                if (
                    (
                        this.zoneSettings?.buildingLimitMin
                        && this.zoneSettings?.buildingLimitMax
                        && this.zoneSettings?.desiredTempHeating
                        && this.zoneSettings?.desiredTempCooling
                        && this.zoneSettings?.zoneHeatingUserLimitMin
                        && this.zoneSettings?.zoneHeatingUserLimitMax
                        && this.zoneSettings?.zoneCoolingUserLimitMin
                        && this.zoneSettings?.zoneCoolingUserLimitMax
                    )
                    &&
                    (
                        this.zoneSettings?.buildingLimitMin?.error
                        || this.zoneSettings?.buildingLimitMax?.error
                        || this.zoneSettings?.desiredTempHeating?.error
                        || this.zoneSettings?.desiredTempCooling?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMax?.error)
                ) {
                    this.displayRangeSlider = false;
                }
            }

        }
        else {
            if (this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin?.val
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax?.val
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating?.val
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling?.val
                && this.zoneSettings?.currentTemp && this.zoneSettings?.currentTemp.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.val
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.val
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.val
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.val
                && this.zoneSettings?.heatingDeadband && this.zoneSettings?.heatingDeadband.hasOwnProperty('val')
                && this.zoneSettings?.coolingDeadband && this.zoneSettings?.coolingDeadband.hasOwnProperty('val')
            ) {

                this.updateSliderInput();
                this.displayRangeSlider = true;
            }
            else if (
                (   this.zoneSettings?.buildingLimitMin
                    && this.zoneSettings?.buildingLimitMax
                    && this.zoneSettings?.currentTemp
                    && this.zoneSettings?.desiredTempHeating
                    && this.zoneSettings?.desiredTempCooling
                    && this.zoneSettings?.zoneHeatingUserLimitMin
                    && this.zoneSettings?.zoneHeatingUserLimitMax
                    && this.zoneSettings?.zoneCoolingUserLimitMin
                    && this.zoneSettings?.zoneCoolingUserLimitMax
                    && this.zoneSettings?.heatingDeadband
                    && this.zoneSettings?.coolingDeadband
                )
                &&
                (
                    this.zoneSettings?.buildingLimitMin?.error
                    || this.zoneSettings?.buildingLimitMax?.error
                    || this.zoneSettings?.currentTemp?.error
                    || this.zoneSettings?.desiredTempHeating?.error
                    || this.zoneSettings?.desiredTempCooling?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMax?.error
                    || this.zoneSettings?.heatingDeadband?.error
                    || this.zoneSettings?.coolingDeadband?.error
                )
            ) {
                this.displayRangeSlider = false;
            }
        }
    }

    updateSliderInput() {
        /**Temperature Unit prefernce conversions**/
        let sliderValueFromZoneSettings: PucSliderInputOutputData = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        if(this.profiles[0]?.profileType == "sense" || this.profiles[0]?.profileType == "monitoring"){
            this.checkForHyperSense = true;
            sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings.currentTemp.val;
            sliderValueFromZoneSettings.desiredTempHeating = '0';
            sliderValueFromZoneSettings.desiredTempCooling = '0';
            sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings.zoneHeatingUserLimitMin.val;
            sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings.zoneHeatingUserLimitMax.val;
            sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings.zoneCoolingUserLimitMin.val;
            sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings.zoneCoolingUserLimitMax.val;
            sliderValueFromZoneSettings.heatingDeadband = '2'
            sliderValueFromZoneSettings.coolingDeadband = '2'
            this.tempConversion()
        } else {
            /**Temperature Unit prefernce conversions**/
            this.celciusConversion(sliderValueFromZoneSettings)
        }

        if ( this.sliderInput ) {
            this.updateSlider(sliderValueFromZoneSettings);
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
        }
        this.schSliderInputData = { ...this.sliderInput };
        this.schSliderInputData.desiredTempCooling = this.schSliderInputData.coolingUserLimitMin;
        this.defaultLimits.desiredTempCooling = this.schSliderInputData.coolingUserLimitMin;
        if(this.schSliderInputData?.heatingUserLimitMax > this.schSliderInputData?.heatingUserLimitMin ) {
            this.schSliderInputData.desiredTempHeating  = this.schSliderInputData?.heatingUserLimitMax;
            this.defaultLimits.desiredTempHeating   = this.sliderInput?.heatingUserLimitMax;
        } else {
            this.schSliderInputData.desiredTempHeating  = this.schSliderInputData?.heatingUserLimitMin;
            this.defaultLimits.desiredTempHeating   = this.sliderInput?.heatingUserLimitMin;
        }
    }

    schedulerEventEmit() {
        this.helperService.getPointData()
        .subscribe(res => {
            this.zoneSettings.heatingDeadband.val = res.heatingDeadband.val
            this.zoneSettings.coolingDeadband.val = res.coolingDeadband.val
        }) 
        this.schedulerUnitCheck = this.unitService.unitTemperaturePreferenceData()
        this.tempConversion() 
    }

    scheduleUnitCheck() {
        if(this.schedulerUnitCheck  == this.unitService.unitTemperaturePreferenceData()){
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference;
        } else if(this.schedulerUnitCheck !== this.unitService.unitTemperaturePreferenceData()) {
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference
        }
    }

    tempConversion() {
        this.scheduleUnitCheck()
        if(this.profiles[0]?.profileType == "sense" || this.profiles[0]?.profileType == "monitoring") {
            this.userTempPreference = this.unitService.unitTemperaturePreferenceData()
        }
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) { 
            this.buildingLimit.min =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMin.val,'buildingLimitMin')
            this.buildingLimit.max =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMax.val,'buildingLimitMin')
        } else {
            this.buildingLimit.min = this.zoneSettings.buildingLimitMin.val;
            this.buildingLimit.max = this.zoneSettings.buildingLimitMax.val;
        }
    }
    updateSlider(sliderValueFromZoneSettings) {
        if (this.sliderUserIntentService.isEquivalent(this.sliderInput, sliderValueFromZoneSettings)) {
            let dataforuserIntentslider;

            let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.refs.ccuName);
            if (datalastupdatedby)
                dataforuserIntentslider = this.sliderUserIntentService.getData(datalastupdatedby, this.refs.ccuName);
            if (dataforuserIntentslider) {
                if (!this.sliderUserIntentService.isEquivalent(this.sliderInput, dataforuserIntentslider)) {
                    this.sliderUserIntentService.setData(dataforuserIntentslider, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
                    this.sliderInput = dataforuserIntentslider;
                }
            }
            else {
                this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
                this.sliderInput = sliderValueFromZoneSettings;
            }
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.refs.ccuName);
        }
    }

    
      mystatProfile(_item, _index) {
        let keys = ['relay1Association', 'relay2Association', 'relay3Association', 'relay4Association']
        let relayKeysStatus = ['relay1', 'relay2', 'relay3', 'relay4']
        if (this.profiles && this.profiles.length) {
            if (_item['profileType'] == 'pipe2') {
              let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
              let relayVal;
              let relayEnabled
              for (let i = 0; i < keys.length; i++) {
                let profileTypeCheck = _item['profileType']
                const relayKey = profileTypeCheck == _item['profileType'] ? keys[i] : '';
                const relayKeyStatus = profileTypeCheck == _item['profileType'] ? relayKeysStatus[i] : '';
                if (this.profiles.length > 1) {
                  relayVal = this.setRelaypropertiesForMystat(profileName, relayKey);
                  relayEnabled = this.setRelaypropertiesForMystat(profileName, relayKeyStatus);
                } else {
                  relayVal = this.relaypropertForEmptyProfiles(relayKey);
                  relayEnabled = this.relaypropertForEmptyProfiles(relayKeyStatus);
                }
                this.humidiferDehumidifier(_item, relayKey, relayVal, relayEnabled, _index);
              }
            }
        }
      }
    
      setRelaypropertiesForMystat(profileName, relayKey) {
        let relay
        if (this.profiles.length > 1) {
          if (this.zoneSettings && profileName && this.zoneSettings[profileName]) {
            if (this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val'] != undefined) {
              relay = this.zoneSettings[profileName][relayKey]['val'];
            }
          } else {
            relay = ''
          }
        }
        return relay;
      }
    
      humidiferDehumidifier(_item, relayKey, relayVal, relayEnabled, _index) {
        if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
          _item[relayKey] = relayVal;
          if (_item['profileType'] == 'pipe2' && relayVal == 6 && relayEnabled == 1 ) {
            _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidifier'], _item['tags'], 1);
            this.profiles[_index] = Object.assign({}, _item);
          }
          if (_item['profileType'] == 'pipe2' && relayEnabled == 1 && relayVal == 7 ) {
            _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
            this.profiles[_index] = Object.assign({}, _item);
          }
        }
      }

    /**Temperature Unit prefernce conversions**/
    celciusConversion(sliderValueFromZoneSettings :any) {
        this.tempConversion()
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                sliderValueFromZoneSettings.desiredTempHeating = this.zoneSettings?.desiredTempHeating?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString(): this.zoneSettings?.desiredTempHeating?.val;
                sliderValueFromZoneSettings.desiredTempCooling = this.zoneSettings?.desiredTempCooling?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString(): this.zoneSettings?.desiredTempCooling?.val;
                sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings?.zoneHeatingUserLimitMin?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMin?.val,'heatingUserLimitMin')).toString(): this.zoneSettings?.zoneHeatingUserLimitMin?.val;
                sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings?.zoneHeatingUserLimitMax?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMax?.val,'heatingUserLimitMax')).toString(): this.zoneSettings?.zoneHeatingUserLimitMax?.val;
                sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings?.zoneCoolingUserLimitMin?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMin?.val,'coolingUserLimitMin')).toString(): this.zoneSettings?.zoneCoolingUserLimitMin?.val;
                sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings?.zoneCoolingUserLimitMax?.val ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMax?.val,'coolingUserLimitMax')).toString(): this.zoneSettings?.zoneCoolingUserLimitMax?.val;
                sliderValueFromZoneSettings.heatingDeadband =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings?.heatingDeadband?.val) 
                sliderValueFromZoneSettings.coolingDeadband =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings?.coolingDeadband?.val);
                this.zoneSettings.heatingDeadband.val = Number(sliderValueFromZoneSettings.heatingDeadband);
                this.zoneSettings.coolingDeadband.val = Number(sliderValueFromZoneSettings.coolingDeadband);   
        } else {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                this.setSliderBasedOnSingleDual(sliderValueFromZoneSettings, false)
                sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings?.zoneHeatingUserLimitMin?.val;
                sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings?.zoneHeatingUserLimitMax?.val;
                sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings?.zoneCoolingUserLimitMin?.val;
                sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings?.zoneCoolingUserLimitMax?.val; 
                sliderValueFromZoneSettings.heatingDeadband = this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings.heatingDeadband.val;
                sliderValueFromZoneSettings.coolingDeadband = this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings.coolingDeadband.val;
                this.zoneSettings.heatingDeadband.val = Number(sliderValueFromZoneSettings.heatingDeadband);
                this.zoneSettings.coolingDeadband.val = Number(sliderValueFromZoneSettings.coolingDeadband);   
        }   
    }

    setSliderBasedOnSingleDual(sliderValueFromZoneSettings, prefUnit) {

        sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
        sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;

        if (this.zoneSettings['singleDual']?.val == 0) {
            sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
            sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;
        } else if (this.zoneSettings['singleDual']?.val == 2) {
            sliderValueFromZoneSettings.desiredTempHeating = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val, 'desiredTempHeating')).toString() : this.zoneSettings?.desiredTempHeating?.val;
            sliderValueFromZoneSettings.desiredTempCooling = '0';
        } else if (this.zoneSettings['singleDual']?.val == 1) {
            sliderValueFromZoneSettings.desiredTempHeating = '0';
            sliderValueFromZoneSettings.desiredTempCooling = prefUnit ? (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val, 'desiredTempCooling')).toString() : this.zoneSettings?.desiredTempCooling?.val;
        }

        return sliderValueFromZoneSettings;
    }

    isDataLoaded(data: any, equipStatus: boolean = false) {
        return this.helperService.isDataLoaded(data, equipStatus);
    }

    callZoneEvent(event, floorName) {

        let parentZone: HTMLElement = event.target.parentElement as HTMLElement;

        while (!parentZone.className.includes('box')) {
            parentZone = parentZone.parentElement as HTMLElement;
        }

        if (event.type == "click") {
            parentZone.click();
        }

    }

    handleHumidityDehumidity() {
        const deviceType = this.profiles.map(profile => 
            profile?.profile?.markers?.find(deviceType => deviceType === 'mystat' || deviceType === 'hyperstat' || deviceType === 'hyperstatsplit')
        )
        if (deviceType?.length || this.multiModuleProfile) {
            if (this.profiles?.length) {
                this.profiles.forEach((_item, _index) => {
                        let deviceName = deviceType[_index];
                        let isMystat = deviceName === 'mystat';
                        let keys = ['relay1Association', 'relay2Association', 'relay3Association', 'relay4Association', 'relay5Association', 'relay6Association']
                        let relayKeysStatus = ['relay1', 'relay2', 'relay3', 'relay4', 'relay5', 'relay6']
                        //Note As of now for only hyperstat split has relay 7 and relay 8 for the other hyperstat profiles
                        // with the old builds we have up to relay6 in futture aprt form hyperstat  split also we will get relay8 
                        if (deviceName == 'hyperstatsplit') {
                            keys = [...keys, 'relay7Association', 'relay8Association']
                            relayKeysStatus = [...relayKeysStatus, 'relay7', 'relay8']
                        }

                    if(isMystat) {
                        this.mystatProfile(_item, _index);
                    }
                    if ((_item['profileType'] == 'cpu' || _item['profileType'] == 'cpuecon' || _item['profileType'] == 'hpu' || _item['profileType'] == 'pipe2') && !isMystat) {
                        let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
                        let relayVal;
                        let relayEnabled
                        for (let i = 0; i < keys.length; i++) {
                            let profileTypeCheck = _item['profileType']
                            const relayKey = profileTypeCheck == _item['profileType'] ? keys[i] : '';
                            const relayKeyStatus = profileTypeCheck == _item['profileType'] ? relayKeysStatus[i] : '';
                            if (this.profiles.length > 1) {
                                relayVal = this.setRelayproperties(profileName, relayKey);
                                relayEnabled = this.setRelayproperties(profileName, relayKeyStatus);
                            } else {
                                relayVal = this.relaypropertForEmptyProfiles(relayKey);
                                relayEnabled = this.relaypropertForEmptyProfiles(relayKeyStatus);
                            }
                            if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                                _item[relayKey] = relayVal;

                                if (_item['profileType'] == 'pipe2' && relayVal == 8 && relayEnabled == 1 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 11 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 10) {
                                    _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidifier'], _item['tags'], 1);
                                    this.profiles[_index] = Object.assign({}, _item);
                                }


                                if (_item['profileType'] == 'pipe2' && relayEnabled == 1 && relayVal == 9 || _item['profileType'] == 'cpu' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'cpuecon' && relayEnabled == 1 && relayVal == 12 || _item['profileType'] == 'hpu' && relayEnabled == 1 && relayVal == 11) {
                                    _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                    this.profiles[_index] = Object.assign({}, _item);
                                }

                            }
                        }
                    }
                });
            }
        } else {

            if (this.profiles?.length) {
                this.profiles.forEach((_item, _index) => {
                    if (_item['profileType'] == 'cpu' || _item['profileType'] == 'hpu') {
                        let relayKey = _item['profileType'] == 'cpu' ? 'relay6Type' : 'relay5Type';
                        let profileName =  _item['profile']?.['name'] ? _item['profile']['name'] : '';
                        let relayVal;
                        if (this.profiles.length > 1) {
                            relayVal = (this.zoneSettings && profileName && this.zoneSettings[profileName]) ?
                                ((this.zoneSettings[profileName]?.[relayKey]?.['val']) ?
                                    (this.zoneSettings[profileName][relayKey]['val'] || '') : '') : '';
                        } else {
                            relayVal = (this.zoneSettings) ?
                                ((this.zoneSettings[relayKey]?.['val']) ?
                                    (this.zoneSettings[relayKey]['val'] || '') : '') : '';
                        }
                        if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                            _item[relayKey] = relayVal;
                            if (relayVal == '2') { // Adding targetHumidity
                                _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidity'], _item['tags'], 1);
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else if (relayVal == '3') { // Adding targetDehumidity
                                _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else {
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            }
                        }
                    }
                });
            }
        }

    }

    setRelayproperties(profileName, relayKey) {
        let relay
        if (this.profiles.length > 1) {
            if (this.zoneSettings && profileName && this.zoneSettings[profileName]) {

                if (this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val']) {
                    relay = this.zoneSettings[profileName][relayKey]['val'] || '';
                }
            } else {
                relay = ''
            }
        }
        return relay;

    }

    relaypropertForEmptyProfiles(relayKey) {
        let relay;
        if (this.zoneSettings) {
            if (this.zoneSettings[relayKey] && this.zoneSettings[relayKey]['val']) {
                relay = this.zoneSettings[relayKey]['val'] || '';
            } else {
                relay = ''
            }
        } else {
            relay = ''
        }

        return relay;
    }

    addClickEvent(event) {
        var $this = this;
        this.checkForEventType = event.type
        if (event.type === 'click') {
            this.zoneHovercheckFlag = true;
            this.zoneClicked(event);
        }
        else if (event.type === 'hover' && event.type !== 'click') {
            this.zoneHovercheckFlag = false;
            this.clearHoverTimer();
            $this.hoverEnterTimer = setTimeout(function() {
				$this.zoneMouseHover(event);
			}, 1000);
        }
        else if (event.type === 'leave') {
            this.clearHoverTimer();
            this.zoneMouseLeave(event);
        }
        this.isFloorPlanLoaded = true;
        this.loaderService.active(false);
     }

     modbusProfileSort() {
        //The below conditional checks is applicable for only modbus profiles.
       //Based on port number child and parent equips are classified.
       let portNumber: number;
       this.profiles.forEach(x => {
           this.isModbus = x.profileType == "modbus";
           if (!this.isModbus) {
               x['profileName'] = x.profile.name;
               return;
           }
            x['profileName'] = `${x.profile.name}_${x.profile._id}`;
           if ((!x.profile?.referenceIDs?.hasOwnProperty('equip')) && this.isModbus) {
               portNumber = x.portNum;;
               x['isChild'] = false;
           }

           if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum == portNumber)) {
               x['isChild'] = true;
           } else if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum != portNumber)) {
               x['isChild'] = false;
           }
       })
         this.profiles.sort((a, b)=>{
             if (!a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
                 return 0;
               }
               if (a.profile.referenceIDs.equip && !b.profile.referenceIDs.equip) {
                 return 1;
               }
               if (!a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
                 return -1;
               }
               if (a.profile.referenceIDs.equip && b.profile.referenceIDs.equip) {
                 return 1;
               }
            return 0;
         })
     }


     async zoneClicked(event, fromSummaryTable = false){
        this.refs.zoneName = event.zone.dis;
        this.notesList = [];
        this.analyticsLayoutService.exportAnalyticsExcelData = [];
        this.analyticsLayoutService.totalAnalyticsCount = 0;
        this.analyticsLayoutService.isAnalyticsListLoading = false;
        this.isDownloadExcelPending = false;
        this.getNotesList(this.helperService.stripHaystackTypeMapping(event.zone.id),'zone')
        let modalLeftPos: number = 120;
        this.showNotesAccordion = false;
        this.modalPopupOpened = true;
        //Clean up the hovered modal
        this.closeHoverModel();
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;
        this.dataForExport = {};
        this.checkWidgestLoaded = {};
        this.zonePriorityVal = undefined;
        this.oldZonePriorityVal = undefined;
        this.scheduleTypeVal = undefined;
        this.oldscheduleTypeVal = undefined;
        this.checkHyperStatProfile = undefined;
        this.zoneScheduleCount = 0;
        this.refs.roomRef = "";
        this.refs.equipRef = '';
        this.zoneSettings = null;
        this.tempZoneSettingsHolder = null;
        this.ccuOrZone = { type: 'zone', actionUsed: 'click' };
        this.hoverZoneData = event['zoneData'];
        this.cancelChanges = [];
        this.zonePriorityCount = 0;
        this.headerUserIntent = false;
        this.intializeDate();
        this.activeTile = event.zone.dis;
        const ccu = this.buildings.ccus.find(c=>c._id == event.ccuId);
        this.refs.ccuName = event['zoneData']?.ccuName;
        if(ccu) {
            this.refs.ccuEquipRef = ccu.referenceIDs.equip
        }
        this.refs.ccuRef = event.ccuId;
        let ccuObj = this.setCCUData({ccuId: event.ccuId});
        this.refs.gateway = ccuObj.gateway;
        this.el.nativeElement.querySelector(".arrow")?.classList.add("hide");
        this.messageService.scrollIndex = window.scrollY;
        (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'fixed';
        
        this.modalStyleClickedFlag = true;
        this.modelStyleOnClicked = {
            top: 45 + 'px',
            left: modalLeftPos + 'px',
            height: 'auto',
            width: '82%',
            display: 'block'
        };
        if (fromSummaryTable) {
            this.modelStyleOnClicked.top = '45px';
        }
        this.hideSummaryTableSection = true;


        try {
            let zoneid = this.helperService.stripHaystackTypeMapping(event.zone.id)
            let query = `siteRef==@${this.refs.siteRef} and (${this.refs.ccuRef ? 'id==@' +zoneid+ ' or ccuRef==@' + this.refs.ccuRef : 'id==@' +zoneid+ ' or roomRef==@'+ zoneid})`;
            this.widgetData = await lastValueFrom(this.siteService.findByQuery(query).pipe(
                map(this.helperService.stripHaystackTypeMapping),
                map(this.helperService.listEntities),
                map(this.helperService.listTuners),
                map(this.helperService.createEntityStructure)));
        } catch(error) {  
            this.widgetData = [];
            console.log('Error in fetching ccu data', error);
        }

        this.getEquipRef(this.helperService.stripHaystackTypeMapping(event.zone.id));
        this.profiles = this.deviceHelper.getZoneProfile([this.roomObj]);
        this.zoneWidgetDataLoaded = true;
        if(this.fileUpload) {
            if(this.profiles?.length > 0 ) {
                const observables = this.profiles.map(profile => {
                    return this.notesService.getTaggedFiles(this.refs.siteRef, profile.profile._id);
                });
            
                forkJoin(observables).subscribe(
                    (responses: any[]) => {
                        responses.forEach((res, index) => {
                            const profile = this.profiles[index];
                            if(profile) {
                                profile['taggedFiles'] = res;
                                profile['enableViewAssets'] = res && res.length > 0;
                            }
                            
                        });
                    },
                    (error) => {
                        console.log('Error fetching tagged files for profiles', error);
                    }
                );
            }
        }
        if(this.profiles.length == 0 ) { 
            this.enableDownLoad = true;
        }
        this.modbusProfileSort();
        this.multiModuleProfile = (this.profiles.length > 1);
        if (this.multiModuleProfile) {
            this.fetchequipstatus();
            const profiles = Array.from(this.profiles)
            profiles.forEach((_item) => {
                if (_item && ['vav', 'dab', 'ti','otn', 'bpos', 'series', 'parallel','vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zonePriorityCount++;
                }
                if (_item && ['vav', 'dab', 'ti', 'series', 'parallel', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zonePriorityCount++;
                }
                if (_item && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'dualDuct', 'series', 'parallel', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                    this.zoneScheduleCount++;
                }
            })
        }
        this.checkProfileFlag();
        this.getProfileData(this.profiles);
        this.getUserSettingData("zoneClick");
        this.loadZoneTuners(this.roomObj);
        setTimeout(() => {
            window.scroll(0,0);
        },50);
         //start the setInterval call to call the api to update the data change if any every 5 sec
        this.pollingFunction();
        this.pollGraphData();
    }

    //Clean up the hovered modal while opening click model
    closeHoverModel(){
        if (this.ccuOrZone.actionUsed == "hover" && this.ccuOrZone.type == "zone") {
            this.modelStyle = {
                display: 'none'
            };
            this.closeModal();
        }
    }

    checkProfileFlag(){
        if (this.profiles[(this.profiles).length - 1]) {
            this.profileCheckFlag = ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti','otn', 'bpos', 'dualDuct', 'series', 'parallel','vrv','chilledBeam'].includes(this.profiles[(this.profiles).length - 1].profileType);
        }
    }

    async zoneMouseHover(event){
        this.refs.zoneName = event.zone.dis;
        if (event.zone.dis === this.activeTile) {
            return;
        }
        this.messageService.getMessage().subscribe(message => {
            if (message.isExpanded !== undefined) {
                this.sideMenu = message.isExpanded
            }
        });

        let isSideOpened = document.getElementsByClassName('fs-sidebar ng-star-inserted expand');

        if (isSideOpened.length > 0) {
            this.sideMenu = true;
        } else {
            this.sideMenu = false;
        }
        //clean up previous hovered zone
        this.closeModal();

      if(this.fileUpload) {
        if(this.profiles?.length > 0 ) {
            const observables = this.profiles.map(profile => {
                return this.notesService.getTaggedFiles(this.refs.siteRef, profile.profile._id);
            });
        
            forkJoin(observables).subscribe(
                (responses: any[]) => {
                    responses.forEach((res, index) => {
                        const profile = this.profiles[index];
                        profile['taggedFiles'] = res;
                        profile['enableViewAssets'] = res && res.length > 0;
                    });
                },
                (error) => {
                    console.log('Error fetching tagged files for profiles', error);
                }
            );
        }
        }
        if (!event?.ccuId) {
            // If event.ccuId is empty, use event.zone.ccuRef as we need to highlight the ccu if zone is not paired
            this.highlightCCU = this.helperService.stripHaystackTypeMapping(event?.zone?.ccuRef)
        } else {
            // highlight the ccu on ccu section if zone is paired
            this.highlightCCU = event?.ccuId;
        }
        const ccu = this.buildings.ccus.find(c=>c._id == event.ccuId);
        if(ccu) {
            this.refs.ccuEquipRef = ccu.referenceIDs.equip
        }
        this.refs.ccuRef = event.ccuId;

        this.ccuOrZone = { type: 'zone', actionUsed: 'hover' };
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;

        this.modalStyleClickedFlag = false;
        this.siteNameModalStyleClickedFlag = false;
        this.styleMouseHoverModel(event);
        this.activeTile = event.zone.dis;
        this.zoneId = event.zone.zoneId;
        this.shapeId = event.id;
        this.hoverZoneData  = event['zoneData'];
        this.refs.ccuName = event['zoneData']?.ccuName;
        this.intializeDate();
        if(this.fileUpload) {
            if(this.profiles?.length > 0 ) {
                const observables = this.profiles.map(profile => {
                    return this.notesService.getTaggedFiles(this.refs.siteRef, profile.profile._id);
                });
            
                forkJoin(observables).subscribe(
                    (responses: any[]) => {
                        responses.forEach((res, index) => {
                            const profile = this.profiles[index];
                            if(profile) {
                                profile['taggedFiles'] = res;
                                profile['enableViewAssets'] = res && res.length > 0;
                            }
                        });
                    },
                    (error) => {
                        console.log('Error fetching tagged files for profiles', error);
                    }
                );
            }
        }
       
        try {
            let zoneid = this.helperService.stripHaystackTypeMapping(event.zone.id);
            let query = `siteRef==@${this.refs.siteRef} and (${this.refs.ccuRef ? 'id==@' +zoneid+ ' or ccuRef==@' + this.refs.ccuRef : 'id==@' +zoneid+ ' or roomRef==@'+ zoneid})`;
            this.widgetData = await lastValueFrom(this.siteService.findByQuery(query).pipe(
                map(this.helperService.stripHaystackTypeMapping),
                map(this.helperService.listEntities),
                map(this.helperService.listTuners),
                map(this.helperService.createEntityStructure)));
        } catch(error) {  
            this.widgetData = [];
            console.log('Error in fetching ccu data', error);
        }
         
      
        this.getEquipRef(this.helperService.stripHaystackTypeMapping(event.zone.id));
        this.profiles = this.deviceHelper.getZoneProfile([this.roomObj]);
        this.modbusProfileSort();
        this.zoneWidgetDataLoaded = true;
        this.multiModuleProfile = (this.profiles.length > 1);
        this.getProfileData(this.profiles);
        this.getUserSettingData("zoneClick");
        setTimeout(() => {
            this.overlayHeight = this.getOverlayHeight();
        }, 20);
    }
    
    getEquipGraphicPoints() {
        let equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        let OAOObj = this.widgetData.find(et=> et._id == this.refs.oaoRef)
        const btuObj = this.widgetData.find(equip => ['system','btu', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
    
        /**EquipGraphics**/
        //Note : Here we are checking if the equipGraphicSystemProfile is not for AdvancedAhu
        if(this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) === -1) {
            systemprofileEquipGraphics.forEach((_profileObj: any) => {
                if(_profileObj.key == 'dcwbEnabled') {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, _profileObj?.filterTags,_profileObj?.domainName), _profileObj.action);
                }else {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null,null, _profileObj?.domainName), _profileObj.action);
                }
            });
        }
        //Note: Here we are keeping the seperate method for VAV Advanced AHU there are lot of thing which may be included in future 
        // And also this profile is only deals with DM integatsion so it will be handled seperatley with out comnbining with other profiles 
        //Cooling stages and heating stages differ based on load and sat 
        if(this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1) {
            this.getAdvancedHybridAhuV2Points(equipObj);
        }

        if(btuObj) {
            this.getZoneSettings('Outlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['outlet','temp','system','displayInUi']), 'read');
            this.getZoneSettings('Inlet Water Temperature', this.helperService.getPointIdbyTags(btuObj, ['inlet','system','temp','displayInUi']), 'read');
            this.getZoneSettings('Flow Rate', this.helperService.getPointIdbyTags(btuObj, ['system','point','btu','actual','flow','logical','displayInUi']), 'read');
            }
            this.getZoneSettings('chilled Water Valve',this.helperService.getPointIdbyTags(equipObj,['chilled','water','valve']),'read')
    
        if(OAOObj) {
            this.getZoneSettings('epidemic mode', this.helperService.getPointIdbyTags(equipObj, ['epidemic','mode']), 'read');
        }
    }
    
    getAdvancedHybridAhuV2Points(equipObj) {
        //vavAdvancedAhuV2EquipGraphics object are defined in the puc component from there were fetching requipred points
        vavDabAdvancedAhuV2EquipGraphics.forEach((_profileObj: any) => {
            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(equipObj, _profileObj?.tags, null, null, _profileObj?.domainName), _profileObj.action);
        });
    }
    
    styleMouseHoverModel(event){
        let x = document.getElementById(event.id + '-center')? document.getElementById(event.id + '-center').getBoundingClientRect() : document.getElementById(event.id).getBoundingClientRect();
        let topPos = document.getElementById('heatMapHeader').getBoundingClientRect().height + 51;
        let modalLeftPos: number = this.sideMenu ? x.right - 35 - 141 : x.right - 35;
        let modalRightPos: number = this.sideMenu ? ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 15 : ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 10;
        if (((window.innerWidth || document.documentElement.clientWidth) - x.right) > ((window.innerWidth || document.documentElement.clientWidth) * 0.42)) {
            this.modelStyle = {
                top:  topPos + window.scrollY + 'px',
                left: modalLeftPos - x.width / 1.6 + 10 + 'px',
                height: window.screen.availHeight,
                width: 42 + '%',
                display: 'block'
            };
        } else {
            this.modelStyle = {
                top:  topPos + window.scrollY + 'px',
                right: modalRightPos - x.width / 1.6 + (this.sideMenu ? 90 : 30) + 'px',
                height: window.screen.availHeight,
                width: 42 + '%',
                display: 'block'
            };
        }
    }

    zoneMouseLeave(event){
        if (this.active) {
            clearTimeout(this.active);
        }
        if (this.ccuOrZone.type == "zone" && this.zoneId == event.zone.zoneId && document.getElementById(event.zone.zoneId) && document.getElementById(event.zone.zoneId).matches(':hover')) {
            return;
        }
        
        this.active = setTimeout(() => {
            if (event.zone.dis === this.activeTile && this.ccuOrZone.actionUsed != 'click') {
                this.modelStyle = {
                    display: 'none'
                };
                this.closeModal();
            }
        }, 50);
    }

    onPopEvent(e) {
        if (e.type === 'mouseenter') {
            if(this.shapeId)
            d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 5);
            if (this.active) {
                clearTimeout(this.active);
            }
        } else if (e.type === 'mouseleave') {
            if (this.ccuOrZone.actionUsed == "hover") {
                if (this.ccuOrZone.type == "zone" && this.zoneId == e.target.id && document.getElementById(e.target.id) && document.getElementById(e.target.id).matches(':hover')) {
                    return;
                }
                if (this.ccuOrZone.type == "ccu" && this.refs.ahuRef == e.target.id && document.getElementById(e.target.id) && document.getElementById(e.target.id).matches(':hover')) {
                    return;
                }
                this.modelStyle = {
                    display: 'none'
                };

                this.siteNameModalStyle = {
                    display: 'none'

                }
                this.closeModal();
                if(this.shapeId) {
                    d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 2);
                }
            }
        }
    }

    ClickedOut(event): void {
         this.checkForEventType = event.type;
         this.showNotesAccordion = true;
         this.modalPopupOpened = false;
         this.hideSummaryTableSection = false;
         this.cdr.detectChanges();
        if (!((<Element>event.target).className == 'fs-heatmap-wrapper__content__box ng-star-inserted')) {
            this.modelStyleOnClicked = {
                display: 'none'
            }
            this.siteNameModalStyle = {
                display: 'none'
            }
            if(this.shapeId) {
                d3?.select(`#${this.shapeId}`)?.select(`.shapeArea`)?.style('stroke-width', 2);
            }
            if(this.checkForEventType == "click") {
                (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'relative';
                document.documentElement.scrollTop = this.messageService.scrollIndex;
            }
            this.closeModal();
        }

        this.siteNameModalStyleOnClicked = {
            display: 'none'
        }

        this.siteNameModalStyleClickedFlag = false;
        this.modalStyleClickedFlag = false;
        this.checkZoneDeadHisPointSubscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.checkZoneDeadSubscription != undefined) {
            this.checkZoneDeadSubscription.unsubscribe();
        }
        if (this.checkIfOAOIsPairedSubscription != undefined) {
            this.checkIfOAOIsPairedSubscription.unsubscribe();
        }
    }

    getProfileData(profileObj: any) {
        profileObj.map(profile => {
            if (profile) {
                this.loadProfileTags(profile.profileType, profile.profile, profile.tags, profile.portNum, profile.modBusEquip);
            }
        });
        //Check if its multi module , and it doenst have any runtime ,fire data fetch from here
        if (this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null && !this.multiModuleProfileHasHPU && !this.multiModuleProfileHasCPU) {
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
        }
    }

    getHPUConfigPoints(profile: any): Observable<any[]> {
        let airFlowEnable = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['th1', 'enable'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));
        let heatPumpchangeovertype = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['zone', 'hpu', 'changeover', 'type'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));
        let hpuRelay5Config = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay5', 'type', 'config'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));

        return forkJoin([airFlowEnable, heatPumpchangeovertype, hpuRelay5Config])
    }

    getCPUConfigPoints(profile: any): Observable<any[]> {
        let cpuRelay6Config = this.siteService.getWritablePointData(this.helperService.getPointIdbyTags(profile, ['relay6', 'type', 'config'], null)).pipe(
            map(this.helperService.stripHaystackTypeMapping));

        return forkJoin([cpuRelay6Config])
    }

    checkIfOAOIsPaired(ahuRef) {
		this.siteService.oaoPairedCheck(ahuRef,'(oao and equip and not zone)').subscribe(res => {
			if (res?.rows?.length > 0) {
				this.OaoShow = true;
				let systemPoints;
                this.connectModuleOaoIspaired = false
				let oaoRef = this.helperService.stripHaystackTypeMapping(res.rows[0].id);
                if ((this.checkForVavDabSystemExternalProfiles.indexOf(this.systemReconfigProfileName) > -1) ||
                    (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) ||
                    (res?.rows[0]?.hasOwnProperty('domainName'))) {
                    systemPoints = [
                        {
                            pointName: 'oaoLastUpdated',
                            query: `(equipRef==@${oaoRef})`,
                            type: 'read',
                            tags: ['heartbeat', 'oao'],
                            domainName: 'heartBeat'
                        },
                        {
                            pointName: 'oao',
                            query: `(equipRef==@${oaoRef.split(' ')[0]})`,
                            type: 'read',
                            tags: ['return', 'air', 'co2', 'sensor'],
                            domainName: 'returnAirCo2'
                        },
                        {
                            pointName: 'smartPrepurge',
                            type: 'write',
                            domainName: 'systemPrePurgeEnable'
                        },
                        {
                            pointName: 'smartPostpurge',
                            type: 'write',
                            domainName: 'systemPostPurgeEnable'
                        },
                        {
                            pointName: 'enhancedVentilation',
                            type: 'write',
                            domainName: 'systemEnhancedVentilationEnable'
                        }
                    ];
                } else {
                    systemPoints = [
                        {
                            pointName: 'oaoLastUpdated',
                            query: `(equipRef==@${oaoRef})`,
                            type: 'read',
                            tags: ['heartbeat']
                        },
                        {
                            pointName: 'oao',
                            query: `(equipRef==@${oaoRef.split(' ')[0]})`,
                            type: 'read',
                            tags: ['return', 'air', 'co2', 'sensor']
                        },
                        {
                            pointName: 'smartPrepurge',
                            type: 'write',
                            tags: ['system', 'userIntent', 'prePurge', 'enabled'],

                        },
                        {
                            pointName: 'smartPostpurge',
                            type: 'write',
                            tags: ['system', 'userIntent', 'postPurge', 'enabled'],

                        },
                        {
                            pointName: 'enhancedVentilation',
                            type: 'write',
                            tags: ['system', 'userIntent', 'enhanced', 'ventilation'],

                        }
                    ];
                }

                this.systemPoints = this.systemPoints.concat(systemPoints);
            }
            else {
                this.OaoShow = false;
            }
            this.getCCUSettingData();
        }, err => {
            this.getCCUSettingData();
        });
    }

    async checkIfConnectModuleIsPaired() {
        let systemPoints = [];

        if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1) {
            if(this.refs.vavDabConnectModule) {
                this.connectModulePaired = true;
            }
            const query = `equipRef == @${this.refs.vavDabConnectModule} and (domainName==@oaoDamper)`;
            const connectModuleOaoIspaired = await firstValueFrom(this.siteService.findByQuery(query));
            // If connectModuleOAOCheck is true, add OAO points
            if (connectModuleOaoIspaired?.rows?.length) {
                systemPoints = systemPoints.concat([
                    {
                        pointName: 'oaoLastUpdated',
                        query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                        type: 'read',
                        tags: ['heartbeat', 'oao'],
                        domainName: 'heartBeat'
                    },
                    {
                        pointName: 'oao',
                        query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                        type: 'read',
                        tags: ['return', 'air', 'co2', 'sensor'],
                        domainName: 'returnAirCo2'
                    },
                    {
                        pointName: 'smartPrepurge',
                        query: `(ccuRef==@${this.ccuId})`,
                        type: 'write',
                        domainName: 'systemPrePurgeEnable'
                    },
                    {
                        pointName: 'smartPostpurge',
                        query: `(ccuRef==@${this.ccuId})`,
                        type: 'write',
                        domainName: 'systemPostPurgeEnable'
                    },
                    {
                        pointName: 'enhancedVentilation',
                        query: `(ccuRef==@${this.ccuId})`,
                        type: 'write',
                        domainName: 'systemEnhancedVentilationEnable'
                    }
                ]);
                this.OaoShow = true;
            }

            // Always add the connect module points
            systemPoints = systemPoints.concat([
                {
                    pointName: 'connectmodulecoolingstage1',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadCoolingStage1"
                },
                {
                    pointName: 'connectmodulecoolingstage2',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadCoolingStage2"
                },
                {
                    pointName: 'connectmodulecoolingstage3',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadCoolingStage3"
                },
                {
                    pointName: 'connectmodulecoolingstage4',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadCoolingStage4"
                },
                {
                    pointName: 'connectmodulecoolingstage5',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadCoolingStage5"
                },
                {
                    pointName: 'connectmoduleheatingstage1',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadHeatingStage1"
                },
                {
                    pointName: 'connectmoduleheatingstage2',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadHeatingStage2"
                },
                {
                    pointName: 'connectmoduleheatingstage3',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadHeatingStage3"
                },
                {
                    pointName: 'connectmoduleheatingstage4',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadHeatingStage4"
                },
                {
                    pointName: 'connectmoduleheatingstage5',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadHeatingStage5"
                },
                {
                    pointName: 'connectmoduleloadbasedHeatingcontrol',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadBasedHeatingControl"
                },
                {
                    pointName: 'connectmoduleloadbasedcoolingcontrol',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'read',
                    domainName: "loadBasedCoolingControl"
                },
                {
                    pointName: 'cn1Status',
                    query: `(equipRef==@${this.refs.vavDabConnectModule})`,
                    type: 'write',
                    domainName: "equipStatusMessage",
                    refCheck: {
                        key: 'equipRef',
                        value: this.refs.vavDabConnectModule
                    }
                },
            ]);
        }

        // Now systemPoints is defined regardless of the if condition
        this.systemPoints = (this.systemPoints || []).concat(systemPoints);
        this.getCCUSettingData();
    }

    async getPiDataDM(profileObj: any) {
        const piData = {
            target: '',
            dynamicTarget: '',
            loopOutput: '',
            input: '',
            inputUnit: '',
            outputUnit: ''
        };
    
        const thermistorNames = [
            'externalAirTempSensor',
            'airTempSensor100kOhms',
            'genericAlarmNC',
            'genericAlarmNO'
        ];
        const nativeSensorNames = [
            "currentTemp", "zoneHumidity", "zoneCo2", "zoneCo", "zoneNo", "pressureSensor",
            "zoneSound", "zoneOccupancy", "zoneIlluminance", "zoneCo2e", "zoneUvi", "zonePm25", "zonePm10"
        ];
        const piAnalogSensorsDm = [
            "voltageInput_ai1",
            "pressureSensor_ai1",
            "differentialAirPressureSensor_025_ai1",
            "airFlowSensor_ai1",
            "zoneHumidity_ai1",
            "zoneCo2_ai1",
            "zoneCo_ai1",
            "zoneNo2_ai1",
            "currentTx10_ai1",
            "currentTx20_ai1",
            "currentTx50_ai1",
            "genericIonSensorPoint_ai1"
        ];
        const piAnalog2SensorsDm = [
            "voltageInput_ai2",
            "pressureSensor_ai2",
            "differentialAirPressureSensor_025_ai2",
            "airFlowSensor_ai2",
            "zoneHumidity_ai2",
            "zoneCo2_ai2",
            "zoneCo_ai2",
            "zoneNo2_ai2",
            "currentTx10_ai2",
            "currentTx20_ai2",
            "currentTx50_ai2"
        ];         
    
        if (!profileObj) {
            return piData;
        }
    
        const entities = profileObj.entities || [];
        const getPointId = (tag: string) =>
            this.helperService.getPointIdbyTags(profileObj, null, null, null, tag)[0];
    
        const observables = [
            this.siteService.getWritablePointData(getPointId('nativeSensorType')).pipe(
                map(this.helperService.stripHaystackTypeMapping)
            ),
            this.siteService.getWritablePointData(getPointId('analog1InputType')).pipe(
                map(this.helperService.stripHaystackTypeMapping)
            ),
            this.siteService.getWritablePointData(getPointId('analog2InputType')).pipe(
                map(this.helperService.stripHaystackTypeMapping)
            ),
            this.siteService.getWritablePointData(getPointId('thermistor1InputType')).pipe(
                map(this.helperService.stripHaystackTypeMapping)
            )
        ];
    
        try {
            const [nativeSensor, analog1, analog2, thermistor] = await lastValueFrom(forkJoin(observables));
            const resolveSensorType = (value: any, names: string[], adjustment = 0) => {
                const index = Number(value?.rows[0]?.val) - adjustment;
                return names[index] || null;
            };
            const resolveSensorType2 = (value: any, names: string[]) => {
                const index = Number(value?.rows[0]?.val);
                return names[index] || null;
            };
            this.sensorType =
                resolveSensorType(nativeSensor, nativeSensorNames, 1) ||
                resolveSensorType(analog1, piAnalogSensorsDm, 1) ||
                resolveSensorType(thermistor, thermistorNames, 1);
            this.sensorType2 = resolveSensorType2(analog2, piAnalog2SensorsDm);
            const pointMappings = [
                { pointId: entities.find(e => e.domainName === 'pidTargetValue')?._id, key: 'target' },
                { pointId: entities.find(e => e.domainName === 'dynamicTargetValue')?._id, key: 'dynamicValue' },
                { pointId: entities.find(e => e.domainName === 'controlVariable')?._id, key: 'loopOutput' },
                { pointId: entities.find(e => e.domainName === 'processVariable')?._id, key: 'inputValue' }
            ];
    
            for (const mapping of pointMappings) {
                const entity = entities?.find(e => e?._id === mapping?.pointId);
                if (entity) {
                    if(mapping.key === 'target') {
                        piData[mapping?.key] = "Target " +this.sensorType;
                    }
                    if (mapping?.key === 'inputValue') {
                        piData.inputUnit = entity.unit || '';
                    } else if (mapping.key === 'loopOutput') {
                        piData.loopOutput = entity.unit || '';
                    } else if((mapping.key === 'dynamicValue') && this.sensorType2!=null) {
                        piData['dynamicTarget'] = "Dynamic Target Value " +this.sensorType2;
                    }
                }
            }
        } catch (error) {
            console.error('Error resolving sensor types:', error);
        }
        return piData;
    }

    loadGraphWidget(profileType: string, profileObj: any, tags: any, airFlowEnabled: boolean, heatPumpChangeOverType: string = null, hpuRelay5Config: string = null, cpuRelay6Config: string = null, moduleIdentifier: string = null, istrueCfmControlEnabled: boolean = false,systemOperationModePoint:string = null,calculativeDamperPoint:boolean =false, calculativeReheatPoint:boolean = false, reheatValue:string = null) {
        const validProfileTypes = ['vav', 'series', 'parallel', 'chilledBeam'];
        this.profiles.map(async profile => {
            if (profile && (profileObj._id === profile.profile._id)) {
                const mystat = this.commonMethodService.checkIfMystat(profileObj)
                let data = null;
                let isMasterControllerEnabled = false;
                let isAirflowDirectionSupportEnabled = false;
                this.zonehasDomainName = profileObj?.markers?.includes('domainName');
                if (profileType == 'pid'  && !this.zonehasDomainName) {
                    data = this.getPIData(profileObj);
                } 
                else if(profileType == 'pid' && this.zonehasDomainName) {
                    data = await this.getPiDataDM(profileObj);
                }
                else if (profileType == 'cpu' || profileType == 'pipe2' ||  profileType == 'hpu') {
                    this.checkHyperStatProfile = profileObj.markers.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
                    if (this.checkHyperStatProfile == 'hyperstat' || this.checkHyperStatProfile == 'hyperstatsplit' || mystat == 'mystat') {
                        data = this.getHsCpuCTData(profileObj);
                    }
                } else if (profileType == 'sense' || profileType == 'monitoring') {
                    this.monitoringProfile = profileType == 'monitoring';
                    if(!ObjectUtil.isNotUndefinedOrNull(profileObj?.domainName)) {
                        data = this.getSenseData(profileObj);
                    }  
                } else if(profileType == 'vrv') {
                    const masterControllerMode = this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv']);
                    const airflowDirectionSupportCapability = this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support']);
                    if(masterControllerMode.length > 0) {
                        await this.siteService.getWritablePointData(masterControllerMode[0]).subscribe((response) => {
                            let masterControllerModeValue = response.rows[0];
                            if(this.helperService.stripHaystackTypeMapping(masterControllerModeValue.val).split(' ')[0] == 1){
                                isMasterControllerEnabled =  true;
                                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true,this.checkHyperStatProfile,null,false,false,null,this.zonehasDomainName);
                            }
                        });
                    
                    }
                    if(airflowDirectionSupportCapability.length>0) {
                        await this.siteService.getHisPointData(airflowDirectionSupportCapability[0], 'current').subscribe((response) => {
                            let airflowDirectionSupportCapabilityValue =  response.rows[0];
                            if(this.helperService.stripHaystackTypeMapping(airflowDirectionSupportCapabilityValue.val).split(' ')[0] == 1){
                                isAirflowDirectionSupportEnabled =  true;
                                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, false, true,this.checkHyperStatProfile,null,false,false,null,this.zonehasDomainName);
                            }
                        });
                    }
                
                } 
            
                const hscpuOperationMode = (this.helperService.getPointIdbyTags(profileObj, ['operating', 'mode', 'zone'], null))
                if (hscpuOperationMode?.length) {
                    this.siteService.getHisPointData(hscpuOperationMode[0], "current").subscribe((writableData) => {
                        this.operationModePoint = this.helperService.stripHaystackTypeMapping(writableData?.rows[0]?.val).split(' ')[0];
                        if (this.operationModePoint.length) {
                            this.deviceHelper.setOperationModeData(this.operationModePoint);
                        }
                    })
                }
                profile.widgets = this.deviceHelper.getDeviceWidgets(profileType, this.refs.siteRef, this.refs.ahuRef, airFlowEnabled, heatPumpChangeOverType, hpuRelay5Config, cpuRelay6Config, moduleIdentifier, data, isMasterControllerEnabled, isAirflowDirectionSupportEnabled, istrueCfmControlEnabled,true,this.checkHyperStatProfile,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue,this.zonehasDomainName,mystat);

                //Only show runtime for first module in case of multimodule profile
                if (this.runTimeGraphsService.isRuntimeRendered && this.runTimeGraphsService.moduleWithRuntime == profile.portNum) {
                    let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
                        //Modify the runtime graph object
                        if (systemProfile) {
                            if(systemProfile.ccuRef != this.refs.ahuRef) {
                                return
                            }
                            //Runtime graphs logic
                            if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                                profile.widgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                                this.loadRuntimeWidgetData(this.refs.ahuRef);
                            }
                            else {
                                let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                                    if (runtimeProfile) {
                                        //We can directly used after fetching once
                                        this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                                        profile.widgets[0] = runtimeProfile.widgetData;
                                        this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                                        subs_runtimeSub.unsubscribe();
                                        subs_sysProfile.unsubscribe();
                                    }
                                });
                                if (this.checkForAdvanceAhuProfiles.indexOf(systemProfile.systemProfileName) > -1) {
                                    (async () => {
                                        try {
                                            const staticPreesurePoint = await this.toogleCheckforStaticPressurePoint();
                                            const co2BasedDamperControlOn = await this.co2BasedDamperControlOn();
                                            this.deviceHelper.getSystemProfileRuntimeWidgetData(
                                                systemProfile.systemProfileName,
                                                this.refs.siteRef,
                                                this.refs.ahuRef,
                                                false,
                                                this.refs.gateway,
                                                this.refs.ccuId,
                                                this.refs.ccuName,
                                                false,
                                                staticPreesurePoint,
                                                co2BasedDamperControlOn
                                            );
                                        } catch (error) {
                                            console.error("Error:", error);
                                        }
                                    })();
                                }else {
                                    this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway,this.refs.ccuId,this.refs.ccuName,false,null,null);
                                }
                            }
                            if (systemProfile.systemProfileName.includes("DEFAULT")) {
                                //for defualt profile remove comfortindex,modes widget and runtime widget
                                this.removeWidgetsFromDefaultSystemProfile();
                                profile.widgets = profile?.widgets?.filter(widget => widget?.id != 'runtimesystemprofile');
                            }
                        }
                        else {
                            //means no runtime for this profile , fetch the data
                            //All points have been fetched , get bulk read
                            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
                        }
                    });
                }
            }
        });

        this.loadWidgetData(profileType, profileObj, moduleIdentifier);
        //For profiles without runtime , fetch the data
        if (!this.multiModuleProfile && this.runTimeGraphsService.moduleWithRuntime == null) {
            //All points have been fetched , get bulk read
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
        }
    }

    getSenseData(profileObj) {
        let senseData = {
            analog1: '',
            analog2: '',
            th1:'',
            th2: '',
        }
        if (profileObj) {
            const profileType = this.monitoringProfile ? 'monitoring' : 'sense';
            let th1      = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th1'], null);
            let th2      = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'th2'], null);
            let analog1  = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog1'], null);
            let analog2  = this.helperService.getPointIdbyTags(profileObj, [profileType, 'logical', 'analog2'], null);
            
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [{
                        pointId: analog1,
                        key: 'analog1'
                    }, {
                        pointId: analog2,
                        key: 'analog2'
                    }, {
                        pointId: th1,
                        key: 'th1'
                    }, {
                        pointId: th2,
                        key: 'th2'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            senseData[point.key] = entity.dispName;
                        }
                    });
                });
            }
        }
        return senseData;
    }


    getPIData(profileObj) {
        let piData = {
            target: '',
            dynamicTarget: '',
            loopOutput:'',
            input: '',
            inputUnit: '',
            outputUnit: ''
        }
        if (profileObj) {
            let target = this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null);
            let dynamicTarget = this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null);
            let input = this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null);
            let loopOutput = this.helperService.getPointIdbyTags(profileObj, ['control', 'variable'], null);
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [ {
                        pointId: dynamicTarget,
                        key: 'dynamicTarget'
                    }, {
                        pointId: target,
                        key: 'target'
                    },{
                        pointId: input,
                        key: 'input'
                    },{
                        pointId: loopOutput,
                        key: 'loopOutput'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            piData[point.key] = entity.dispName
                            if (point.key == 'input') {
                                piData.inputUnit = entity.unit;
                            } else if(point.key == 'loopOutput') {
                                piData.loopOutput = entity.unit;
                            }else {
                                piData.outputUnit = entity.unit;
                            }
                        }
                    });
                });
            }
        }

        return piData;

    }

    getHsCpuCTData(profileObj) {
        const hsCpuCTData = {
            analog1Ct: '',
            analog2Ct: ''
        };
        const msPipe2Data = {
            analog1Ct: '',
        }
        const isMystat = this.commonMethodService.checkIfMystat(profileObj);
        if (profileObj) {
            const analog1Ct = this.helperService.getPointIdbyTags(profileObj, ['analog1', 'in', 'current', 'logical'], null);
            const analog2Ct = this.helperService.getPointIdbyTags(profileObj, ['analog2', 'in', 'current', 'logical'], null);
            if (profileObj.entities) {
                if(!isMystat) {
                    profileObj.entities.map(entity => {
                        [{
                            pointId: analog1Ct,
                            key: 'analog1Ct'
                        }, {
                            pointId: analog2Ct,
                            key: 'analog2Ct'
                        }].forEach((point) => {
                            if (point.pointId == entity._id) {
                                hsCpuCTData[point.key] = entity.dispName;
                            }
                        });
                    });
                }
                else {
                    profileObj.entities.map(entity => {
                        [{
                            pointId: analog1Ct,
                            key: 'analog1Ct'
                        }].forEach((point) => {
                            if (point.pointId == entity._id) {
                                msPipe2Data[point.key] = entity.dispName;
                            }
                        });
                    });
                }
            }
        }
        return isMystat ? msPipe2Data : hsCpuCTData;
    }

    triggerFetchTimeSeriesData(key,moduleIdentifier) {
        //Check if its multi module , fire data fetch from here
        if (this.multiModuleProfile) {
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, key + moduleIdentifier);
        }
    }

    getTrueCfmEdgePoints(profileObj: any): Promise<{ calculativeDamperPoint: boolean, calculativeReheatPoint: boolean, reheatValue: any, systemOperationModePoint: any }> {
        const calculativeDamperPoint = !!this.helperService.getPointIdbyTags(profileObj, null, null, null, ['damperCmdCal'])?.[0];
        const calculativeReheatPoint = !!this.helperService.getPointIdbyTags(profileObj, null, null, null, ['reheatCmdCal'])?.[0];
        const reheatType = this.helperService.getPointIdbyTags(profileObj, null, null, null, ['reheatType'])?.[0];
    
        let reheatValue = null;
        let systemOperationModePoint = null;
    
        const reheatPromise = reheatType?.length > 0
            ? firstValueFrom(this.siteService.getWritablePointData(reheatType))
                .then(response => {
                    reheatValue = this.helperService.stripHaystackTypeMapping(response?.rows?.[0]?.val);
                })
                .catch(error => {
                    console.error('Error fetching writable point data:', error);
                })
            : Promise.resolve();
    
        const systemOperationModePromise = this.getSystemOperatingModepoint()
            .then(result => {
                systemOperationModePoint = result;
            })
            .catch(error => {
                console.error('Error retrieving operating mode', error);
            });
    
        return Promise.all([reheatPromise, systemOperationModePromise]).then(() => {
            return {
                systemOperationModePoint,
                calculativeDamperPoint,
                calculativeReheatPoint,
                reheatValue
            };
        });
    }

    async loadProfileTags(profileType: string, profileObj: any, tags: any, moduleIdentifier: string = null, modBusEquip: string = null) {
        this.profileObj.profileType = profileType;
        this.profileObj.profile = profileObj;
        let zoneProfileType = profileObj.markers.find(profileName => profileName === 'hyperstat' ||profileName === 'smartstat' || profileName === 'hyperstatsplit' || profileName === 'mystat');
        switch (profileType) {
            case "hpu":
                if (zoneProfileType == 'smartstat') {
                    this.multiModuleProfileHasHPU = true;
                    this.getHPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
                        let airflowEnable = responseList[0];
                        let hpcType = responseList[1];
                        let hpuRelay5Config = responseList[2];
                        if (airflowEnable.rows.length > 0 && hpcType.rows.length > 0 && hpuRelay5Config.rows.length > 0) {
                            this.loadGraphWidget(profileType, profileObj, tags, airflowEnable.rows[0].val, hpcType.rows[0].val, hpuRelay5Config.rows[0].val, null, moduleIdentifier);
                        }
                        this.triggerFetchTimeSeriesData("hpu", moduleIdentifier);
                    });
                } else {
                    this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier);
                }
                break;

            case 'chilledBeam':
            case 'vav':
            case 'series':
            case 'parallel':
                let isVavTrueCfmControlEnabled: boolean;
                const vavTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow'],null,null,'airFlowSensor');
                const vavTrueCfmMinHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'min', 'trueCfm'],null,null,'minCFMReheating');
                const vavTrueCfmMaxHeating = this.helperService.getPointIdbyTags(profileObj, ['heating', 'max', 'trueCfm'],null,null,'maxCFMReheating');
                const vavTrueCfmMinCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'min', 'trueCfm'],null,null,'minCFMCooling');
                const vavTrueCfmMaxCooling = this.helperService.getPointIdbyTags(profileObj, ['cooling', 'max', 'trueCfm'],null,null,'maxCFMCooling');
                if (vavTrueCfmAirFlow.length > 0 && vavTrueCfmMinHeating.length > 0 && vavTrueCfmMaxHeating.length > 0 && vavTrueCfmMinCooling.length > 0 && vavTrueCfmMaxCooling.length > 0) {
                    isVavTrueCfmControlEnabled = true;
                } else {
                    isVavTrueCfmControlEnabled = false;
                }
                this.getTrueCfmEdgePoints(profileObj)
                .then(({ systemOperationModePoint, calculativeDamperPoint, calculativeReheatPoint, reheatValue }) => {
                    this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isVavTrueCfmControlEnabled,systemOperationModePoint,calculativeDamperPoint, calculativeReheatPoint, reheatValue );
                })
                .catch(error => {
                  this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isVavTrueCfmControlEnabled,null,false, false, null);
                });
                break;

            case 'dab':
            case 'dualDuct':
                let isDabTrueCfmControlEnabled: boolean;
                const dabTrueCfmAirFlow = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'air', 'flow']);
                const dabTrueCfmIAQ = this.helperService.getPointIdbyTags(profileObj, ['trueCfm', 'iaq']);
                if (dabTrueCfmAirFlow.length > 0 && dabTrueCfmIAQ.length > 0) {
                    isDabTrueCfmControlEnabled = true;
                } else {
                    isDabTrueCfmControlEnabled = false;
                }
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier, isDabTrueCfmControlEnabled,null,false, false, null);
                break;
               
            case 'cpu':
                this.loadGraphsForCPU(zoneProfileType, profileType, profileObj, tags, moduleIdentifier);
                break;

            default:
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier);
                break;
        }

        if (this.ccuOrZone.actionUsed == "click" || this.ccuOrZone.actionUsed == 'hover') {
            this.loadUserIntent(profileType,profileObj,zoneProfileType);
           
        } else {
            this.loadHeartBeatOnHover(profileType,profileObj);
        }

    }

    loadGraphsForCPU(zoneProfileType,profileType,profileObj,tags,moduleIdentifier) {
        if(zoneProfileType== "hyperstat"  || zoneProfileType == "hyperstatsplit") {
            if(ObjectUtil.isNotUndefinedOrNull(profileObj.domainName)) {
                this.loadGraphWidget(profileType, profileObj, tags, true, null, null, null, moduleIdentifier,false,null,false, false, null);
                if (this.multiModuleProfile) {
                    this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange, profileType + moduleIdentifier);
                }
             } else {
            let hsCpuAnalogOut1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog1'], this.refs.roomRef))
            let hsCpuAnalogOut2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog2'], this.refs.roomRef))
            let hsCpuAnalogOut3 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'output', 'analog3'], this.refs.roomRef))
            let hsCpuAnalogIn1 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog1'], this.refs.roomRef))
            let hsCpuAnalogIn2 = (this.helperService.getPointIdbyTags(profileObj, ['association', 'input', 'analog2'], this.refs.roomRef))
            
            this.hsCpuWidgetsData.push(hsCpuAnalogOut1[0],hsCpuAnalogOut2[0],hsCpuAnalogOut3[0],hsCpuAnalogIn1[0],hsCpuAnalogIn2[0])
            this.siteService.getBulkWritablePointData(this.hsCpuWidgetsData.filter(v => v)).subscribe((writableData) => {
                 
                /** Analog out 1,2,3  point are useful in future validating the keycard and other auto away sensor points in graphs **/
                
                const hsCpuAnalogIn1Value = this.helperService.stripHaystackTypeMapping( writableData?.rows[3]?.data[0]?.val).split(' ')[0];
                const hsCpuAnalogIn2Value = this.helperService.stripHaystackTypeMapping( writableData?.rows[4]?.data[0]?.val).split(' ')[0];

                this.loadGraphWidget(profileType, profileObj, tags, true, null,hsCpuAnalogIn1Value,hsCpuAnalogIn2Value,moduleIdentifier,false,null,false, false, null);
                this.triggerFetchTimeSeriesData(profileType,moduleIdentifier);
            });
        }   
        } else {
            this.multiModuleProfileHasCPU = true;
             this.getCPUConfigPoints(profileObj).pipe(takeUntil(this.unsubscribe)).subscribe(responseList => {
                const cpuRelay6Config = responseList[0];
                if (cpuRelay6Config.rows.length > 0) {
                    this.loadGraphWidget(profileType, profileObj, tags, true, null, null, cpuRelay6Config.rows[0].val,moduleIdentifier);
                }
                this.triggerFetchTimeSeriesData("cpu",moduleIdentifier);
            });        
        }
    }

    loadHeartBeatOnHover(profileType,profileObj) {
        let equipName = undefined;
        if (this.multiModuleProfile) {
            equipName = profileObj.name
        }
        switch (profileType) {
            case 'vav':
            case 'dab':
            case 'cpu':
            case 'hpu':
            case 'pipe2':
            case 'pipe4':
            case 'sse':
            case 'ti':
            case 'otn':
            case 'bpos':
            case 'dualDuct':
            case 'series':
            case 'parallel':
            case 'vrv':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null,null,'heartBeat'), 'read', equipName);
                break;

            case 'emr':
                equipName = profileObj.name;
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
                break;

            case 'chilledBeam':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null,null,'heartBeat'), 'read', equipName);
                break;

            case 'pid':
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null,null,'heartBeat'), 'read', equipName);
                break;

            case 'sense':
                equipName = null;
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
            break;

            case 'modbus':
                let points = [];
                points = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null);
                
                points = points.map(point => point);

                if(points.length > 0) {
                    this.getZoneSettings("lastUpdated", [points[0]], this.helperService.pointType(profileObj, points[0], this.refs.roomRef) ? 'read&hisWrite' : 'read&hisRead', equipName);  
                } 

                break;

            default:
                console.log('This profile is not handled');
                break;
        }
    }


    getRefFromData(_ref) {
        let ref = "";
        if (_ref == "roomRef") {
            ref = this.refs.equipRef
        } else if (_ref = "equipRef") {
            ref = this.refs.equipRef
        }
        return ref;
    }

    loadUserIntent(profileType,profileObj,zoneProfileType) {
        let equipName;
        if (this.multiModuleProfile) {
            equipName = profileObj.name
        }
        const certificationLevel = this.authService.certificationLevel;
        const domainName = profileObj?.domainName != undefined;
        switch (profileType) {
            case 'vav':
                zoneProfile_vav.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                        
                    if (configData.certificationCheck.includes(certificationLevel)) {
                       this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null,configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;
                
            case 'series':
                zoneProfile_series.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                        
                    if (configData.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null, configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;
            
            case 'parallel':
                zoneProfile_parallel.forEach((configData: any) => {
                    this.isDomainIntegrated = !!profileObj.domainName;
                    
                    if (configData.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(configData.key, this.helperService.getPointIdbyTags(profileObj, configData.tags, null,configData?.filter, configData?.domainName), configData.action, equipName);
                    }
                });
                domainName ? this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'enable'], this.refs.roomRef), 'write', equipName) : this.getZoneSettings('autoAway', this.helperService.getPointIdbyTags(profileObj, ['auto', 'away', 'vav'], this.refs.roomRef), 'write', equipName);
                break;

            case 'dab':
                zoneProfile_dab['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'cpu':
                if (zoneProfileType == "hyperstat" || zoneProfileType == "hyperstatsplit") {
                    zoneProfile_cpu_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                    if (zoneProfileType == "hyperstat") {
                        this.isDomainIntegrated = !!profileObj.domainName;
                        zoneProfile_hyperstatCpu_specific.forEach((_profileObj: any) => {
                            if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                            }
                        });
                    } else {
                        zoneProfile_hyperstatSplit_specific.forEach((_profileObj: any) => {
                            if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                            }
                        });
                    }

                } else {
                    zoneProfile_cpu_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                break;

            case 'hpu':
                if (zoneProfileType == "hyperstat") {
                    this.isDomainIntegrated = !!profileObj.domainName;
                    zoneProfile_hyperstathpu['userIntent'].forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                } else {
                    zoneProfile_hpu_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                break;

            case 'pipe2':
                if (zoneProfileType == "hyperstat") {
                    this.isDomainIntegrated = !!profileObj.domainName;
                    zoneProfile_hs_pipe2['userIntent'].forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });

                } else if (zoneProfileType == "smartstat") {
                    ZoneProfile_2pipe_non_hyperstat.forEach((_profileObj: any) => {
                        if (_profileObj.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                        }
                    });
                }
                else if(zoneProfileType == "mystat") {
                    zoneProfile_Mystat_pipe2['userIntent'].forEach((_profileObj: any) => {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, null,  null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    });
                }
                break;

            case 'pipe4':
                zoneProfile_pipe4.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'emr':
                equipName = profileObj.name;
                zoneProfile_emr.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'pid':
                if (!ObjectUtil.isNotUndefinedOrNull(this.profileObj?.profile.domainName)) {
                    zoneProfile_pid['userIntent'].forEach((point) => {
                        if (point.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(
                                point.key,
                                this.helperService.getPointIdbyTags(profileObj, point?.tags, null, null, null),
                                point.action
                            );
                        }
                    });
                } else {
                    zoneProfile_pid['dmUserIntent'].forEach((point) => {
                        if (point.certificationCheck.includes(certificationLevel)) {
                            this.getZoneSettings(
                                point.key,
                                this.helperService.getPointIdbyTags(profileObj, null, null, null, point.domainName),
                                point.action
                            );
                        }
                    });
                }
            break;


            case 'sse':
                zoneProfile_sse['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'ti':
                zoneReconfig_ti['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'otn':
                zoneProfile_otn['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter,_profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'bpos':
                zoneProfile_bpos.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });
                break;

            case 'dualDuct':
                zoneProfile_dualDuct.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'vrv':
                zoneProfile_vrv.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    }
                });
                break;

            case 'modbus':
                profileObj = this.getModbusPoints(profileObj, equipName);
                break;

            case 'sense':
                zoneProfile_sense.forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'monitoring':
                zoneProfile_monitoring['userIntent'].forEach((_profileObj: any) => {
                    if (_profileObj.certificationCheck.includes(certificationLevel)) {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter, _profileObj?.domainName), _profileObj.action, equipName);
                    }
                });
                break;

            case 'chilledBeam':
                zoneProfile_chilledBeam['userIntent'].forEach((_profileObj: any) => {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj,_profileObj.tags, _profileObj.roomRef, _profileObj?.filter, _profileObj?.domainName), _profileObj.action, equipName);
                });
                break;

            default:
                console.log('This profile is not handled');
                break;
        }
        if (profileType !== 'modbus') {
            this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null,null,'desiredTempHeating'), 'write');
            this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null,null,'desiredTempCooling'), 'write');
            this.getZoneSettings('currentTemp', this.helperService.getPointIdbyTags(profileObj, ['current', 'temp'], null,null,'currentTemp'), 'read',equipName);
            const siteObj = this.getSiteObj(['equip','tuner']);
            this.getZoneSettings('zoneHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('buildingLimitMin', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'min'],null,['tuner']), 'write');
            this.getZoneSettings('buildingLimitMax', this.helperService.getPointIdbyTags(siteObj, ['building', 'limit', 'max'],null,['tuner']), 'write');
            this.getZoneSettings('buildingToZoneDifferential', this.helperService.getPointIdbyTags(siteObj, ['building', 'default', 'differential', 'kind'],null,['tuner']), 'write');
            this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef,), 'write');
            this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write');    
            this.getZoneSettings('zoneLevelunoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['zone', 'setback', 'unoccupied', 'kind','schedulable'], this.refs.roomRef), 'write');
            let entities = ArrayUtil.deepFlatten(this.widgetData, 'entities');
            this.getZoneSettings('singleDual', this.helperService.getPointIdbyTags(entities, ['hvacMode'], this.refs.roomRef), 'read');

            if (this.multiModuleProfile) {
                this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null), 'write', profileObj.name);
                this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null), 'write', profileObj.name);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', profileObj.name);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', profileObj.name);
            }

            this.getZoneSettings('zoneVacation', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('zoneSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'special'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('equipScheduleStatus', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleStatus'], null), 'write');
            this.getZoneSettings('zoneSchedule', this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'days'], this.refs.roomRef), 'schedule');
            this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'days']) , 'schedule');
            this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , 'schedule');
            this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write');
            //revamped scheduler building level user limit points
            this.getZoneSettings('buildingHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'heatingUserLimitMax'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'coolingUserLimitMin'), 'write');
            this.getZoneSettings('buildingHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'heatingUserLimitMin'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'coolingUserLimitMax'), 'write');
            this.getZoneSettings('buildingHeatingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'deadband', 'base', 'default', 'schedulable'],null,null,'heatingDeadband'), 'write');
            this.getZoneSettings('buildingCoolingDeadband', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'deadband', 'base', 'default', 'schedulable'],null,null,'coolingDeadband'), 'write');
            this.getZoneSettings('buildingUnoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings.schedulable, ['unoccupied', 'setback', 'default', 'schedulable'],null,null,'unoccupiedZoneSetback'), 'write');
        }
    }

    getModbusPoints(profileObj,equipName) {
        let isHeartBeat = false;
        let points = [];
        profileObj['noPoints'] = true;
        let heartBeatPoints = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null);
        if(heartBeatPoints.length) isHeartBeat = true;
        points = this.helperService.getPointIdbyTags(profileObj, ["modbus", "shortDis", "displayInUi"], null);
        points = heartBeatPoints.concat(points);
        points = points.map(point => point);
        if(points.length > 0) {
            if(isHeartBeat)
                profileObj['noPoints'] = points.length < 2;
            else 
                profileObj['noPoints'] = false;
            
            for (let i = 0; i < points.length; i++) {
                let label = i==0 && isHeartBeat? 'lastUpdated': "modbus" + (i + 1);
                let poinType = this.helperService.pointType(profileObj, points[i], null,'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, points[i], null,'his')? 'read&hisRead' : 'read&read'); 
                this.getZoneSettings(label, [points[i]], poinType, equipName);
            }
        }
        return profileObj;
    }

    getModBuslabels(points,isHeartBeat,profileObj,equipName) {
        for (let i = 0; i < points.length; i++) {
            let label = i == 0 && isHeartBeat ? 'lastUpdated' : "modbus" + (i + 1);
            let poinType = this.helperService.pointType(profileObj, points[i], this.refs.roomRef, 'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, points[i], this.refs.roomRef, 'his') ? 'read&hisRead' : 'read&read');
            this.getZoneSettings(label, [points[i]], poinType, equipName);
        }
    }

    loadRuntimeWidgetData(equipRef: string) {
        if (this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsForCollection.get(equipRef).forEach((value, key) => {
                this.getPointId(key, value);
            });
        }

        if (this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef)) {
            this.runTimeGraphsService.cmBoardPortsMappingsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            });
        }

        if (this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef)) {
            this.runTimeGraphsService.fullyModulatingProfileTagsCollection.get(equipRef).forEach((value, key) => {
                if (value.isEnabled) {
                    this.getPointId(value.param, value.ref);
                }
            })
        }

        //All points have been fetched , get bulk read
        this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);

        //Fetch the properties
        const btuObj = this.widgetData?.find(equip => ['system','btu', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const equipObj = this.widgetData?.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        this.getPointProperties(equipObj);
        this.getPointProperties(btuObj);
    }

    getCommonPoints(moduleIdentifier, siteObj) {
        this.getPointId('heatingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'min','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('coolingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'max','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('heatingUserLimitMax' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['heating', 'user', 'limit', 'max','schedulable'], this.refs.roomRef)[0]);
        this.getPointId('coolingUserLimitMin' + moduleIdentifier, this.helperService.getPointIdbyTags(this.buildings.schedulable, ['cooling', 'user', 'limit', 'min','schedulable'], this.refs.roomRef)[0]);
        
    }

    loadWidgetData(profileType: string, profileObj: any, moduleIdentifier: string) {
        let siteObj = this.getSiteObj(['tuner', 'equip']);
        const isMystat = this.commonMethodService.checkIfMystat(profileObj);
        const diagnosticObj = this.widgetData.find(equip => ['equip', 'diag'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstat' || e === 'hyperstatsplit');
        if (!moduleIdentifier) { 
          return;
        }
        switch (profileType) {
        
            case 'vav':
            case 'series':
            case 'parallel':
                this.getCommonPoints(moduleIdentifier,siteObj);
                vav_terminal['widgets'].forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,null,p?.domainName)[0]);
                })
                this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vav', 'temp'], null,null,'currentTemp')[0]);
                break;

            case 'dab':
                this.getCommonPoints(moduleIdentifier, siteObj);
                zoneProfile_dab['widgets'].forEach((p) => {
                    this.getPointId(p.key + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
                })
                break;



            case 'cpu':
                if (hyperStatProfileName == "hyperstat" || hyperStatProfileName == "hyperstatsplit") {
                    this.getCommonPoints(moduleIdentifier, siteObj);
                    this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'logical', 'scheduleType'], this.refs.roomRef, null, 'scheduleType')[0]);
                    zoneProfile_hsplit_cpu_widgets.forEach((p) => {
                        this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, this.refs.roomRef, null, p.domainName)[0]);
                    })
                    if (hyperStatProfileName == "hyperstat") {
                        this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'dcv', 'analog'], this.refs.roomRef, null, 'dcvDamperModulating')[0])
                        //In future we may use
                        // this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['output', 'fan', 'logical', 'cmd', 'analog'], this.refs.roomRef, null, 'linearFanSpeed')[0]);
                        // this.getPointId('modulatingFanSpeedAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['output', 'fan', 'logical', 'cmd', 'analog'], this.refs.roomRef, null, 'stagedFanSpeed')[0]);
                    } else {
                        this.getPointId('modulatingDamperAnalog1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'analog', 'economizer', 'output'], this.refs.roomRef, null, 'oaoDamper')[0]);
                    }
                    /**Old Hyper stat cpu code */
                } else {
                    this.getCommonPoints(moduleIdentifier, siteObj);
                    this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'cpu', 'temp'], null)[0]);
                    this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'heating'], null)[0]);
                    this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'cpu', 'temp', 'cooling'], null)[0]);
                    this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'scheduleType'], null)[0]);
                    this.getPointId('condfanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage1', 'cmd'], null)[0]);
                    this.getPointId('condfanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'cpu', 'stage2', 'cmd'], null)[0]);
                    this.getPointId('cpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'humidifier'], null)[0]);
                    this.getPointId('cpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'cpu', 'dehumidifier'], null)[0]);
                    this.getPointId('condheatingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage1'], null)[0]);
                    this.getPointId('condheatingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage2'], null)[0]);
                    this.getPointId('condcoolingStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage1'], null)[0]);
                    this.getPointId('condcoolingStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage2'], null)[0]);
                    this.getPointId('condheatingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'heating', 'cpu', 'stage3'], null)[0]);
                    this.getPointId('condcoolingStage3' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cooling', 'cpu', 'stage3'], null)[0]);
                    this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'air', 'temp', 'th1'], null)[0]);
                    this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'fan', 'mode'], null)[0]);
                    this.getPointId('ZoneOperationalMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'operating', 'mode', 'temp'], null)[0]);
                    this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'cpu', 'conditioning', 'mode'], null)[0]);
                    this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                    this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto', 'away', 'config'], null)[0]);
                    this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cpu', 'auto', 'forced'], null)[0]);
                    this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                    this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                    this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                    this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                    this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                }
                break;

            case 'hpu':
                this.getCommonPoints(moduleIdentifier,siteObj); 
                this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode'], null)[0]);
                if (hyperStatProfileName == "hyperstat") {
                    zoneProfile_hyperstathpu['widgets'].forEach((p) => {
                        if(['voc',"co2","pm2p5"].indexOf(p.name) > -1) {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,['config'],p?.domainName)[0]);
                        } else {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
                        }
                    })
                } else {
                    this.getPointId('currentTemp' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'hpu', 'temp'], null)[0]);
                    this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'heating'], null)[0]);
                    this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'hpu', 'temp', 'cooling'], null)[0]);
                    this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'scheduleType'], null)[0]);
                    this.getPointId('occupancyDetection' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);

                    this.getPointId('compressorStage1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage1'], null)[0]);
                    this.getPointId('compressorStage2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'compressor', 'stage2'], null)[0]);
                    this.getPointId('fanLow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'hpu', 'stage1'], null)[0]);
                    this.getPointId('auxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'aux', 'heating'], null)[0]);
                    this.getPointId('hpuOperatingMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'operating', 'mode', 'temp'], null)[0]);
                    // hpc cooling
                    this.getPointId('heatpumpChangeoverCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'cooling'], null)[0]);
                    // hpc heating
                    this.getPointId('heatpumpChangeoverHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'heatpump', 'changeover', 'heating'], null)[0]);

                    // relay5 data
                    // fanstage2
                    this.getPointId('fanHigh' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'fan', 'hpu', 'stage2'], null)[0]);
                    // hpuHumidifier
                    this.getPointId('hpuHumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'humidifier'], null)[0]);
                    // hpuDehumidifier
                    this.getPointId('hpuDehumidifier' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'standalone', 'logical', 'hpu', 'dehumidifier'], null)[0]);


                    this.getPointId('airflowTempSensorTh1' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'air', 'temp', 'th1'], null)[0]);
                    this.getPointId('FanModeCpuHpu' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'hpu', 'fan', 'mode'], null)[0]);
                    this.getPointId('ZoneConditionMode' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'conditioning', 'mode'], null)[0]);
                    this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'away', 'config'], null)[0]);
                    this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['hpu', 'auto', 'forced'], null)[0]);
                    this.getPointId('Humidity' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                    this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                    this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                    this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                    this.getPointId('CO2Equivalent' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                    this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    /** Sensor values for smart stat and hyperstat**/
                    this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                    this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);

                
                }
                break;
            case 'pipe2':
                this.getCommonPoints(moduleIdentifier,siteObj)
                if (hyperStatProfileName == "hyperstat") {
                    zoneProfile_hs_pipe2['widgets'].forEach((p) => {
                        if(['voc',"co2","pm2p5"].indexOf(p.name) > -1) {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,['config'],p?.domainName)[0]);
                        } else {
                            this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null,null,p?.domainName)[0]);
                        }
                    })
                } else if (isMystat) {
                    zoneProfile_Mystat_pipe2['widgets'].forEach((p) => {
                        this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, null, null,null,p.domainName)[0]);
                    });
                } else {
                    this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'scheduleType'], null)[0]);
                    this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);

                    this.getPointId("airflowTempSensorTh1" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'air', 'temp', 'th1'], null)[0]);
                    this.getPointId("SupplyWaterTemperature" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'water', 'temp', 'th2'], null)[0]);
                    this.getPointId("FanModeFCU" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'fan', 'mode'], null)[0]);
                    this.getPointId("ZoneOperationalMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'operating', 'mode', 'temp'], null)[0]);
                    this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe2', 'temp'], null)[0]);
                    this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'heating'], null)[0]);
                    this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe2', 'temp', 'cooling'], null)[0]);
                    
                    this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto','forced'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe2', 'auto', 'away','config'], null)[0]);
                    this.getPointId("ZoneConditionMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe2', 'conditioning', 'mode'], null)[0]);
                    this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);

                    this.getPointId("fanLow" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'low'], null)[0]);
                    this.getPointId("fanMedium" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'medium'], null)[0]);
                    this.getPointId("fanHigh" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe2', 'high'], null)[0]);
                    this.getPointId("auxHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe2', 'aux', 'heating'], null)[0]);
                    this.getPointId("waterValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe2', 'valve'], null)[0]);

                    this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                    this.getPointId("VOC" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                    this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                    
                    this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                    this.getPointId("Humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                    this.getPointId("CO2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                    this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                    
                    this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                    /** Sensor values for smart stat and hyperstat**/
                    this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                    this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                }
                break;

            case 'pipe4':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("fanLow" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'low'], null)[0]);
                this.getPointId("fanMedium" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'medium'], null)[0]);
                this.getPointId("fanHigh" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'fcu', 'pipe4', 'high'], null)[0]);
                this.getPointId("heatingValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'heating', 'pipe4', 'valve'], null)[0]);
                this.getPointId("coolingValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'cooling', 'pipe4', 'valve'], null)[0]);
                this.getPointId("auxHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'fcu', 'pipe4', 'aux', 'heating'], null)[0]);
                this.getPointId("waterValve" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'water', 'fcu', 'pipe4', 'valve'], null)[0]);
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'pipe4', 'temp'], null)[0]);
                this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'heating'], null)[0]);
                this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'pipe4', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'scheduleType'], null)[0]);
                this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'zone', 'sensor', 'logical'], null)[0]);

                this.getPointId("Humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId("VOC" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);

                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto','forced'], null)[0]);
                    this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pipe4', 'auto', 'away','config'], null)[0]);
                this.getPointId("airflowTempSensorTh1" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'air', 'temp', 'th1'], null)[0]);
                this.getPointId("FanModeFCU" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'fan', 'mode'], null)[0]);
                this.getPointId("ZoneOperationalMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'operating', 'mode', 'temp'], null)[0]);
                this.getPointId("ZoneConditionMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'pipe4', 'conditioning', 'mode'], null)[0]);
                this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null)[0]);
                this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
               
                this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                
                this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                /** Sensor values for smart stat and hyperstat**/
               
                break;

            case 'emr':
                this.getPointId("currentRate" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'current', 'rate'], null)[0]);
                this.getPointId("energyMeterReading" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'emr', 'cur', 'sp'], null)[0]);
                break;

            case 'pid':
                const widgets = this.zonehasDomainName ? zoneProfile_pid['widgets'] : zoneProfile_pid['nonDmWidgets']
                widgets.forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj,this.zonehasDomainName?null:p?.tags, null, null, p?.domainName)[0]);
                })
                break;

            case 'sse':
                this.getCommonPoints(moduleIdentifier,siteObj);
                zoneProfile_sse['widgets'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, this.refs.roomRef,null,p?.domainName)[0]);
                })
                break;

            case 'ti':
                this.getCommonPoints(moduleIdentifier,siteObj);
                zoneReconfig_ti['widgets'].forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
                });
                break;
            case 'otn':
                this.getCommonPoints(moduleIdentifier,siteObj)
                zoneProfile_otn['widgets'].forEach((p: any) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, this.refs.roomRef,null,p.domainName)[0]);
                });
                break;
            
            case 'bpos':
                this.getCommonPoints(moduleIdentifier,siteObj)
                ProfileWidgets['bpos'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;


            case 'dualDuct':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'dualDuct'], null)[0]);
                this.getPointId("humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'dualDuct'], null)[0]);
                this.getPointId("targetMinInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'min', 'humidity', 'inside'], null)[0]);
                this.getPointId("targetMaxInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'dualDuct', 'max', 'humidity', 'inside'], null)[0]);
                
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'dualDuct', 'temp'], null)[0]);
                this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'heating'], null)[0]);
                this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'dualDuct', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("co2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'air'], null)[0]);
                this.getPointId("zoneCO2Target" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'dualDuct', 'target'], null)[0]);
                this.getPointId("voc" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'air'], null)[0]);
                this.getPointId("zoneVOCTarget" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'dualDuct', 'target'], null)[0]);
                
                this.getPointId("enteringAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'entering', 'sensor'], null)[0]);

                this.getPointId("dischargeAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'dualDuct', 'air', 'discharge', 'sensor'], null)[0]);
                this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dualDuct', 'scheduleType'], null)[0]);
                this.getPointId("maxHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
                this.getPointId("maxCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
                this.getPointId("minHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'heating', 'damper', 'limit'], null)[0]);
                this.getPointId("minCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'dualDuct', 'cooling', 'damper', 'limit'], null)[0]);
                this.getPointId('coolingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('heatingDamperPos' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('coolingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId('heatingSupplyAirflow' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null)[0]);
                this.getPointId("reheatPosition" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['reheat', 'dualDuct', 'valve'], null)[0]);
                this.getPointId("zonePriority" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dynamic', 'priority'], null)[0]);
                this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
                this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
                this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId('autoForcedOccupied' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['dualDuct', 'auto','forced'], null)[0]);
                this.getPointId('autoAway' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['dualDuct', 'auto', 'away','config'], null)[0]);
                break;

            case 'modbus':
                break;

            case 'sense':
                ProfileWidgets['sense'].forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null)[0]);
                })
                break;

            case 'monitoring':
                zoneProfile_monitoring['widgets']?.forEach((p)=>{
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null, p?.domainName)[0]);
                })
                break;

            case 'chilledBeam':
                this.getCommonPoints(moduleIdentifier,siteObj);
                zoneProfile_chilledBeam['widgets'].forEach((p) => {
                    this.getPointId(p.name + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, p.tags, null, null,p?.domainName)[0]);
                })
                break;

            case 'vrv':
                this.getCommonPoints(moduleIdentifier,siteObj);
                this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vrv', 'temp'], null)[0]);
                this.getPointId('desiredTempHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'heating'], null)[0]);
                this.getPointId('desiredTempCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vrv', 'temp', 'cooling'], null)[0]);
                
                this.getPointId("vrvHumidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'vrv','sensor'], null)[0]);
                this.getPointId("fanSpeed" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['fanSpeed', 'vrv','enum'], null)[0]);
                this.getPointId("airflowDirection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv','enum'], null)[0]);
                this.getPointId("filterStatus" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['filterStatus', 'vrv'], null)[0]);
                this.getPointId("coolHeatRight" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['coolHeatRight', 'vrv'], null)[0]);
                this.getPointId("operationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'enum', 'vrv'], null)[0]);
                this.getPointId("masterOperationMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['operation', 'mode', 'master', 'vrv'], null)[0]);
                this.getPointId("masterControllerMode" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['masterController', 'vrv'], null)[0]);
                this.getPointId("airflowDirectionSupportCapability" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['airflowDirection', 'vrv', 'support'], null)[0]);
                this.getPointId('scheduleType' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'vrv', 'scheduleType'], null)[0]);
                this.getPointId('zoneOccupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'vrv'], null)[0]);
                // Sensor Widget Graph Points
                this.getPointId('NO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
                this.getPointId('VOC' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'sensor', 'cur'], null)[0]);
                this.getPointId('Pressure' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
                this.getPointId('CO2' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'sensor', 'cur'], null)[0]);
                this.getPointId('CO' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
                
                this.getPointId('Occupancy' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
                this.getPointId('sound' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
                this.getPointId('testOperation' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','testOperation'], null)[0]);
                this.getPointId('telcoCheck' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['telecoCheck','vrv'], null)[0]);
                this.getPointId('vrvTh1Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th1','sensor'], null)[0]);
                this.getPointId('vrvTh2Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th2','sensor'], null)[0]);
                this.getPointId('vrvTh3Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th3','sensor'], null)[0]);
                this.getPointId('vrvTh4Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th4','sensor'], null)[0]);
                this.getPointId('vrvTh5Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th5','sensor'], null)[0]);
                this.getPointId('vrvTh6Sensor' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['vrv','th6','sensor'], null)[0]);
                
                this.getPointId('Illuminance' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
                this.getPointId('UVI' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
                this.getPointId('pm10' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm10', 'sensor'], null)[0]);
                this.getPointId('pm2p5' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pm2p5', 'sensor'], null)[0]);
                break;
            default:
                console.log('This profile is not handled');
                break;
        }

        this.getPointId('zoneequipmentConnected'+ moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null)[0]);
        if(diagnosticObj)
            this.getPointId('cloudConnected'+ moduleIdentifier, this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0]);
        if(profileType == 'pid' && this.zonehasDomainName) {
            const checkDomainNameMatchfor1 = (row) => {
                return this.piAnalogSensorsDmDomainNames?.includes(row?.domainName) ||
                       this.thermistorSensorDmDomainNames?.includes(row?.domainName) ||
                       this.nativeSensorDMDomainNames?.includes(row?.domainName);
              };
              const checkDomainNameMatchfor2 = (row) => {
                return this.piAnalog2SensorsDmDomainNames?.includes(row?.domainName);
              };
            const matchingRows1 = profileObj?.entities?.filter(checkDomainNameMatchfor1);
            const matchingRows2 = profileObj?.entities?.filter(checkDomainNameMatchfor2);
            let target = profileObj?.entities?.filter(item => item?.domainName == 'pidTargetValue');
            let dynamicTargetValue = profileObj?.entities?.filter(item => item?.domainName == 'dynamicTargetValue');
            if(target?.length>0 && matchingRows1?.length > 0){
                profileObj.entities.filter(item => item.domainName == 'pidTargetValue')[0].unit = matchingRows1[0]?.unit;
            }
            if(dynamicTargetValue?.length>0 && matchingRows2?.length>0){
                profileObj.entities.filter(item => item.domainName == 'dynamicTargetValue')[0].unit = matchingRows2[0]?.unit;
            }
        }
        //Set the properties
        this.getPointProperties(profileObj);
        this.getPointProperties(siteObj);
    }

    getSeriesOrParallel(moduleIdentifier,profileObj,key) {
        this.getPointId("currentTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['current', 'vav', 'temp', key], null)[0]);
        this.getPointId("desiredTempHeating" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vav', 'temp', 'heating', key], null)[0]);
        this.getPointId("desiredTempCooling" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['desired', 'vav', 'temp', 'cooling', key], null)[0]);
        this.getPointId("zoneOccupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode', 'vav', key], null)[0]);
        this.getPointId("humidity" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['humidity', 'vav', key], null)[0]);
        this.getPointId("targetMinInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'vav', 'min', 'humidity', 'inside', key], null)[0]);
        this.getPointId("targetMaxInsideHumidty" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['target', 'vav', 'max', 'humidity', 'inside', key], null)[0]);
        this.getPointId("co2" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'vav', 'air', key], null)[0]);
        this.getPointId("zoneCO2Target" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2', 'target', 'vav'], null)[0]);
        this.getPointId("voc" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'vav', 'air', key], null)[0]);
        this.getPointId("zoneVOCTarget" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['voc', 'vav', 'target'], null)[0]);

        this.getPointId('cfmMinHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj,['heating','min','trueCfm',key],null)[0])
        this.getPointId('cfmMaxHeating' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['heating','trueCfm','max',key],null)[0])
        this.getPointId('cfmMinCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling','trueCfm','min',key],null)[0])
        this.getPointId('cfmMaxCooling' + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['cooling','trueCfm','max',key],null)[0])
        this.getPointId('cfmAirFlowVolume' + moduleIdentifier,this.helperService.getPointIdbyTags(profileObj, [ 'air','flow','trueCfm'],   null)[0]);
        
        this.getPointId("enteringAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'vav', 'air', 'entering', 'sensor', key], null)[0]);
        this.getPointId("dischargeAirTemp" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['temp', 'vav', 'air', 'discharge', 'sensor', key], null)[0]);
        this.getPointId("scheduleType" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'vav', 'scheduleType'], null)[0]);
        this.getPointId("maxHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'vav', 'heating', 'damper', 'pos', key], null)[0]);
        this.getPointId("maxCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['max', 'vav', 'cooling', 'damper', 'pos', key], null)[0]);
        this.getPointId("minHeatingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'vav', 'heating', 'damper', 'pos', key], null)[0]);
        this.getPointId("minCoolingDamperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['min', 'vav', 'cooling', 'damper', 'pos', key], null)[0]);
        this.getPointId("damperPos" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['damper', 'vav', key, 'normalized'], null)[0]);
        this.getPointId("reheatPosition" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['reheat', 'vav', 'valve', key], null)[0]);
        this.getPointId("zonePriority" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['zone', 'dynamic', 'priority'], null)[0]);
        this.getPointId("occupancyDetection" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'detection', 'zone'], null)[0]);
        this.getPointId("CO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co', 'sensor', 'cur'], null)[0]);
        this.getPointId("NO" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['no', 'sensor', 'cur'], null)[0]);
        this.getPointId("Pressure" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['pressure', 'sensor', 'cur'], null)[0]);
        this.getPointId("Occupancy" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['occupancy', 'sensor', 'cur'], null)[0]);
        this.getPointId("sound" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['sound', 'sensor', 'cur'], null)[0]);
        this.getPointId("CO2Equivalent" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['co2Equivalent', 'sensor', 'cur'], null)[0]);
        this.getPointId("Illuminance" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['illuminance', 'sensor', 'cur'], null)[0]);
        this.getPointId("UVI" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, ['uvi', 'sensor', 'cur'], null)[0]);
        this.getPointId(key+"Fan" + moduleIdentifier, this.helperService.getPointIdbyTags(profileObj, [key, 'fan'], null)[0]);
    }

    loadSystemWidgetsData() {
        let OAOObj = this.isConnectModuleOaoIsPaired 
        ? this.widgetData.find(et => et._id === this.refs?.vavDabConnectModule) 
        : this.widgetData.find(et => et._id === this.refs?.oaoRef); 
        const btuObj = this.widgetData.find(equip => ['system','btu', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const getEMRObj = this.widgetData.find(equip => ['system','emr', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const diagnosticObj = this.widgetData.find(equip => ['equip', 'diag'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        let equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        let siteObj = this.getSiteObj(['tuner', 'equip']);
        const getModbusObj = this.widgetData.find(equip => ['system','modbus','equip'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const getBypassBamper = this.widgetData.find(equip => ['bypassDamper','equip'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        // get ccu version
        const appVerId = this.helperService.getPointIdbyTags(diagnosticObj, ['app', 'diag', 'version'])[0] || '';
        const ccuConfigObj = this.equipConfig('ccuConfiguration');
        this.version = '';
        if (appVerId) {
            this.siteService.getWritablePointData(appVerId).pipe(
               map(this.helperService.stripHaystackTypeMapping), takeUntil(this.unsubscribe)
           ).subscribe(({ rows }) => {
               if (rows && rows.length > 0) {
                   this.version = rows[0].val.toString();
                   this.refs['ccuVersion'] = this.version;
               }
           }, () => {
              
           });
       }
       this.getSystemWidgetsData(equipObj,diagnosticObj,siteObj,OAOObj,btuObj,getEMRObj,getModbusObj,getBypassBamper,ccuConfigObj)
        //Set the properties
        this.getPointProperties(equipObj);
        this.getPointProperties(siteObj);
        this.getPointProperties(diagnosticObj);
        OAOObj ? this.getPointProperties(OAOObj) : '';
        getBypassBamper ? this.getPointProperties(getBypassBamper) : '';
        this.getCCUAppVersions(diagnosticObj);
    }

    getSystemWidgetsData(equipObj,diagnosticObj,siteObj,OAOObj,btuObj,getEMRObj,getModbusObj,getBypassBamper,ccuConfigObj) {
        let sysObj:any;
        for (let i = 0; i < systemprofileWidgets.length; i++) {
            const systemProfileObj = systemprofileWidgets[i];
            
            if (systemProfileObj.objType == 'equipObj' || (systemProfileObj.objType == 'equipObj' && systemProfileObj.key == 'ConditionMode')) {
                sysObj = equipObj;
            } else if (systemProfileObj.objType == 'OAOObj' && OAOObj) {
                sysObj = OAOObj;
            } else if (systemProfileObj.objType == 'diagnosticObj') {
                sysObj = diagnosticObj;
            } else if (systemProfileObj.objType == 'modbusObj') {
                sysObj = getModbusObj;
            } else if (systemProfileObj.objType == 'btuObj') {
                sysObj = btuObj;
            } else if (systemProfileObj.objType == 'getEMRObj') {
                sysObj = getEMRObj;
            } else if (systemProfileObj.objType == 'modbusObj') {
                sysObj = getModbusObj;
            } else if (systemProfileObj.objType == 'ccuConfigObj') {
                sysObj = ccuConfigObj;
            } else if(systemProfileObj.objType == 'bypassDamperObj') {
                sysObj = getBypassBamper;
            } else if (systemProfileObj.objType == "schedulable" && this.showSchedulerOccupancy) {
                sysObj = this.buildings.schedulable;
            } else if (systemProfileObj.objType == "notSchedulable" && !this.showSchedulerOccupancy) {
                sysObj = siteObj;
            }
            this.getPointId('ConditionMode', this.getConditioningStatusPoint_tempCode(equipObj));
            this.getPointId(systemProfileObj.key, this.helperService.getPointIdbyTags(sysObj,systemProfileObj.tags ? systemProfileObj.tags : null, null,null, systemProfileObj?.domainName)[0]);

        } 
    }

    async getCCUAppVersions(diagnosticObj) {
        // get ccu , home app, remote access app, bacnet app, cm version ids
        const ccuAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['app', 'diag', 'version'],null,null,'appVersion')[0] || 'NA';
        const backneApptId = this.helperService.getPointIdbyTags(diagnosticObj, ['bacnet', 'diag', 'version'],null,null,'bacnetAppVersion')[0] || 'NA';
        const remoteAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['remoteAccess', 'diag', 'version'],null,null,'remoteAccessAppVersion')[0] || 'NA';
        const homeAppId = this.helperService.getPointIdbyTags(diagnosticObj, ['home', 'diag', 'version'],null,null,'homeAppVersion')[0] || 'NA';
        let device = this.widgetData.find(equip => ['cm', 'device'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef || equip.referenceIDs.ccu == this.refs.ccuId));
        const cmVerId = this.helperService.getPointIdbyTags(device, ['firmware', 'version'],null,null,'firmwareVersion')[0] || '';
        this.version = '';
        this.cmVersion = '';
        this.homeAppVersion = '';
        this.remoteAccessAppVersion = '';
        this.backnetAppVersion = '';
        this.versionText = "";
        let versionIds = [ccuAppId, backneApptId, remoteAppId, homeAppId, cmVerId].filter(id => id);
        if (!versionIds.length) return;
        try {
          const {rows} = await lastValueFrom(this.siteService.getBulkWritablePointData(versionIds).pipe(
              map(this.helperService.stripHaystackTypeMapping)
          ));
          /*
        In CCU builds that do not use the CCUConfiguration model, version checks for displaying the version on the CCU widget are performed based on the presence of the app tag or a specific app tag. 
        However, in builds that include the CCUConfiguration model, the app tag is present for all apps, such as Home, BACnet, Remote, and CCU. 
        To address this, the checks are now nested within a condition that verifies whether the domain name is present.             */
          if (rows?.length > 0) {
              rows.forEach(row => {
                if (!row.hasOwnProperty('domainName')) {
                    if (row.hasOwnProperty('app')) {
                        this.version = row?.data[0]?.val || 'NA';
                        this.refs['ccuVersion'] = this.version;
                    } else if (row.hasOwnProperty('bacnet') && row.hasOwnProperty('diag')) {
                        this.backnetAppVersion = row?.data[0]?.val || 'NA';
                        this.refs['bacAppVersion'] =this.backnetAppVersion 
                    } else if (row.hasOwnProperty('remoteAccess')) {
                        this.remoteAccessAppVersion = row?.data[0]?.val || 'NA';
                        this.refs['remoteAppVersion'] =this.remoteAccessAppVersion 
                    } else if (row.hasOwnProperty('home')) {
                        this.homeAppVersion = row?.data[0]?.val || 'NA';
                        this.refs['homeAppVersion'] =this.homeAppVersion 
                    } else if (row.hasOwnProperty('firmware')) {
                        this.cmVersion = row?.data[0]?.val || 'NA';
                    }
                } else {
                    if (row.domainName === 'appVersion') {
                        this.version = row.data[0]?.val || 'NA';
                        this.refs['ccuVersion'] = this.version;
                    } else if (row.domainName === 'bacnetAppVersion') {
                        this.backnetAppVersion = row.data[0]?.val || 'NA';
                        this.refs['bacAppVersion'] =this.backnetAppVersion 
                    } else if (row.domainName === 'remoteAccessAppVersion') {
                        this.remoteAccessAppVersion = row.data[0]?.val || 'NA';
                        this.refs['remoteAppVersion'] =this.remoteAccessAppVersion 
                    } else if (row.domainName === 'homeAppVersion') {
                        this.homeAppVersion = row.data[0]?.val || 'NA';
                        this.refs['homeAppVersion'] =this.homeAppVersion 
                    } else if (row.domainName === 'firmwareVersion') {
                        this.cmVersion = row?.data[0]?.val || 'NA';
                    }
                }
              });
          }
          this.versionText = `CCU: ${this.version ? 'v' + this.version : 'NA'} | CM: ${this.cmVersion ? 'v' + this.cmVersion : 'NA'} | Home: ${this.homeAppVersion ? 'v' + this.homeAppVersion : 'NA'} | Remote Access: ${this.remoteAccessAppVersion ? 'v' + this.remoteAccessAppVersion : 'NA'} | BACnet: ${this.backnetAppVersion ? 'v' + this.backnetAppVersion : 'NA'}`;
      } catch (error) {
          console.error("Error fetching version data:", error);
      }
  }

    getConditioningStatusPoint_tempCode(equipObj) {
        let point = this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0];

        if (point == undefined) {
            point = this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'conditioning'])[0]
        }

        return point;
    }

    fetchequipstatus() {
        this.multiModuleZoneStatus = [];
        let pointsList = [];

        let entities = ArrayUtil.deepFlatten(this.widgetData || [], 'entities');
        pointsList = this.helperService.getPointIdbyTags(entities, ['zone', 'his', 'status', 'enum'], this.refs.roomRef,['diag'],'equipStatus');
        this.siteService.getCurrenthisReadMany(
            pointsList
        ).pipe( takeUntil(this.unsubscribe)).subscribe(({ rows }) => {
            if (rows && rows.length > 0) {
                rows.forEach((row) => {

                    let desc = row.dis;
                    let substringpos = desc.lastIndexOf("-");
                    let profile = desc.slice(0, substringpos);
                    let zonedeadStatus = row.data[0] ? this.helperService.stripHaystackTypeMapping(row.data[0].val).split(' ')[0] : undefined;
                    this.multiModuleZoneStatus.push({ 'profile': profile, 'equipStatus': zonedeadStatus });
                });
            }
            else {
            }
           
        },
        (err) => {
        });
    }

    handleZonePriority() {
        if (this.multiModuleProfile) {
            this.multiModuleZonePriority();
            this.getMultiZonePriorityVal();
        } else if (this.zoneSettings?.zonePriority) {
            this.zonePriorityVal = String(this.zoneSettings.zonePriority?.val);
        }
    }
    handleZoneSchedule() {
        if (this.multiModuleProfile) {
            this.multiModuleZoneSchedule();
            this.getMultiScheduleTypeVal();
        } else if (this.zoneSettings?.scheduleType?.hasOwnProperty('val')) {
                this.scheduleTypeVal = String(this.zoneSettings.scheduleType.val)
        }
    }


    getUserSettingData(key: string) {
        this.getUserSettingDataSubscriptionCollection[key] = this.helperService.getPointData()
            .pipe(
                takeUntil(this.unsubscribe))
            .subscribe(res => {

                if (key == 'reConfig') {
                    this.delayChanges.next(ObjectUtil.deepClone({
                        val: res,
                        key: key
                    }))

            } else {
                this.delayChanges.next(ObjectUtil.deepClone({
                    val: res,
                    key: key
                }))

            }
        });
    }

    calculateAvgCurrentTemp() {
        this.avgCurrentTemp = 0;
        let avgTempList = [];
        let profileZoneStatus;
        this.profiles.forEach(profile => {
            if (new RegExp(/^(vav|dab|cpu|pipe2|pipe4|hpu|sse|ti|dualDuct|series|parallel|vrv|chilledBeam)$/).test(profile.profileType)) {

                let filteredProfile = this.multiModuleZoneStatus.filter((x) => { if (x.profile == profile.profile.name) { return x; } })[0];
                profileZoneStatus = (filteredProfile) ? parseInt(filteredProfile.equipStatus) : undefined;
                if (profileZoneStatus != 3)
                    avgTempList.push(parseFloat(this.zoneSettings[profile.profile.name].currentTemp.val));

            }
        });
        var filtered = avgTempList.filter(function (value, index, arr) {

            return (Math.trunc(Number(value)) !== 0);

        });

        this.avgCurrentTemp = (filtered && filtered.length > 0) ? parseFloat(this.average(filtered).toFixed(1)) : 0;
        this.multipleModuleInput.currentTemp = this.avgCurrentTemp;
    }



    findHighestDeadbands() {
        this.coolingDeadbandArray = [];
        this.heatingDeadbandArray = [];
        this.profiles.forEach(profile => {
            if (profile != undefined) {
                this.coolingDeadbandArray.push(this.zoneSettings[profile.profile.name].coolingDeadband.val);
                this.heatingDeadbandArray.push(this.zoneSettings[profile.profile.name].heatingDeadband.val);
            }
        });

        this.multipleModuleInput.coolingDeadband = (Math.max(...this.coolingDeadbandArray)).toString();
        this.multipleModuleInput.heatingDeadband = (Math.max(...this.heatingDeadbandArray)).toString();
        return this.multipleModuleInput
    }


    getEquipRef(zoneId: string) {
        const room = this.widgetData?.find(et=> et._id == zoneId);
       if (room) {
           this.roomObj = room;
           let equip = room.entities.filter(equip => equip.type== 'equip')[0];
           if(equip) {
               this.refs.roomRef = equip?.referenceIDs?.room;
               this.refs.ccuRef = equip?.referenceIDs?.ccu;
               this.refs.equipRef = equip._id;
               this.refs.ahuRef = equip?.referenceIDs?.ahu ||  equip?.referenceIDs?.gateway;
           }
           
       }
           
   }

    formatZoneName(equipName: any) {
        let profileName = this.helperService.setZoneProfileName(equipName);
        return profileName;
    }

    async getBuildingDetails(siteId) {
        this.siteService.getReadById(siteId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(({ rows }) => {
                this.buildings.buildingDetails = rows;
                this.siteName = this.buildings?.buildingDetails[0].dis;
            });
    }

    onSettingsChange(settingType: string = undefined, event: any, profileEquipName: string = undefined) {
        let settingValue = (settingType) ? event.target.value : event.value;
        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
        settingType = (settingType) ? settingType : event.mode;

        this.onControlChange(settingType, settingValue, settingsObj);
    }

    updateScheduleTypeOnButtonClick(data: any) {
        if (data.type.includes('scheduleType')) {
            this.oldscheduleTypeVal = this.scheduleTypeVal;
            this.scheduleTypeVal = data.value.toString();
            if (parseInt(data.value) != 2) {
                let refs = ObjectUtil.deepCopy(this.refs);
                refs['scheduleId'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'days'], this.refs.roomRef);
                refs['floorRef'] = this.roomObj['referenceIDs']['floor']
                this.namedScheduleService.refs = refs;
            }
        }
    }

		updateMultiModuleProfileOnBtnClick(event) {
			const data = [];
			this.helperService.multiModulePointsOverride = true;
			if (event.type.includes('zonePriority')) {
					this.multiModuleZonePriorityList.forEach((_item) => {
							const settingValue = event.value;
							const settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
							data.push({
									type: event.type,
									value: settingValue,
									priorityArray: settingsObj[event.type].priorityArray || []
							});
					});
                    this.setUserUpdatedValue();
					this.helperService.handleInputChange(data, true);
			} 
			if (event.type.includes('scheduleType')) {
				this.updateScheduleTypeOfMultiModuleProfile(event);
			}

			this.helperService.multiModulePointsOverride = false;
		}

		updateScheduleTypeOfMultiModuleProfile(event: any) {
			const data = [];
			if (parseInt(event.value) == 1) {
				// zone containment
				const conflictArr = this.schValidation(event);
				// if there's no conflict
				if (!conflictArr.length) {
						this.multiModuleScheduleTypeList
								.forEach((item) => {
										const settingVal = event.value;
										const settingsObj = (item) ? this.zoneSettings[item.profile] : this.zoneSettings;
										data.push({
												type: event.type,
												value: settingVal,
												priorityArray: settingsObj[event.type].priorityArray || []
										});

								});
                        this.setUserUpdatedValue();
						this.helperService.handleInputChange(data, true);
				} else {
						this.showForceTrimPopup(event);
				}
		} else {
				this.multiModuleScheduleTypeList
						.forEach((_item) => {
								const settingValue = event.value;
								const settingObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
								data.push({
										type: event.type,
										value: settingValue,
										priorityArray: settingObj[event.type].priorityArray || []
								});
						});
                this.setUserUpdatedValue();
				this.helperService.handleInputChange(data, true);
			}
		}

    onBtnClick(event: any) {
        this.refs.siteDetails = this.buildings.buildingDetails?.find(building => building.id === `r:${this.refs.siteRef}`) || {};
        if (event.type.includes("zonePriority")) {
            this.oldZonePriorityVal = this.zonePriorityVal;
            this.zonePriorityVal = event.value.toString();
        }
        this.updateScheduleTypeOnButtonClick(event);
        if ((event.type.includes('scheduleType') && parseInt(event.value) == 2)) {
            this.scheduleTypeVal = 2;
        } else {
            if ((event.type.includes("zonePriority") || event.type.includes("scheduleType")) && this.multiModuleProfile) {
                this.updateMultiModuleProfileOnBtnClick(event);
            }
            else {
                this.updateScheduleTypeForSingleModuleProf(event);
            }
        }
    }

		updateScheduleTypeForSingleModuleProf(data) {
			if (data.type.includes('scheduleType')) {
				if (parseInt(data.value) == 1) {
						const conflictArr = this.schValidation(data);
						// if there's no conflict
						if (!conflictArr.length) {
								this.onControlChange(data.type, data.value, this.zoneSettings);
						} else {
								this.showForceTrimPopup(data);
						}
				} else {
						this.onControlChange(data.type, data.value, this.zoneSettings);
				}
			} else {
				this.onControlChange(data.type, data.value, this.zoneSettings);
			}
		}


    onControlChange(mode: string, inputData: any, priorityArray: any) {
        let data = []
				const addEntity = mode.includes('scheduleType');
        data.push({
            type: mode,
            value: inputData,
            priorityArray: priorityArray[mode].priorityArray || []
        })
        this.setUserUpdatedValue();
        this.helperService.handleInputChange(data, addEntity);
    }

    UpdateBuildingsDataForVacationEdit(entityid: any) {
        this.siteService.findByQuery(this.query + this.refs.siteRef).pipe(
            map(this.helperService.stripHaystackTypeMapping),
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure)).subscribe(
            (response: any) => {
                this.buildingMaster = response;
                this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings['devices'] = this.getNodes(response, ['device']);
                this.buildings['equips'] = this.getNodes(response, ['equip']);
                this.buildings["floors"] = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
                this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule') || [];
                this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
                    if (this.refs.roomRef) {
                        this.updateZoneEntitesOnMessage(this.refs.roomRef);
                    }
                }
                if (this.ccuOrZone.actionUsed == 'click' && this.ccuOrZone.type == 'ccu') {
                    this.getScheduleInfoForCCU(this.refs.ahuRef);
                }
            },
        );
    }

    updateZoneEntitesOnMessage(roomRef) {
        this.getZoneSettings('zoneVacation', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], roomRef), 'vacation');
        this.getZoneSettings('zoneSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'special'], roomRef), 'vacation');
        this.getUserSettingData("vacationAdded");
    }

    getUserPref(siteRef) {
        this.userPref.getUserPerf(siteRef).subscribe(res=>{
            this.userPrefData['ccuOrder'] = res['ccuIdsByInterest'];
            this.setCCUOrder();
            this.setCcuColors();
        },err=>{
            console.log(err);
        })
    }

    getBuildingFloors(siteRef: string, isReload = false) {
        this.helperService.tunersInfo = new Map();
        this.getUserPref(siteRef);
        this.getEntitySubscription?.unsubscribe();
        this.getEntitySubscription =
            this.siteService.findByQuery(this.query + siteRef).pipe(
                  map(this.helperService.stripHaystackTypeMapping),
                  map(this.helperService.listEntities),
                  map(this.helperService.listZones),
                  map(this.helperService.createEntityStructure),
            ).subscribe(
                async (response: any) => {
                    this.buildingMaster = response;
                    this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                    const entities = ArrayUtil.deepFlatten(response, 'entities');
                    this.buildings['devices'] = this.getNodes(response, ['device']);
                    this.buildings['equips'] = this.getNodes(response, ['equip']);
                    const allEquips = this.getNodes(entities, ['equip']);
                    this.allDevices = this.getNodes(entities, ['cm', 'device']);
                    this.allNodes = this.getNodes(entities, ['node']);
                    this.allEquips = [...allEquips, ...this.allDevices, ...this.allNodes];

                    let floors = (this.helperService.getBuildingComponents(this.buildingMaster, 'floor')||[]).map(floor=>{
                        floor['floorNum'] = Number(floor['floorNum']);
                        return floor;
                    });
                    if (this.compareValues(floors, this.buildings.floors)) {
                        this.buildings.floors = floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
                    } else {
                        for (let i = 0; i < this.buildings.floors.length; i++) {
                            this.buildings.floors[i].entities = floors[i].entities
                        }
                    }
                    
                    this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule');
                    this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                    this.buildings.floorsMaster = this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
                    this.buildings.ccus = this.buildings.floorCcu = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
                    this.buildings['schedulable'] = entities.filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                    const allZones = this.getNodes(entities, ['room']);
                    this.buildings.zones = allZones;
                    this.setCCUOrder();
                    this.setCcuColors();

                    let concatQuery = '';
                    this.buildings.ccus.forEach((ccuObj, i) => {
                        concatQuery += 'ahuRef==@' + ccuObj.referenceIDs.ahu + `${this.buildings.ccus.length - 1 == i ? '' : ' or '}`
                    });
            
                    //For the following htig we are checkong of  bypass damper and oao profile ad these are different equips form the sytem profile
                    const combinedQuery = `${concatQuery} and ((equip and not zone and (bypassDamper or (oao) or (domainName==@dabAdvancedHybridAhuV2_connectModule) or (domainName==@vavAdvancedHybridAhuV2_connectModule) or (domainName==@ccuConfiguration))))`;
                    this.siteService.findByQuery(combinedQuery).subscribe(res => {
                        res = this.helperService.stripHaystackTypeMapping(res);

                        // grouping the rows based on a  ahuRef and  and  system profile type
                        // Checking if the current 'ahuRef' exists in the data object
                        const groupedRows = res.rows.reduce((data, row) => {

                            if (!data[row.ahuRef]) {
                                data[row.ahuRef] = {};
                            }
                            // Storing the row data under the profile within the ahuRef object
                            data[row.ahuRef][row.profile] = { ahuRef: row.ahuRef, profile: row.profile, id: row.id,domainName : row.domainName };
                            return data;
                        }, {});

                        this.buildings.ccus.forEach(ccuObj => {
                            const checkConnectModule = groupedRows[ccuObj.referenceIDs?.ahu];
                            const connectModule = checkConnectModule.dabAdvancedHybridAhuV2_connectModule || checkConnectModule.vavAdvancedHybridAhuV2_connectModule;
                            const bypassRow = groupedRows[ccuObj.referenceIDs?.ahu]?.BYPASS_DAMPER;
                            const oaoRow = groupedRows[ccuObj.referenceIDs?.ahu]?.OAO;
                            const ccuConfigObj = groupedRows[ccuObj.referenceIDs?.ahu]?.ccuConfiguration;
                            if (bypassRow) {
                                ccuObj.hasBypassProfile = true;
                            }

                            if (oaoRow) {
                                this.oaoDomainNameCheck = oaoRow.domainName === "smartnodeOAO" ? true :false
                                ccuObj.hasOAOProfile = true;
                                ccuObj.referenceIDs['oaoRef'] = oaoRow.id;
                            }
                            if (ccuConfigObj) {
                                ccuObj.referenceIDs['ccuConfigRef'] = ccuConfigObj.id;
                            }
                            //Here after getting the connectModule id we are calling the getOAOisPairedwithConnectModule
                            //method where we are checking for Oao is there or not 
                            if(connectModule) {
                                ccuObj.referenceIDs['vavDabConnectModule'] =connectModule.id ;
                                this.getOAOisPairedwithConnectModule(connectModule.id,ccuObj)
            
                            }
                        });
                    });

                    // Also make the call to get the global dashboard associated with this userId
                    // Get site Details
                    this.refs.siteDetails = this.buildings.buildingDetails?.find(building => building.id === `r:${siteRef}`) || {};
                    this.buildings.equips = this.helperService.getBuildingComponents(this.buildingMaster, 'equip');
                    this.buildings.tuners = this.getSiteObj(['tuner', 'equip']) || [];

                    this.heatmapService.setBuildingCcus(this.buildings.ccus);
                    this.heatmapService.setBuildingFloors(this.buildings.floors);
                    this.heatmapService.setBuildings(this.buildings);
                    this.fetchCCuListWithPairedZones();
                    await this.fetchColourScaleDeadbands();
                    this.skeletonContentLoaded = true;
                },
            );
        this.isFloorPlanLoaded = false;
        this.refs.siteRef = siteRef;
        this.getEquipsAndDevicesFromSiteId(siteRef);
        let buildingIDArray = this.refs.siteRef.split(' ');
        this.pubNubService.subscribe(buildingIDArray);
        //Set time zone
        this.vacationsService.getSIteTz(this.refs.siteRef);
        this.vacationsService.siteTimezone.subscribe(tz => this.siteTz = tz);
        this.resetChatSession();
    }

    resetChatSession () {
        this.chatSessionActive = false;
        this.aiDashboardData = null;
    }

    async getOAOisPairedwithConnectModule(equipId, ccuObj) {
        try {
            const query = `equipRef == @${equipId} and (domainName==@oaoDamper)`;
            const checkConnectModuleisPaired = await firstValueFrom(this.siteService.findByQuery(query));
    
            if (checkConnectModuleisPaired?.rows?.length) {
                ccuObj.hasOAOProfile = true;
                this.connectModuleOaoIspaired = true;
                ccuObj.checkConnectModuleAsOao = true;
            }
        } catch (error) {
            this.OaoShow = false;
            console.error("Error in getOAOisPairedwithConnectModule:", error);
        }
    }


    compareValues(arr1, arr2) {
        if (arr1.length != arr2.length) return true;
        arr1.filter((o1) => {
            return arr2.some((o2) => {
                return o1._id === o2._id && o1.name == o2.name; // return the ones with equal id
            });
        }).length != arr1.length;
    }

    async fetchColourScaleDeadbands() {
        this.heatingDeadBandRoomRefList = [];
        this.coolingDeadBandRoomRefList = [];
        this.buildings.zones.forEach((zone) => {
            this.heatingDeadBandRoomRefList.push({ 'pointId': this.pointIDOrPointIDsWithComma(this.buildings.schedulable, zone._id, ['heating', 'deadband', 'base','schedulable']), 'roomRef': zone._id, 'zoneName': zone.name, 'val': '' });
            this.coolingDeadBandRoomRefList.push({ 'pointId': this.pointIDOrPointIDsWithComma(this.buildings.schedulable, zone._id, ['cooling', 'deadband', 'base', 'schedulable']), 'roomRef': zone._id, 'zoneName': zone.name, 'val': '' });
            
        });
        if (this.heatingDeadBandRoomRefList?.length || this.coolingDeadBandRoomRefList?.length) {
            let pointsList = [];
            let hbList = this.heatingDeadBandRoomRefList.map(x => { if (x.pointId) { return x.pointId; } }).filter(notUndefined => notUndefined !== undefined);
            if (hbList.length > 0) {
                hbList = hbList.reduce(this.commaSeparatedPointIds).split(',');
                pointsList = [...pointsList, ...hbList];
            }
            let cbList = this.coolingDeadBandRoomRefList.map(x => { if (x.pointId) { return x.pointId; } }).filter(notUndefined => notUndefined !== undefined);
            if (cbList.length > 0) {
                cbList = cbList.reduce(this.commaSeparatedPointIds).split(',');
                pointsList = [...pointsList, ...cbList];
            }
            if (!pointsList.length) return;
            let result:any 
            try {
              result = await lastValueFrom(this.siteService.getCurrenthisReadMany(
                  pointsList
              ))
            } catch (error) {
              console.log(error);
            }
            
            let rows = result?.rows || [];;

            rows.forEach((res) => {
                let pointIdFromRes = this.helperService.stripHaystackTypeMapping(res.id.split(' ')[0]);
                if (res.cooling) {
                    this.coolingDeadBandRoomRefList.filter((x) => {
                        if (x?.pointId?.includes(pointIdFromRes) && x?.pointId?.includes(',')) {
                            x.val += (((res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '') + ',')
                        }
                        else if (x?.pointId?.includes(pointIdFromRes)) {
                            x.val = (res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '';
                        }

                    });
                }
                else if (res.heating) {
                    this.heatingDeadBandRoomRefList.filter((x) => {
                        if ((x?.pointId?.includes(pointIdFromRes)) && (x?.pointId?.includes(','))) {
                            x.val += (((res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '') + ',')
                        }
                        else if (x?.pointId?.includes(pointIdFromRes)) {
                            x.val = (res.data.length >= 1) ? this.helperService.stripHaystackTypeMapping(res.data[0].val).split(' ')[0] : '';
                        }
                    });
                }
            });

            let heatingDeadbandValues = [...this.heatingDeadBandRoomRefList.map(x => {
                if (x.pointId) {
                    if (x.val?.includes(',')) {
                        x.val = this.findMaxValuefromCommaSeparated(x.val);
                        return x.val.toString();
                    }
                    else { return x.val; }
                }
            })].filter(notUndefined => notUndefined !== undefined);
            let coolingDeadbandValues = [...this.coolingDeadBandRoomRefList.map(x => {
                if (x.pointId) {
                    if (x?.val.includes(',')) {
                        x.val = this.findMaxValuefromCommaSeparated(x.val);
                        return x.val.toString();
                    }
                    else { return x.val; }
                }
            })].filter(notUndefined => notUndefined !== undefined);
            this.setSpreadRange(this.heatmapService.getMostOccuringHighestArrayValue(heatingDeadbandValues), this.heatmapService.getMostOccuringHighestArrayValue(coolingDeadbandValues));
            this.heatmapService.setDeadbandScale(this.deadbandScale);
            this.heatmapService.setDeadbandRoomRefList(this.heatingDeadBandRoomRefList, this.coolingDeadBandRoomRefList);

        }
    }

    setSpreadRange(heatingDeadband, coolingDeadband) {
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            heatingDeadband = this.unitService.convertingDeadBandValue(heatingDeadband)
            coolingDeadband = this.unitService.convertingDeadBandValue(coolingDeadband)
        }
        const deadbandScaleValues: number[] = [
            heatingDeadband > 0 ? -(3 * heatingDeadband) : 0,
            heatingDeadband ? -(2 * heatingDeadband) : 0,
            heatingDeadband ? -(1 * heatingDeadband) : 0,
            0,
            1 * coolingDeadband,
            2 * coolingDeadband,
            3 * coolingDeadband
        ];

        this.deadbandScale = deadbandScaleValues.map(value => Math.round((value + Number.EPSILON) * 100) / 100);
        this.heatmapService.setDeadbandScale(this.deadbandScale);
        this.heatmapService.setDeadbandRoomRefList(this.heatingDeadBandRoomRefList, this.coolingDeadBandRoomRefList);
    }

    fetchCCuListWithPairedZones() {
        let concatQuery = '';
        this.buildings.ccus.forEach((ccuObj, i) => {
            concatQuery += 'ahuRef==@' + ccuObj.referenceIDs.ahu + ' or gatewayRef==@' + ccuObj.referenceIDs.ahu + `${this.buildings.ccus.length - 1 == i ? '' : ' or '}`
        });

        let query = ' not ccu and (' + concatQuery + ')';
        this.ccusWithPairedZones = [];

        this.siteService.getHaystackDataByQuery(query).subscribe(res => {
            res = this.helperService.stripHaystackTypeMapping(res);
            if (res.rows.length > 0) {
                this.buildings.ccus.forEach((ccu) => {
                    let rows = res.rows.filter(data => (data.ahuRef == ccu.referenceIDs.ahu || data.gatewayRef == ccu.referenceIDs.ahu));
                    if (rows.length > 0) {
                        const zoneList = [];
                        rows.forEach((row) => {
                            const zoneId = row.roomRef;
                            const zoneExists = zoneList.filter((x) => { this.getFilterZone(x, zoneId); });
                            if (!zoneExists || (zoneExists.length == 0)) {
                                zoneList.push(zoneId);
                            }
                        });
                        this.ccusWithPairedZones.push({ 'id': ccu._id, 'CCUName': ccu.name, 'ahuRef': ccu.referenceIDs.ahu, 'pairedZoneList': zoneList });
                    } else {
                        this.ccusWithPairedZones.push({ 'id': ccu._id, 'CCUName': ccu.name, 'ahuRef': ccu.referenceIDs.ahu, 'pairedZoneList': [] });
                    }
                });
            }
            this.heatmapService.setCcusWithPairedZones(this.ccusWithPairedZones);
            this.loadFloorLayouts(this.buildings.floorsMaster);
        },error =>{
            this.heatmapService.setCcusWithPairedZones(this.ccusWithPairedZones);
            this.loadFloorLayouts(this.buildings.floorsMaster);
        });
    }

    getFilterZone(x, zoneId) {
        if (x.zoneId == zoneId) { return x; }
    }
    
    fetchTimeSeriesData(daterange, adIdentifier: string = "") {
        //site timezone
        let tz: string = this.siteService.getIANATimeZone(this.siteTz);
        const utcOffset = dayjs().tz(tz).utcOffset();
        let id = this.ccuOrZone.type + this.ccuOrZone.actionUsed + (this.ccuOrZone.type == 'ccu' ? this.refs.ahuRef : this.refs.roomRef) + adIdentifier + daterange.startDate.toString() + '-' + daterange.endDate.toString(); // this will serve as unique id subs handling

        //Set up check for download widgets status of data fetch
        if (this.ccuOrZone.actionUsed == 'click') {
            this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                if (singlePoint != undefined) {
                    this.checkWidgestLoaded[key.replace(singlePoint, '')] = false;
                }
            });
        }

        let alreadySubscribed = (this.subscriptionCollection.filter(subs => {
            return subs.id == id
        }).length > 0)

        if (!alreadySubscribed) {
            this.enableDownLoad = false;
            let pointList = [...this.graphService.graphPointsCollection.values()].filter(notUndefined => notUndefined !== undefined);
            if(pointList.length > 0) {
                let hisReadManySubject = this.siteService.gethisReadManyInterpolate(
                    pointList,
                    daterange,
                    tz
                ).subscribe((res) => {
                    let tempZones = [];
                    if (res?.rows) {
                        this.enableDownLoad = true;
                        let rows = this.filterTimeSeriesData(res);
                        this.graphService.graphPointsCollection.forEach((singlePoint, key) => {
                            // singlePoint
                            if (singlePoint != undefined) {
                                let filteredPointResponse = ObjectUtil.deepClone(rows.filter((x) => {
                                    let ref = this.helperService.stripHaystackTypeMapping(x.id).split(' ')[0];
                                    if ((typeof (singlePoint) != undefined) && ref.includes(singlePoint)) { return x; }
                                })[0]);
                                if (filteredPointResponse) {
                                    let hisData = [];

                                    hisData = [].concat(filteredPointResponse.data).map((row => {
                                        row.val = row['d_d'] ? row['d_d'].split(':')[1] : null;
                                        row.ts = DateUtil.convertUTCtoTimezone(row.time.split(' UTC')[0].split('t:')[1], utcOffset).format('YYYY-MM-DDTHH:mm:ss');
                                        delete row.time;
                                        delete row.d;
                                        delete row.d_d;
                                        if (row.val !== '' && row.ts != '') {
                                            return row;
                                        }
                                    }));

                                    let zoneType = key.replace(singlePoint, '');

                                    //Handle cooling and heating user limits for multi module
                                    if (zoneType.includes('heatingUserLimitMin') || zoneType.includes('heatingUserLimitMax') || zoneType.includes('coolingUserLimitMax') || zoneType.includes('coolingUserLimitMin')) {
                                        let tempHisData = [];
                                        if (!this.heatCoolLimitsHisData.has(singlePoint)) {
                                            //Cache the limits in service
                                            tempHisData = hisData.map(x =>Object.assign({}, x));
                                            this.heatCoolLimitsHisData.set(singlePoint, tempHisData);

                                        }
                                        else {
                                            //Check if it exists in cache in case of multi module
                                            tempHisData = this.heatCoolLimitsHisData.get(singlePoint);
                                        }
                                        tempZones[zoneType] = tempHisData;
                                    }
                                    else {
                                        let tempHisData = [];
                                        tempHisData = hisData.map(x => Object.assign({}, x));
                                        tempZones[zoneType] = tempHisData;
                                    }

                                    if (this.ccuOrZone.actionUsed == 'click') {
                                        this.checkWidgestLoaded[zoneType] = true;
                                    }
                                    //added data to be exported
                                    if (this.ccuOrZone.actionUsed == 'click' && hisData.length > 0) {
                                        this.dataForExport[zoneType] = hisData.map(x => Object.assign({}, x));
                                    }
                                }
                            }
                        });
                        this.subscriptionCollection.find(subs => subs.id == id).subscription.unsubscribe();
                    }
                    this.graphService.setData(tempZones);
                    if (this.systemWidgets?.[0]?.name?.includes("Default System Profile")) {
                        this.heatMapToolTipService.deletefromOrigGraphData("comfortIndex" + this.refs.ahuRef);
                        this.heatMapToolTipService.deletefromOrigGraphData("modes" + this.refs.ahuRef);
                        //Hide the accordian
                        let runtimeAccordian = (<HTMLInputElement>document.getElementById('accordian_' + this.systemWidgets[0].id + this.refs.ahuRef))
                        runtimeAccordian.click();
                    }
                }, noResponse => {
                    this.graphService.resetDataonInvalidDate();
                });
                this.subscriptionCollection.push({ id: id, subscription: hisReadManySubject });
            } else {
                this.enableDownLoad = true;
                this.graphService.resetDataonInvalidDate();
            }
        } else {
            this.enableDownLoad = true;
        }
    }

//***************************************Conversion Functions for graph data*************************************************************
filterTimeSeriesData(data) {
    try {
        if((this.profileObj?.profileType == 'pid') && this.zonehasDomainName) {
            const checkDomainNameMatchFor1 = (row) => {
                return this.piAnalogSensorsDmDomainNames?.includes(row?.domainName) ||
                       this.thermistorSensorDmDomainNames?.includes(row?.domainName) ||
                       this.nativeSensorDMDomainNames?.includes(row?.domainName);
              };
            const checkDomainNameMatchFor2 = (row) => {
                return this.piAnalog2SensorsDmDomainNames?.includes(row?.domainName)
            };
            const matchingRows1 = data?.rows?.filter(checkDomainNameMatchFor1);
            const matchingRows2 = data?.rows?.filter(checkDomainNameMatchFor2);
            let target = data?.rows?.filter(item => item?.domainName == 'pidTargetValue');
            let dynamicTargetValue = data?.rows?.filter(item => item?.domainName == 'dynamicTargetValue');
            if(target?.length>0 && matchingRows1?.length>0) {
                data.rows.filter(item => item?.domainName == 'pidTargetValue')[0].unit = matchingRows1?.filter(item => item?.domainName == this.sensorType)[0]?.unit ;
            }
            if(dynamicTargetValue?.length>0 && matchingRows2?.length>0) {
                data.rows.filter(item => item?.domainName == 'dynamicTargetValue')[0].unit = matchingRows2[0]?.filter(item => item?.domainName == this.sensorType2)[0]?.unit;
            }
        }
    }
    catch(error) {
        console.error("Error processing domain name matches or unit assignments:", error)
    }
    let res = this.ccuOrZone.type == 'ccu' ? data['rows'] : this.zoneSingleDualGraph(data);
    res = this.convertStagedFanValuetoVolt(res);
    let dataUnitExist = res?.filter(item => this.unitService.unitListArray.includes(item.unit)) || [];
    const dataUnitNotExist = res?.filter(item => !this.unitService.unitListArray.includes(item.unit)) || [];
    const userPreference = JSON.parse(localStorage.getItem('user_preference'));
    if (dataUnitExist.length > 0) {
        dataUnitExist = this.unitService.convertDataOnUnit(dataUnitExist,userPreference);
    }
    return dataUnitNotExist.concat(dataUnitExist) ;
}

    convertStagedFanValuetoVolt(data) {
        const dataChanges = [];
        data.forEach(x => {
            if (x.hasOwnProperty('predefined') && x.hasOwnProperty('fan') && x.hasOwnProperty('speed')) {
                x.data.forEach(y => {
                    if (y.d_d) {
                        const value = Number(this.helperService.stripHaystackTypeMapping(y.d_d)) * 10 / 100;
                        const valueToString = value.toString();
                        y.d_d = `n:${valueToString}`

                    }
                })
                dataChanges.push(x)
            } else {
                dataChanges.push(x)
            }
        })
        return dataChanges;
    }
//******************************************************************Conversion FUnctions Ends************************************************
    getSystemProfileName(profile) {
        return this.commonService.getSystemProfileName(profile);
    }

    getSystemprofileEquiNames() {
        localStorage.setItem('refs', this.refs);
        this.deviceHelper.systemProfileSubject.pipe(first()).subscribe((systemProfile) => {
            this.equipGraphicSystemProfile = systemProfile['systemProfileName']
            this.equipGraphicsProfiles = this.getSystemProfileName(systemProfile['systemProfileName']);
           
            if(this.equipGraphicsProfiles.toLowerCase() == 'Default System Profile'.toLowerCase()
            || ["VVT-C External Ahu", "DAB External Ahu","VAV External Ahu"].map(item => item.toLowerCase()).indexOf(this.equipGraphicsProfiles.toLowerCase()) > -1) {
                this.showEquipGraphics = false 
            } else {
                this.showEquipGraphics = true 
            }
            this.getSystemProfileConfig()
        });
    }

    checkFilesTaggedforProfile(siteRef, profileID){
        if(this.fileUpload) {
            this.notesService.getTaggedFiles(siteRef,profileID).subscribe((res) => {
                this.taggedFilesListSystemProfile = res;
                if(res && res.length > 0){
                    this.enableViewAssetsSystemProfile = true;
                } else {
                    this.enableViewAssetsSystemProfile = false;
                }
            }, (error) => {
                console.log('Error in fetching tagged files', error);
            })
        }
    }

    async getSystemSettingsOnClick(ccuObj: any, fromSummaryTable = false) {
        this.OaoShow = false;
        this.analyticsLayoutService.exportAnalyticsExcelData = [];
        this.analyticsLayoutService.totalAnalyticsCount = 0;
        this.analyticsLayoutService.isAnalyticsListLoading = false;
        this.isDownloadExcelPending = false;
        let modalLeftPos: number = 120;
        this.hoverdOrClickEventFalg = true; 
        this.showNotesAccordion = false;
        this.modalPopupOpened = true;
        this.messageService.scrollIndex = window.scrollY;
        (<HTMLElement>document.getElementsByClassName('fs-heatmap-wrapper__content')[0]).style.position = 'fixed';
        this.ccuOrZone = { type: 'ccu', actionUsed: 'click' };
        //Clean up the hovered modal
        if (this.ccuOrZone.actionUsed == "click" && this.ccuOrZone.type == "zone") {
            return;
        }
        this.closeModal();
  
        this.intializeDate();
        ccuObj = this.setCCUData(ccuObj);
        this.refs.ccuName = ccuObj.ccuName;
        this.refs.ahuRef = ccuObj.ccuAhu;
        this.refs.siteRef = ccuObj.siteRef;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.ccuRef = ccuObj.ccuId;
        this.refs.oaoRef = ccuObj.oaoRef;
        this.refs.ccuConfigRef = ccuObj.ccuConfigRef;
        this.refs.gateway = ccuObj.gateway;
        this.refs.ccuId = ccuObj.ccuId;
        this.refs.vavDabConnectModule = ccuObj?.vavDabConnectModule;
        this.ccuOrZone = { type: 'ccu', actionUsed: 'click' };
        this.hoverdOrClickEventFalg = true; 
        this.showNotesAccordion = false;
        this.siteNameModalStyleClickedFlag = true;
        this.siteNameModalStyleOnClicked = {
            top: 45 + 'px',
            left: modalLeftPos + 'px',
            height: 'auto',
            width: '82%',
            display: 'block'
        };

        if (fromSummaryTable) {
            this.siteNameModalStyleOnClicked.top = '45px';
        }
        this.hideSummaryTableSection = true;
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');


        if(this.fileUpload){
            this.checkFilesTaggedforProfile(ccuObj.siteRef, ccuObj.ccuId);
        }

        try {
            this.widgetData = await lastValueFrom(this.siteService.findByQuery('ccuRef==@' + ccuObj.ccuId).pipe(
            map(this.helperService.stripHaystackTypeMapping),
            map(this.helperService.listEntities),
            map(this.helperService.listTuners),
            map(this.helperService.createEntityStructure)));
        } catch(error) {  
            this.widgetData = [];
            console.log('Error in fetching ccu data', error);
        }
        
        this.getSystemprofileEquiNames();
        const equipObj =  this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gatewayRef);
        const hasDomainName = equipObj?.markers?.includes('domainName');
        this.isdomainName = hasDomainName;
        let humidifierEnabled:any;
        let connectivityWidgetDetails = this.getConnectivityWidgetStatus();
        if(equipObj) {
            const humidifierRef = this.helperService.getPointIdbyTags(equipObj, ['point', 'system', 'config', 'enabled', 'output', 'sp', 'humidification'])
            if(humidifierRef?.length>0) {
                this.siteService.getWritablePointData(humidifierRef[0]).subscribe((response) => {
                    let humidityValue = response.rows[0];
                    if(this.helperService.stripHaystackTypeMapping(humidityValue.val).split(' ')[0] == 1){
                        humidifierEnabled =  true
                    }
                });
            } 
        }
        
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;

        this.dataForExport = {};
        this.checkWidgestLoaded = {};
        

        this.loadTunerData(ccuObj);
       
        this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj,true,humidifierEnabled,connectivityWidgetDetails);
        this.getSystemProfile();
        this.getSystemProfileType()
        this.loadSystemWidgetsData();
        this.getCcuSettings(this.refs.ahuRef);
        setTimeout(() => {
            /**
                * Note this is specially for vavAdvancedHybridAhuV2/dabAdvancedHybridAhuV2that to connect module is paired with equip.
                * As of now, without load and sat based, we cannot configure the connect module. In the future, 
                * there are chances to configure the connect module directly.
                * Handling in the different method this method is used only for conditioning mode check.
                * As of the current implementation, we are not implementing connect module in widgets and equip graphics.
                * In the future, we can implement the connect module in widgets and equip graphics based on requirements.
                * Sat based will not come in the connect module.
            */
            if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1 && this.refs.vavDabConnectModule) {
                this.checkIfConnectModuleIsPaired();
             } 
             if(ObjectUtil.isNotUndefinedOrNull(this.refs.oaoRef)) {
                this.checkIfOAOIsPaired(this.refs.ahuRef); 
             }
                this.getCCUSettingData();
       
        }, 1000);
      
      
    
        if (this.ccuOrZone.actionUsed == "click" && this.ccuOrZone.type == "ccu") {
            this.getSystemProfileConfig();
            this.getScheduleInfoForCCU(this.refs.ahuRef);
        }
      
        this.getNotesList(ccuObj.ccuId, "system")
        setTimeout(() => {
            window.scroll(0,0);
        },50);
        //start the setInterval call to call the api to update the data change if any every 5 sec
        this.pollingFunction();
        this.pollGraphData();
    }


    async getNotesList(id, type) {
        this.notesList = await this.notesService.fetchNotes(this.refs.siteRef, "", ["pinned"], [], null);
        let notes = this.notesService.checkForZoneCcuReName(this.notesList, this.buildings.allZones, this.buildings.ccus)
        if (notes?.length > 0) {
            this.notesList = notes
        }
        this.filterNotesList(id, type);
    }
    
    filterNotesList(id, type) {
        this.filteredNotesList = [];
        this.notesList.forEach(note => {
            if (type == 'system' && !note.archived && ((note.taggedCcus.indexOf(id) > -1) || (!note.taggedCcus.length && !note.taggedZones.length))) {
                this.filteredNotesList.push(note)
            } else if (!note.archived && (note.taggedZones.indexOf(id) > -1) || (!note.taggedCcus.length && !note.taggedZones.length)) {
                this.filteredNotesList.push(note)
            }
        });
        this.filteredNotesList = this.notesService.sortNotesList(this.filteredNotesList);
        if (this.filteredNotesList?.length) {
           this.notesService.getHierarchy(this.refs.siteRef).toPromise().then(siteHierarchy => {
              this.filteredNotesList  = this.notesService.addTooltip(this.filteredNotesList, siteHierarchy);
            }).catch((err) => {
              this.alertService.error("Failed to fetch CCU and Zone hierarchy.");
            })
        }
    }


    pollingFunction() {
        clearTimeout(this.messagingSubscribe);
        this.messagingSubscribe = setTimeout(() => {
            this.helperService.clearWritablePointsUpdatedStatus();
            this.helperService.getPointData().subscribe(res => {
                this.pollingFunction();
            });
        },5000);
    }

    getSystemProfile(){
        let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
            //Modify the runtime graph object
            if (systemProfile) {
                //Runtime graphs logic
                if(systemProfile.ccuRef != this.refs.ahuRef) {
                    return
                }                
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                    this.systemWidgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                    this.loadRuntimeWidgetData(this.refs.ahuRef);
                    subs_sysProfile.unsubscribe();
                }
                else {
                    let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                        if (runtimeProfile) {
                            //We can directly use after fetching once
                            this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                            this.systemWidgets[0] = runtimeProfile.widgetData;
                            this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                            subs_runtimeSub.unsubscribe();
                            subs_sysProfile.unsubscribe();
                        }
                    });
                }
                let equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
                const hasDomainName = equipObj?.markers?.includes('domainName');
                if (this.checkForAdvanceAhuProfiles.indexOf(systemProfile.systemProfileName) > -1) {
                    (async () => {
                        try {
                            const staticPreesurePoint = await this.toogleCheckforStaticPressurePoint();
                            const co2BasedDamperControlOn = await this.co2BasedDamperControlOn();
                            this.deviceHelper.getSystemProfileRuntimeWidgetData(
                                systemProfile.systemProfileName,
                                this.refs.siteRef,
                                this.refs.ahuRef,
                                false,
                                this.refs.gateway,
                                this.refs.ccuId,
                                this.refs.ccuName,
                                hasDomainName,
                                staticPreesurePoint,
                                co2BasedDamperControlOn
                            );
                        } catch (error) {
                            console.error("Error:", error);
                        }
                    })();
                } else {
                    this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, false, this.refs.gateway,this.refs.ccuId,this.refs.ccuName,hasDomainName,null,null);
                }
                this.onSystemMode = false;
                this.setSystemWidget(systemProfile);
            }
            else {
                //means no runtime for this profile , fetch the data
                //All points have been fetched , get bulk read
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
            }
            if (this.systemReconfigurationSettings) {
                this.getSystemReconfigurationData(this.systemReconfigurationSettings);
            }
        });
    }

    setSystemWidget(systemProfile){
        //fetch Comfort index profile
        if (systemProfile.systemProfileName !== "vavAdvancedHybridAhuV2" && systemProfile.systemProfileName.includes("VAV") || systemProfile.systemProfileName.includes("vav")) {
            this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemVavComfortIndexGraphWidget()).getGraphWidget();
        }
        else if (systemProfile.systemProfileName !== "dabAdvancedHybridAhuV2" && systemProfile.systemProfileName.includes("DAB") || systemProfile.systemProfileName.includes("dab")) {
            this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemDabComfortIndexGraphWidget()).getGraphWidget();
        }
        else if (systemProfile.systemProfileName.includes("DEFAULT") ) {
            this.disableAllInputs();
            //for defualt profile remove comfortindex,modes widget and runtime widgt
            this.removeWidgetsFromDefaultSystemProfile();
        }
    }

    //check All data for export loaded or not
    checkWidgetDataLoaded() {
        if (this.ccuOrZone.type == 'ccu') {
            return (this.systemWidgets.length + this.analyticsLayoutService.totalAnalyticsCount) > 0
        } else if(this.ccuOrZone.type == 'zone') {
            let totalWidgetLength = 0;
            this.profiles?.forEach(x => totalWidgetLength += x?.widgets?.length);
            return (totalWidgetLength + this.analyticsLayoutService.totalAnalyticsCount) > 0
        }
    }

    disableAllInputs() {
        this.onSystemMode = true;
    }

    enableAllInputs() {
        document.querySelectorAll('input').forEach(element => {
            element.disabled = false;
        });
        this.onSystemMode = false;
    }
    callSystemSettingsAfterDelay($event){
        var $this = this;
        this.getSystemprofileEquiNames()
        this.hoverdOrClickEventFalg = false;
        this.ccuHoverCheckFlag = true;
        this.clearHoverTimer();
        this.hoverEnterTimer = setTimeout(async ()=> {
            await $this.getSystemSettingsOnHover($event);
        }, 1000); 
    }
    clearHoverTimer(){
        window.clearTimeout(this.hoverEnterTimer);
    }
    async getSystemSettingsOnHover(ccuObj: any) {
        let x = ccuObj.$event.target.getBoundingClientRect();
        //clean up previous hovered ccu obj
        if (this.ccuOrZone.actionUsed == 'click') {
            return;
        }
        if (this.refs.ccuName == ccuObj.$event.target.innerText) {
            return;
        }
        this.messageService.scrollIndex = window.scrollY;
        //to dismiss select dropdown on hover
        document.body.click();
        let ele: any = document.activeElement
        if (ele?.blur) {
            ele.blur();
        }

        this.closeModal();
        this.intializeDate();
        this.ccuOrZone = { type: 'ccu', actionUsed: 'hover' };
        this.heatMapToolTipService.ccuorzone = this.ccuOrZone;
        ccuObj = this.setCCUData(ccuObj);
        this.intializeDate();
        this.refs.ccuName = ccuObj.ccuName;
        this.refs.ahuRef = ccuObj.ccuAhu;
        this.refs.siteRef = ccuObj.siteRef;
        this.refs.equipRef = ccuObj.equipRef;
        this.refs.oaoRef = ccuObj.oaoRef;
        this.refs.gateway = ccuObj.gateway;
        this.refs.ccuId = ccuObj.ccuId;
        this.refs.ccuRef = ccuObj.ccuId;
        let isSideOpened = document.getElementsByClassName('fs-sidebar ng-star-inserted expand');
        if (isSideOpened.length > 0) {
            this.sideMenu = true;
        } else {
            this.sideMenu = false;
        }
        let modalLeftPos: number = this.sideMenu ? x.right - 35 - 141 : x.right - 35;
        let modalRightPos: number = this.sideMenu ? ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 15 : ((window.innerWidth || document.documentElement.clientWidth) - x.left) - 10
        this.siteNameModalStyleClickedFlag = false;

        if (((window.innerWidth || document.documentElement.clientWidth) - x.right) > ((window.innerWidth || document.documentElement.clientWidth) * 0.42) + 140) {
            this.siteNameModalStyle = {
                top: this.isHeaderFixed ? Math.abs(document.body.getBoundingClientRect().top) + 96 + 'px' : ccuObj.$event.target.offsetTop+40 + 'px',
                left: modalLeftPos + 5 + 'px',
                height: window.screen.availHeight,
                width: 48 + '%',
                display: 'block'
            };

            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.add('left-arrow');
        } else {
            this.siteNameModalStyle = {
                top: this.isHeaderFixed ? Math.abs(document.body.getBoundingClientRect().top) + 96 + 'px' : ccuObj.$event.target.offsetTop+40 + 'px',
                right: modalRightPos + 25 + 'px',
                height: window.screen.availHeight,
                width: 48 + '%',
                display: 'block'
            };

            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.add('right-arrow');
            this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');
        }
        
        try {
            this.widgetData = await lastValueFrom(this.siteService.findByQuery('ccuRef==@' + ccuObj.ccuId).pipe(
                map(this.helperService.stripHaystackTypeMapping),
                map(this.helperService.listEntities),
                map(this.helperService.listTuners),
                map(this.helperService.createEntityStructure)))

        } catch(error) {  
            this.widgetData = [];
            console.log('Error in fetching ccu data', error);
        }
        let connectivityWidgetDetails = this.getConnectivityWidgetStatus();
        let humidifierEnabled:any;
        let equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        const humidifierRef = await this.helperService.getPointIdbyTags(equipObj, ['point', 'system', 'config', 'enabled', 'output', 'sp', 'humidification'])
        const hasDomainName = equipObj?.markers?.includes('domainName');
        if(humidifierRef.length>0) {
            await this.siteService.getWritablePointData(humidifierRef[0]).subscribe((response) => {
                let humidityValue = response.rows[0];
                if(this.helperService.stripHaystackTypeMapping(humidityValue.val).split(' ')[0] == 1){
                    humidifierEnabled =  true
                    this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true,humidifierEnabled,connectivityWidgetDetails);
                }
            });
        } 
            this.systemWidgets = this.deviceHelper.getSystemWidgets(this.refs.siteRef, ccuObj, true,humidifierEnabled,connectivityWidgetDetails);

        let subs_sysProfile = this.deviceHelper.systemProfileSubject.subscribe(systemProfile => {
            //Modify the runtime graph object
            if (systemProfile) {
                if(systemProfile.ccuRef != this.refs.ahuRef) {
                    return
                }
                //Runtime graphs logic
                if (this.runTimeGraphsService.runTimeProfile.size > 0 && this.runTimeGraphsService.runTimeProfile.has(this.refs.ahuRef)) {
                    this.systemWidgets[0] = this.runTimeGraphsService.runTimeProfile.get(this.refs.ahuRef);
                    this.loadRuntimeWidgetData(this.refs.ahuRef);
                    subs_sysProfile.unsubscribe();
                }
                else {
                    let subs_runtimeSub = this.deviceHelper.systemRuntimeSubject.subscribe(runtimeProfile => {
                        if (runtimeProfile) {
                            //We can directly use after fetching once
                            this.runTimeGraphsService.runTimeProfile.set(runtimeProfile.ahuRef,runtimeProfile.widgetData);
                            this.systemWidgets[0] = runtimeProfile.widgetData;
                            this.loadRuntimeWidgetData(runtimeProfile.ahuRef);
                            subs_runtimeSub.unsubscribe();
                            subs_sysProfile.unsubscribe();
                        }
                    });
                    if (this.checkForAdvanceAhuProfiles.indexOf(systemProfile.systemProfileName) > -1) {
                        (async () => {
                            try {
                                const staticPreesurePoint = await this.toogleCheckforStaticPressurePoint();
                                const co2BasedDamperControlOn = await this.co2BasedDamperControlOn();
                                this.deviceHelper.getSystemProfileRuntimeWidgetData(
                                    systemProfile.systemProfileName,
                                    this.refs.siteRef,
                                    this.refs.ahuRef,
                                    true,
                                    this.refs.gateway,
                                    this.refs.ccuId,
                                    this.refs.ccuName,
                                    hasDomainName,
                                    staticPreesurePoint,
                                    co2BasedDamperControlOn
                                );
                            } catch (error) {
                                console.error("Error:", error);
                            }
                        })();
                    } else {
                        this.deviceHelper.getSystemProfileRuntimeWidgetData(systemProfile.systemProfileName, this.refs.siteRef, this.refs.ahuRef, true, this.refs.gateway,this.refs.ccuId,this.refs.ccuName,hasDomainName,null,null);
                    }

                }
                //fetch Comfort index profile
                if (systemProfile.systemProfileName.includes("VAV")) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemVavComfortIndexGraphWidget()).getGraphWidget();
                }
                else if (systemProfile.systemProfileName.includes("DAB")) {
                    this.systemWidgets[this.systemWidgets.length - 1] = (new PucSystemDabComfortIndexGraphWidget()).getGraphWidget();
                }
                else if (systemProfile.systemProfileName.includes("DEFAULT")) {
                    //for defualt profile remove comfortindex modes widget and runtime widget
                    this.removeWidgetsFromDefaultSystemProfile();
                }
            }
            else {
                //means no runtime for this profile , fetch the data
                //All points have been fetched , get bulk read
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
            }
        });
        this.loadSystemWidgetsData();

        this.messageService.getMessage().subscribe(message => {
            if (message.isExpanded !== undefined) {
                this.sideMenu = message.isExpanded
            }
        });
    }

    getConnectivityWidgetStatus() {
        const OAOObj =  this.widgetData.find(et=> et._id == this.refs.oaoRef);
        const btuObj = this.widgetData.find(equip => ['system','btu', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const getEMRObj = this.widgetData.find(equip => ['system','emr', 'equip', 'profile'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        const diagnosticObj = this.widgetData.find(equip => ['equip', 'diag'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        let connectivity = {
            oao:'',
            btu:'',
            emr:'',
            cloud:''
        }
         //contectivity
         if(OAOObj) {
            connectivity.oao =  this.helperService.getPointIdbyTags(OAOObj, ['heartbeat','oao'])[0];
        }
        if(btuObj) {
            connectivity.btu = this.helperService.getPointIdbyTags(btuObj, ['heartbeat','btu'])[0];
        }
        if(getEMRObj) {
            connectivity.emr =  this.helperService.getPointIdbyTags(getEMRObj, ['heartbeat','emr'])[0];
        }
        if(diagnosticObj) {
            connectivity.cloud =  this.helperService.getPointIdbyTags(diagnosticObj, ['cloud', 'diag', 'connected'])[0];
        }
        return connectivity;
    }

    getCCuSettingData() {
        this.helperService.getPointData()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(res => {
							if (res) {
								const buildingSettings = ObjectUtil.deepClone(this.buildingDataTempHolder);
								const response = ObjectUtil.deepClone(res);
							
								if(buildingSettings?.cloudLastUpdated) {
									delete buildingSettings.cloudLastUpdated;
								}
								if(response?.oaoLastUpdated) {
									delete response.oaoLastUpdated;
								}
								if (!ObjectUtil.isEqual(response,buildingSettings)) {
									this.ccuSetting = (res) ? ObjectUtil.deepClone(res) : this.ccuSetting;
									this.buildingDataTempHolder = ObjectUtil.deepClone(res);
									this.getSystemReconfigurationData(res);
                                    
                                    if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1) {
                                        this.ahuProfilesGlobalisationCheck();
                                    }
									this.setOTAandCCUSettings();
									this.isOnline('btu');
									this.isOnline('emr');
									this.isOAOOnline();
									// Setting Intrinsic schedule data.
									this.setIntrinsicScheduleData(res);
								}
							} else {
								this.setOTAandCloudStatusSettings(res);
							}
            });

         }

	setIntrinsicScheduleData(data: any) {
		if (!data) {return};
        const intrinsicScheduleData = {};
        intrinsicScheduleData['buildingSchedules'] = data?.buildingOccupancy?.id ? data.buildingOccupancy?.val : [];
		intrinsicScheduleData['buildingVacations'] = data?.buildingVacations && data?.buildingVacations?.length ? data.buildingVacations : [];
		intrinsicScheduleData['buildingSpecialSchedule'] = data?.buildingSpecialSchedule?.length ? data?.buildingSpecialSchedule : [];
		const mappedZones =
			(this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.ahuRef === this.refs.ahuRef))?.pairedZoneList || [];
		let zoneList = this.buildings.zones.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
		intrinsicScheduleData['zones'] = [];
        zoneList = zoneList.map(zone => {
            return this.checkProfileTypeOfZones(zone);
        }).filter(r => r);
		zoneList.forEach(zone => {
			if (data[zone.name]?.zoneSchedule && !(data[zone.name]?.zoneSchedule?.error) && (data[zone.name]?.scheduleType?.val > -1)) {
				const obj = {
					id: zone._id,
					name: zone.name,
					scheduleType: data[zone.name]?.scheduleType?.val,
					zoneSchedule: data[zone.name]?.zoneSchedule,
					zoneVacations: data[zone.name]?.zoneVacations,
					zoneSpecialSchedule: data[zone.name]?.zoneSpecialSchedule
				};
				intrinsicScheduleData['zones'].push(obj);
			}
		});
        
        if(!ObjectUtil.isEqual(this.intrinsicScheduleData,intrinsicScheduleData)) {
            this.intrinsicScheduleData = ObjectUtil.deepCopy(intrinsicScheduleData);
        }
		
	}

    getCcuSettings(ccuAhu: any) {
        //NOTE: For conditioning mode , due to tag change adding a work around for backword compatibility , in future if all builds
        //of CCU >=523 then we can use the updated tags , till then the check will be in place
        let equipObj = this.widgetData?.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        let systemMode = equipObj?this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0]:null;
		const systemPoints = [
			{
				pointName: 'systemMode',
				query: `(equipRef==@${ccuAhu})`,
				type: 'write',
				tags: ['system', 'userIntent', 'mode', `${!systemMode ? 'conditioning' : 'rtu'}`],
				domainName: "conditioningMode"
			},
			{
				pointName: 'cloudLastUpdated',
				query: `(equipRef==@${this.refs.equipRef})`,
				type: 'read',
				tags: ['diag', 'cloud', 'connected'],
                domainName: "ccuHeartbeat"
			},
			{
				pointName: 'desiredComfortIndex',
				type: 'write',
				tags: ['system', 'userIntent', 'ci', 'desired']
			},
            {
                pointName: 'demandResponse',
                query: `(equipRef==@${this.refs.ccuConfigRef})`,
                type: 'write',
                tags: ['userIntent', 'demand', 'system', 'response'],
                domainName: "demandResponseEnrollment"
            },
			{
				type: 'write',
				pointName: 'minHumidity',
				tags: ['min', 'system', 'target', 'humidity'],
				domainName: "systemtargetMinInsideHumidity"
			},
            {
				type: 'write',
				pointName: 'maxHumidity',
				tags: ['max', 'system', 'target', 'humidity'],
				domainName: "systemtargetMaxInsideHumidity"
			},
			{
                pointName: 'equipStatus',
                type: 'write',
                query: `(equipRef==@${this.refs.ahuRef})`,
                tags: ['system', 'status', 'message'],
                domainName: "equipStatusMessage",
                refCheck: {
                    key: 'equipRef',
                    value: this.refs.ahuRef
                }
            }, 
			{
				pointName: 'occupancyStatus',
				type: 'write',
				tags: ['system', 'scheduleStatus'],
				domainName: "equipScheduleStatus"
			},
			{
				pointName: 'ductStaticPressureSetPoint',
				type: 'read',
				domainName: "ductStaticPressureSetpoint"
			}, {
				pointName: 'supplyAirflowTempSetpoint',
				type: 'read',
				domainName: "supplyAirflowTemperatureSetpoint"
			}, {
				pointName: 'dcvDamper',
				type: 'read',
				domainName: 'dcvDamperCalculatedSetpoint'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'airTempHeatingSp',
                type: 'read',
                domainName: 'airTempHeatingSp'
            }, {
                pointName: 'airTempCoolingSp',
                type: 'read',
                domainName: 'airTempCoolingSp'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'operatingMode',
                type: 'write',
                domainName: 'operatingMode'
            }, {
                pointName: 'demandResponseActivation',
                query: `(equipRef==@${this.refs.ccuConfigRef})`,
                type: 'write',
                tags: ['demand', 'activation', 'response'],
                domainName: "demandResponseActivation"
            },{
                pointName: 'relay-1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay1'],
                domainName: 'relay1OutputEnable',
            },{
                pointName: 'relay-2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay2'],
                domainName: 'relay2OutputEnable',
            },
            {
                pointName: 'relay-3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay3'],
                domainName: 'relay3OutputEnable',
            },
            {
                pointName: 'relay-4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay4'],
                domainName: 'relay4OutputEnable',
            },
            {
                pointName: 'relay-5',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay5'],
                domainName: 'relay5OutputEnable',
            },
            {
                pointName: 'relay-6',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay6'],
                domainName: 'relay6OutputEnable',
            },
            {
                pointName: 'relay-7',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay7'],
                domainName: 'relay7OutputEnable',
            },
            {
                pointName: 'analog-out1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog1'],
                domainName: "analog1OutputEnable"
            },
            {
                pointName: 'analog-out2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog2'],
                domainName: "analog2OutputEnable"
            },
            {
                pointName: 'analog-out3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog3'],
                domainName: "analog3OutputEnable"
            },
            {
                pointName: 'analog-out4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog4'],
                domainName: "analog4OutputEnable"
            },
            {
                pointName: 'relay-1Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay1'],
                domainName: 'relay1OutputAssociation',
            },
            {
                pointName: 'relay-2Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay2'],
                domainName: 'relay2OutputAssociation',
            },
            {
                pointName: 'relay-3Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay3'],
                domainName: 'relay3OutputAssociation',
            },
            {
                pointName: 'relay-4Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay4'],
                domainName: 'relay4OutputAssociation',
            },
            {
                pointName: 'relay-5Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay5'],
                domainName: 'relay5OutputAssociation',
            },
            {
                pointName: 'relay-6Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay6'],
                domainName: 'relay6OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay7'],
                domainName: 'relay7OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'humidifier', 'sp', 'relay7']
            },


            //VAV Advanced AHU Points
            {
                pointName: 'supplyAirflowTemperatureSetpoint',
                type: 'read',
                domainName: 'supplyAirflowTemperatureSetpoint'

            }, {
                pointName: 'ductStaticPressureSetpoint',
                type: 'read',
                domainName: 'ductStaticPressureSetpoint'
            },
            {
                pointName: 'supplyAirflowTempControl',
                type: 'write',
                domainName: 'supplyAirTempControlOn'
            }, {
                pointName: 'pressureBasedFanControl',
                type: 'write',
                domainName: 'pressureBasedFanControlOn'
            },
            //Supply Air Temp Points 
            {
                pointName: 'supplyAirTemperature1',
                type: 'read',
                domainName: 'supplyAirTemperature1'
            }, {
                pointName: 'supplyAirTemperature2',
                type: 'read',
                domainName: 'supplyAirTemperature2'
            }, {
                pointName: 'supplyAirTemperature3',
                type: 'read',
                domainName: 'supplyAirTemperature3'
            }, {
                pointName: 'averageSat',
                type: 'read',
                domainName: 'averageSat'
            }, {
                pointName: 'satSpMin',
                type: 'read',
                domainName: 'minSat'
            }, {
                pointName: 'satSpMax',
                type: 'read',
                domainName: 'maxSat'
            },
            //Duct static pressure points
            {
                pointName: 'ductStaticPressureSensor1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2'
            },
            {
                pointName: 'ductStaticPressureSensor2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2'
            }, {
                pointName: 'ductStaticPressureSensor3',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2'
            }, {
                pointName: 'averagePressure',
                type: 'read',
                domainName: 'averagePressure'
            }, {
                pointName: 'minPressure',
                type: 'read',
                domainName: 'minPressure'
            }, {
                pointName: 'maxPressure',
                type: 'read',
                domainName: 'maxPressure'
            }, {
                pointName: "coolingstage1",
                domainName: "loadCoolingStage1",
                type: "read",
            }, {
                pointName: "coolingstage2",
                domainName: "loadCoolingStage2",
                type: "read",
            }, {
                pointName: "coolingstage3",
                domainName: "loadCoolingStage3",
                type: "read",
            }, {
                pointName: "coolingstage4",
                domainName: "loadCoolingStage4",
                type: "read",
            }, {
                pointName: "coolingstage5",
                domainName: "loadCoolingStage5",
                type: "read",
            }, {
                pointName: "heatingstage1",
                domainName: "loadHeatingStage1",
                type: "read",
            }, {
                pointName: "heatingstage2",
                domainName: "loadHeatingStage2",
                type: "read",
            }, {
                pointName: "heatingstage3",
                domainName: "loadHeatingStage3",
                type: "read",
            }, {
                pointName: "heatingstage4",
                domainName: "loadHeatingStage4",
                type: "read",
            }, {
                pointName: "heatingstage5",
                domainName: "loadHeatingStage5",
                type: "read",
            },
            {
                pointName: "satcoolingstage1",
                domainName: "satCoolingStage1Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage2",
                domainName: "satCoolingStage2Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage3",
                domainName: "satCoolingStage3Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage4",
                domainName: "satCoolingStage4Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage5",
                domainName: "satCoolingStage5Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage1",
                domainName: "satHeatingStage1Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage2",
                domainName: "satHeatingStage2Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage3",
                domainName: "satHeatingStage3Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage4",
                domainName: "satHeatingStage4Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage5",
                domainName: "satHeatingStage5Feedback",
                type: "read",
            },
            {
                pointName: "loadbasedcoolingcontrol",
                domainName: "loadBasedCoolingControl",
                type: "read",
            },{
                pointName: "satbasedcoolingcontrol",
                domainName: "satBasedCoolingControlFeedback",
                type: "read",
            },{
                pointName: "satbasedheatingcontrol",
                domainName: "satBasedHeatingControlFeedback",
                type: "read",
            },{
                pointName: "loadbasedheatingcontrol",
                domainName: "loadBasedHeatingControl",
                type: "read",
            },{
                pointName: 'dcvDamperControl',
                type: 'read',
                domainName: ['co2BasedDamperControl']
            },{
				pointName: 'advancedAhuOperatingMode',
				type: 'read',
				domainName: 'operatingMode'
			}, {
                pointName: 'compositSignal',
				type: 'read',
				domainName: 'compositeSignal'
            },
            //Duct static pressure Points New 
            {
                pointName: 'ductStaticPressureSensor1_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_1',
            }, {
                pointName: 'ductStaticPressureSensor1_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2',
            }, {
                pointName: 'ductStaticPressureSensor1_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_10',
            },
            //
            {
                pointName: 'ductStaticPressureSensor2_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_1',
            }, {
                pointName: 'ductStaticPressureSensor2_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2',
            }, {
                pointName: 'ductStaticPressureSensor2_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_10',
            },
            //
            {
                pointName: 'ductStaticPressureSensor3_1',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_1',
            }, {
                pointName: 'ductStaticPressureSensor3_2',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2',
            }, {
                pointName: 'ductStaticPressureSensor3_10',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_10',
            },
            // 
            {
                pointName: 'pressureSensorBusAdd0',
                type: 'write',
                domainName: 'pressureSensorBusAdd0',
            }, {
                pointName: 'analog1InputAssociation',
                type: 'write',
                domainName: 'analog1InputAssociation',
            }, {
                pointName: 'analog2InputAssociation',
                type: 'write',
                domainName: 'analog2InputAssociation',
            },
		];
        this.systemPoints = systemPoints;
    }


    getCCUSettingData() {
        const domainNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'minSat', 'maxSat', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor3_2', 'averagePressure', 'minPressure', 'maxPressure'];
        this.siteService.getHaystackDataByQuery(this.filterQuery()).subscribe(({ rows }) => {
            if (rows?.length > 0) {
                this.systemPoints = this.systemPoints.map(r => {
                    rows.forEach(row => {
                        let arr = 0;
                        if (row?.domainName) {
                            //Here we are specifically checking for vavAdvancedHybridAhuV2/dabAdvancedHybridAhuV2 profile to get the correct id for connect module points
                            // As we are getting the samee domain name for connect module points that we need to show in the UI excluding above domianNames so we are adding this additionial condition check.
                            if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1 && (row?.domainName == r?.domainName)) {
                                if (row?.hasOwnProperty('connectModule') && domainNames.includes(row?.domainName)) {
                                    return;
                                } else {
                                    if(r?.domainName == row?.domainName) {
                                        let type = r?.refCheck?.key;
                                        if(!r.hasOwnProperty('refCheck') || (r.hasOwnProperty('refCheck') && r.refCheck.value == this.helperService.stripHaystackTypeMapping(row[type]))) {
                                            r['id'] = row?.id?.replace('r:', '');
                                        }
                                    }  
                                }
                            } else if (row?.domainName == r?.domainName && !r.hasOwnProperty('id')) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        } else {
                            r?.tags?.forEach(tag => {
                                if (row[tag]) {
                                    arr += 1;
                                }
                            });
                            if (r?.tags?.length == arr) {
                                r['id'] = row?.id?.replace('r:', '');
                            }
                        }
                    });
                    return r;
                });
                this.systemPoints.forEach(point => {
                    if (point.id) {
                        this.ccuSettingObj[point.pointName] = {};
                        this.ccuSettingObj[point.pointName]['id'] = point.id;
                        this.helperService.assemblePointIdData(point.id, point.type, point.pointName, undefined, 'update');
                        Object.assign(this.priorityArray, { [point.pointName]: {} });
                        this.priorityArray[point.pointName]['id'] = point.id;
                    }
                });
                this.getCCuSettingData();
            }
        });
    }

    filterQuery() {
		let concatQuery = 'point and ';
		const uniquePoints = [...new Set(this.systemPoints.map(item => item.query).filter(item => item))]
		uniquePoints.forEach((r, i) => {
			concatQuery += r + `${uniquePoints.length - 1 == i ? '' : ' or '}`
		});
		 return concatQuery;
	}


    onSystemModeChange(event: any, mode: string) {
        this.onCCUControlChange('systemMode', this.modeList.indexOf(mode), this.ccuSetting);
    }

    onSliderValueChange(onSliderValueChange: any) {
        let sliderValue = String(onSliderValueChange.value);
        setTimeout(() => {
            this.onControlChange('desiredComfortIndex', this.rangeSliderHack[sliderValue], this.ccuSetting);
        }, 200);
    }

    onToggleBtnChange(toggleValue: any, mode: string) {
        const toggleBtnValue = (toggleValue.checked) ? 1 : 0;
        this.onCCUControlChange(mode, toggleBtnValue, this.ccuSetting);
    }

    onNumberTickerChange(event: MatSelect, mode: string) {
        let numberValue = event.value;

        this.onCCUControlChange(mode, numberValue, this.ccuSetting);
    }

    onCCUControlChange(mode, inputData: any, ccuSettingObj: any) {
        let data = []
        data.push({
            type: mode,
            value: inputData,
            priorityArray: ccuSettingObj[mode].priorityArray || []
        })
        this.setUserUpdatedValue();
        this.helperService.handleInputChange(data);
    }

    getRoomNames(roomRef: string) {
        return this.buildings?.rooms?.filter(room => this.helperService.stripHaystackTypeMapping(room?.id)?.split(' ')[0] === this.helperService.stripHaystackTypeMapping(roomRef));
    }

    getRoomObj(roomRef: string) {
        return this.buildings?.floors?.map(floor => floor?.entities?.filter(room => room?.name === roomRef));
    }

    getEquip(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == this.refs?.ahuRef)[0];
    }

    equipConfig(domainName: any) {
        //This is specifically added for domain name since no backwardcompatility is required, as for backward compatibility equipObj will be used
        return this.widgetData.filter(equip =>
            equip.domainName === domainName && equip?.referenceIDs?.ccu == this.refs.ccuRef
        )[0];
    }

    getDiagObj(equipTags: any, zone=false) {
        return this.buildings?.equips
        ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == (zone?this.refs?.ccuEquipRef:this.refs?.equipRef))[0];
    }
    getEMRObj(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?.referenceIDs?.gateway == this.refs?.gateway)[0];
    }

    getBtuObj(equipTags: any) {
        return this.buildings?.equips
            ?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?.referenceIDs?.gateway == this.refs?.gateway)[0];
    }

    getOAOEquip(equipTags: any) {
        return this.buildings?.equips?.filter(equip => equipTags.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == this.refs?.oaoRef)[0];
    }

    getEquipObjBytags(equipTags: any) {
        return this.buildings.equips
            .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef ))[0];
    }


    getSiteObj(equipTags: any) {
        return this.buildings?.equips?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1))[0];
    }

    unHighLighZones($event: any) {
        let x = $event.target.getBoundingClientRect();

        if ($event.clientX < x.right && document.querySelector('.ccu-modal-wrapper') && !document.querySelector('.ccu-modal-wrapper').matches(':hover')) {

            this.siteNameModalStyle = {
                display: 'none'
            };
            if (this.ccuOrZone.actionUsed != 'click')
                this.closeModal(true);
        }
        this.ccuHoverCheckFlag=false;
    }

    getRoomNameById(roomRef: string) {
        return this.buildings.floors.map(floor => floor.entities.filter(room => room._id === roomRef)[0])[0];
    }

    loadFloorInfo(floorRef) {
        this.allFLoor = [];
        this.selectedFloorRef = floorRef;
        if (floorRef?.length) {
            floorRef.forEach(x => {
                if (x._id == 'all') {
                    this.allFLoor.push(x);
                    this.allDetails = true;
                }
            })
            this.refs.floorRef = floorRef;
            this.buildings.floors = floorRef.filter(floor => {
                for (let i of this.buildings.floorsMaster) {
                    if (i._id == floor._id) {
                        return floor;
                    }
                }
            });

            this.setFloorCCuId(floorRef);
            this.getZonesByCCU(this.buildings.floors, false);
        } else {
            this.selectedFloorRef = 'all';
            this.singleFloorFlagStyle = true;
            this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
            this.buildings.floorCcu = this.buildings.ccus;
            this.buildings.floors = ArrayUtil.clone(this.buildings.floorsMaster);
            this.buildings.floors = this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
            this.getZonesByCCU('all');
            this.lazyLoadFloors = ArrayUtil.clone((this.buildings.floors.length == this.lazyLoadFloors.length) ? this.buildings.floors : this.buildings.floors.slice(0, 2 * this.floorheight));

        }
        this.multiFloorSelected = floorRef.length >= 2 && this.compareFloorChanges.length == 1;
        this.buildings.floors.map((floor) => {
            this.getFloorPlanDimensions(floor);
        });
        this.compareFloorChanges = floorRef?.filter(x =>x)
    }

    setFloorCCuId(floorRef) {
        this.buildings.zones.forEach((zone) => {
            let ccu = this.ccusWithPairedZones.find(ccu => {
                if (ccu.pairedZoneList.indexOf(zone._id) > -1) {
                    return ccu;
                }
            });
            if (ccu) {
                zone['ccuId'] = ccu.id;
            }
        })

        const ccus = [];

        const filteredZones = [];
        this.buildings.zones.forEach(x => floorRef.forEach(y => {
            if (y._id == x.referenceIDs.floor) {
                filteredZones.push(x)
            }
        }))

        filteredZones.forEach((zone) => {
            if (ccus.indexOf(zone.ccuId) == -1) { ccus.push(zone.ccuId); }
        });


        this.buildings.floorCcu = this.buildings.ccus.filter(ccu => {
            return ccus.indexOf(ccu._id) > -1;
        });
    }

      changeCCU(ccuRef) {
        this.allCcus =[];
        this.selectedCCu = ccuRef;
        this.getZonesByCCU(ccuRef,true);
      }

      changeZone(zoneRef) {
        let floors = [];
        if ((!this.ccuForLayout.length && !this.selectedFloorRef) || this.allDetails || !zoneRef.length ) {
            floors = this.buildings.floors
        } else if (this.selectedFloorRef) {
            floors = this.selectedFloorRef.floors
        } else {
            floors = this.buildings.floors
        }

        //Set the zone data for highlight and load respective floors:
        if(!zoneRef.length) {
            this.selectedZone = ''
            this.lazyLoadFloors = ArrayUtil.clone(floors);
        } else {
            this.selectedZone = zoneRef;
            if (this.selectedFloorRef) {
                this.lazyLoadFloors = ArrayUtil.clone(floors);
            } else {
                this.lazyLoadFloors = ArrayUtil.clone((this.buildings.floors.length == this.lazyLoadFloors.length) ? this.buildings.floors : this.buildings.floors.slice(0, 2 * this.floorheight));
            }
        }
              setTimeout(() => {
                  this.scrollZoneIntoView();
              }, 50);
              this.scrollZoneIntoView();
        
            }

    scrollZoneIntoView() {
        let ele = document.getElementById(this.selectedZone);
        if (ele) {
            setTimeout(() => {
                ele.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            }, 50)

        }
    }
    searchChange(event) {
        this.searchText = event.searchText;
        this.searchMatchingZones = event.zones;
        if (this.searchText) {
            window.scrollTo(0, 0)
        }
    }



    getZonesByCCU(ccuRef,ccuSelected?) {
        this.ccuForLayout = ccuRef;
        let floors = ArrayUtil.clone(this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum)));
      if (this.selectedFloorRef && this.selectedFloorRef != 'all'  && ccuSelected) {
            this.buildings.floors = [];
            this.buildings.floors = this.selectedFloorRef.filter(floor => {
              for(let i of this.buildings.floorsMaster) {
                    if (i._id == floor._id) {
                    return floor;
                }
                }
            })
        }
        this.setLazyLoadFloorData(ccuSelected,ccuRef,floors);
        if (Array.isArray(this.lazyLoadFloors)) {
            this.lazyLoadFloors.forEach(floor => {
            this.getFloorPlanDimensions(floor);
            });
        }
    }

    setLazyLoadFloorData(ccuSelected, ccuRef, floors) {
        if (ccuSelected && ccuRef?.length) {
            if (ccuRef.forEach(x => x.hasOwnProperty('referenceIDs'))) {
                this.lazyLoadFloors = [];
               this.lazyLoadFloors = ArrayUtil.clone(ccuRef);
                            } else {
                this.lazyLoadFloors = ArrayUtil.clone((this.lazyLoadFloors.length == floors.length) ? floors : floors.slice(0, 2 * this.floorheight));
                            }
        } else if (!ccuSelected && ccuRef?.length) {
            this.lazyLoadFloors = []
            this.lazyLoadFloors = ArrayUtil.clone(ccuRef);
                    }
    }

    loadMoreFloors() {
        if(this.selectedFloorRef && this.selectedFloorRef !='all'){
            return;
        }
        if(this.lazyLoadFloors.length == this.buildings.floors.length) return;
        this.showLazyLoader = true;
        setTimeout(()=>{
            let floors = ArrayUtil.clone(this.buildings.floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum)));
            let index = this.lazyLoadFloors.length+2 > floors.length ? floors.length : this.lazyLoadFloors.length+2;
            let nextFloors = floors.slice(this.lazyLoadFloors.length,index) || [];
            nextFloors.forEach(floor => {
                this.getFloorPlanDimensions(floor);
            });
            this.lazyLoadFloors = this.lazyLoadFloors.concat(nextFloors);
            this.showLazyLoader = false;
        }, 1500)
    }

    getCcus(ccu, ccuFloor) {
        return ccuFloor.filter(ccuF => ccuF === ccu.referenceIDs.ahu || ccuF === ccu.referenceIDs.gateway);
    }

    getCcusByRoom(ahuRef: string) {
        return this.buildings.ccus.filter(ccu => {
            if (ccu.referenceIDs.ahu === ahuRef || ccu.referenceIDs.gateway === ahuRef) {
                return true;
            } else {
                return false;
            }
        });
    }

    loadFloorLayouts(floors: any[]) {
        if (floors.length) {
            floors.forEach(floor => {
                floor.image = 'Loading...';
            });
            let floor = floors.sort((a, b) => Number(a.floorNum) - Number(b.floorNum));
            if (this.lazyLoadFloors.length != floor.length) {
                this.lazyLoadFloors = floor.slice(0, 2 * this.floorheight);
            }
        } else {
            this.loaderService.active(false);
        }
    }

    checkAllZonesLoaded(i) {
        if (i <= 0)
            this.getZonesByCCU('all');
    }

    checkFloorZonesLoaded(floor, i) {
        if(floor.entities.length == floor.droppeditem.length) i--;
        return i;
    }

    getCCUPariedWithZone(zoneId: string) {
        if (!this.ccusWithPairedZones.length) return null;
        for (var i = 0; i < this.ccusWithPairedZones.length; i++) {
            if (this.ccusWithPairedZones[i].pairedZoneList.indexOf(zoneId) > -1) return [this.ccusWithPairedZones[i].id, this.ccusWithPairedZones[i].CCUName]
        }
    }

    getCCUColor(ccuId) {
        if (ccuId) {
            let ccu = this.buildings.ccus.find((ccu) => {
                return ccu._id == ccuId;
            })
            return ccu.color;
        }
        return '';
    }

    setCcuColors() {
        let i = 0;
        for (let ccu of this.buildings.floorCcu) {
            ccu.color = this.ccuColors[i % this.ccuColors.length];
            i++;
        }
        this.buildings.floorCcu = ObjectUtil.deepClone(this.buildings.floorCcu);
        this.heatmapService.setBuildingCcus(this.buildings.floorCcu);
    }

    sanitizeSvgUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    closeModal(onCcuHover = false) {
        this.tuners = []; // clear tuners array on close modal.
        this.vacationSkeletonLoader = true
        this.ccuOrZone.type = '';
        this.ccuOrZone.actionUsed = '';
        this.equipGraphicData = []
        this.equipGraphicSystemProfile = ''
        this.systemReconfigurationSettings = null;
        this.humidifier = false;
        this.deHumidifier = false;
        this.oaoArcValue = 0;
        this.heatMapToolTipService.ccuorzone = null;
        this.activeTile = '';
        this.zoneId = "";
        this.refs.ccuName = "";
        this.refs.ccuAhu = "";
        this.refs.oaoRef = "";
        this.systemWidgets = [];
        this.heatCoolLimitsHisData.clear();
        this.multiModuleProfileHasHPU = false;
        this.multiModuleProfileHasCPU = false;
        this.btuMeterType = "";
        this.emrMeterType = "";
        this.showEmr = false;
        this.showBtu = false;
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('right-arrow');
        this.el.nativeElement.querySelector('.ccu-modal-wrapper')?.classList?.remove('left-arrow');
        this.highlightCCU = '';
        this.tempZoneSettingsHolder = null;
        this.customWidgetDataForExport = {};
        this.dataForExport = {};
        this.hoverZoneData = null;
        this.refs.ccuEquipRef = null;
        this.overrideCancelled = false;
        this.equipGraphicData = []
        this.equipGraphicSystemProfile = ''
        this.equipGraphicsProfiles = '';
        this.systemReconfigurationSettings = null;
        this.modalStyleClickedFlag = false;
        this.siteNameModalStyleClickedFlag = false;
        this.enableDownLoad = false;
        this.profiles = [];
        this.enableViewAssetsSystemProfile = false;
        this.version = '';
        this.cmVersion = '';
        this.homeAppVersion = '';
        this.remoteAccessAppVersion = '';
        this.backnetAppVersion = '';
        this.versionText = "";
        this.zoneWidgetDataLoaded = false;
        this.widgetData = [];
        this.showPredefinedEquipGraphic = {};
        this.graphicsLoaded = false;
        this.onGraphicListChangedEvent = null;
        // to force body to render height calculation
        window.scrollBy(0, -1);
        window.scrollBy(0, 1);
        //to dismiss select dropdown on hover
        document.body.click();

        this.siteNameModalStyle = {
            display: 'none'
        }
        this.siteNameModalStyleOnClicked = {
            display: 'none'
        }

        this.modelStyle = {
            display: 'none'
        };

        this.modelStyleOnClicked = {
            display: 'none'
        }

        this.overlayHeight = this.getOverlayHeight(true);

        if (this.subscriptionCollection.length) {
            this.subscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.subscriptionCollection = [];
        }

        Object.keys(this.getUserSettingDataSubscriptionCollection).forEach((e) => {
            this.getUserSettingDataSubscriptionCollection[e].unsubscribe();
            delete this.getUserSettingDataSubscriptionCollection[e];
        });

        if (this.getCCUSettingDataSubscriptionCollection.length) {
            this.getCCUSettingDataSubscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.getCCUSettingDataSubscriptionCollection = []
        }

        if (this.getCCUSettingDataChannelSubscriptionCollection.length) {
            this.getCCUSettingDataChannelSubscriptionCollection.forEach(subs => {
                subs.subscription.next();
                subs.subscription.unsubscribe();
                subs.subscription.complete();
            })
            this.getCCUSettingDataChannelSubscriptionCollection = [];
        }

        //clear pending requests on runtime-graph-service
        this.runTimeGraphsService.cancelPendingRequestsSubject.next(true);
        this.runTimeGraphsService.cancelPendingRequestsSubject.complete();

        //clear pending requests on device-helper-service
        this.deviceHelper.cancelPendingRequestsSubject.next(true);
        this.deviceHelper.cancelPendingRequestsSubject.complete();

        this.multiModuleProfile = false;
        this.buildingLimit = {
            min: undefined,
            max: undefined
        };
        this.sliderInput = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        this.helperService.clearData();
        this.sliderUserIntentService.clearData();
        this.headerUserIntent = false;
        this.displayRangeSlider = undefined;
        this.zoneVacations = [];
        this.multiModuleZonePriorityList = new Map();
        this.multiModuleScheduleTypeList = new Map();
        //clearing graphPointsCollection for second hover
        //Note : will update this logic while supporting multiple profiles case
        this.graphService.graphPointsCollection.clear();
        //clean up tool tip service
        this.heatMapToolTipService.clearTooltipData();
        //Clean up graph heights
        this.heatMapToolTipService.graphHeights.clear();
        //Clean up stale graph data
        this.graphService.resetGraphData();
        //Clean runtime flag for multi module
        this.runTimeGraphsService.isRuntimeRendered = false;
        this.runTimeGraphsService.moduleWithRuntime = null;
        this.zoneSettings = null;
       // document.documentElement.scrollTop = this.messageService.scrollIndex;
        //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
        clearInterval(this.timeSeriesinteval);
        this.checkForEventType = "";
        this.runTimeGraphsService.iscmBoardPortsMappingsCollectionRequested.clear();
        this.runTimeGraphsService.iscmBoardPortsMappingsCollectionFetched.clear();
        this.runTimeGraphsService.isFullyModulatingProfileTagsFetched.clear();
        this.runTimeGraphsService.isFullyModulatingProfileTagsCollectionRequested.clear();

        this.runTimeGraphsService.iscmBoardPortsMappingsForCollectionFetched.clear();
        this.runTimeGraphsService.iscmBoardPortsMappingsForCollectionFetched.clear();
        this.runTimeGraphsService.isCollectionRequested.clear();

        document.body.style.overflowX = 'hidden';
    }

    getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
            Object.assign(this.priorityArray, { [settingType]: {} });
            this.priorityArray[settingType]['id'] = pointID;
        }
    }

    setDateRange(event: any) {
        if (event.startDate !== null) {
            // Clear subs for time series so we can go back to that date
            this.subscriptionCollection = [];

            const tz: string = this.siteService.getIANATimeZone(this.siteTz);
            const offset = dayjs().tz(tz).utcOffset();
            const centralOffset = dayjs().utcOffset();
            let startDate = event.startDate.toDate().toString();
            startDate = startDate.substring(0, startDate.lastIndexOf(' ')).trim();
            const selectedDate: any = {
                endDate: event.endDate,
                startDate: event.startDate,
                timeZone: tz
            };
           selectedDate.startDate = dayjs(new Date(startDate)).startOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');

            let endDate = event.endDate.toDate().toString();
            endDate = endDate.substring(0, endDate.lastIndexOf(' '));

            // Check if end date is before today's date
            if (dayjs(new Date(event.endDate.format('YYYY/MM/DD'))).endOf('day').isBefore(dayjs(Date.now()))) {
               selectedDate.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
            } else if ((new Date(event.endDate.format('YYYY/MM/DD')).toDateString()) == (new Date(Date.now()).toDateString())) {
                const day = dayjs(Date.now()).tz(tz)
                const currentDay = this.checkDateconversion(day.year(), day.month(), day.date(), day.hour(), day.minute(), day.second(), day.millisecond())
                selectedDate.endDate = currentDay == new Date(event.endDate.format('YYYY/MM/DD')).toDateString() ? dayjs(Date.now()).tz(tz) : dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
            } else if (dayjs(new Date(event.endDate.format('YYYY/MM/DD'))).endOf('day').isAfter(dayjs(Date.now()))) {
               selectedDate.endDate = dayjs(Date.now()).tz(tz);
            } else {
               selectedDate.endDate = dayjs(new Date(endDate)).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
            }

            this.heatMapToolTipService.selectedDateRange = selectedDate;
            // Reloading the Analytics charts based on the selected date range
            this.analyticsLayoutService.dateRangeUpdated.next(true);
            this.dataForExport = {};
            this.checkWidgestLoaded = {};
            this.heatCoolLimitsHisData.clear();
            this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
        }
    }
    checkdisplayprofile() {
        if (this.headerUserIntent == true) {
            //Multimodule profile cannot be pi and emr
            if (this.multiModuleProfile) {
                if (typeof (this.displayRangeSlider) != undefined) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (this.profiles?.length == 1) {
                    if ((this.profiles[0]?.profileType == 'pi' || this.profiles[0]?.profileType == 'emr'))
                        return true;
                    else {
                        if(this.profiles[0]?.profileType == 'sense' || this.profiles[0]?.profileType == 'monitoring') {
                            this.senseProfilecheck()
                        }
                        if (typeof (this.displayRangeSlider) != undefined) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
        }
    }


    senseProfilecheck () {
        this.updateSliderInput()
        this.displayRangeSlider = true;
     }

     checkDateconversion(year,month,date,hour,minute,second,millisecond) {
        return new Date(year,month,date,hour,minute,second,millisecond).toDateString()
      }
 


    desiredTempsChangedHandler(changedbuttonid: PucUserIntentTempSliderValueChangedIds) {
        let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.refs.ccuName);
        let sliderdata = this.sliderUserIntentService.getData(datalastupdatedby, this.refs.ccuName);
        let  heatingdesiredvalue
        let  coolingdesiredvalue
        //cel support writing the data
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            heatingdesiredvalue =  this.unitService.celeciusToFahrenheit(sliderdata.desiredTempHeating)
            coolingdesiredvalue =  this.unitService.celeciusToFahrenheit(sliderdata.desiredTempCooling)
        } else {
            heatingdesiredvalue = sliderdata.desiredTempHeating;
            coolingdesiredvalue = sliderdata.desiredTempCooling;
        }
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Heating || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)

            this.heatingDesriedTempChangeHolder.push({
                "type": 'desiredTempHeating',
                "temp": heatingdesiredvalue
            });
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Cooling || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)
            this.coolingDesriedTempChangeHolder.push({
                "type": 'desiredTempCooling',
                "temp": coolingdesiredvalue
            });
        this.onChangeDesiredTempSubject.next({
            type: '', temp: ''
        })
    }

    onChangeDesiredTemp(settingType: any[]) {
        let data = [];
        if (this.multiModuleProfile) {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        let profileEquipName = profile.profile.name;
                        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[profileEquipName][type.name].priorityArray || []
                        })
                    }
                });


            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);

        } else {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        let profileEquipName = profile.profile.name;
                        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[type.name].priorityArray || []
                        })
                    }
                });

            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);
        }
    }


    setXAxisCords(xVals: any) {
        if (xVals?.length) {
            //clean up any item from previous selection
            this.xCords = [];
            xVals.forEach((x, i) => {
                if (x.textContent.toString() != "" && !x.textContent.toString().includes(".")) {
                    this.xCords[i] = x.textContent.toString();
                }
            });
        }
    }

    schValidation(event) {
        if (this.zoneSettings?.zoneSchedule?.val && this.zoneSettings?.buildingOccupancy?.val) {
            this.conflictMsg = '';
            this.forceTrimArray = [];

            let arr = (a, b) => {
                return parseInt(a?.day) - parseInt(b?.day) || parseInt(a?.sthh) - parseInt(b?.sthh);
            }
            let zoneSchInfo = this.zoneSettings?.zoneSchedule?.val;
            zoneSchInfo?.sort(arr);

            zoneSchInfo?.forEach(zoneSch => {
                this.zoneContainment(zoneSch);
            });


            return this.forceTrimArray;

        }
    }

    //method to force trim
    forceTrim() {
        if (this.forceTrimArray.length) {
            let zoneInfo = this.zoneSettings?.zoneSchedule?.val;
            return this.schedulerService.forceTrim(this.forceTrimArray, zoneInfo);
        }
    }

    // Group the data based on the selected grouping mode
    formatGroupedData(groupedData) {
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        let formattedGroupedData = {};
        const flattenGroupedData = (groupedData) => {
            return Object.values(groupedData).flat();
        };
        const removeDuplicates = (targetArray) => {
            return [...new Set(targetArray)];
        };

        // Grouping logic based on the selected mode
        switch (currentSelectedGroup?.enum) {
            case 'EVERYDAY':
                formattedGroupedData = { "All Days": removeDuplicates(flattenGroupedData(groupedData)) };
                break;
            case 'WEEKDAY_WEEKEND':
                formattedGroupedData = {
                    "Weekday": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [0, 1, 2, 3, 4]))),
                    "Weekend": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [5, 6])))
                };
                break;
            case 'WEEKDAY_SATURDAY_SUNDAY':
                formattedGroupedData = {
                    "Weekday": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [0, 1, 2, 3, 4]))),
                    "Saturday": removeDuplicates(groupedData["SATURDAY"] || []),
                    "Sunday": removeDuplicates(groupedData["SUNDAY"] || [])
                };
                break;
            case '7_DAY':
                formattedGroupedData = this.pickDays(groupedData, [0, 1, 2, 3, 4, 5, 6]);
                break;
        }
        formattedGroupedData = this.getOrderedData(formattedGroupedData);
        return formattedGroupedData;
    }

    // Order the grouped data based on the selected grouping mode
    getOrderedData(groupedData) {
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        const selectedGroupEnum = currentSelectedGroup?.enum;
        const getOrderedDays = (selectedGroupEnum) => {
            switch (selectedGroupEnum) {
                case 'EVERYDAY':
                    return ['All Days'];
                case 'WEEKDAY_WEEKEND':
                    return ['Weekday', 'Weekend'];
                case 'WEEKDAY_SATURDAY_SUNDAY':
                    return ['Weekday', 'Saturday', 'Sunday'];
                case '7_DAY':
                    return weeks;
            }
        }
        const orderedDays = getOrderedDays(selectedGroupEnum) || [];
        const orderedGroupedData = [];
        orderedDays.forEach(day => {
            if (groupedData[day] && groupedData[day].length) {
                orderedGroupedData.push({ day, times: groupedData[day] });
            }
        });
        return orderedGroupedData;
    }

    // Pick the days based on the selected grouping mode
    pickDays(groupedData, dayIndexes) {
        return dayIndexes.reduce((acc, dayIndex) => {
            const dayName = weeks[dayIndex];
            if (groupedData[dayName]) {
                acc[dayName] = groupedData[dayName];
            }
            return acc;
        }, {});
    }

    showForceTrimPopup(event) {
        let groupedData: any = {};
        if (this.forceTrimArray.length) {
            this.forceTrimArray.forEach(item => {
                let day = weeks[item.index];
                if (!groupedData[day]) {
                    groupedData[day] = [];
                }
                groupedData[day].push(item.trim);
            });
            groupedData = this.formatGroupedData(groupedData);
        } else {
            groupedData = null;
        }

        groupedData?.map(_grp => {
            _grp.times = this.schedulerService.mergeOverlappingRanges(_grp.times);
            _grp.times = _grp.times.map(time => this.schedulerService.convertTo12HourFormat(time));
            return _grp.times;
        });

        //Handling popup
        const dialogRef:any = this.dialog.open(SchedulerForceTrimModalComponent, {
            panelClass: 'fs-mat-dialog-container',
            width: '533px',
            maxHeight: '400px'
        });
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        dialogRef.componentInstance.discardText = 'Re-Edit';
        dialogRef.componentInstance.saveText = 'Force-Trim & Save';
        dialogRef.componentInstance.showConfirmIcon = true;
        dialogRef.componentInstance.data = groupedData;
        dialogRef.componentInstance.title = 'Zone Schedule is outside Building Occupancy';
        dialogRef.componentInstance.groupName = currentSelectedGroup?.displayName;
        if (this.forceTrimArray.length) {
            dialogRef.afterClosed().subscribe(result => {
                if (!result) {
                    this.scheduleTypeVal = this.oldscheduleTypeVal;
                }
                if (result == 'confirm') {
                    let zoneSchInfo = this.forceTrim();
                    zoneSchInfo = this.forceTrimForSchedulGroup(zoneSchInfo);
                    zoneSchInfo = zoneSchInfo.map(sch => {
                        return ObjectUtil.removeKey('isSplit', sch);
                    })
                    //save zone schedule
                    let schId = this.helperService.getPointIdbyTags(this.buildings.entites, ['zone', 'schedule', 'days'], this.refs.roomRef)
                     this.siteService.updateSchedule(
                        schId[0],
                        this.helperService.stripHaystackTypeMapping(this.refs.roomRef),
                        this.helperService.stripHaystackTypeMapping(this.refs.ccuRef),
                        this.helperService.stripHaystackTypeMapping(JSON.stringify(zoneSchInfo)).replace(/['"]+/g, '').replace(/(:[\d.]+)(,)/g, '$1 '),
                        'zoneRef',
                        this.helperService.stripHaystackTypeMapping(this.refs.siteRef),
                        this.zoneSettings?.zoneSchedule?.unoccupiedZoneSetback,
                        this.zoneSettings?.zoneSchedule?.followBuilding,
                        this.zoneSettings?.zoneSchedule?.scheduleGroup
                    ).pipe(map(this.helperService.stripHaystackTypeMapping),  takeUntil(this.unsubscribe)
                    ).subscribe(res => {
                        if (res.rows) {
                            this.zoneSettings.zoneSchedule.val = res.rows[0].days;
                        }
                    });
                    if (event.type.includes("zonePriority") || event.type.includes("scheduleType") && this.multiModuleProfile) {

                        this.multiModuleScheduleTypeList
                            .forEach((_item) => {
                                let settingValue = event.value;
                                let settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
                                this.onControlChange(event.type, settingValue, settingsObj)
                            })
                    }
                    else {
                        this.onControlChange(event.type, event.value, this.zoneSettings);
                    }
                }
            });
        }
        else {
            dialogRef.close();
        }
    }

    forceTrimForSchedulGroup(scheduleInfo) {
        const scheduleGroup = this.zoneSettings?.zoneSchedule?.scheduleGroup;
        if (parseInt(scheduleGroup)) {
            if (parseInt(scheduleGroup) == 3) {
                let everydayScheduleInfo = ObjectUtil.deepClone(scheduleInfo);
                everydayScheduleInfo = everydayScheduleInfo.sort((a,b) => Number(a.day) - Number(b.day));
                everydayScheduleInfo = this.schedulerService.findCommonTimeRanges(everydayScheduleInfo, 0, parseInt(scheduleGroup));
                scheduleInfo = ObjectUtil.deepClone(everydayScheduleInfo);
            } else if (parseInt(scheduleGroup) == 2) {
                let weekDayscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day != 5 && item.day != 6));
                let weekEndscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day == 5 || item.day == 6));
                const weekdaySchInfo = this.schedulerService.findCommonTimeRanges(weekDayscheduleInfo, 0, parseInt(scheduleGroup));
                const weekendSchInfo = this.schedulerService.findCommonTimeRanges(weekEndscheduleInfo, 5, parseInt(scheduleGroup));
                scheduleInfo = [...weekdaySchInfo, ...weekendSchInfo];
            } else if (parseInt(scheduleGroup) == 1) {
                const weekDayscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day != 5 && item.day != 6));
                const weekdaySchInfo = this.schedulerService.findCommonTimeRanges(weekDayscheduleInfo, 0, parseInt(scheduleGroup));
                const weekendSchInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day == 5 || item.day == 6));
                scheduleInfo = [...weekdaySchInfo, ...weekendSchInfo];
            }
        }
        return scheduleInfo;
    }


    //checks If ZoneSchedule's Exceeding BuildingSchedule
    //returns true or false
    zoneContainment(zoneSch) {

        let zoneSchStartTime = dayjs(zoneSch['sthh'] + ':' + zoneSch['stmm'], 'HH:mm');
        let zoneSchEndTime = dayjs(zoneSch['ethh'] + ':' + zoneSch['etmm'], 'HH:mm');

        let buildingSchInfo = this.zoneSettings?.buildingOccupancy?.val || [];

        let forceTrimArray = this.schedulerService.zoneContainment(buildingSchInfo,zoneSch?.day,zoneSchStartTime,zoneSchEndTime);
        this.forceTrimArray = this.forceTrimArray.concat(forceTrimArray);
    }

    revertChangedValues(paramList: any) {
        for (let i = paramList.length - 1; i >= 0; i--) {
            if (this.ccuOrZone.type == 'ccu' && this.ccuOrZone.actionUsed == 'click' && this.ccuSetting[paramList[i].mode]) {

                this.ccuSetting[paramList[i].mode].val = parseFloat(paramList[i].val);
                const cmRadioEle: any = document.getElementById('radio-'+ this.modeList[this.ccuSetting.systemMode.val].toLowerCase());
                if(cmRadioEle) {
                    cmRadioEle.checked = true;
                }
                if (paramList[i].mode == 'desiredComfortIndex') {
                    this.desiredCIValue = this.ccuSetting[paramList[i].mode].val;
                    this.desiredCISliderValue = this.rangeSliderHack[parseInt(this.desiredCIValue)];

                }
                else if (paramList[i].mode == 'compensateHumidity') {
                    this.CompensateHumidity.checked = parseInt(paramList[i].val);
                    this.CompensateHumidity._checked = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'demandResponse') {
                    this.DemandResponse.checked = parseInt(paramList[i].val);
                    this.DemandResponse._checked = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'smartPrepurge') {
                    this.ccuSetting.smartPrepurge.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'smartPostpurge') {
                    this.ccuSetting.smartPostpurge.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'enhancedVentilation') {
                    this.ccuSetting.enhancedVentilation.val = parseInt(paramList[i].val);
                }

                else if (paramList[i].mode == 'minHumidity') {
                    this.ccuSetting.minHumidity.val = parseInt(paramList[i].val);
                }
                else if (paramList[i].mode == 'maxHumidity') {
                    this.ccuSetting.maxHumidity.val = parseInt(paramList[i].val);
                }
            }
            else if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click' && this.zoneSettings[paramList[i].mode]) {

                if (!this.multiModuleProfile && this.zoneSettings[paramList[i].mode].val) {
                    this.zoneSettings[paramList[i].mode].val = parseFloat(paramList[i].val);
                }
            }
            if (paramList[i].mode == "zonePriority") {
                this.zonePriorityVal = this.oldZonePriorityVal;
            }
            if (paramList[i].mode == "scheduleType") {
                this.scheduleTypeVal = this.oldscheduleTypeVal;
            }
            paramList.splice(i);
            this.helperService.revertPointValues.splice(i);

        }
    }

    isInvalidDate = (m: any) => {
        //Start date should always be today or before but never after
        return m.isAfter(dayjs(), 'day')
    }

    getPointId(key: string, pointRef: string) {
        let id: Array<string> = new Array<string>();
        if(!pointRef) 
        return;
        //change the check from key -> pointRef by kuladeep 3.11.19
        if (this.graphService.graphPointsCollection.has(key + pointRef)) {
            id.push(this.graphService.graphPointsCollection.get(key + pointRef));
        }
        else {
            this.graphService.graphPointsCollection.set(key + pointRef, pointRef)
            id.push(pointRef);
        }

        return id;
    }

    //this will fill in details for enum and units
    getPointProperties(dataObj) {
        if (this.graphService.graphPointsCollection.size > 0) {
            if (dataObj?.entities) {
                dataObj.entities.map(entity => {
                    this.graphService.graphPointsCollection?.forEach((value, key) => {
                        if (value == entity._id) {
                            this.setGraphEnumUnit(entity,value,key);
                        }
                    });
                });
            }
        }
    }

    setGraphEnumUnit(entity,value,key) {
        let unitCategory: boolean = false;
        let unit;
        if (this.unitService.unitListArray.includes(entity.unit)) {
            unit = this.mapUnitBasedOnPreference(entity);
            unitCategory = true;
        }
        if(entity.markers.includes("predefined")) {
            this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), 'V');
            this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        } else {
        this.graphService.graphPointsUnitsCollection.set(key.replace(value, ''), unitCategory ? unit : entity.unit);
        this.graphService.graphPointsEnumCollection.set(key.replace(value, ''), entity.enum);
        }
    }

    mapUnitBasedOnPreference(data) {
        let returnUnit;
        let userPreference = this.authService.getLoggedInUserPreferences();
        if (this.unitService.temperatureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnTemperature(userPreference);
        } else if (this.unitService.energyConsumptionUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnEnergyConsumption(userPreference);
        } else if (this.unitService.airflowVolumeUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnAirFlowVolume(userPreference);
        } else if (this.unitService.airPressureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnAirPressure(userPreference);
        } else if (this.unitService.waterPressureUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnWaterPressure(userPreference);
        } else if (this.unitService.waterFlowUnitListArray.includes(data.unit)) {
            returnUnit = this.convertUnitBasedOnWaterflow(userPreference);
        }
        return returnUnit;
    }

    convertUnitBasedOnTemperature(userPreference) {
        let temperatureUnit;
        const userTemperaturePreference = userPreference.temperatureUnit;
        if (userTemperaturePreference == this.unitService.temperatureUnitListArray[0]) {
            temperatureUnit = this.unitService.temperatureUnitListArray[0];
        } else {
            temperatureUnit = this.unitService.temperatureUnitListArray[1];
        }
        return temperatureUnit;
    }

    convertUnitBasedOnEnergyConsumption(userPreference) {
        let energyConsumptionUnit;
        const userEnergyConsumptionPreference = userPreference.energyConsumptionUnit;
        if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[1]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[1];
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[2]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[2];
        } else if (userEnergyConsumptionPreference == this.unitService.energyConsumptionUnitListArray[3]) {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[3];
        } else {
            energyConsumptionUnit = this.unitService.energyConsumptionUnitListArray[0];
        }
        return energyConsumptionUnit;
    }

    convertUnitBasedOnAirFlowVolume(userPreference) {
        let airflowVolumeUnit;
        const userAirflowVolumePreference = userPreference.airflowVolumeUnit;
        if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[1]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[1];
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[2]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[2];
        } else if (userAirflowVolumePreference == this.unitService.airflowVolumeUnitListArray[3]) {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[3];
        } else {
            airflowVolumeUnit = this.unitService.airflowVolumeUnitListArray[0];
        }
        return airflowVolumeUnit;
    }

    convertUnitBasedOnAirPressure(userPreference) {
        let airPressure;
        const userAirPressurePreference = userPreference.airPressureUnit;
        if (userAirPressurePreference == this.unitService.airPressureUnitListArray[1]) {
            airPressure = this.unitService.airPressureUnitListArray[1];
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[2]) {
            airPressure = this.unitService.airPressureUnitListArray[2];
        } else if (userAirPressurePreference == this.unitService.airPressureUnitListArray[3]) {
            airPressure = this.unitService.airPressureUnitListArray[3];
        } else {
            airPressure = this.unitService.airPressureUnitListArray[0];
        }
        return airPressure;
    }

    convertUnitBasedOnWaterPressure(userPreference) {
        let waterPressure;
        const userWaterPressurePreference = userPreference.waterPressureUnit;
        if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[1]) {
            waterPressure = this.unitService.waterPressureUnitListArray[1];
        } else if (userWaterPressurePreference == this.unitService.waterPressureUnitListArray[2]) {
            waterPressure = this.unitService.waterPressureUnitListArray[2];
        } else {
            waterPressure = this.unitService.waterPressureUnitListArray[0];
        }
        return waterPressure;
    }

    convertUnitBasedOnWaterflow(userPreference) {
        let waterFlow;
        const userWaterFlowPreference = userPreference.waterFlowUnit;
        if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[1]) {
            waterFlow = this.unitService.waterFlowUnitListArray[1];
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[2]) {
            waterFlow = this.unitService.waterFlowUnitListArray[2];
        } else if (userWaterFlowPreference == this.unitService.waterFlowUnitListArray[3]) {
            waterFlow = this.unitService.waterFlowUnitListArray[3];
        } else {
            waterFlow = this.unitService.waterFlowUnitListArray[0];
        }
        return waterFlow;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.ccuOrZone.actionUsed == "hover") {
            this.graphWidth = document.getElementsByClassName('fs-content')[0].clientWidth * 0.5 - 60;
        }
        else if (this.ccuOrZone.actionUsed == "click") {
            this.graphWidth = document.getElementsByClassName('fs-content')[0].clientWidth - 375 - 60;
        }
    }

    isProfilePI(equipName) {
        return ['pid','sense','monitoring'].indexOf(equipName?.profileType) > -1;
    }

    isModbusProfile(equipName) {
        /**if there are previous builds already installed in the site for that we are using  or condition*/
        if(equipName?.modBusEquip == "emr" || equipName?.modBusEquip == "emr_zone") {
            this.showModbusEmr = true;
        } else {
            this.showModbusEmr = false;
        }
        return ['modbus'].indexOf(equipName?.profileType) > -1;
    }

    getOverlayHeight(close = false) {

        if (!close) {
            let ele: any = document.getElementsByTagName('html')[0]
            return ele.scrollHeight + 'px'
        }
        let ele = document.getElementsByClassName('fs-content')[0];
        return ele.getBoundingClientRect().height + 'px'
    }
    exportExcel(type) {
        const analyticsExportCount = this.analyticsLayoutService.totalAnalyticsCount;
        const analyticsExportDataList = Object.keys(this.analyticsLayoutService.exportAnalyticsExcelData)?.length;
        this.isDownloadExcelPending = true;
        if (analyticsExportCount == analyticsExportDataList && this.analyticsLayoutService.isAnalyticsListLoading) {
            this.isDownloadExcelPending = false;
            clearInterval(this.timeSeriesinteval);
            clearInterval(this.clearExcelInterval);
            const fileName = type == 'Zone' ? this.refs?.zoneName : this.refs?.ccuName + '_' + this.refs?.ahuRef;
            let header = type + ' Data for ' + fileName;

            let allWidgets = { ...this.dataForExport };
            Object.keys(this.customWidgetDataForExport).forEach((key) => {
                allWidgets = { ...allWidgets, ...this.customWidgetDataForExport[key] };
            });
            let cols = Object.keys(allWidgets);
            const pointsDataLength = cols.map((col) => allWidgets[col]?.length ? allWidgets[col]?.length : 0);
            let maxArraylength = Math.max(...pointsDataLength);
            let index = pointsDataLength.indexOf(maxArraylength);
            let jsonData = [];
            if (cols.length > 0) {
                for (let i = 0; i < allWidgets[cols[index]]?.length; i++) {
                    let data = {}
                    for (let j = 0; j < cols.length; j++) {
                        if (j == 0) {
                            data['datetime'] = allWidgets[cols[j]][i] ? allWidgets[cols[j]][i].ts : ''
                        }
                        data[cols[j]] = allWidgets[cols[j]][i] ? allWidgets[cols[j]][i].val : ''
                    }
                    jsonData.push(data);
                }
            }
            cols.unshift('datetime');
            const analyticsData = this.exportData.formatGlobalExportData(jsonData);
            const analyticsCols = analyticsData.rows.filter(row => row != 'datetime');
            jsonData = analyticsData.sheetData;
            const analyticsColsKeys = analyticsCols.map(_col =>_col.key);
            let columns = cols.concat(analyticsCols.map(_col => _col.columnName));
            cols = cols.concat(analyticsColsKeys);
            columns = columns.map(_headerCol => _headerCol == 'datetime' ? 'Date | Time' : _headerCol);
            this.exportData.exportExcel(jsonData, fileName, header, cols, columns, this.isPartner);
            this.pollGraphData();
        } else {
            clearInterval(this.clearExcelInterval);
            clearInterval(this.timeSeriesinteval); // clearing the fetch time series data interval.
            this.clearExcelInterval = setInterval(() => {
                this.exportExcel(type);
            }, 1000);
        }
    }

    ngOnDestroy() {
        if (this.zendesklauncher) {
            this.zendesklauncher.style.right = '14px';
        }
        this.helperService.clearData();
        Object.keys(this.subscriptions).forEach(e => {
            this.subscriptions[e].unsubscribe();
        });
        this.pubNubService.unsubscribe();
        this.getEntitySubscription.unsubscribe();
        this.highlightRoomsSubscriptionCollection = [];
        this.vacationsService.updateVacationList = true;
        sessionStorage.clear();
        this.graphService.setData(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.cancelChanges = [];
        this.runTimeGraphsService.runTimeProfile.clear();
        //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
        clearInterval(this.timeSeriesinteval);
    }

    getFloorPlanDimensions(floor) {
        const img = new Image();

        img.src = floor.urlFloorZoneIn;
        img.addEventListener('load', () => {
            const ele = document.getElementById(floor.id);
            if (ele) {
                let containerWidth = ele.getBoundingClientRect().width;
                let containerHeight = ele.getBoundingClientRect().height;

                const xScaleFactor = containerWidth / img.width;
                const yScaleFactor = containerHeight / img.height;
                floor['imageWidth'] = img.width * xScaleFactor;
                floor['imageHeight'] = img.height * yScaleFactor;
            }

        });
    }

    getSystemProfileConfig() {
        let equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        const OAOObj =  this.widgetData.find(et=> et._id == this.refs.oaoRef);
        this.widgetData.find(et=> et._id == this.refs.oaoRef);
        const certificationLevel = this.authService.certificationLevel;
        // below is the method to get Equip Graphic points data;
        if((this.equipGraphicSystemProfile !=='dabExternalAHUController') || (this.equipGraphicSystemProfile !=='vavExternalAHUController')) {
            this.getEquipGraphicPoints();
        }
        // Loop all the profile tags array and get the required data for the system configuration setting based on system profile.
          systemProfileTagsArray.forEach((_profileObj: any) => {
            if (_profileObj.certificationCheck.includes(certificationLevel)) {
            const key = _profileObj.key;
                if (key == 'dcwbEnabled') {
                    this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, systemProfileFilterTags.tags, null, systemProfileFilterTags.filterTags), _profileObj.action);
                } else {
                    this.getZoneSettings(key, this.helperService.getPointIdbyTags(equipObj, _profileObj.tags,null,_profileObj?.filterTags,_profileObj?.domainName), _profileObj.action);
                }
            }
        });
        if (OAOObj) {
            /** OAO **/
            systemProfileOaoTagsArray.forEach(_profileObj => {
                if (_profileObj.certificationCheck.includes(certificationLevel)) {
                    if (_profileObj.key == 'outsideminopen') {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, systemProfileFilterOAOTags.tags, null,systemProfileFilterOAOTags.filterTags,systemProfileFilterOAOTags.domainName), _profileObj.action);
                    } else {
                        this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(OAOObj, _profileObj.tags,null,null,_profileObj.domainName), _profileObj.action);
                    }
                }
            });
        }
        this.getUserSettingData('reConfig');
    }


    checkIfSystemBTUIsPaired(sysBtuData) {
        let btuSystemData;
        this.siteService.findByQuery(`equipRef==@${sysBtuData[0]?.id}`).subscribe(sysBtuResponse => {
            btuSystemData = sysBtuResponse?.rows;
            this.fetchSystemModbusData(btuSystemData, 'btu', sysBtuData);
        }, error => {
            console.error("Error in ccusResponseObservable:", error);
        });
    }

    checkIfSystemEMRIsPaired(sysEmrData) {
        let emrSystemData;
        this.siteService.findByQuery(`equipRef==@${sysEmrData[0]?.id}`).subscribe(sysEmrResponse => {
            emrSystemData = sysEmrResponse?.rows;
            this.fetchSystemModbusData(emrSystemData, 'emr', sysEmrData);
        }, error => {
            console.error("Error in :", error);
        });
    }

    checkIfSystemModbusIsPaired(sysModbusData) {
        let modbusSystemData;
        this.siteService.findByQuery(`equipRef==@${sysModbusData[0]?.id}`).subscribe(sysModbusResponse => {
            modbusSystemData = sysModbusResponse?.rows;
            this.fetchSystemModbusData(modbusSystemData, 'modbus', sysModbusData);
            // This method is for fetching the user intent only for VAV/DAB EXTERNAL AHU
            this.modbusEquipId = sysModbusData[0]?.id;
            this.checkForModbusCurrentValues(this.modbusEquipId);
        }, error => {
            console.error("Error in :", error);
        });
    }

    //this method will fetch the system modbus data
    fetchSystemModbusData(systemData, modbusTypeCheck, res) {
        let isHeartBeat = false;
        if (systemData?.length > 0) {

            if (modbusTypeCheck === 'btu') {
                this.showBtu = true;
            }

            if (modbusTypeCheck === 'emr') {
                this.showEmr = true;
            }


            if (modbusTypeCheck === 'modbus') {
                this.showModbus = true;
            }

            // Note: Even though we are fetching data on an equipment basis, we will receive data specific to that particular equipment.
            // As per the requirement, we need to display points with the 'displayInUi' tag, hence the following filter is necessary.
            // Sometimes, we will receive schedule type and other points where the 'displayInUi' key may not exist.
            // In such cases, if we do not use the filter, it will break the code.

            let points = systemData.filter(p => p['displayInUi'] && p['equipRef']);
            const heartBeatPoints = systemData.filter(p => p['heartbeat'] && p['equipRef']) || [];
            isHeartBeat = heartBeatPoints.length > 0;
            points = heartBeatPoints.concat(points);

            const modbusProfileType = res[0].profile.split('_');
			const splitModbusDis = res[0]?.['dis']?.split('-');
			const modbusFromCCU = splitModbusDis[splitModbusDis?.length - 2]?.toLowerCase() == res[0]?.equipType?.toLowerCase();
			
			let modbusEquipName;
			
			// Condition to handle displayName from response depending on if it is from hayloft or not.
			if (modbusFromCCU) {
				const modbusProfile = res[0]?.['dis']?.substring(this.refs?.siteDetails?.dis?.length + 1).slice(0, -(res[0]?.group?.length + 1) - (modbusProfileType[1]?.length + 1));
				
                //format of value is not required for ahu profiles, as will be consuming the name from ccu directly.
                modbusEquipName = modbusTypeCheck === 'modbus' && ( this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') ?  `${res[0]?.['dis']?.substring(this.refs?.siteDetails?.dis?.length + 1)?.split('-')[0]}` +'-'+res[0]?.equipType+'(' +`${res[0]?.equipType?.toUpperCase()}` + `${res[0]?.group}` + ')' :  `${modbusProfile}-${modbusProfileType[1]?.toLowerCase()}` + '(' + `${modbusProfileType[1]}` + `${res[0]?.group}` + ')';
			} else {
			  modbusEquipName = `${splitModbusDis[splitModbusDis?.length - 2]}` + '(' + `${res[0]?.group}` + ')';
			}
			
			if (modbusTypeCheck === 'btu') {
				this.btuMeterType = modbusEquipName;
				this.hideDisplayUIBtuText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'emr') {
				this.emrMeterType = modbusEquipName;
				this.hideDisplayUIText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'modbus') {
				this.modbusType = modbusEquipName;
				this.hideDisplayUIModbusText = isHeartBeat ? points.length > 1 : true;
			}

            if (points.length > 0) {
                for (let i = 0; i < points.length; i++) {
                    const label = (i === 0 && isHeartBeat) ? 'lastUpdated' : `modbus${i + 1}`;
                    this.getSystemModbusSettings(
                        label,
                        [this.helperService.stripHaystackTypeMapping(points[i].id)],
                        points[i]['writable'] ? 'read&hisWrite' : 'read&hisRead',
                        modbusTypeCheck
                    );
                }
            } else {
                if (modbusTypeCheck === 'btu') {
                    this.hideDisplayUIBtuText = false;
                    this.getCCuSettingData();
                }

                if (modbusTypeCheck === 'emr') {
                    this.hideDisplayUIText = false;
                    this.getCCuSettingData();
                }

                if (modbusTypeCheck === 'modbus') {
                    this.hideDisplayUIModbusText = false;
                    this.getCCuSettingData();
                }
            }
        }
    }


    checkForModbusCurrentValues(modbusEquipId) {
        const systemPoints = [
            {
                pointName: 'ductStaticPressureCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'pressure', 'sensor','displayInUi','modbus','system'],
              },  {
                pointName: 'supplyAirflowTempCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'temp', 'sensor','displayInUi','modbus','system']
              },
        ];

        this.systemPoints = this.systemPoints.concat(systemPoints);
        this.getCCUSettingData();
    }



    getSystemModbusSettings(mode: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, mode, profileEquipName, 'update'));
            this.ccuSettingObj[mode] = {};
            this.ccuSettingObj[mode]['id'] = pointID;
            this.helperService.assemblePointIdData(pointID, endPointType, mode, profileEquipName, 'update');
        }
    }

    getSystemProfileType() {
        //In the following method we are filtering  all the system modbus profiles with the gateway ref 
        // then we are  grouping the equip refs based on the profile if it is EMR or BTU or VAV/DAB extenal ahu
        const combinedQuery = `gatewayRef==@${this.refs.gateway} and (system and modbus and profile))`;
        this.siteService.findByQuery(combinedQuery)
            .subscribe(
                res => {
                    //
                    if (!res?.rows?.length) {
                        return;
                    }
                    res = this.helperService.stripHaystackTypeMapping(res);

                    const filteredRows = res.rows.filter(row => row.hasOwnProperty('profile') && row.profile !== undefined);
                    // Handle no rows with profile defined
                    if (filteredRows.length === 0) {
                        return;
                    }
                    // Grouping the rows based on profile so profile name will come as key and object will come as value
                    const groupedRows = filteredRows.reduce((data, row) => {
                        if (!data[row.profile]) {
                            data[row.profile] = [];
                        }
                        data[row.profile].push(row);
                        return data;
                    }, {});

                    // based on profiles we are excuting the respective mentods
                    Object.keys(groupedRows).forEach(profile => {
                        if (profile === 'MODBUS_EMR') {
                            this.checkIfSystemEMRIsPaired(groupedRows[profile]);
                        }

                        if (profile === 'MODBUS_BTU') {
                            this.checkIfSystemBTUIsPaired(groupedRows[profile]);
                        }

                        if (profile === 'dabExternalAHUController' || profile === 'vavExternalAHUController') {
                            this.checkIfSystemModbusIsPaired(groupedRows[profile]); 
                        }
                    });
                },
                error => {
                    console.error("Error occurred while fetching data:", error);
                }
            );
    }

    getSystemReconfigurationData(result: any) {
        const self = this;
        const systemSettings = ObjectUtil.deepClone(this.systemReconfigurationSettings)
        let equipData = ObjectUtil.deepClone(this.systemReconfigurationSettings);

        if (!ObjectUtil.isEqual(result, systemSettings)) {
            this.systemReconfigurationSettings = ObjectUtil.deepClone(result);
            //In case of non-dm build, the points for reconfiguration are created using CCU, but in some cases, i.e. VAV related profiles which are listed below,some dynamic points are not created, hence they are initialised using this.setRelayMappingforVAVAndDAB()
            //Whereas in DM build points which are created by model do not have value,since they do not have value it results in scenario where reconfiguration is not displayed,hence they are set using this.setRelayMappingforVAVAndDAB()
            if (this.isdomainName && ['VAV Staged RTU', 'VAV Staged RTU with VFD Fan', 'VAV Fully Modulating AHU', 'DAB Staged RTU with VFD Fan', 'DAB Staged RTU', 'DAB Fully Modulating AHU'].map(item => item.toLowerCase()).includes(this.equipGraphicsProfiles.toLowerCase())) {
                this.setRelayMappingforVAVAndDAB()
            }
            equipData = ObjectUtil.deepClone(result);
            this.equipGraphicData = ObjectUtil.deepClone(equipData);
        }
        if (!self.systemReconfigProfileName || self.systemReconfigProfileName.trim() == 'System Profile...') {
            self.systemReconfigProfileName = self.systemWidgets?.[0] ? this.setSystemReconfigName(self.systemWidgets[0]?.name?.slice(8)) : '';
            self.systemReconfigTitle = self.systemWidgets?.[0] ? self.systemWidgets[0]?.name?.slice(8) : '';
        }
        if (self.systemReconfigProfileName === 'System Profile') {
            self.systemReconfigProfileName = 'Default System Profile';
            self.systemReconfigTitle = 'Default System Profile';
        }

        this.profileReconfigCheck = !['vavExternalAHUController', 'dabExternalAHUController', 'vavAdvancedHybridAhuV2', 'dabAdvancedHybridAhuV2'].includes(this.equipGraphicSystemProfile);
        if (equipData?.dcwbEnabled?.hasOwnProperty('val') && equipData?.dcwbEnabled?.val == 1) {
            this.equipGraphicsProfiles = this.configService.getConfig('appDisplayName') == 'ClimaVision' ? "VVT-C Fully Modulating AHU with DCWB" : "DAB Fully Modulating AHU with DCWB"
        }
    }


    hasCooling() {

        // For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
         //here in the  array coolingKeys containing the names of the keys that represent heating stages.
         
         if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1) {

            if (this.ccuSetting.hasOwnProperty('compositSignal')) {
                return true;
            } else {
                const coolingKeys = ['coolingstage1', 'coolingstage2', 'coolingstage3', 'coolingstage4', 'coolingstage5', 'satcoolingstage5', 'satcoolingstage1', 'satcoolingstage2', 'satcoolingstage3', 'satcoolingstage4', 'satcoolingstage5', 'satbasedcoolingcontrol', 'loadbasedcoolingcontrol',
                    'connectmodulecoolingstage1', 'connectmodulecoolingstage2', 'connectmodulecoolingstage3', 'connectmodulecoolingstage4', 'connectmodulecoolingstage5', 'connectmoduleloadbasedcoolingcontrol'];
                const isCooling = this.checkStagesPresent(this.systemReconfigurationSettings, coolingKeys);
                return isCooling;
            }
        }

        if (!this.systemReconfigurationSettings  || this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') return true;
        const keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3", "analog-out4"];
        let isCooling = false;
        for (let i = 0; i < keys.length; i++) {
            if (this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val)) {
                if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val) == 1) {
                    return true;
                } else if(this.ccuSetting['dcwbEnabled'] && this.ccuSetting['dcwbEnabled'].val == 1) {
                    if(keys[i]=="analog-out4") {
                        return true;
                    }
                }else if (keys[i] == "analog-out1") {
                    return true;
                }
                else if (this.systemReconfigurationSettings[keys[i] + "Mapping"] && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) < 5) {
                    return true;
                }
            }
        }
        return isCooling;
    }

    hasHeating() {

        // For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
        //here in the  array heatingKeys containing the names of the keys that represent heating stages.
        if (this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1) {
            if (this.ccuSetting.hasOwnProperty('compositSignal')) {
                return true;
            } else {
                const heatingKeys = ['heatingstage1', 'heatingstage2', 'heatingstage3', 'heatingstage4', 'heatingstage5','satheatingstage1', 'satheatingstage2', 'satheatingstage3', 'satheatingstage4', 'satheatingstage5','satbasedheatingcontrol','loadbasedheatingcontrol',
                'connectmoduleheatingstage1','connectmoduleheatingstage2','connectmoduleheatingstage3','connectmoduleheatingstage4','connectmoduleheatingstage5','connectmoduleloadbasedheatingcontrol'];
                const isHeating = this.checkStagesPresent(this.systemReconfigurationSettings, heatingKeys);
                return isHeating;
            }
        }


        if (!this.systemReconfigurationSettings  || this.equipGraphicSystemProfile == 'vavExternalAHUController' || this.equipGraphicSystemProfile == 'dabExternalAHUController') return true;
        let keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3","analog-out4"];
        if (this.systemReconfigProfileName != "VAV Fully Modulating AHU" && this.systemReconfigProfileName != "DAB Fully Modulating AHU") keys.push('relay-7');
        let isHeating = false;
        for (let i = 0; i < keys.length; i++) {
            if (this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val)) {
                if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.systemReconfigurationSettings[keys[i]] && parseInt(this.systemReconfigurationSettings[keys[i]].val) == 1) {
                    return true;
                } else if (keys[i] == "analog-out3" && this.systemReconfigurationSettings[keys[i]].val && this.systemReconfigurationSettings[keys[i]].val) {
                    return true;
                }
                else if (this.systemReconfigurationSettings[keys[i] + "Mapping"] && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) >= 5 && parseInt(this.systemReconfigurationSettings[keys[i] + "Mapping"].val) < 10) {
                    return true;
                }

            }
        }
        return isHeating;
    }

    isShowCMmode(mode) {
        if (mode == 1) {
            return this.hasCooling() && this.hasHeating();
        } else if (mode == 2) {
            return this.hasCooling()
        } else if (mode == 3) {
            return this.hasHeating();
        }
        return true;
    }

        //checkStagesPresent that takes two parameters:
      // 1. systemReconfigurationSettings: an object representing the system user intent data 
      // 2. requiredKeys: an array containing the names of the keys to be checked for existence It might be Heating Keys or cooling Keys
      checkStagesPresent(systemReconfigurationSettings, requiredKeys) {
        for (const key of requiredKeys) {
            if (systemReconfigurationSettings?.hasOwnProperty(key)) {
                // If the key is found in the systemReconfigurationSettings object, return true it might be Cooling key or heating key
                return true;
            }
        }
        // If none of the required keys are found in the systemReconfigurationSettings object, return false  it might be isCooling key or isHeating
        return false;
    }

    profileByID(index, profile) {
        return profile.profile._id;
    }

    floorsId(index, floor) {
        return floor._id;
    }

    autoRefreshChange(val) {
       this.handleAutoRefresh(val);
    }

    handleAutoRefresh(val) {
        // Refresh now
        if (this.autoRefreshSelection == val && val == 1) {
            this.autoRefreshSelection = -val;
            this.refresh();
        } else if (val == 1) {
            this.autoRefreshSelection = val;
            this.refresh();
        } else if (val !== 6) {
            this.clearAutoRefresh();
            this.setAutoRefresh(val);
            this.autoRefreshSelection = val;
        } else {
            // If the value is 6
            this.clearAutoRefresh();
        }
    }

    clearAutoRefresh() {
        if (this.autoRefreshSub) {
            this.autoRefreshSub.unsubscribe();
        }
    }

    setAutoRefresh(timer) {
        this.autoRefreshSub = interval(timer * 1000).subscribe(x => {
            this.refresh();
        });
    }

    refresh() {
        this.autoRefreshSubject.next(true);
    }

    /**************** Custom Heatmap Viz Code  ***********************************/

    getNodes(arr, tags) {
        if (!arr || (Array.isArray(arr) && !arr.length)) {
            return [];
        }
        return arr.filter((_filterItem) => _filterItem)
            .filter((_item) => {
                const found = (_item.markers && Array.isArray(_item.markers)) ? tags.every(elem => _item.markers.indexOf(elem) > -1) : false;
                if (found) {
                    return _item;
                }
            })
    }

    isOAOOnline() {
        if(!(this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val'))) return;
        this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(this.ccuSetting?.oaoLastUpdated?.time).local();
        let timeDiffMinutes = Math.abs(this.ccuSetting?.oaoLastUpdated?.val.diff(dayjs(), 'minutes'))
        return timeDiffMinutes < 16;
    }
    
    isOnline(key) {
        if(!(this.ccuSetting?.[key]?.lastUpdated?.hasOwnProperty('val'))) return;
        this.ccuSetting[key]['lastUpdated']['val'] = dayjs.utc(this.ccuSetting[key].lastUpdated?.time).local();
        let timeDiffMinutes = Math.abs(this.ccuSetting[key]?.lastUpdated?.val.diff(dayjs(), 'minutes'))
        return timeDiffMinutes < 16;
    }

    dateFormat(val) {
        return DateUtil.dateFormat(val,this.siteService.getIANATimeZone(this.siteTz));
    }
    isProfileEMR(equipName) {
        return equipName && (equipName.profileType && equipName.profileType && equipName.profileType == 'emr');
    }
    heartBeathandle() {
        if(!this.zoneSettings) return;
        if (this.profiles && (this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length-1]))) {
          this.profiles.forEach(profile => {
            if(this.zoneSettings[profile.profile.name]?.lastUpdated?.time && this.zoneSettings[profile.profile.name]?.lastUpdated?.hasOwnProperty('val')) {
              this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name].lastUpdated.time)?.local();
            } 
          });
        } else if (this.zoneSettings?.lastUpdated?.time && this.zoneSettings?.lastUpdated?.hasOwnProperty('val')) {
            this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings?.lastUpdated?.time).local();
        }
        
      }

    isModuleOnline(profile) {
        if (this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length-1])) {
          if(!(this.zoneSettings?.[profile.profile.name]?.lastUpdated?.hasOwnProperty('val'))) return;
            this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name]?.lastUpdated?.time).local();
            let timeDiffMinutes = Math.abs(this.zoneSettings[profile.profile.name]?.lastUpdated?.val?.diff(dayjs(), 'minutes'))
            return timeDiffMinutes < 16;
        } else {
            if(!(this.zoneSettings?.lastUpdated?.hasOwnProperty('val'))) return;
            this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings.lastUpdated?.time).local();
            let timeDiffMinutes = Math.abs(this.zoneSettings?.lastUpdated?.val?.diff(dayjs(), 'minutes'))
            return timeDiffMinutes < 16;
        }
    }

    @HostListener('window: scroll', ['$event'])
    onWindowScroll($event) {
        this.isHeaderFixed = (window.scrollY > 40);
        let bottomReached = window.scrollY + window.innerHeight >= document.body.scrollHeight - 150;
        if(bottomReached && !this.modalPopupOpened) {
            this.loadMoreFloors()
        }
       
        if(this.summaryTableOpen){
            this.hideCCUHeader = this.calculateCCUHeaderVisibility('accordian_summary_table', this.summaryTableOpen);
        } else if(this.noteUploadOpen){
            this.hideCCUHeader = this.calculateCCUHeaderVisibility('accordian_notes_upload', this.noteUploadOpen);
        }
    }

    calculateCCUHeaderVisibility(elementId, isOpen) {
        if (isOpen && ((document.getElementById(elementId)?.getBoundingClientRect()?.top - document.getElementsByClassName('ccu-list-ctnr')[0]?.getBoundingClientRect()?.top) <= 37)) {
            return true;
        } else {
            return false;
        }
    }

    userPrefChange(event) {
        this.userPrefData['ccuOrder'] = event || [];
        this.setCCUOrder();
        this.setCcuColors();
        this.handleAutoRefresh(1);
    }

    setCCUOrder() {
        ArrayUtil.mapOrder(this.buildings.floorCcu,this.userPrefData.ccuOrder,'_id');
    }

    /**************** Custom Heatmap Viz Code  End***********************************/

    async filterChangeHandle(event, fromHeaderComponent: boolean = true) {
        if (!event) event = { filterId: 'default', defaultInd: true };
        let filterWithPoints = event;
        if (!fromHeaderComponent) {
            filterWithPoints = ObjectUtil.deepClone(this.filterSrvc.getFilterFromList(event) || event);
        } else {
            filterWithPoints = event;
        }
        await this.setFilterPoints(filterWithPoints);
        if (!this.initialFilter) {
            await this.setUserPreferencesData(JSON.stringify(event), ["lastSelectedFilter"]);
        }
        if (this.initialFilter) {
            this.initialFilter = !this.initialFilter;
        }
    }

    async setFilterPoints(filterWithPoints) {
        if((filterWithPoints?.pointDefinitions?.length || (filterWithPoints?.defaultInd && filterWithPoints?.filterId != 'alert')) && this.refs.siteRef) {
            filterWithPoints = await this.heatmapService.getMatchedPointsFromSelectedFilter(filterWithPoints,this.refs.siteRef);
        }
        this.selectedFilter = filterWithPoints;
        this.heatmapService.selectedFilter = ObjectUtil.deepClone(this.selectedFilter);
    }

    defaultFilterChangeHandle(event) {
        if (!event) event = { filterId: 'default', defaultInd: true };
        this.defaultFilter = event;
        this.setUserPreferencesData(JSON.stringify(event), ["defaultFilter"]);
    }

    
    getScheduleInfoForCCU(selectedAhuRef) {
        const mappedZones =
        this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.ahuRef === selectedAhuRef)?.pairedZoneList || [];
        let zoneList = this.buildings.zones.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
        zoneList = this.getZonesSchedulesByCCURef(zoneList);
        // Ignore the zones have 'sense', emr, pid, modbus.
        zoneList = zoneList.map(zone => {
            return this.checkProfileTypeOfZones(zone);
        }).filter(r => r);
        // Get Zone Point Ids for all the zones.
        this.getAllZonePointIds(zoneList);
    }

    checkProfileTypeOfZones(zone) {
        const profile = this.deviceHelper.getZoneProfile([zone]);
        const includeProfiles = ['vav', 'dab', 'ti', 'dualDuct', 'series', 'parallel', 'otn', 'bpos','chilledBeam'];
        const found = profile.find(item => includeProfiles.includes(item.profileType));
        if (found) {
            return zone;
        }
    }

		getSchIdRefs(zoneId) {
			let schId = [];
			this.buildings.floors.forEach(element => {
				const found = element.entities.find(r => r._id == zoneId);
				if (found && Object.keys(found) && Object.keys(found).length) {
					schId = [found['referenceIDs']['schedule']];
				}	
			});
			return schId;
		}

    getZonesSchedulesByCCURef(zoneList) {
        return zoneList.map((zone) => {
            zone['scheduleId'] = this.getSchIdRefs(zone._id);
			zone['zoneSpecialSchIds'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'range', 'special'], zone._id)
            zone['scheduleType'] =  this.helperService.getPointIdbyTags(this.buildings, ['zone', 'scheduleType'], zone._id);
            zone['zoneVacationIds'] = this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], zone._id);
            return zone;
        });
    }

	getAllZonePointIds(zoneList) {
		zoneList.forEach(zone => {
			this.getZoneSettings('zoneSchedule', zone['scheduleId'], 'schedule', zone.name);
			this.getZoneSettings('zoneSpecialSchedule', zone['zoneSpecialSchIds'], 'vacation', zone.name);
			this.getZoneSettings('scheduleType', zone['scheduleType'], 'write', zone.name);
			this.getZoneSettings('zoneVacations', zone['zoneVacationIds'], 'vacation', zone.name);
		});
        this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'days']) , null);
        this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , null);
		this.getZoneSettings('buildingSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'special', 'schedule', 'range'], null), 'vacation');
		this.getZoneSettings('buildingVacations', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'range', 'vacation'], null), 'vacation');
		this.getCCuSettingData();
	}

    UpdateBuildingsDataWhenNamedSchApply() {
        this.siteService.findByQuery(this.query + this.refs.siteRef).pipe(
            map(this.helperService.stripHaystackTypeMapping),
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure)).subscribe(
				(response: any) => {
						this.buildingMaster = response;
						this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
                        this.buildings['devices'] = this.getNodes(response, ['device']);
                        this.buildings['equips'] = this.getNodes(response, ['equip']);

						this.buildings["floors"] = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
						this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule') || [];
                        this.buildings['schedulable'] = this.buildings['entites'].filter(equip => ['schedulable'].every(elem => equip.markers.indexOf(elem) > -1))
                        this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy') || [];
						if (this.ccuOrZone.type == 'zone' && this.ccuOrZone.actionUsed == 'click') {
							const floorData = this.buildings["floors"].map(floor => floor.entities.filter(room => room._id === this.roomObj?._id));
                            if(floorData[0] && floorData[0][0]) {
								this.updateRoomObj(floorData[0][0]);
                            }
						}
				});

    }

		updateRoomObj(floorData) {
			if (floorData && floorData.length > 0) {
				let equipRefence;
				this.roomObj = ObjectUtil.deepClone(floorData);
				equipRefence = floorData.map(equip => equip.entities.filter(entity => entity.entities.length > 0))[0][0];
				this.refs.roomRef = equipRefence == undefined ? undefined : equipRefence.referenceIDs.room;
				this.refs.equipRef = equipRefence == undefined ? undefined : equipRefence._id;
				if (equipRefence == undefined) {
					this.refs.ahuRef = undefined;
				} else {
					if (equipRefence.referenceIDs.ahu == undefined) {
						this.refs.ahuRef = equipRefence.referenceIDs.gateway;
					} else {
						this.refs.ahuRef = equipRefence.referenceIDs.ahu;
					}
				}
			}
		}

        async setUserPreferencesData(event, data) {
            try {
                await this.getUserPreferencesData(false);
            } catch(e) {
                
            }
            if (data.length == 1) {
              this.userPreferenceData.heatmapPreferences[data] = event;
            } else {
              this.userPreferenceData.heatmapPreferences[data[0]][data[1]] = event;
            }

            Object.keys(this.userPreferenceData.heatmapPreferences).forEach((preferenceKey) => {
                if (preferenceKey.includes(",")) {
                    delete this.userPreferenceData.heatmapPreferences[preferenceKey];
                }
            });

           this.userPreferenceApi();
          }
          userPreferenceApi(){
            this.userPref
            .setUserPreferenceData(this.userPreferenceData)
            .subscribe((res) => {
              this.userPreferenceData = res;
              this.getUserPreferencesHelper(res);
            });
          }

          async getUserPreferencesData(firstCall:boolean) {
            const self = this;
            return new Promise<void>((resolve, reject) => {
                this.userPref.getUserPreferenceData().subscribe({
                  next: (res) => {
                    this.userPreferenceData = res;
                        if (firstCall) {
                            const heatmapPreferences = res["heatmapPreferences"];
                            const lastSelectedFilter = heatmapPreferences["lastSelectedFilter"];
                            const defaultFilter = heatmapPreferences["defaultFilter"];
                            this.filterChangeHandle(ObjectUtil.parseJSON(lastSelectedFilter), false);
                            const parsedDefaultFilter = defaultFilter ? ObjectUtil.parseJSON(defaultFilter) : {filterId:'default', defaultInd:true};
                            if (self.filterSrvc.getFilterFromList(parsedDefaultFilter)) {
                                this.defaultFilter = parsedDefaultFilter;
                            }
                        }
                    this.getUserPreferencesHelper(res);
                  },
                  error: (err) => {
                    this.filterChangeHandle(null, false);
                    this.defaultFilter = null;
                    reject(err);
                  },
                  complete: () => {
                    resolve();
                  }
                });
              });
              
          }

          getUserPreferencesHelper(res){
            this.zonePreDefinedWidgetsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["preDefinedWidgets"];
            this.zoneAlertsAccordionOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["alertsAccordion"]
            this.zoneSettingsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneSettings"];
            this.CCUPreDefinedWidgetsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["preDefinedWidgets"];
            this.CCUAlertsAccordionOpenState =  res["heatmapPreferences"]['CCUHeatmapPreferences']["alertsAccordion"];
            this.CCUSettingsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["CCUSettings"];   
            this.zoneTunersOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneTuners"]; 
            this.systemTunersOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["systemTuners"];
            this.systemLevelEquipGraphics = res["heatmapPreferences"]['CCUHeatmapPreferences']['systemEquipGraphics'];
            this.CCUreconfigurationOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["reconfiguration"];
            this.remoteAccessOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["remoteAccess"];
            this.zoneReconfigurationOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["zoneReconfiguration"];   
            this.terminalEquipGraphics = res["heatmapPreferences"]['zoneHeatmapPreferences']['accordionSectionSelction'];    
            this.ccuPointSummaryOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["pointSummary"];
            this.zonePointSummaryOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["pointSummary"];
            this.terminalEquipGraphics = res["heatmapPreferences"]['zoneHeatmapPreferences']['accordionSectionSelction'];
            this.showlastUpdatedToggleEnabled = res["heatmapPreferences"]['showLastUpdated'] || false;
            this.ccuAnalyticsOpenState = res["heatmapPreferences"]['CCUHeatmapPreferences']["analytics"];
            this.zoneAnalyticsOpenState = res["heatmapPreferences"]['zoneHeatmapPreferences']["analytics"];
        }
        setUserPreferencesDataForCustomWidget(data){
            this.userPreferenceData.heatmapPreferences[data.array[0]][data.array[1]] = data.value;
            this.userPreferenceApi();
        }
        openSchedulerModal(schedule) {
            //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
            clearInterval(this.messagingSubscribe);
            clearInterval(this.timeSeriesinteval);
            document.body.style.overflow = 'hidden';
            this.openedSchedulerEditModal = true;
            this.tempConversion();
            this.schedulerEditDialogRef = this.dialog.open(SchedulerEditModalComponent, {
                panelClass: 'scheduler-occupancy-edit-modal',
                maxHeight: '1000px',
                width: '1050px',
                maxWidth:'1100px',
                autoFocus: false,
                data: {
                    zoneName: this.refs.zoneName,
                    scheduleId: schedule.id,
                    orgName: this.refs?.siteDetails?.organization,
                    scheduleInfo: schedule.val,
                    buildingLimits: this.buildingLimit,
                    defaultLimits: this.defaultLimits,
                    sliderDisplayFlag : true,
                    roomRef: this.roomObj?._id,
                    ccuRef:this.roomObj?.referenceIDs?.ccu,
                    fromZonePage: true,
                    buildingScheduleInfo: this.zoneSettings?.buildingOccupancy?.val,
                    refId : this.refs.roomRef,
                    siteId: this.refs.siteRef,
                    scheduleGroup: schedule.scheduleGroup,
                    enableScheduleGroup: this.enableScheduleGroup,
                    zoneSpecialSchedules: this.zoneSettings?.zoneSpecialSchedule,
                    unoccupiedSetback: this.zoneSettings?.zoneLevelunoccupiedZoneSetback?.val,
                    followBuilding: this.zoneSettings['zoneSchedule']?.followBuilding,
                    buildingToZoneDifferential: this.zoneSettings?.buildingToZoneDifferential?.val ? this.userTempPreference == this.unitService.temperatureUnitListArray[0]? this.unitService.convertingDeadBandValue(this.zoneSettings.buildingToZoneDifferential.val) : this.zoneSettings.buildingToZoneDifferential.val : 3,
                    globalizationUnit: this.userTempPreference,
                    buildingHeatingUserLimitMax: this.zoneSettings?.buildingHeatingUserLimitMax?.val,
                    buildingCoolingUserLimitMin: this.zoneSettings?.buildingCoolingUserLimitMin?.val,
                    buildingHeatingUserLimitMin: this.zoneSettings?.buildingHeatingUserLimitMin?.val,
                    buildingCoolingUserLimitMax: this.zoneSettings?.buildingCoolingUserLimitMax?.val,
                    buildingHeatingDeadband: this.zoneSettings?.buildingHeatingDeadband?.val,
                    buildingCoolingDeadband: this.zoneSettings?.buildingCoolingDeadband?.val,
                    buildingUnoccupiedZoneSetback: this.zoneSettings?.buildingUnoccupiedZoneSetback?.val,
                    buildingLimitsMinFahrenheit: this.zoneSettings?.buildingLimitMin?.val,
                    buildingLimitsMaxFahrenheit: this.zoneSettings.buildingLimitMax?.val,
                    buildingToZoneDifferentialFahrenheit: this.zoneSettings?.buildingToZoneDifferential?.val ? this.zoneSettings.buildingToZoneDifferential.val: 3,
                    siteTz: this.siteTz
                }
            });
            this.schedulerEditDialogRef.afterClosed().subscribe(_onClose => {
                document.body.style.overflow = null;
                this.openedSchedulerEditModal = false;
                //start the setInterval call to call the api to update the data change if any every 5 sec
                this.pollingFunction();
                this.pollGraphData();
            });
        }

        summaryTableOpened(event){
            this.summaryTableOpen =  event
        }

        notedUploadOpened(event){ 
            this.noteUploadOpen = event;
        }      

        get isValidCertificationLevel(): boolean {
            return this.authService.certificationLevel == 'L0' || this.authService.certificationLevel == 'L1';
        }

        get isSummaryTableCheckboxDisabled(): boolean {
            return this.authService.certificationLevel != 'L0';
        }

        updateLastedValues(res) {
            if(!res) return;
            if (this.profiles && this.profiles.length > 1 || this.isProfileEMR(this.profiles[this.profiles.length - 1])) {
                this.profiles.forEach(profile => {
                    if (res[profile.profile.name]?.lastUpdated?.time && res[profile.profile.name]?.lastUpdated?.hasOwnProperty('val')) {
                        this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(res[profile.profile.name].lastUpdated.time).local();
                    }
                });
            } else if(res?.lastUpdated?.time && res?.lastUpdated?.hasOwnProperty('val')) {
                    this.zoneSettings['lastUpdated']['val'] = dayjs.utc(res.lastUpdated.time).local();
            }
        }

        pollGraphData() {
            clearInterval(this.timeSeriesinteval);
            this.timeSeriesinteval = setInterval(() => {
                const tz: string = this.siteService.getIANATimeZone(this.siteTz);
                const offset = dayjs().tz(tz).utcOffset();
                const centralOffset = dayjs().utcOffset();
                let endDate = this.heatMapToolTipService.selectedDateRange.endDate;
                const selectedDate: any = {
                    endDate: this.selected.endDate,
                    startDate: this.selected.startDate,
                    timeZone: this.selected.timeZone
                };
                // Check if end date is before today's date
                if (dayjs(new Date(endDate.format('YYYY/MM/DD'))).endOf('day').isBefore(dayjs(Date.now()))) {
                    selectedDate.endDate = dayjs(new Date(endDate.format('YYYY/MM/DD'))).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                    return;
                } else if ((new Date(endDate.format('YYYY/MM/DD')).toDateString()) == (new Date(Date.now()).toDateString())) {
                    const day = dayjs(Date.now()).tz(tz)
                    const currentDay = this.checkDateconversion(day.year(), day.month(), day.date(), day.hour(), day.minute(), day.second(), day.millisecond())
                    selectedDate.endDate = currentDay == new Date(endDate.format('YYYY/MM/DD')).toDateString() ? dayjs(Date.now()).tz(tz) : dayjs(new Date(endDate.format('YYYY/MM/DD'))).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                } else if (dayjs(new Date(endDate.format('YYYY/MM/DD'))).endOf('day').isAfter(dayjs(Date.now()))) {
                    selectedDate.endDate = dayjs(Date.now()).tz(tz);
                } else {
                    selectedDate.endDate = dayjs(new Date(endDate.format('YYYY/MM/DD'))).endOf('day').tz(tz).add(-(offset - centralOffset), 'minutes');
                }
                // Check if end date is before today's date
                if (dayjs(new Date(selectedDate.endDate.format('YYYY/MM/DD'))).isBefore(dayjs(new Date(selectedDate.startDate)))) {
                    selectedDate.endDate = dayjs(Date.now()).tz(tz);
                }
                this.heatMapToolTipService.selectedDateRange = selectedDate;
                this.fetchTimeSeriesData(this.heatMapToolTipService.selectedDateRange);
    
                // Also make the calls for getting custom widgets data
                this.customHeatmapService.setRefreshSubject();
            }, 60000);
        }

      checkForHeatingOrCooling(current,zoneDetails) {
        if (zoneDetails['singleDual']?.val == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
            return 'cooling';
        } else if (zoneDetails['singleDual']?.val == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
            return 'heating';
        }

        if (zoneDetails['singleDual']?.val == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
            return 'cooling';
        } else if (zoneDetails['singleDual']?.val == "2" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
            return 'heating'
        }
    }

    filterWidgetTempValues(graphIndexCooling,graphIndexHeating,res) {
        if (graphIndexCooling.length) {
            let coolArray = graphIndexCooling.map((i) => i.id);
            for (let i = 0; i <= graphIndexCooling.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return coolArray.indexOf(item.id) === -1;
                });
            }
        } else if (graphIndexHeating.length) {
            let heatArray = graphIndexHeating.map((i) => i.id);
            for (let i = 0; i <= graphIndexHeating.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return heatArray.indexOf(item.id) === -1;
                });
            }

        }
        return res;
    }

    zoneSingleDualGraph(res) {
        const graphIndexCooling = [];
        const graphIndexHeating = [];
        res['rows'].find((current) => {
            if (this.hoverZoneData?.singleDual == "1" && (current.hasOwnProperty('heating') && current.hasOwnProperty('desired'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2" && (current.hasOwnProperty('cooling') && current.hasOwnProperty('desired'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual  == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2"  && (current.hasOwnProperty('limit') && current.hasOwnProperty('min') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }

            if (this.hoverZoneData?.singleDual  == "1" && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('heating'))) {
                graphIndexCooling.push(current);
            } else if (this.hoverZoneData?.singleDual == "2"  && (current.hasOwnProperty('limit') && current.hasOwnProperty('max') && current.hasOwnProperty('cooling'))) {
                graphIndexHeating.push(current);
            }
        });


        if (graphIndexCooling.length) {
            let coolArray = graphIndexCooling.map((i) => i.id);
            for (let i = 0; i <= graphIndexCooling.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return coolArray.indexOf(item.id) === -1;
                });
            }
        } else if (graphIndexHeating.length) {
            let heatArray = graphIndexHeating.map((i) => i.id);
            for (let i = 0; i <= graphIndexHeating.length; i++) {
                res['rows'] = res['rows'].filter((item) => {
                    return heatArray.indexOf(item.id) === -1;
                });
            }

        }
        return res['rows'];
    }

	setOTAandCCUSettings() {
		if (this.ccuSetting?.desiredComfortIndex?.hasOwnProperty('val')) {
			this.desiredCIValue = this.ccuSetting.desiredComfortIndex?.val;
			this.desiredCISliderValue = this.rangeSliderHack[parseInt(this.desiredCIValue)];
		}
		if (this.ccuSetting?.occStatus) {
			let occstatus = this.ccuSetting.occStatus?.val;
			this.OccStatus = this.occStatusValues[occstatus];
		}
		if (this.ccuSetting?.oao?.val) {
			this.oaoArcValue = parseInt(this.ccuSetting.oao.val);
		}
		if (this.ccuSetting?.cloudLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['cloudLastUpdated']['val'] = dayjs.utc(this.ccuSetting.cloudLastUpdated.time).local();
		}
		if (this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(this.ccuSetting.oaoLastUpdated.time).local();
		}
	}

	setOTAandCloudStatusSettings(res) {
		this.buildingDataTempHolder = ObjectUtil.deepClone(res);
		if (res?.cloudLastUpdated.hasOwnProperty('val')) {
			this.ccuSetting['cloudLastUpdated']['val'] = dayjs.utc(res.cloudLastUpdated.time).local();
		}
		if (this.ccuSetting?.oaoLastUpdated?.hasOwnProperty('val')) {
			this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(res.time).local();
		}
	}

	checkCertificationAccess(view) {
		return this.authService.isUserAllowedToCheck(view);
	}
    async loadZoneTuners(roomInfo) {
        this.zoneTuners = [];
        this.helperService.tunersInfo.forEach(async (_item, _key) => {
            let obj = {};
            const promise = new Promise((resolve) => {
                const filteredItems: any[] = _item.filter((_filterItem) => {
                    const zoneTunerFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['roomRef', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    if (zoneTunerFound && _filterItem?.referenceIDs?.room == roomInfo?._id) {
                        return _filterItem;
                    }
                });
                resolve(filteredItems);
            });
            const filteredItems: any = await promise;
            if(Object.keys(filteredItems).length > 0) {
                this.appendZoneTuners(_item, _key, filteredItems, obj);
            }
        });

    }

    appendZoneTuners(_item, _key, filteredItems, obj) {
        if (filteredItems && filteredItems.length > 0) {
            if ((Array.isArray(_item) && _item.length) && Array.isArray(filteredItems) && filteredItems.length) {
                const names: Array<any> = _key.split('_') || [];
                const name = (names.length) ? names[0] : _key;
                obj['name'] = name;
                obj['values'] = filteredItems;
                obj['unit'] = _item[0]['unit'] || '';
                obj['minVal'] = _item[0]['minVal'] || '';
                obj['maxVal'] = _item[0]['maxVal'] || '';
                obj['incrementVal'] = _item[0]['incrementVal'] || '';
                obj['tunerGroup'] = _item[0]['tunerGroup'] || '';
                if (obj['unit'] && this.unitService.unitListArray.includes(obj['unit'])) {
                    obj = this.unitService.formatTunerList(obj, true);
                }
                this.zoneTuners.push(obj);
            }
        }
    }

    onTunerChange(event) {
        this.tunerDataChanged = event || false;
    }

    async loadTunerData(ccuInfo) {
        this.deviceHelper.systemProfileSubject.pipe(first()).subscribe((systemProfile) => {
            if (systemProfile.ccuRef != this.refs.ahuRef) {
                return
            }
            const equipZones = this.helperService.equipZones;

            let ccuType;
            if (systemProfile.systemProfileName && systemProfile.systemProfileName.toLowerCase().includes('vav')) {
                ccuType = 'VAV';
            } else if (systemProfile.systemProfileName && systemProfile.systemProfileName.toLowerCase().includes('dab')) {
                ccuType = 'DAB';
            } 

            this.tuners = [];
            this.checkForSystemLevelTuner(ccuInfo,equipZones,ccuType)
        });
    }

    checkForSystemLevelTuner(ccuInfo, equipZones, ccuType) {
        this.helperService.tunersInfo.forEach(async (_item, _key) => {
            let obj = {};
            const promise = new Promise((resolve) => {
                const filteredItems = _item.filter((_filterItem) => {
                    const systemFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['system', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    const oaoFound = (_filterItem.markers && Array.isArray(_filterItem.markers)) ? ['oao', 'tuner'].every(elem => _filterItem.markers.indexOf(elem) > -1) : false;
                    if ((oaoFound && ccuInfo['oaoRef'] == _filterItem['referenceIDs']['equip']) || (systemFound && ccuInfo['ccuAhu'] == _filterItem['referenceIDs']['equip'])) {
                        return _filterItem;
                    } else {
                        const found = ArrayUtil.findInArray(equipZones, '_id', _filterItem['referenceIDs']['equip']);
                        if (found && (ccuInfo['ccuAhu'] == found['referenceIDs']['ahu'] || ccuInfo['ccuAhu'] == found['referenceIDs']['gateway'])) {
                            return _filterItem;
                        }
                    }
                });
                resolve(filteredItems);
            });
            const filteredItems = await promise;
            if(Object.keys(filteredItems).length > 0) {
                this.appendTunerData(_item, _key, filteredItems, obj, ccuType);
            }
        });
    }

    appendTunerData(_item, _key, filteredItems, obj, ccuType) {
        if (filteredItems && filteredItems.length > 0 ) {
            if ((Array.isArray(_item) && _item.length && ((_item[0]['tunerGroup'] != 'VAV' && _item[0]['tunerGroup'] != 'DAB') || ccuType == _item[0]['tunerGroup'])) || !ccuType) {
                const names: Array<any> = _key.split('_') || [];
                const name = (names.length) ? names[0] : _key;
                obj['name'] = name;
                obj['values'] = filteredItems;
                obj['unit'] = _item[0]['unit'] || '';
                obj['minVal'] = _item[0]['minVal'] || '';
                obj['maxVal'] = _item[0]['maxVal'] || '';
                obj['incrementVal'] = _item[0]['incrementVal'] || '';
                obj['tunerGroup'] = _item[0]['tunerGroup'] || '';
                if (obj['unit'] && this.unitService.unitListArray.includes(obj['unit'])) {
                    obj = this.unitService.formatTunerList(obj, true);
                }
                this.tuners.push(obj);
            }
        }
    }

    //This method returns the profile name to systemReconfigProfileName.VVT-C is changed to DAB here, to avoid impact on logics being done for DAB profiles. 
    setSystemReconfigName(profile) {
        if (profile?.includes('VVT-C')) {
            return profile?.replace('VVT-C', 'DAB')
        }
        return profile;
    }

    /**
     * Returns true if the chat-feature is enabled on the Heatmap **and** a site is selected.
     */
    get chatEnabled() {
        return this.show75FChatButton === true && this.refs.siteRef;
    }

    /**
     * Toggles the chat session.
     */
    chatSessionActive: boolean = false;
    onToggleChat() {
        this.chatSessionActive = !this.chatSessionActive;
    }

    updateTimeToggle(event) {
        this.setUserPreferencesData(event,['showLastUpdated']);
    }

    setTerminalGraphicValues(res) {
        this.equipGraphicData = ObjectUtil.deepClone(res);
        const profileTypesToCheck = ['chilledBeam', 'dab', 'dualDuct', 'sse','series','pipe2', 'pipe4','hpu','vav', 'parallel','cpu'];
        const checkProfileTypeMatch = this.profiles.some((obj: { profileType: string }) => profileTypesToCheck.includes(obj.profileType));
        if(this.zonehasDomainName && this.profiles[0]?.profile?.domainName.toLowerCase().includes('hpu')) {
            this.reconfigForDM()
        }
    }

    reconfigForDM() {
        this.profiles.forEach((profile) => {
            if (this.checkHyperStatProfile && ObjectUtil.isNotUndefinedOrNull(profile.profile?.domainName)) {
                const HSProfileeReconfigPoints = [
                    'relay1',
                    'relay2',
                    'relay3',
                    'relay4',
                    'relay5',
                    'relay6',
                    'analogOutRelay2',
                    'analogOutRelay1',
                    'analogOutRelay3',
                    'thIn1Enabled',
                    'thIn2Enabled',
                    'analogInRelay2',
                    'analogInRelay1',
                    'relay1Association',
                    'relay2Association',
                    'relay3Association',
                    'relay4Association',
                    'relay5Association',
                    'relay6Association',
                    'analogOut1Association',
                    'analogOut2Association',
                   'analogOut3Association',
                    'analogIn1Association',
                    'analogIn2Association',
                    'thIn1Association',
                    'thIn2Association',
                ];
    
                  HSProfileeReconfigPoints.forEach((prop) => {
                    if (!(prop in this.zoneSettings)) {
                        this.zoneSettings[prop] = { val : 0 };
                    }
                });
            }
        });
    }
      checkForUnitConversion(equipData) {
        if (this.userTempPreference == '°C') {
            equipData['Outside Air Temp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['Outside Air Temp']?.val?.split(' ')[0]), 'outsideAirTemp').toString() + this.userTempPreference;
            equipData['currentTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['currentTemp']?.val?.split(' ')[0]), 'currentTemp').toString() + this.userTempPreference;
            equipData['mixedAirTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['mixedAirTemp']?.val?.split(' ')[0]), 'mixedAirTemp').toString() + this.userTempPreference;
            equipData['desiredTempCooling'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['desiredTempCooling']?.val), 'desiredTempCooling').toString() + this.userTempPreference;
            equipData['desiredTempHeating'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['desiredTempHeating']?.val), 'desiredTempHeating').toString() + this.userTempPreference;
            equipData['supplyAirTemp'].val = this.unitService.fahrenheitToCelecius(parseFloat(equipData['supplyAirTemp']?.val), 'supplyAirTemp').toString() + this.userTempPreference;
        } else {
            const tempArray = ['Outside Air Temp', 'currentTemp', 'mixedAirTemp','desiredTempCooling','desiredTempHeating','supplyAirTemp'];

            for (let i = 0; i <= tempArray.length; i++) {
                if (equipData && equipData[tempArray[i]]?.hasOwnProperty('val')) {
                    if (!equipData[tempArray[i]]?.val?.toString().includes('°F')) {
                        equipData[tempArray[i]].val = `${equipData[tempArray[i]].val} °F`;
                    }
                }
            }
        }
        return equipData;
    }

    scrollNExpandNotes(event){
        this.expandNotesSection = event;
        this.lazyLoadFloors = this.buildings.floors;
    }

    //Advanced Ahu User Intent
    getSupplyAirflowTempValue(ccuSetting) {
        const val = ccuSetting?.supplyAirflowTempControl?.val;
        const propertyNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'];
        switch (val) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                const propertyName = propertyNames[val];
                return ccuSetting.hasOwnProperty(propertyName) && ccuSetting[propertyName]?.val != null ? ccuSetting[propertyName].val : '-';
            default:
                return '-';
        }
    }

    getDischargeAirflowTempValue(ccuSetting) {
        const pressureFanControl = ccuSetting?.pressureBasedFanControl?.val;
        const analogInput1 = ccuSetting?.analog1InputAssociation?.val;
        const analogInput2 = ccuSetting?.analog2InputAssociation?.val;
        const busAdd0 = ccuSetting?.pressureSensorBusAdd0?.val;

        const checkSensors = (sensors) => sensors.map(sensor => ccuSetting?.[sensor]?.val).find(val => val !== undefined) ?? '-';

        switch (pressureFanControl) {
            case 0:
                if ([12, 13, 14].includes(analogInput1) || [12, 13, 14].includes(analogInput2)) {
                    return checkSensors(['ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10']);
                }
                if (busAdd0 === 1) {
                    return ccuSetting?.ductStaticPressureSensor1_2?.val ?? '-';
                }
                break;
            case 1:
                if ([15, 16, 17].includes(analogInput1) || [15, 16, 17].includes(analogInput2)) {
                    return checkSensors(['ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10']);
                }
                if (busAdd0 === 2) {
                    return ccuSetting?.ductStaticPressureSensor2_2?.val ?? '-';
                }
                break;
            case 2:
                if ([18, 19, 20].includes(analogInput1) || [18, 19, 20].includes(analogInput2)) {
                    return checkSensors(['ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10']);
                }
                if (busAdd0 === 3) {
                    return ccuSetting?.ductStaticPressureSensor3_2?.val ?? '-';
                }
                break;
            case 3:
                return ccuSetting?.averagePressure?.val ?? '-';
            case 4:
                return ccuSetting?.minPressure?.val ?? '-';
            case 5:
                return ccuSetting?.maxPressure?.val ?? '-';
        }

        return '-';
    }
    

    getOperatingMode(ccuSetting) {
        if (!ccuSetting || !ccuSetting.advancedAhuOperatingMode) {
            return '-';
        }
        switch (Number(ccuSetting.advancedAhuOperatingMode.val)) {
            case 0:
                return 'Off';
            case 1:
                return 'Cooling';
            case 2:
                return 'Heating';
            case 3:
                return 'Temp Dead';
            default:
                return '-';
        }
    }

        /**  In this method we will ccheck for loaded based point sif load based ispaired we will not show 
    supply air temp section in the user intent*/
    shouldDisplaySupplyAirTemp() {
        const  loadBasedConfigCheck= [
            'satbasedheatingcontrol','satbasedcoolingcontrol'
        ];
        for (let prop of loadBasedConfigCheck) {
            if (this.ccuSetting.hasOwnProperty(prop)) {
                return true;
            }
        }
    }
    

     //This method helps to set the default relay mapping values for system level VAV profiles, since relay-mapping points aren't created by CCU when associated relay is in OFF state.
    setRelayMappingforVAVAndDAB() {
        const relayAssociationsforVAV = this.equipGraphicsProfiles?.toLowerCase()?.includes('VAV Fully Modulating AHU'.toLowerCase()) && !(this.equipGraphicsProfiles?.toLowerCase()?.includes('DAB Fully Modulating AHU'.toLowerCase())) ? { "relay-7Mapping": { val: 0 }, "relay-3Mapping": { val: 0 }, "analogOut1MinCooling": { val: 2 }, "analogOut1MaxCooling": { val: 10 }, "analogOut2MinStatic": { val: 2 }, "analogOut2MaxStatic": { val: 10 }, "analogOut3MinHeating": { val: 2 }, "analogOut3MaxHeating": { val: 10 }, "analogOut4MinAir": { val: 2 }, "analogOut4MaxAir": { val: 10 } } : { "relay-1Mapping": { val: 0 }, "relay-2Mapping": { val: 1 }, "relay-3Mapping": { val: 10 }, "relay-4Mapping": { val: 5 }, "relay-5Mapping": { val: 6 }, "relay-6Mapping": { val: 11 }, "relay-7Mapping": { val: 0 } };
        const relayAssociationsForDAB = this.equipGraphicsProfiles?.toLowerCase()?.includes('DAB Fully Modulating AHU'.toLowerCase())
        const applyRelayMapping = (mapping) => {
            for (const key in mapping) {
                if (!Object.prototype.hasOwnProperty.call(this.systemReconfigurationSettings, key) ||
                    !Object.prototype.hasOwnProperty.call(this.systemReconfigurationSettings[key] || {}, 'val')) {
                    this.systemReconfigurationSettings[key] = mapping[key];
                }
            }
        };
        if (relayAssociationsForDAB) {
            const dabFullyModulatingAHUReconfigPoints = [
                'loopType', 'relay-7Mapping', 'relay-7', 'relay-3', 'analogOut3MinCooling',
                'analogOut3MaxCooling', 'analogOut1MinCooling', 'analogOut1MaxCooling',
                'analog-out1', 'analogOut4AtMinCoolingLoop', 'analogOut4AtMinCo2Loop',
                'chilledWaterMaxFlowRate', 'analog-In1AtValveFull', 'Analog-In1AtMaxCHWValve',
                'Analog-Out2AtMaxFanSpeed', 'Analog-Out3AtMaxHeating', 'analogOut4AtMaxCoolingLoop',
                'analogOut4AtMaxCo2Loop', 'analogOut2MinStatic', 'analog-out4', 'analog-out3',
                'analog-out2'
            ];
            dabFullyModulatingAHUReconfigPoints.forEach((prop) => {
                if (!(prop in this.systemReconfigurationSettings)) {
                    this.systemReconfigurationSettings[prop] = { val: 0 };
                }
            });
        }
        else {
            applyRelayMapping(relayAssociationsforVAV);
        }
    }


    ahuProfilesGlobalisationCheck() {
        const userTempPreference = this.authService.getLoggedInUserPreferences();
        if (this.userTempPreference === "°C") {
            ["airTempHeatingSp", "airTempCoolingSp", 'supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'].forEach(key => {
                if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                    let value = this.ccuSetting[key].val.split(' ')[0];
                    this.ccuSetting[key].val = this.unitService.fahrenheitToCelecius(value, key) + this.userTempPreference;
                }
            });
        }
        if (userTempPreference.airPressureUnit !== 'inH₂O') {
            ['ductStaticPressureSetPoint', 'ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10',
                'ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10',
                'ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10', 'averagePressure', 'minPressure', 'maxPressure'].forEach(key => {
                    if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                        let value = this.ccuSetting[key].val.split(' ')[0];
                        this.ccuSetting[key].val = this.unitService.checkForCFMConversion(parseFloat(value), userTempPreference.airPressureUnit) +' '+userTempPreference.airPressureUnit;
                    }
                });
        }
    }

    checkForSystemAdvanceAhuProfile(): boolean {
        return this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1;
    }


    isValidProfile(profiles) {
       return this.deviceHelper.isZoneNon75FProfile(profiles);
    }
    async getSystemOperatingModepoint() {
        const equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        let hsCpuAnalogOut1 = this.helperService.getPointIdbyTags(equipObj, null, null, null, ['operatingMode'])[0];
        try {
            const res = await firstValueFrom(this.siteService.getHisPointData(hsCpuAnalogOut1, "current"));
            if (res?.rows?.length > 0) {
                return this.helperService.stripHaystackTypeMapping(res.rows[0]?.val).replace(/\ .*/, '');
            } else {
                console.error('Error: No data returned for the operating mode point');
                return null;
            }
        } catch (error) {
            console.error('Error fetching data', error);
            return null;
        }

      }
     /**
     * Returns the text for the schedule group based on the zone settings.
     * If the zone settings contain a valid schedule group, the corresponding label is returned.
     * Otherwise, an empty string is returned.
     * 
     * @returns The text for the schedule group or an empty string.
     */
     setScheduleGroupText() {
        if (this.zoneSettings?.zoneSchedule?.hasOwnProperty('scheduleGroup')) {
            if (ObjectUtil.isNotUndefinedOrNull(this.zoneSettings?.zoneSchedule?.scheduleGroup)) {
                return this.scheduleGroupLabel[this.zoneSettings?.zoneSchedule?.scheduleGroup];
            }
        }
        return '';
    }

        /**
     * Compares two version numbers.
     * @param {string} version1 - The first version number.
     * @param {string} version2 - The second version number.
     * @returns {number} - Returns 1 if version1 is greater than version2, -1 if version1 is less than version2, or 0 if they are equal.
     */
    compareVersions(version1, version2) {
        const v1Parts = version1?.split('.').map(Number);
        const v2Parts = version2?.split('.').map(Number);
    
        for (let i = 0; i < Math.max(v1Parts?.length, v2Parts?.length); i++) {
            const v1 = v1Parts[i] || 0;
            const v2 = v2Parts[i] || 0;
            if (v1 > v2) return 1;
            if (v1 < v2) return -1;
        }
        return 0;
    }

    getCcuVersion() {
        const diagnosticObj = this.widgetData.find(equip => ['equip', 'diag'].every(elem => equip.markers.indexOf(elem) > -1) && (equip.referenceIDs.gateway == this.refs.gateway || equip.referenceIDs.ahu == this.refs.ahuRef));
        // get ccu version
        const appVerId = this.helperService.getPointIdbyTags(diagnosticObj, ['app','diag', 'version'],null,null,'appVersion')[0] || '';
        if (appVerId) {
            this.siteService.getWritablePointData(appVerId).pipe(
                map(this.helperService.stripHaystackTypeMapping), takeUntil(this.unsubscribe)
            ).subscribe(({ rows }) => {
                if (rows && rows.length > 0) {
                    this.version = rows[0].val.toString();
                    this.refs['ccuVersion'] = this.version;
                    this.enableScheduleGroup = this.compareVersions(this.version, this.compareCcuVersion) > 0;
                }
            }, () => {
                
            });
        }
    }
    /**
     * Sets the CCU data based on the provided CCU object.
     * @param ccuObj The CCU object containing the necessary data.
     * @returns An object with the CCU data.
     */
    setCCUData(ccuObj) {
        let selectedCCUObj: any = this.buildings?.ccus?.find(ccuData => ccuData?._id == ccuObj?.ccuId);
        this.isConnectModuleOaoIsPaired = selectedCCUObj.checkConnectModuleAsOao;
        return {
            ccuId: ccuObj.ccuId,
            "siteRef": selectedCCUObj?.referenceIDs?.site,
            "ccuName": selectedCCUObj?.name,
            "ccuAhu": selectedCCUObj?.referenceIDs?.ahu,
            "ccuOAOCheck": selectedCCUObj?.hasOAOProfile,
            "ccuBypassDamperCheck": selectedCCUObj?.hasBypassProfile,
            "equipRef": selectedCCUObj?.referenceIDs?.equip,
            "gateway": selectedCCUObj?.referenceIDs?.gateway,
            "oaoRef": selectedCCUObj?.referenceIDs?.oaoRef,
            "ccuConfigRef": selectedCCUObj?.referenceIDs?.ccuConfigRef,
            "vavDabConnectModule" :selectedCCUObj?.referenceIDs?.vavDabConnectModule,
            "checkConnectModuleAsOao":selectedCCUObj?.checkConnectModuleAsOao,
            $event: ccuObj.$event
        }
    }

    async transformData(dataArray: any[]): Promise<{ [key: string]: string }> {
        const result: { [key: string]: string } = {};

        dataArray.forEach(item => {
            const domainName = item.domainName;
            const value = item.data[0]?.val;

            if (domainName && value) {
                // Remove the 'n:' prefix if it exists
                const cleanedValue = value.startsWith('n:') ? value.slice(2) : value;
                result[domainName] = cleanedValue;
            }
        });

        return result;
    }

    async toogleCheckforStaticPressurePoint(): Promise<string | undefined> {
        try {
            const equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
            const pointIds = this.getPointIdsForVavDabadvanceSystemProfiles(equipObj);

            // Fetch writable data and current read points data
            const [writableData, writableData1] = await Promise.all([
                pointIds.advanceAhuSpPoint.filter(v => v).length ? this.siteService.getBulkWritablePointData(pointIds.advanceAhuSpPoint.filter(v => v)).toPromise() : Promise.resolve(null),
                pointIds.advanceAhuSpPointReadpoints.filter(v => v).length ? this.siteService.getCurrenthisReadMany(pointIds.advanceAhuSpPointReadpoints.filter(v => v)).toPromise() : Promise.resolve(null)
            ]);

            const domainNames = writableData1?.rows?.map(item => item.domainName);
            const transformedWritableData = await this.transformData(writableData?.rows || []);

            return this.determineResult(transformedWritableData, domainNames);
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    // Method to get point IDs
    private getPointIdsForVavDabadvanceSystemProfiles(equipObj: any): {
        advanceAhuSpPoint: (string | undefined)[],
        advanceAhuSpPointReadpoints: (string | undefined)[]
    } {
        const tags = [
            'pressureSensorBusAdd0', 'pressureBasedFanControlOn', 'analog1InputAssociation', 'analog2InputAssociation',
            'ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10',
            'ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10',
            'ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10'
        ];

        const pointIds: { [key: string]: string | undefined } = {};
        tags.forEach(tag => {
            pointIds[tag] = this.helperService.getPointIdbyTags(equipObj, null, null, null, tag)[0];
        });

        // Return the structured object with arrays
        return {
            advanceAhuSpPoint: [
                pointIds['pressureSensorBusAdd0'],
                pointIds['pressureBasedFanControlOn'],
                pointIds['analog1InputAssociation'],
                pointIds['analog2InputAssociation']
            ],
            advanceAhuSpPointReadpoints: [
                pointIds['ductStaticPressureSensor1_1'], pointIds['ductStaticPressureSensor1_2'], pointIds['ductStaticPressureSensor1_10'],
                pointIds['ductStaticPressureSensor2_1'], pointIds['ductStaticPressureSensor2_2'], pointIds['ductStaticPressureSensor2_10'],
                pointIds['ductStaticPressureSensor3_1'], pointIds['ductStaticPressureSensor3_2'], pointIds['ductStaticPressureSensor3_10']
            ]
        };
    }

    getSensorKey(pressureFanControl: number, analogInput: number) {
        const sensorMaps = {
            0: { 12: 'ductStaticPressureSensor1_1', 13: 'ductStaticPressureSensor1_2', 14: 'ductStaticPressureSensor1_10' },
            1: { 15: 'ductStaticPressureSensor2_1', 16: 'ductStaticPressureSensor2_2', 17: 'ductStaticPressureSensor2_10' },
            2: { 18: 'ductStaticPressureSensor3_1', 19: 'ductStaticPressureSensor3_2', 20: 'ductStaticPressureSensor3_10' }
        };
        return sensorMaps[pressureFanControl]?.[analogInput];
    };

    async co2BasedDamperControlOn() {
        const equipObj = this.widgetData.find(et=> et._id == this.refs.ahuRef || et._id == this.refs.gateway);
        let hsCpuAnalogOut1 = this.helperService.getPointIdbyTags(equipObj, null, null, null, ['co2BasedDamperControlOn'])[0];
        try {
            const res = await firstValueFrom(this.siteService.getWritablePointData(hsCpuAnalogOut1));
            if (res?.rows?.length > 0) {
                return this.helperService.stripHaystackTypeMapping(res.rows[0]?.val).replace(/\ .*/, '');
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    private determineResult(transformedWritableData: any, domainNames: string[] | undefined): string | undefined {
        const pressureSensorBusAdd0Value = Number(transformedWritableData['pressureSensorBusAdd0']);
        const pressureBasedFanControlValue = Number(transformedWritableData['pressureBasedFanControlOn']);
        const analog1InputAssociationValue = Number(transformedWritableData['analog1InputAssociation']);
        const analog2InputAssociationValue = Number(transformedWritableData['analog2InputAssociation']);
 
        const getSensorResult = (analogInput: number): string | undefined => {
            const sensorKey = this.getSensorKey(pressureBasedFanControlValue, analogInput);
            return sensorKey && domainNames?.includes(sensorKey) ? sensorKey : undefined;
        };
    
        if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog1InputAssociationValue)) {
            const result = getSensorResult(analog1InputAssociationValue);
            if (result) return result;
        }
    
        if ([12, 13, 14, 15, 16, 17, 18, 19, 20].includes(analog2InputAssociationValue)) {
            const result = getSensorResult(analog2InputAssociationValue);
            if (result) return result;
        }
    
        const additionalChecks = {
            0: { 1: 'ductStaticPressureSensor1_2' },
            1: { 2: 'ductStaticPressureSensor2_2' },
            2: { 3: 'ductStaticPressureSensor3_2' }
        };
    
        const additionalResult = additionalChecks[pressureBasedFanControlValue]?.[pressureSensorBusAdd0Value];
        if (additionalResult && domainNames?.includes(additionalResult)) {
            return additionalResult;
        }
    }
        
    getEquipsAndDevicesFromSiteId(siteRef) {
        this.siteService.getQuerybySite(siteRef,"(equip or device or ccu or room or floor)").subscribe((res) => {
            res = this.helperService.stripHaystackTypeMapping(res);
            this.entitiesList = res.rows;
        }, (err) => {
            console.log(err, 'something went wrong');
        });
    }
    
    onAddNewAnalytics() {
        const self = this;
        const dialogRef = this.dialog.open(AnalyticsListComponent, {
            panelClass: 'pointsummary-padding-dialog',
        });

        dialogRef.componentInstance.siteId = this.refs.siteRef;
        dialogRef.componentInstance.siteNameRef = this.siteName;
        dialogRef.componentInstance.analyticsFrom = 'global';

        dialogRef.afterClosed().subscribe(() => {
            this.clickMenu();
        });
    }

    removeWidgetsFromDefaultSystemProfile() {
        this.systemWidgets = this.systemWidgets?.filter(widget => widget.id != 'comfortIndex');
        this.systemWidgets = this.systemWidgets?.filter(widget => widget.id != 'modes');
        this.systemWidgets = this.systemWidgets?.filter(widget => widget.id != 'runtimesystemprofile');
    }

    graphicListChangeEvent(data) {
        this.onGraphicListChangedEvent = data;
    }

    showPredefinedEquipGraphics(data) {
        this.showPredefinedEquipGraphic[data.entityRef] = data.showPredefinedGraphics;
        setTimeout(() => {
            this.graphicsLoaded = true;
        }, 100)
        
    }

       /**Function which generates the equips listed in a sorted order and passes it to sortOrderComponent
      * If user clicks on Save CTA in sortOrderComponent, the updated order is saved in the backend
      * If user clicks on Cancel CTA, the order remains the same
      * Based on zone or CCU click the equips are extracted from zoneSettings/ccuSettings
      */
    sortModbusEquips(profile) {
        const modbusObject = this.ccuOrZone.type == 'zone' ? !this.zoneSettings[profile?.profile?.name] ? this.commonService.getModbusEquipsForReorder(this.zoneSettings) : this.zoneSettings[profile?.profile?.name] : this.ccuSetting[profile];
        this.commonService.getLatestEquipIndex(modbusObject);
    }

    
    //Enable Eexternal equips sort option only if there are more than 1 equip
    enableSort(profile) {
        if ((this.ccuOrZone.type == 'zone' && !ObjectUtil.isNotUndefinedOrNull(this.zoneSettings)) || (this.ccuOrZone.type == 'ccu' && (!ObjectUtil.isNotUndefinedOrNull(this.ccuSetting) || !this.ccuSetting[profile]))) {
            return;
        }
        const hasExternalEquips = this.ccuOrZone.type == 'zone' ? !this.zoneSettings[profile?.profile?.name] ? this.commonService.getModbusEquipsForReorder(this.zoneSettings) : this.zoneSettings[profile?.profile?.name] : this.ccuSetting[profile];
        return Object.keys(hasExternalEquips)?.filter(x => x?.includes('modbus'))?.length > 1 && !this.isVofm;
    }

    checkIfAdvancedAHUProfile() {
        return this.checkForAdvanceAhuProfiles.indexOf(this.equipGraphicSystemProfile) > -1;
    }

}
