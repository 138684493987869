import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AlertService } from '@75f/portal-ui-components';
import { EasyStreetService } from '../../services/easystreet.service';
import { debounceTime } from 'rxjs';
import { CountdownConfig } from 'ngx-countdown';
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];
@Component({
  selector: 'fs-easy-street-metrics',
  templateUrl: './easy-street-metrics.component.html',
  styleUrls: ['./easy-street-metrics.component.scss']
})

export class EasyStreetMetricsComponent implements OnChanges {

  @Input() siteCheckoutDetails: any
  @Input() selectedSite: any;
  @Input() isLoading = true;
  @Input() isSortByEquip: boolean;
  @Input() isRunEnabled: boolean = false;
  noCCUsFound: boolean = false;
  placeholderMsg = 'No CCUs added yet.';
  skeletonRows = Array(6);
  tableData: any[] = [];
  showCountdownTimer: boolean = false;

  config: CountdownConfig = {
    leftTime: 0,
    format: 'D HH:mm:ss',
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return CountdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
            // When days is empty
            if (name === 'D' && v <= 0) {
              return '';
            }
            return v.toString().padStart(match.length, '0');
          });
        }
        return current;
      }, formatStr);
    },
  };

  constructor(
    private easystreetService: EasyStreetService, 
    public alertService: AlertService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {    
    if(changes['siteCheckoutDetails'] && changes['siteCheckoutDetails'].currentValue) {
      if(this.siteCheckoutDetails.length > 0) {
        // If siteCheckoutDetails are available, then build the table data
        this.buildTableData();
      } else {
        this.noCCUsFound = this.siteCheckoutDetails && this.siteCheckoutDetails.length > 0 ? false : true;
        // If no siteCheckoutDetails are available, then build the default table data
        this.buildDefaultTableData();
      }
    }
  }

  /**
   * Run test for a single or all the CCUs in the table data.
   * @param selectedCcu - The selected CCU object
   * If selectedCcu parameter is not recieved, then run test for all the CCUs in the table data.
   */ 
  runTests(selectedCcu?: any) {
    let ccuIds = [];
    this.tableData = this.tableData?.map((ccu: any) => {
      if(selectedCcu) {
        if (ccu.ccuId === selectedCcu.ccuId) {
          ccuIds.push(ccu.ccuId);
          return { ...ccu, status: 'SCHEDULED' };
        }
      } else {
        ccuIds = this.tableData?.map((ccu: any) => ccu.ccuId);
        return { ...ccu, status: 'SCHEDULED' };
      }
      return ccu;
    });
    const requestPayload = {
      "ccuIds": [...ccuIds]
    };
    this.easystreetService.requestSiteCheckout(this.selectedSite, requestPayload)
    .subscribe((res) => {
      if(res && res.systems && res.systems.length > 0) {
        res.systems.forEach((systemObj: any) => {
          this.tableData = this.tableData?.map((ccuObj: any) => {
            if (ccuObj.ccuId === systemObj.ccuId) {
              const updatedCCUObj = { ...ccuObj, status: systemObj.status};
              if(systemObj.status === 'SCHEDULED' && (systemObj.estimatedRuntimeMinutes == 0 || systemObj.estimatedRuntimeMinutes)) {
                this.showCountdownTimer = true;
                updatedCCUObj['startDateTime'] = systemObj.startDateTime,
                updatedCCUObj['estimatedRuntimeMinutes'] = systemObj.estimatedRuntimeMinutes;
                updatedCCUObj['apiCalledOnCompletion'] = false;
              }
              return updatedCCUObj;
            }
            return ccuObj;
          });
        });
      }
    }, (err) => {
      this.alertService.error(err.error ? err.error.error : 'Failed to run test, please try again');
      this.setDefaultStatusForCCUs(requestPayload.ccuIds);
    });
  }

  /**
   * Cancel the test run for the selected CCU.
   * @param selectedCcu - The selected CCU object
   */ 
  cancelRun(selectedCcu) {
    const payload = {
      "ccuIds": [selectedCcu.ccuId]
    }
    this.easystreetService.cancelOnGoingSiteCheckout(this.selectedSite, payload)
    .subscribe((res) => {
      this.showCountdownTimer = false;
      this.tableData = this.tableData?.map((ccu: any) => {
        if (ccu.ccuId === selectedCcu.ccuId) {
          return { ...ccu, status: res && res.status ? res.status : 'COMPLETED', apiCalledOnCompletion: false };
        }
        return ccu;
      });
    }, (err) => {
      this.alertService.error(err && err.error ? err.error.error : 'Failed to cancel test, please try again');
    });
  }

  /**
   * Method to fallback to the default state for the CCUs, when the "Request a site checkout" (Run Test) API fails.
   */
  setDefaultStatusForCCUs(ccuIds: any[]) {
    this.tableData = this.tableData?.map((ccuObj: any) => {
      if (ccuIds.includes(ccuObj.ccuId)) {
        // Update the status to 'COMPLETED'
        return { ...ccuObj, status: 'COMPLETED' };
      }
      // Return the original object if no match
      return ccuObj;
    });
  }

  /**
   * Building the default table data when no CCUs are available.
   */
  buildDefaultTableData() {
    this.tableData = this.siteCheckoutDetails?.map((ccu: any) => {
      let existingRow = {};
      if(this.tableData && this.tableData.length > 0) {
        existingRow = this.tableData.find((row: any) => {
          return row['apiCalledOnCompletion'] && row.ccuId === ccu.ccuId;
        });
      }
      return {
        ccuName: ccu.ccuName,
        ccuId: ccu.ccuId.replace('r:', ''),
        score: { value: null },
        rating: 'NEEDS FOCUS',
        status: 'COMPLETED',
        apiCalledOnCompletion: existingRow && existingRow['apiCalledOnCompletion'] || false,
      };
    });
  }

  /**
   * Building the table data by formatting the score and rating values of each CCU.
   */
  buildTableData() {
    this.tableData = this.siteCheckoutDetails?.map((ccu: any) => {
      let existingRow = {};
      if(this.tableData && this.tableData.length > 0) {
        existingRow = this.tableData.find((row: any) => {
          return row['apiCalledOnCompletion'] && row.ccuId === ccu.ccuId;
        });
      }
      if(ccu.score) {
        if(ccu.score.value || ccu.score.value == 0 && !this.easystreetService.isInvalidValue(ccu.score.value)) {
          ccu.score.value = ccu.score.value === 'NaN' ? 0 : ccu.score.value;
          ccu.rating = ccu.score.value <= 0.75 ? 'NEEDS FOCUS'
          : ccu.score.value > 0.75 && ccu.score.value < 0.95 ? 'GOOD' : 'EXCELLENT';
        } else {
          ccu.score.value = null;
          ccu.rating = 'NEEDS FOCUS';
        }
      } else {
        ccu.score = { value: null };
        ccu.rating = 'NEEDS FOCUS';
      }
      ccu['apiCalledOnCompletion'] = existingRow && existingRow['apiCalledOnCompletion'] || false;
      return ccu;
    });
  }

  /*
  * Check if any tests are running to change disabled state of the Run All CCUs button.
  */
  isTestsRunning() {
    const runningStatus = ['SCHEDULED', 'RUNNING'];
    return this.tableData.some((ccu: any) => runningStatus.includes(ccu.status));
  }

  /**
   * Get the remaining time for the test to complete
   * @param row - The ccu object in the row of the table data
   */
  getRemainingTime(row: any) {
    if (!row.startDateTime) {
      return null; // No start time, timer is null
    }
    const startTimeUtc = new Date(row.startDateTime).getTime(); // Convert startDateTime to UTC
    const currentTimeUtc = new Date().getTime(); // Current time in UTC
    let elapsedTime = Math.floor((currentTimeUtc - startTimeUtc) / 1000); // Elapsed time in seconds
    if(elapsedTime<=0){elapsedTime = 0}
    const totalTimeInSeconds = (row.estimatedRuntimeMinutes || 0) * 60; // Total runtime in seconds
    const remainingTime = Math.max(totalTimeInSeconds - elapsedTime, 0); // Calculate remaining time    
    this.config.leftTime = remainingTime; // Set the remaining time in milliseconds
    return this.config;
  }

  /**
   * Handle the countdown event when the timer is done
   */
  handleCountdownEvent(event: any, row: any) {
    if (event.action === 'done') {
    // Skip API call if it has already been made for this row when timer stopped or reached 0
    if (row.apiCalledOnCompletion) {
      return;
    }
    const targetIndex = this.tableData.findIndex((ccu: any) => ccu.ccuId === row.ccuId);
    this.tableData[targetIndex]['apiCalledOnCompletion'] = true; 
    // Check out site details again, when the timer reaches stops or reaches 0
    this.checkOutSiteDetails();
    }
  }

  /**
   * Get latest site Check out details
   * @param isSortByEquip - A boolean value to check if the sort by equip toggle is enabled 
   */
  checkOutSiteDetails(): void {
    this.easystreetService.getSiteCheckOutDetails(this.selectedSite, this.isSortByEquip).pipe(
      debounceTime(1000)
    ).subscribe({
      next: (response) => {
        if(response) {
          this.siteCheckoutDetails = response.systems || [];
          this.buildTableData();
        } else {
          this.siteCheckoutDetails = [];
        }
      },
      error: (err) => {
        this.siteCheckoutDetails = null;
        this.alertService.error(err.error ? err.error.error : 'Something went wrong, please try again');
      }
    });
  }

}
