import { Injectable } from '@angular/core';
import html2pdf from 'html2pdf.js';
import { latoRegularFont } from 'src/assets/font/lato';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EasystreetExportService {
  constructor() { }
  siteName: string;
  siteHasScore: boolean;

  // Helper function to apply styles to elements with a given class name
  applyStylesToElements(clonedElement: HTMLElement, className: string, styles: { [key: string]: string }) {
    if (!clonedElement) {
      return;
    }

    const elements = clonedElement.querySelectorAll(className);
    if (elements.length === 0) {
      return;
    }

    elements.forEach((nestedChild: any) => {
      if (nestedChild && nestedChild.style) {
        Object.assign(nestedChild.style, styles);
      }
    });
  }


  // Global PDF generator
  generateGlobalPDF(): void {

    // Create a container for all content
    const content = document.createElement('div');

    // Add the logo
    const logo = document.createElement('img');
    logo.src = '/assets/images/easystreet_pdfheader.png';
    if(environment.partner) {
      logo.style.width = '100%';
    } else {
      logo.style.width = '136px';
      logo.style.height = '20px';
    }
    content.appendChild(logo);

    // Add a horizontal line after the logo
    const line = document.createElement('hr');
    line.style.border = '0'; // Remove default border
    line.style.borderTop = '0.5px solid #CCCCCC'; // Set the color and size of the line
    content.appendChild(line);

    // Add Title
    const title = document.createElement('h3');
    title.textContent = this.siteName;
    title.style.fontFamily = 'Lato';
    title.style.fontSize = '11px';
    title.style.fontWeight = '700';
    title.style.lineHeight = '13px';
    title.style.margin = '0';
    content.appendChild(title);

    // Create a parent div for the gauge chart
    const gaugeChartContainer = document.createElement('div');
    gaugeChartContainer.style.display = 'flex';
    gaugeChartContainer.style.justifyContent = 'center';
    gaugeChartContainer.style.alignItems = 'center';
    gaugeChartContainer.style.width = '100%';
    gaugeChartContainer.style.margin = '20px 0';

    const gaugeChartHtml = document.getElementById('gauge-section');
    if (gaugeChartHtml) {
      // Create a fresh container for the cloned chart
      const clonedGaugeChart = document.createElement('div');
      clonedGaugeChart.style.display = 'flex';
      clonedGaugeChart.style.flexDirection = 'column';
      clonedGaugeChart.style.alignItems = 'center';
      clonedGaugeChart.style.justifyContent = 'center';
      clonedGaugeChart.style.width = '100%';

      // Check if a canvas element is present (for ECharts)
      const canvas = gaugeChartHtml.querySelector('canvas');
      if (canvas) {
        // Convert the ECharts canvas to a base64 image
        const img = document.createElement('img');
        img.src = canvas.toDataURL(); // Convert the canvas to an image
        img.style.width = '350px'; // Adjust width to match the gauge chart size
        img.style.height = '300px'; // Adjust height as per design

        // Append the image to the cloned container
        clonedGaugeChart.appendChild(img);
      }

      // Add descriptive text (footer) below the image
      const footerText = document.createElement('div');
      footerText.innerHTML =
        'Installation score measures the success rate of HVAC device installations.';
      footerText.style.fontSize = '10px';
      footerText.style.color = '#666666';
      footerText.style.textAlign = 'center';
      footerText.style.marginTop = '-35px'; // Reduced margin for closer spacing

      // Append the footer text to the cloned gauge chart
      clonedGaugeChart.appendChild(footerText);

      // Append the cloned gauge chart to the gaugeChartContainer
      gaugeChartContainer.appendChild(clonedGaugeChart);

      // Append the gauge chart container to the content
      content.appendChild(gaugeChartContainer);
    }

    // Add the 'Metrics' text
    const metricsText = document.createElement('div');
    metricsText.textContent = 'Metrics';
    metricsText.style.fontFamily = 'Lato';
    metricsText.style.fontSize = '11px';
    metricsText.style.fontWeight = '700';
    metricsText.style.lineHeight = '13px';
    metricsText.style.textAlign = 'left';
    content.appendChild(metricsText);

    // Add the diagnostic text
    const diagnosticText = document.createElement('div');
    diagnosticText.textContent = 'Run diagnostic tests on each CCU to ensure optimal performance.';
    diagnosticText.style.fontFamily = 'Lato';
    diagnosticText.style.fontSize = '9px';
    diagnosticText.style.fontWeight = '400';
    diagnosticText.style.lineHeight = '11px';
    diagnosticText.style.textAlign = 'left';
    diagnosticText.style.color = '#666666';
    content.appendChild(diagnosticText);


    const metricesHtml = document.getElementById('es-metrices-table');
    if (metricesHtml) {
      const clonedMetrices = metricesHtml.cloneNode(true) as HTMLElement;
      clonedMetrices.style.marginTop = '10px';
      clonedMetrices.style.display = 'block';

      // Append the container to the content
      content.appendChild(clonedMetrices);
    }

    const punchListText = document.createElement('div');
    punchListText.textContent = 'Punch List';
    punchListText.style.fontFamily = 'Lato';
    punchListText.style.fontSize = '11px';
    punchListText.style.fontWeight = '700';
    punchListText.style.lineHeight = '13px';
    punchListText.style.textAlign = 'left';
    punchListText.style.marginTop = '10px';
    punchListText.style.marginBottom = '10px';
    content.appendChild(punchListText);

    // Add the metadata content
    const reportMetaData = document.getElementById('report-meta-data');
    if (reportMetaData) {
      const clonedMetaData = reportMetaData.cloneNode(true) as HTMLElement; // Clone the metadata content
      clonedMetaData.style.display = 'flex';
      this.applyStylesToElements(clonedMetaData, '.punch-list', {
        padding: '0px 8px',
        height: '28px'
      });

      this.applyStylesToElements(clonedMetaData, '.score-name', {
        fontSize: '8px'
      });

      this.applyStylesToElements(clonedMetaData, '.score-value', {
        fontSize: '14px',
        paddingTop: '0px'
      });

      content.appendChild(clonedMetaData);
    }


    // Add the metadata content
    const punchListHtml = document.getElementById('punch-list');
    if (punchListHtml) {
      const clonedPunchListHtml = punchListHtml.cloneNode(true) as HTMLElement; // Clone the metadata content

      content.appendChild(clonedPunchListHtml);
    }


    const failedCheckListText = document.createElement('div');
    failedCheckListText.textContent = 'Failed Check List';
    failedCheckListText.style.fontFamily = 'Lato';
    failedCheckListText.style.fontSize = '9px';
    failedCheckListText.style.fontWeight = '700';
    failedCheckListText.style.textAlign = 'left';
    failedCheckListText.style.marginTop = '16px';
    failedCheckListText.style.marginBottom = '8px';
    content.appendChild(failedCheckListText);

    // Add check list items
    const punchReportList = document.querySelectorAll('[id*="es-punch-list-"]');
    if (punchReportList.length) {
      punchReportList.forEach((element: any) => {
        const clonedPunchElement = element.cloneNode(true) as HTMLElement; // Clone each checklist item
        clonedPunchElement.style.marginRight = '0px'
        clonedPunchElement.style.marginBottom = '6px';
        clonedPunchElement.style.padding = '6px 10px';
        clonedPunchElement.style.minHeight = '20px';
        clonedPunchElement.style.width = '100%';

        // Apply styles to elements
        this.applyStylesToElements(clonedPunchElement, '.failed-test-title', {
          fontSize: '9px',
          fontFamily: 'Lato',
          fontWeight: '400',
          lineHeight: '11px',
          textAlign: 'left',
        });

        this.applyStylesToElements(clonedPunchElement, '.afected-equips-title', {
          fontSize: '7px',
          fontFamily: 'Lato',
          fontWeight: '700',
          lineHeight: '9px',
          textAlign: 'left',
          marginTop: '6px'
        });


        this.applyStylesToElements(clonedPunchElement, '.es-chip', {
          fontSize: '7px',
          fontFamily: 'Lato',
          fontWeight: '400',
          lineHeight: '9px',
          textAlign: 'left',
          padding: '3px 8px 4px 8px',
        });

        content.appendChild(clonedPunchElement);
      });
    } else {
      const runTestText = document.createElement('div');
      runTestText.textContent = this.siteHasScore ? 'No checks failed' :'Run Test to generate the Checkout Report';
      runTestText.style.fontFamily = 'Lato';
      runTestText.style.fontSize = '9px';
      runTestText.style.fontWeight = '700';
      runTestText.style.lineHeight = '11px';
      runTestText.style.textAlign = 'center';
      runTestText.style.color = '#666666';
      runTestText.style.marginTop = '20px';

      content.appendChild(runTestText);
    }

    // Define the options for html2pdf.js
    const options = {
      margin: [18, 30], // Adjust margins
      filename: `${this.siteName}-checkout-report.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: {
        unit: 'pt',
        format: 'letter',
        orientation: 'portrait',
        callback: (doc) => {
          const totalPages = doc.internal.getNumberOfPages(); // Get total number of pages
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;

          doc.setFontSize(10); // Set font size for page numbers

          // Loop through each page and add the page number
          for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i); // Go to the specific page
            const pageNumberText = `Page ${i} of ${totalPages}`;
            doc.text(pageNumberText, pageWidth / 2, pageHeight - 20, {
              align: 'center',
            });
          }
        },
      },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    html2pdf().set(options)
      .from(content)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages(); // Get total pages

        pdf.addFileToVFS("lato.ttf", latoRegularFont.base64);
        pdf.addFont("lato.ttf", "Lato", "normal");

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i); // Go to each page
          pdf.setFontSize(8);
          pdf.setFont("Lato", 'normal');
          pdf.color = '#666666';
          pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, {
            align: 'center'
          });
        }
      })
      .save();
  }

  // CCU Data PDF generator
  exportCCUData(ccuName: string, reportData: any): void {
    // Create a container for all content
    const content = document.createElement('div');
    // Create and style the logo
    const logo = document.createElement('img');
    logo.src = '/assets/images/easystreet_pdfheader.png';
    if(environment.partner) {
      logo.style.width = '100%';
    } else {
      logo.style.width = '136px';
      logo.style.height = '20px';
    }
    content.appendChild(logo);

    // Add a horizontal line after the logo
    const line = document.createElement('hr');
    line.style.border = '0';
    line.style.borderTop = '0.5px solid #CCCCCC';
    content.appendChild(line);

    // Add Title
    const title = document.createElement('h3');
    title.textContent = `${this.siteName}: ${ccuName}`;
    title.style.fontFamily = 'Lato';
    title.style.fontSize = '11px';
    title.style.fontWeight = '700';
    title.style.lineHeight = '13px';
    title.style.margin = '0';
    content.appendChild(title);

    // Add the generated date
    const generatedOn = document.createElement('p');
    generatedOn.textContent = `Generated on: ${reportData.date}`;
    generatedOn.style.fontFamily = 'Lato';
    generatedOn.style.fontSize = '9px';
    generatedOn.style.fontWeight = '400';
    generatedOn.style.lineHeight = '11px';
    generatedOn.style.textAlign = 'left';
    generatedOn.style.color = '#666666';
    generatedOn.style.margin = '0';
    generatedOn.style.marginBottom = '10px';
    content.appendChild(generatedOn);

    // Add the metadata content
    const reportMetaData = document.getElementById('report-meta-data-ccu');
    if (reportMetaData) {
      const clonedMetaData = reportMetaData.cloneNode(true) as HTMLElement; // Clone the metadata content


      this.applyStylesToElements(clonedMetaData, '.punch-list', {
        padding: '0px 8px',
        height: '28px'
      });


      this.applyStylesToElements(clonedMetaData, '.score-name', {
        fontSize: '8px'
      });

      this.applyStylesToElements(clonedMetaData, '.score-value', {
        fontSize: '14px',
        paddingTop: '0px'
      });

      content.appendChild(clonedMetaData);
    }

    // Add "Check List" Title
    const checkListTitle = document.createElement('h3');
    checkListTitle.textContent = 'Check List';

    // Apply styles
    checkListTitle.style.fontFamily = 'Lato';
    checkListTitle.style.fontSize = '9px';
    checkListTitle.style.fontWeight = '700';
    checkListTitle.style.lineHeight = '11px';
    checkListTitle.style.textAlign = 'left';
    content.appendChild(checkListTitle);

    // Add check list items
    const ccuReportList = document.querySelectorAll('[id*="es-check-list-"]');
    if (ccuReportList.length) {
      ccuReportList.forEach((element: any) => {
        const clonedElement = element.cloneNode(true) as HTMLElement; // Clone each checklist item
        clonedElement.style.marginRight = '0px'
        clonedElement.style.marginBottom = '6px';
        clonedElement.style.padding = '6px 10px';
        clonedElement.style.minHeight = '20px';

        // Apply styles to elements
        this.applyStylesToElements(clonedElement, '.check-list-description', {
          fontSize: '9px',
          fontFamily: 'Lato',
          fontWeight: '400',
          lineHeight: '11px',
          textAlign: 'left',
          marginLeft: '4px'
        });

        this.applyStylesToElements(clonedElement, '.check-list-root-cause-header', {
          fontSize: '7px',
          fontFamily: 'Lato',
          fontWeight: '700',
          lineHeight: '9px',
          textAlign: 'left',
          marginTop: '6px'
        });

        this.applyStylesToElements(clonedElement, '.check-list-root-cause-details', {
          fontSize: '9px',
          fontFamily: 'Lato',
          fontWeight: '400',
          lineHeight: '11px',
          textAlign: 'left',
          marginTop: '2px'
        });

        this.applyStylesToElements(clonedElement, '.es-chip', {
          fontSize: '7px',
          fontFamily: 'Lato',
          fontWeight: '400',
          lineHeight: '9px',
          textAlign: 'left',
          padding: '3px 8px 4px 8px',
        });

        this.applyStylesToElements(clonedElement, '.status-box', {
          height: '11px',
          marginTop: '2px'
        });

        this.applyStylesToElements(clonedElement, '.status-text', {
          fontSize: '7px',
          padding: '0.5px 3px 3px 3px'
        });

        content.appendChild(clonedElement);
      });
    } else {
      const runTestText = document.createElement('div');
      runTestText.textContent = 'Run Test to generate the Checkout Report';
      runTestText.style.fontFamily = 'Lato';
      runTestText.style.fontSize = '9px';
      runTestText.style.fontWeight = '700';
      runTestText.style.lineHeight = '11px';
      runTestText.style.textAlign = 'center';
      runTestText.style.color = '#666666';
      runTestText.style.marginTop = '20px';

      content.appendChild(runTestText);
    }

    // Configure pdf options
    const options = {
      margin: [18, 30],
      filename: `${this.siteName}-${ccuName}-${reportData.exportDate}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: {
        unit: 'pt',
        format: 'letter',
        orientation: 'portrait',
        callback: (doc) => {
          const totalPages = doc.internal.getNumberOfPages(); // Get total number of pages
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;

          doc.setFontSize(10); // Set font size for page numbers

          // Loop through each page and add the page number
          for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i); // Go to the specific page
            const pageNumberText = `Page ${i} of ${totalPages}`;
            doc.text(pageNumberText, pageWidth / 2, pageHeight - 20, {
              align: 'center',
            });
          }
        },
      },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    html2pdf().set(options)
      .from(content)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages(); // Get total pages

        pdf.addFileToVFS("lato.ttf", latoRegularFont.base64);
        pdf.addFont("lato.ttf", "Lato", "normal");

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i); // Go to each page
          pdf.setFontSize(8);
          pdf.setFont("Lato", 'normal');
          pdf.color = '#666666';
          pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, {
            align: 'center'
          });
        }
      })
      .save();
  }
}
