import { Component, OnInit, Input, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Building, mapsTheme } from '@/shared/_models';
import { SelectedBuildingService, CCUALERTService } from '@/shared/_services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
    MessageService, LoaderService, SiteService,
    HelperService,
    UserService,
    AuthenticationService,
    ObjectUtil
} from '@75f/portal-ui-components';
import { lastValueFrom } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare var google: any;
enum alertColour {
    severe = "#e24301",
    moderate = "#fbb03b",
    low = "#0071bc",
    none = "#90EE90"
};

@Component({
    selector: 'fs-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DashboardLayoutComponent implements OnInit {
    searchFieldInput:any;
    @Input() isShow?: boolean = false;
    /** Skeleton Loader Variables**/
    mockBuildings: Array<any> = new Array(7);
    skeletonContentLoaded: boolean;
    /*****/
    isActive: boolean;
    buildingList: any;
    selectedBuilding: Building = { siteId: undefined, ccus: undefined, rooms: undefined, siteName: undefined, orgName: undefined };
    AllSites: number;
    AllCcus: any[] = [];
    AllRooms: any[] = [];
    severeAlertCount = 0;
    moderateAlertCount = 0;
    lowAlertCount = 0;
    mapsTheme: mapsTheme;
    map: google.maps.Map;
    AllSiteIdList: any = [];
    googlePinSymbol = function (color) {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: color,
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1,
        };
    };
    searchText = '';
    filteredbuildinglist: any = [];
    markerslist: any = [];
    vofmSites: any = [];
    private unsubscribe: Subject<void> = new Subject();
    orgList: any[];
    currentInfoWindow: google.maps.InfoWindow;
    zoomTimeout: any;
    markers: { [key: string]: google.maps.Marker } = {};
    infoWindows: { [key: string]: google.maps.InfoWindow } = {};
    debounceTimeout: any;
    defaultZoom: number;
    bounds: google.maps.LatLngBounds;
    previousSearchResult: any = [];
    markersLoaded:boolean = false;
    hoverTimeout: any;
    hoverInterval: any;
    searchInterval: any;
    allAlertsData: any;
    constructor(public messageService: MessageService,
        private siteService: SiteService,
        public loaderService: LoaderService,
        public router: Router,
        public selectedBuildingService: SelectedBuildingService,
        public helperService: HelperService,
        public ccuAlertService: CCUALERTService,
        private activatedRoute: ActivatedRoute,
        public cd: ChangeDetectorRef,
        private analyticsService: AnalyticsService,
        public userService: UserService,
        public authService: AuthenticationService,
        ) {
        this.mapsTheme = new mapsTheme();

    }
    async ngOnInit() {
        this.helperService.isDashboardPage$.next(true);
        (<HTMLElement>document.getElementsByClassName('fs-content')[0]).style.marginLeft = '0px';
        this.messageService.openSidebar(false);
        this.helperService.clearData();
        localStorage.removeItem('SelectedSite');
        this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
            const refresh = paramMap.get('refresh');
            if (refresh) {
                this.fetchData();
            }
        });

        this.getsiteList();
        await this.getAlertCounts(this.AllSiteIdList, "false", null);
        this.selectedBuildingService.clearSelectedBuilding();
        let styledMapType = new google.maps.StyledMapType(this.mapsTheme.mapsThemeJSON);

        // Create a map object, and include the MapTypeId to add
        // to the map type control.
        this.map = new google.maps.Map(document.getElementById('map'), {
            streetViewControl: false,
            zoom: 2,
            minZoom: 2,
            maxZoom:15,
            mapTypeControlOptions: {
                mapTypeIds: ['styled_map']
            }
        });


        //Associate the styled map with the MapTypeId and set it to display.
        this.map.mapTypes.set('styled_map', styledMapType);
        this.map.setMapTypeId('styled_map');


    }

    /**
     *This method fetches the data and calls getsiteList()
    */

    fetchData() {
        this.getsiteList();
        this.selectedBuildingService.clearSelectedBuilding();
    }
/**
 *This method gets all the site list and calls getmarkers()
 */
    getsiteList() {

        let authSiteIds = [];
        this.loaderService.active(true);
        this.siteService.getAuthSites().subscribe(data => {
            if (data.sites && data.sites.length > 0) {
                authSiteIds = data.sites.map(site => site.siteId);
                this.AllSiteIdList = [];
                this.orgList = this.groupByOrg(data.sites);
                this.AllSiteIdList = authSiteIds.map(siteId => siteId?.split('@')[1]);
                this.siteService.getSites_v1(authSiteIds).pipe(map(this.helperService.stripHaystackTypeMapping)).subscribe(async ({ rows }) => {
                    this.AllSites = (rows ?? []).length;
                    this.buildingList = this.setSitesToOrgList(rows);
                    this.filteredbuildinglist = ObjectUtil.deepClone(this.buildingList);
                    this.skeletonContentLoaded = true;
                   try {
                    const ccus:any = await lastValueFrom(this.siteService.getCCUs_v1(authSiteIds));
                    const zones:any = await lastValueFrom(this.siteService.getRooms_v1(authSiteIds))
                    this.AllRooms = zones.rows || [];
                    this.AllCcus = ccus.rows || [];
                   } catch(e){
                        console.log(e);
                   }
                     //marker using Geocoder
                     this.getmarkers();
                },
                err => {this.AllSites = 0;
                        this.skeletonContentLoaded = true;
                        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
                        this.analyticsService.pageLoadTime(loadTime);
                    });
            } else {
                this.map.setCenter({ lat: 0, lng: 0 });
                this.AllSites = 0;
                this.AllCcus = [];
                this.AllRooms = [];
                this.messageService.setSitesExists(false);
                this.skeletonContentLoaded = true;
                let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
                this.analyticsService.pageLoadTime(loadTime);
            }

        }, () => {
            this.loaderService.active(false);
        })
    }

    /** this method will create an array the organizations with metadata of sites  **/
    groupByOrg(data){
        let orgList = [];
        let orgnames = []
        data.map(site => {
            if (!orgnames.includes(site.orgName)) {
                let org = {};
                org["orgName"] = site.orgName;
                org["orgId"] = site.orgId;
                org["sites"] = [];
                orgnames.push(site.orgName)
                orgList.push(org)
            }
        });
        orgList = orgList?.sort((a:any, b:any) => (a.orgName || '').toLowerCase().localeCompare((b.orgName || '').toLowerCase()));
        return orgList;
    }

    /** this method will push the sites to the matching organization object**/
    setSitesToOrgList(data){
        data?.map(site => {
            this.orgList?.map((obj)=>{
                if(obj.orgName?.trim() == site.organization?.trim()){
                    obj["sites"].push(site);
                }
            })
        });
        this.orgList?.map((org)=>{
            org.sites?.sort((a: any, b: any) => (a.dis || '').toLowerCase().localeCompare((b.dis || '').toLowerCase()));
        })
        return this.orgList;
    }

/**
 *This method gets markers and calls bindInfoWindow()
 */
    async getmarkers() {
        if (this.buildingList?.length) {
            this.bounds = this.setMapBounds();

            // Calculate the total number of sites to set batch size
            const totalSites = this.buildingList.reduce((count, org) => count + org.sites.length, 0);

            // If the number of sitess are more than 10, set the batch size to 10% of the total sites if not process the markers individually.
            const batchSize = totalSites <= 10 ? 5 : Math.max(10, Math.ceil(totalSites * 0.1));

            let markerPromises = [];

            for (const org of this.buildingList) {
                for (const site of org.sites) {
                    const siteDetails = this.setPositionForInfoWindow(site);
                    //Asyncronous funtion which loads the markers and info window for each site depedning on the batch size.
                    const markerPromise = (async () => {
                        try {
                            const countRes = siteDetails.countTotalQuery;                           
                            const siteAlertDetails = this.setMarkerandAlertsColor(countRes,siteDetails.siteRef);

                            this.skeletonContentLoaded = true;
                            let infowindow = new google.maps.InfoWindow({
                                content: `
                                <div class="dashboard-content__siteDetailsPopUp__siteNameLabelBackground">
                                    <h3 class="m-b-10 m-t-10 notranslate">${site.dis}</h3>
                                </div>
                                <div class="m-b-5">CCUs: ${siteAlertDetails.ccusTotal}</div>
                                <div class="m-b-5">Zones: ${siteAlertDetails.zonesTotal}</div>
                                <div class="m-b-5">
                                    <span class="dashboard-content__siteDetailsPopUp__propertyLabels">Alerts: </span>
                                    <span class="dashboard-content__siteDetailsPopUp__dot dashboard-content__siteDetailsPopUp__dot__severe"></span>
                                    <span id="alertSevereCount" class="dashboard-content__siteDetailsPopUp__propertyLabels"> ${siteAlertDetails.severeTotal}</span>
                                    <span class="dashboard-content__siteDetailsPopUp__propertyLabels">&nbsp;|&nbsp;</span>
                                    <span class="dashboard-content__siteDetailsPopUp__dot dashboard-content__siteDetailsPopUp__dot__moderate"></span>
                                    <span id="alertModerateCount" class="dashboard-content__siteDetailsPopUp__propertyLabels"> ${siteAlertDetails.moderateTotal}</span>
                                    <span class="dashboard-content__siteDetailsPopUp__propertyLabels">&nbsp;|&nbsp;</span>
                                    <span class="dashboard-content__siteDetailsPopUp__dot dashboard-content__siteDetailsPopUp__dot__low"></span>
                                    <span id="alertLowCount" class="dashboard-content__siteDetailsPopUp__propertyLabels"> ${siteAlertDetails.lowTotal}</span>
                                </div>`
                            });

                            let marker = new google.maps.Marker({
                                icon: this.googlePinSymbol(siteAlertDetails.colour),
                                title: site.id,
                                position: { lat: siteDetails.lat, lng: siteDetails.long },
                                map: this.map,
                            });

                            this.markerslist.push(marker);
                            this.markers[site.id] = marker;
                            this.infoWindows[site.id] = infowindow;

                            this.bindInfoWindow(marker, this.map, infowindow, `<p>${site.dis}</p>`);
                        } catch (error) {
                            console.error(`Failed to process site ${site.id}:`, error);
                        }
                    })();

                    markerPromises.push(markerPromise);

                    // If the number of markers processed is equal to the batch size, wait for all the markers to be processed before proceeding.
                    if (markerPromises.length >= batchSize) {
                        await Promise.all(markerPromises);
                        markerPromises = [];
                    }

                }
            }

            // Wait for all the markers to be processed before proceeding.
            if (markerPromises.length > 0) {
                await Promise.all(markerPromises);
            }

            //panToClusterOnLanding is method which triggers to have the landing page animation.
            this.panToClusterOnLanding(this.bounds, 1000);
            this.ccuAlertService.setAlertOf("AllSite");
            this.messageService.setSitesExists(true);

        }
    }


    setMapBounds() {
        let bounds = new google.maps.LatLngBounds();
        this.buildingList?.forEach(org => {
            org?.sites?.forEach(site => {
                let lat = site?.geoCoord == undefined ? 0 : parseFloat(site?.geoCoord?.split(',')[0]);
                let long = site?.geoCoord == undefined ? 0 : parseFloat(site?.geoCoord?.split(',')[1]);
                bounds?.extend({ lat: lat, lng: long });
            });
        });
        this.map.setCenter(bounds?.getCenter());
        return bounds;
    }

/**
 *This method gets the alert counts
 */
    getAlertCounts(siteList, isFixed, severity) {
         this.siteService.getAlertsCount({
            siteIds: siteList, isFixed: isFixed
        }, true).pipe( takeUntil(this.unsubscribe)).subscribe
            ((alertCount) => {
                this.allAlertsData = alertCount;
                this.severeAlertCount = alertCount?.severity['SEVERE'] || 0;
                this.moderateAlertCount = alertCount?.severity['MODERATE'] || 0;
                this.lowAlertCount = alertCount?.severity['LOW'] || 0;
                this.ccuAlertService.setTotalAlertCount(this.severeAlertCount + this.moderateAlertCount + this.lowAlertCount);
                this.ccuAlertService.setSelectedSites(siteList);
                this.ccuAlertService.setAlertOf("AllSite");
            });

    }
/**
 *This method call the addListener() inside of  google.maps.event
 */
    bindInfoWindow(marker, map, infowindow, html) {
        google.maps.event.addListener(marker, 'mouseover', function () {
            infowindow.open(map, this);
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
            infowindow.close();
        });
        google.maps.event.addListener(marker, 'click', event => {
            this.navToHeatmap( marker.title);
        });
    }

    /**
         * *This method seraches the searchtext and sets the marker visibility true if searched
     */
    onSiteSearch(searchtext) {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            this.searchText = searchtext;
            let filteredSites = [];
            this.filteredbuildinglist = [];
            const searchTextLowerCase = searchtext?.toLowerCase();
            for (const org of this.buildingList) {
                const orgNameCheck = org.orgName.toLowerCase().includes(searchTextLowerCase);
                const siteNameCheck = org.sites.some(site => site.dis.toLowerCase().includes(searchTextLowerCase));

                if (orgNameCheck || siteNameCheck) {
                    const filteredOrg = { ...org, sites: [] };

                    if (orgNameCheck) {
                        filteredOrg.sites = org.sites;
                        filteredSites = filteredSites.concat(org.sites);
                        this.filteredbuildinglist.push(filteredOrg);
                    } else {
                        for (const site of org.sites) {
                            if (site.dis.toLowerCase().includes(searchTextLowerCase)) {
                                filteredOrg.sites.push(site);
                                filteredSites.push(site);
                            }
                        }
                        this.filteredbuildinglist.push(filteredOrg);
                    }
                }
            }

            if(this.markersLoaded){
                this.handleSearchAnimation(searchtext,filteredSites);
            } else {
                this.searchInterval = setInterval(() => {
                    if (this.markersLoaded) {
                        clearInterval(this.searchInterval);
                        this.handleSearchAnimation(searchtext,filteredSites);
                    }
                }, 100);
            }
        }, 1000);
    }

    handleSearchAnimation(searchtext, filteredSites) {
        let searchResultMarkers = [];
        this.markerslist.forEach(sitemarker => {
            let sitenames = sitemarker.getTitle().split(' ');
            let found = filteredSites.find((obj) => obj.id == sitenames[0]);
            if (found) {
                sitemarker.setVisible(true);
                searchResultMarkers.push(sitemarker);
            } else {
                sitemarker.setVisible(false);
            }
        });
        const isSameResult = this.areSearchResultsSame(filteredSites, this.previousSearchResult);
        // Update the previous search result
        this.previousSearchResult = filteredSites;
        if (searchtext == undefined || searchtext == "") {
            let currentZoom = this.map.getZoom();
            let targetZoom = this.defaultZoom;
            let step = 0.5; // The amount by which zoom is decreased in each step

            this.smoothZoomOut(this.bounds, currentZoom, targetZoom, step);
            return;
        }

        if ((searchResultMarkers.length && !isSameResult) || this.markerslist.length == 1) {
            if (filteredSites.length > 1) {
                const bounds = new google.maps.LatLngBounds();
                searchResultMarkers.forEach(location => {
                    bounds?.extend(new google.maps.LatLng(location.getPosition()));
                });
                this.panToClusterOnSearch(bounds, 600);
            } else {
                //Handling the case where search result has onl one element separately, because the zoom level should be more here such that surrouding street names are visible.
                const marker = this.markers[filteredSites[0].id];
                const endCenter = marker.getPosition();
                const currentZoom = this.map.getZoom();
                this.handleSingleDestOnSrch(endCenter, currentZoom <= 5 ? currentZoom + 10 : currentZoom, 2000); // Adjust zoom level as needed
            }
        }
    }

    smoothZoomOut(targetBounds, currentZoom, targetZoom, step) {
        // Check if current zoom level is greater than target zoom level
        if (currentZoom > targetZoom) {
            // Decrease zoom level by step
            this.map.setZoom(currentZoom - step);
            // Call function again after a short delay
            setTimeout(() => {
                this.smoothZoomOut(targetBounds, currentZoom - step, targetZoom, step);
            }, 40);
        } else {
            // Fit the map to the target bounds when target zoom level is reached
            this.map.fitBounds(targetBounds);
            this.map.setZoom(targetZoom);
        }
    }

    //Method which helps in zooming into a cluster of sites which we get on search:
    panToClusterOnSearch(bounds, duration) {
        const startZoom = this.map.getZoom();

        // Fit bounds to get the desired zoom level and center
        this.map.fitBounds(bounds);
        let endZoom = this.map.getZoom();

        // Reset the map to original center and zoom
        this.map.setZoom(startZoom);

        if (startZoom === endZoom) {
            this.map.setZoom(startZoom + 1);
        }

        //This condition is to ensure that the zoom level is not more than 5, to have a clear view of surrounding areas
        endZoom = endZoom > 5 ? 5 : endZoom;
        this.zoomToClusterOnSearch(endZoom, duration,bounds);
    }

    zoomToClusterOnSearch(endZoom, duration,bounds) {
        const startZoom = this.map.getZoom();
        const zoomStepCount = 15;
        const zoomStepDelay = duration / zoomStepCount;
        const zoomStep = (endZoom - startZoom) / zoomStepCount;

        let currentZoom = startZoom;
        let stepCount = 0;

        const zoomInterval = setInterval(() => {
            if (stepCount < zoomStepCount) {
                currentZoom += zoomStep;
                this.map.setZoom(currentZoom);
                stepCount++;
            } else {
                clearInterval(zoomInterval);
                this.map.fitBounds(bounds);
                this.map.setZoom(endZoom);
            }
        }, zoomStepDelay);
    }

    handleSingleDestOnSrch(endCenter, endZoom, duration) {
        const startZoom = this.map.getZoom();
        const zoomStepCount = 10;
        const zoomStepDelay = duration / zoomStepCount;
        const zoomStep = (endZoom - startZoom) / zoomStepCount;

        let currentZoom = startZoom;
        let stepCount = 0;

        const zoomInterval = setInterval(() => {
            if (stepCount < zoomStepCount) {
                currentZoom += zoomStep;
                this.map.setZoom(currentZoom);
                this.map.setCenter(endCenter); // Keep the center fixed during zoom
                stepCount++;
            } else {
                clearInterval(zoomInterval);
                this.map.setCenter(endCenter); // Ensure final center is the exact end center
                this.map.setZoom(endZoom); // Ensure final zoom level is set
            }
        }, zoomStepDelay);
    }

    areSearchResultsSame(current: any[], previous: any[]): boolean {
        if (current?.length !== previous?.length) {
            return false;
        }
        // Assuming each element has a unique `id` property
        const currentIds = current.map(item => item?.id).sort();
        const previousIds = previous.map(item => item?.id).sort();
        return JSON.stringify(currentIds) === JSON.stringify(previousIds);
    }




/**
 *This method enables the sideBar
 */
    enableSidebar() {
        this.helperService.isDashboardPage$.next(false);
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        let siteName = buildingDetails ? buildingDetails.siteName : '';
        if (document.getElementsByClassName('fs-global-topbar__userbuliding--name')) {
            (<HTMLElement>document.getElementsByClassName('fs-global-topbar__userbuliding--name')[0]).innerText = siteName;
        }
    }
/**
 *This method navigates to the heatmap and calls enableSidebar()
 */
    navToHeatmap(siteId: string): void {
        const site = this.buildingList?.flatMap(org => org.sites).find(site => site.id === siteId);
        if (site) {
            this.selectedBuilding.siteId = siteId;
            this.selectedBuilding.orgName = site.organization;
            this.selectedBuilding.siteName = site.dis;
        }
        this.selectedBuildingService.setBuildingData(this.selectedBuilding);
        this.selectedBuildingService.setSelectedBuilding(this.selectedBuilding)
       
        const siteList = [this.helperService.stripHaystackTypeMapping(siteId).split(' ')[0]];
        
        this.router.navigate(['/heatmap']).then(() => {
            this.enableSidebar();
            this.messageService.showSidebar(!this.isShow);
            this.isShow = !this.isShow;
        }).catch(() => {});
    }

    setPositionForInfoWindow(site) {
        let lat = site.geoCoord == undefined ? 0 : parseFloat(site?.geoCoord?.split(',')[0]);
        let long = site.geoCoord == undefined ? 0 : parseFloat(site?.geoCoord?.split(',')[1]);
        let siteRef = this.helperService.stripHaystackTypeMapping(site?.id?.split(' ')[0]);
        
        let countTotalQuery = this.allAlertsData?.sites?.find(alertSite => alertSite.siteId === siteRef);
        return { lat: lat, long: long, countTotalQuery: countTotalQuery,siteRef:siteRef }
    }

    setMarkerandAlertsColor(countRes, siteRef) {
        if(!countRes) countRes = {total:0, severity: {SEVERE: 0, MODERATE: 0, LOW: 0}};
        let zonesTotal = this.AllRooms.filter(room => room?.siteRef?.includes(siteRef)).length;
        let ccusTotal = this.AllCcus.filter(ccu => ccu?.siteRef?.includes(siteRef)).length;
        let severeTotal = countRes?.severity['SEVERE'] || 0;
        let moderateTotal = countRes?.severity['MODERATE'] || 0;
        let lowTotal = countRes?.severity['LOW'] || 0;
        countRes['priority'] = (severeTotal > 0 ? 'severe' : (moderateTotal > 0 ? 'moderate' : (lowTotal > 0 ? 'low' : '')));
        let colour;
        if (countRes.total == 0) {
            colour = alertColour.none;
        }
        switch (countRes.priority) {
            case "severe":
                colour = alertColour.severe;
                break;
            case "moderate":
                colour = alertColour.moderate;
                break;
            case "low":
                colour = alertColour.low;
                break;
            case "none":
                colour = alertColour.none;
                break;
            case "":
                colour = alertColour.none;
                break;
        };

        return { ccusTotal: ccusTotal, zonesTotal: zonesTotal, severeTotal: severeTotal, moderateTotal: moderateTotal, lowTotal: lowTotal, colour: colour, countRes: countRes }
    }

    //This method is used to display the info window on hover of the site name for a timeperiod of 1 sec.,
    onMouseEnter(buildinginfo) {
        if (this.markersLoaded) {
            this.handleHoverAnimation(buildinginfo);
        } else {
            // Check every 100ms if markers are loaded
            this.hoverInterval = setInterval(() => {
                if (this.markersLoaded) {
                    clearInterval(this.hoverInterval);
                    this.handleHoverAnimation(buildinginfo);
                }
            }, 100);
        }
    }



    //This method is used to close the info window on mouse leave, currentInfoWIndow will have the value of infoWindow which is opened on mouse enter.
    onMouseLeave() {
        if (this.currentInfoWindow) {
            this.currentInfoWindow.close();
            this.currentInfoWindow = null;
        }
        if (this.zoomTimeout) {
            clearTimeout(this.zoomTimeout);
        }
        if (this.hoverInterval) {
            clearInterval(this.hoverInterval); // Clear the hover interval if the user stops hovering
        }
    }

    handleHoverAnimation(buildinginfo) {
        const marker = this.markers[buildinginfo?.id];
        const infoWindow = this.infoWindows[buildinginfo?.id];
        if (this.currentInfoWindow) {
            this.currentInfoWindow.close();
        }

        clearTimeout(this.zoomTimeout);
        this.zoomTimeout = setTimeout(async () => {
            if (marker && infoWindow) {
                if (this.map.getZoom() != this.defaultZoom) {
                    await this.panToDestonHover(marker.getPosition(), 5, 1000);
                } else {
                    this.map.setCenter(marker.getPosition());
                    const zoom = this.map.getZoom();
                    this.map.setZoom(zoom < 5 ? 5 : 7); // Adjust zoom level as needed
                } // Wait for the animation to complete
                this.currentInfoWindow = infoWindow;
                infoWindow.open(this.map, marker);
            }
        }, 1000); // Delay added to start the animation only if user hovers for 1 sec.
    }

    /**Method used for:- Get the centre of the map and then calculate the delay for pannng and find the current incremented latitude and longitude step */
    panToDestonHover(latLng: google.maps.LatLng, zoom: number, duration: number) {
        const start = this.map.getCenter();
        const end = latLng;
        const panStepCount = 30;
        const panStepDelay = duration / panStepCount;
        const latStep = (end?.lat() - start?.lat()) / panStepCount;
        const lngStep = (end?.lng() - start?.lng()) / panStepCount;
        const startZoom = this.map.getZoom();

        let stepCount = 0;
        return this.zoomToDestonHover(stepCount, panStepCount, start, end, panStepDelay, zoom, latStep, lngStep, startZoom);
    }

    /**Recurrsive method used for:- Pan/Centre the map to the destination by creating a smooth delay rather than directly ending up in the destination.
     Add a delay while moving every step. Once the stepCount matches total step animation ends and there's a  slight zomm into the marker*/
    zoomToDestonHover(stepCount, panStepCount, start: google.maps.LatLng, end: google.maps.LatLng, panStepDelay, zoom, latStep, lngStep, startZoom) {
        return new Promise<void>((resolve) => {
            const panStep = () => {
                if (stepCount < panStepCount) {
                    stepCount++;
                    const lat = start?.lat() + latStep * stepCount;
                    const lng = start?.lng() + lngStep * stepCount;
                    this.map.setCenter(new google.maps.LatLng(lat, lng));

                    setTimeout(panStep, panStepDelay);
                } else {
                    this.map.setCenter(end);
                    if (startZoom < zoom) {
                        this.map.setZoom(zoom); // Apply zoom after panning completes if it's different from initial zoom
                    }
                    resolve(); // Resolve the promise after the animation completes
                }
            };

            panStep();
        });
    };

  

    /**This method checks if the map is already zoomed in and if not zooms in to the selected site, 
     thereby avoiding the setMapBounds not be called if map is already set to defualt zoom level which is 2. **/
     hoverOutsideSitePanel() {
      const startZoom = this.map.getZoom();
         if (startZoom != 2 && !this.searchText.length) {
            this.map.fitBounds(this.bounds);
            this.map.setZoom(this.defaultZoom);
         }
    }

    /**This method is used to pan the map with zoom when there's a cluster of sites */
    panToClusterOnLanding(bounds, duration) {
        const startCenter = this.map.getCenter();
        const endCenter = bounds?.getCenter();
        const startZoom = this.map.getZoom();

        // Below set of code is to get the ideal zoom level so that all the bounds are covered withing the area of map. 
        this.map.fitBounds(bounds);
        let endZoom = this.map.getZoom();
        this.map.setCenter(startCenter);
        this.map.setZoom(startZoom);

        // There are cases where the zoomlevel is same as the startZoom(the end zoom level is caluclated by fitbounds), in such cases we need to increment the zoom level by 1.
        if (startZoom === endZoom) {
            endZoom = startZoom + 1;
        }

        endZoom = endZoom > 5 ? 5 : endZoom;
        this.defaultZoom = endZoom;

        const panStepCount = 20;
        const zoomStepCount = 10;
        const panStepDelay = duration / panStepCount;
        const zoomStepDelay = duration / zoomStepCount;
        const latStep = (endCenter.lat() - startCenter.lat()) / panStepCount;
        const lngStep = (endCenter.lng() - startCenter.lng()) / panStepCount;
        const zoomStep = (endZoom - startZoom) / zoomStepCount;

        let stepCount = 0;

        this.zoomToClusterOnLanding(stepCount, panStepCount, zoomStepCount, startCenter, endCenter, panStepDelay, zoomStepDelay, startZoom, zoomStep, latStep, lngStep, bounds);
    }
    
    
    /**Recurrsive method used for:- Pan/Centre the map to get overall view of sites by creating a smooth delay rather than directly ending up in the centre.
     This is done by adding a delay while moving every step. Once the stepCount matches total step,animation ends and there's a  slight zomm into the marker*/
    zoomToClusterOnLanding(stepCount, panStepCount, zoomStepCount, start, end, panStepDelay, zoomStepDelay, startZoom, zoomStep, latStep, lngStep, bounds) {
        if (stepCount < panStepCount) {
            stepCount++;
            const lat = start.lat() + latStep * stepCount;
            const lng = start.lng() + lngStep * stepCount;
            this.map.setCenter(new google.maps.LatLng(lat, lng));

            setTimeout(() => this.zoomToClusterOnLanding(stepCount, panStepCount, zoomStepCount, start, end, panStepDelay, zoomStepDelay, startZoom, zoomStep, latStep, lngStep, bounds), panStepDelay);
        } else if (stepCount < panStepCount + zoomStepCount) {
            stepCount++;
            const zoom = startZoom + zoomStep * (stepCount - panStepCount);
            this.map.setZoom(zoom);

            setTimeout(() => this.zoomToClusterOnLanding(stepCount, panStepCount, zoomStepCount, start, end, panStepDelay, zoomStepDelay, startZoom, zoomStep, latStep, lngStep, bounds), zoomStepDelay);
        } else {
            this.map.fitBounds(bounds);
             // Adjust zoom level dynamically until all markers are visible. If there's only one marker, set the zoom level to 5.
             if(this.markerslist?.length > 1) {
                this.adjustZoomToFitMarkers(bounds)
             }   else {
                this.map.setZoom(5)
                this.defaultZoom = 5;
             }
            // Set the flag to true once the markers and animation are completed
            this.markersLoaded = true;
        }
    }

    //When both start and end zoom are same, we need the animation effect along with that the zoom level should be such that all the markers are visible.
    //This method checkes if all the markers are visible and if not, it adjusts the zoom level to fit all the markers by reducing the zoom level to 1.
    adjustZoomToFitMarkers(bounds) {
        const adjustZoom = () => {
            const currentZoom = this.map.getZoom();
            this.defaultZoom = currentZoom;
            if (!this.checkIfBoundsContainsMarkers() && currentZoom > 1) {
                this.map.setZoom(currentZoom - 1);
                setTimeout(adjustZoom, 100);
            } else {
                // Ensure final center is the exact end center
                this.map.setCenter(bounds?.getCenter());
                //do not allow the zoom level to go beyond 5
                const currentZoom = this.map.getZoom() > 5 ? 5 : this.map.getZoom();
                this.defaultZoom = currentZoom
                this.map.setZoom(currentZoom);
                 this.markersLoaded = true;
            }
        };
        adjustZoom();
    }

    checkIfBoundsContainsMarkers() {
        const currentBounds = this.map.getBounds();
        let allMarkersVisible = true;

        for (const marker of this.markerslist) {
            if (!currentBounds?.contains(marker.getPosition())) {
                allMarkersVisible = false;
                break;
            }
        }
        return allMarkersVisible;
    };
    
    
    

    ngOnDestroy() {
        if (localStorage.getItem('SelectedSite'))
            this.helperService.isDashboardPage$.next(false);
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
