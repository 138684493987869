<div class="zonepage-wrapper">
  <div class="zonepage-wrapper__nav">
    <div class="zonepage-wrapper__nav__searchbar">
      <input type="text" id="inputZoneName" placeholder="Search" title="Zone Name" (keyup)="searchZone()"><i
        class="fas fa-search" aria-hidden="true"></i>
    </div>


    <ul class="zonepage-wrapper__nav__zoneList" *ngIf=" zoneInfo && zoneInfo.length; else navSkeleton">
      <li *ngFor="let zone of zoneInfo;let i = index;" (click)="selectzone(zone)" [class.highlight]="zone._id == roomRef"
        [title]="zone.name">
        <div class="d-flex p-l-5 p-b-2">
          <div [attr.id]="'zoneName-' + zone.name" class="zoneName notranslate">{{ zone.name }}</div>
          <div [attr.id]="'zoneStatus-' + i" [class.online]="allZonesData?.[zone._id]?.online" class="status"></div>
        </div>
        <div class="container">
          <div *ngIf="allZonesData?.[zone._id]?.zonePaired" class="zone-image-container m-t-2">
            <img [src]="getProfileIcons(zone._id)" alt="Thermometer"
              [ngClass]="{'zone-image-highlight': zone._id == roomRef, 'zone-image': zone._id != roomRef}" />
          </div>
          <div *ngIf="allZonesData?.[zone._id]?.hasOwnProperty('zonePaired') && !allZonesData?.[zone._id]?.zonePaired">
            <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
          </div>
          <div *ngIf="allZonesData?.[zone._id]?.hasOwnProperty('zonePaired') && !allZonesData?.[zone._id]?.zonePaired"
            class="not-paired">No Device Paired</div>
          <div
            *ngIf="checkCurrentTempDisplay(allZonesData?.[zone._id]?.currentTemp) && allZonesData?.[zone._id]?.zonePaired"
            class="p-l-5">
            {{allZonesData?.[zone._id]?.currentTemp}}
            <span class="p-l-5">{{userTempPreference}}</span>
          </div>
        </div>
      </li>
    </ul>
    <!--Skeleton For Zone Name-->
    <ng-template #navSkeleton>
      <ul class="zonepage-wrapper__nav__zoneList" *ngIf="showZonesSkeletonLoader">
        <li *ngFor="let zone of mockBuildings">
          <div class="skeleton-zonename">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </div>
          <div class="skeleton-currenttemp">
            <span>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </span>
          </div>
        </li>
      </ul>
    </ng-template>
  </div>
  <div class="zonepage-wrapper__content" *ngIf="showLoadSkeleton; else contentSkeleton">
    <div *ngIf="roomRef !== 'noRef'" style="width: 90%;">
      <puc-widget class="zonepage-wrapper__content__widget" title="">
        <div class="zone-settings">
          <div class="zone-settings__section" *ngIf="!isModbusProfile(profiles[profiles.length-1])">
            <div *ngIf="profiles[profiles.length-1] && zoneSettings" [ngClass]="{'pointerDisable': isVofm}">
              <puc-profiles *ngIf='checkdisplayprofile()' [profileName]='profiles'
                [sliderDisplayFlag]='displayRangeSlider' (desiredTempsChanged)='desiredTempsChangedHandler($event)'
                [profileObj]='profileObj'
                [inputBuildingLimit]='buildingLimit' [inputZoneName]='zoneName' [currentRate]='zoneSettings.currentRate'
                [energyReading]='zoneSettings.energyReading'
                [piLoopData]="getPIData(profiles[profiles.length-1].profile)"  [senseData]="getSenseData(profiles[profiles.length-1].profile)"
                [profileFormattedName]="formatZoneName(profiles[profiles.length-1])"
                [siteTimeZone]="siteTz"
                [zoneSettings]="zoneSettings" [profileObj]="profileObj">
              </puc-profiles>
            </div>
            <br />
          </div>

          <div class="zone-settings__section" *ngIf="zoneSettings && !isModbusProfile(profiles[profiles.length-1])" [ngClass]="{'pointerDisable': isVofm}">
            <ul [ngStyle]="{'padding-inline-start': '0px'}">
              <li *ngIf="profileCheckFlag">
                <span [ngStyle]="{'font-size': '16px'}">Status : </span> <i class="settings__html"
                  [innerHTML]="isDataLoaded(zoneSettings.equipScheduleStatus?.val, true)" aria-hidden="true">
                </i>
              </li>
              <li class="label" *ngIf="!profileCheckFlag && !isProfilePI(profiles[profiles.length-1])">
                
                <div class="d-flex">
                  <h3 class="highlight notranslate">{{ formatZoneName(profiles[profiles.length-1]) }}</h3>
                  <div  [class.online]="isModuleOnline(profiles[profiles.length-1])" class="status margin-top-1"></div>
                </div>
                <span [ngStyle]="{'font-size': '16px'}">Status : </span> <span id="zone-status" class="settings__html"
                  [innerHTML]="isDataLoaded(zoneSettings?.equipStatusMessage?.val)||isDataLoaded(zoneSettings[profiles[profiles.length-1]?.profile?.name]?.equipStatusMessage?.val)">
                </span>
                <div [ngStyle]="{'font-size': '16px'}">Last Updated : <span id="zone-last-updated" class="settings__html">
                  {{dateFormat(zoneSettings?.lastUpdated?.val)}}
                </span></div>
              </li>
              <!-- Master Operation mode for VRV profile -->
              <li *ngIf = " this.profileObj.profileType == 'vrv' && isDataLoaded(zoneSettings.masterControllerMode?.val) == 0">
                <span id="off" [ngStyle]="{'font-size': '16px'}">Master Operation Mode : </span> <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 0" aria-hidden="true">Off
                </i>
                <i id="fan" class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 1" aria-hidden="true">Fan (Ventilation)
                </i>
                <i id="heat-mode" class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 2" aria-hidden="true">Heat Only Mode
                </i>
                <i id="cool-mode" class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 3" aria-hidden="true">Cool Only Mode
                </i>
                <i id="auto-mode" class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 4" aria-hidden="true">Auto
                </i>
              </li>
            </ul>
          </div>

          <div *ngIf="!showModbusEmr">
            <img class="modbus" src="assets/images/modbus.svg" *ngIf="isModbusProfile(profiles[profiles.length-1])" alt="">
          </div>
          <div *ngIf="showModbusEmr">
            <img class="modbus" src="assets/images/energymeter.svg"
              *ngIf="isModbusProfile(profiles[profiles.length-1])" alt="">
          </div>

          <div class="zone-settings__section" *ngFor="let profile of profiles" [ngClass]="{'pointerDisable': isVofm}">
            <ng-container
              *ngIf="(profileCheckFlag || isModbusProfile(profile)) && profile">
              <div class="d-flex modbus-header-section">
                <div class="modbus-header">
                <h3 class="highlight notranslate">{{ formatZoneName(profile) }}</h3>
                <div  *ngIf = "!profile.isChild || !isModbus"  [class.online]="isModuleOnline(profile)" class="status margin-top-1"></div>
                </div>
                <div *ngIf="profile?.profileType == 'modbus' && enableSort(profile) && allowModbusRearrange">
                  <img class="sort-modbus-icon" src="./assets/images/reorder.svg" alt="" matTooltip="Reorder Equips" matTooltipPosition="above" [matTooltipClass]="['reorder-ccu-tooltip', 'common-style-tooltip']" (click)="sortModbusEquips(profile)" />
                </div>
              </div>
              <puc-device-settings *ngIf="zoneSettings && !profile.profile['noPoints']" [siteRef]="refs.siteRef" [ccuRef]="refs.ccuRef"
                [settings]="profiles.length>1?zoneSettings[profile.profile.name]:zoneSettings" [tags]="profile"
                [cancelValues]="cancelChanges"
                [siteTimeZone]="siteTz"
                [isVofm] = "isVofm"
                (modeChange)="onSettingsChange('', $event, profiles.length>1?profile.profile.name:'')">
              </puc-device-settings>
              <div  *ngIf="profile.profile['noPoints']">
                <h6 class="display_modbus">None of the parameter is enabled in CCU for display. Please enable in CCU to view.</h6>
            </div>
            </ng-container>
          </div>
       
          <div class="zone-settings__section" *ngIf="zoneSettings && zonePriorityVal" [ngClass]="{'pointerDisable': isVofm}">
            <h3>Zone Priority</h3>
            <div [ngStyle]="{'margin':'auto', 'width':'100%'}">

              <puc-button-group [id]="'zonePriorityId'" [options]="options.zonePriority"
                [selectedOption]="zonePriorityVal" (optionClick)="onScheduleOrZonePriorityClick($event)">
              </puc-button-group>
            </div>
          </div>
          <div class="zone-settings__section" *ngIf="profileCheckFlag && zoneSettings" [ngClass]="{'pointerDisable': isVofm}">
            <h3>Schedule</h3>
            <puc-button-group [id]="'scheduleTypeId'" [options]="showSchedulerOccupancy? options.zoneScheduleRevamp : options.zoneSchedule" [selectedOption]="scheduleTypeVal"
              (optionClick)="onScheduleOrZonePriorityClick($event)">
            </puc-button-group>
            <div class="forScheduler" *ngIf="profileCheckFlag">
              <ng-container *ngIf="scheduleTypeVal == 1 && showSchedulerOccupancy">
                <div class="legend-container">
                  <div class="schedule-group-heatMap" *ngIf="enableScheduleGroup">{{setScheduleGroupText()}}</div>
                  <div class="legend-container-right">
                    <div class="inner-container" [ngStyle]="{'padding-top': !item?.button ? '2px': '0px'}"  *ngFor="let item of schedulerOccupancyLegend" style="display: flex;">
                      <ng-container *ngIf="!item?.button">
                        <div class="inner-div legend-color-box" [ngStyle]="{'background-color': item?.color}"></div>
                        <div class="inner-div legend-item">{{item.name}}</div>
                      </ng-container>
                      <ng-container *ngIf="item?.button">
                          <div class="legend-item edit-occupancy" (click)="openSchedulerModal(zoneSettings.zoneSchedule)">EDIT SCHEDULE</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="zone-schedule-view"  *ngIf="showSchedulerOccupancy && !openedSchedulerEditModal">
                <ng-container *ngIf="scheduleTypeVal == 1 && !zoneSettings?.zoneSchedule?.id" >
                  <div class="no-buildingSchedule"> Zone Schedule entity does not exist</div>
                </ng-container>
                <puc-scheduler-occupany
                  *ngIf="scheduleTypeVal == 1 && zoneSettings?.zoneSchedule?.val"
                    [scheduleId]="zoneSettings.zoneSchedule.id"
                    showUserTemp="true"
                    [enableScheduleGroup]="enableScheduleGroup"
                    [scheduleInfo]="zoneSettings?.zoneSchedule?.val"
                    hideLegend="true"
                    [buildingScheduleInfo]="zoneSettings?.buildingOccupancy?.val"
                    [buildingLimits]="buildingLimit"
                    [elementId]="'modal-view-occupancy'"
                    [siteTz]="siteTz"
                    [scheduleGroup] = "zoneSettings?.zoneSchedule?.scheduleGroup"
                    [defaultLimits]="defaultLimits"
                    [unoccupiedSetback]="zoneSettings?.zoneLevelunoccupiedZoneSetback?.val"
                    [buildingHeatingUserLimitMax]="this.zoneSettings?.buildingHeatingUserLimitMax.val"
                    [buildingCoolingUserLimitMin]="this.zoneSettings?.buildingCoolingUserLimitMin.val"
                    [buildingHeatingUserLimitMin]="this.zoneSettings?.buildingHeatingUserLimitMin.val"
                    [buildingCoolingUserLimitMax]="this.zoneSettings?.buildingCoolingUserLimitMax.val"
                    [buildingHeatingDeadband]="this.zoneSettings?.buildingHeatingDeadband.val"
                    [buildingCoolingDeadband]="this.zoneSettings?.buildingCoolingDeadband.val"
                    [buildingUnoccupiedZoneSetback]="this.zoneSettings?.buildingUnoccupiedZoneSetback.val"
                    [followBuilding]="this.zoneSettings['zoneSchedule']?.followBuilding">
                </puc-scheduler-occupany>
              </div>
              <ng-container *ngIf="!showSchedulerOccupancy">
                <ng-container *ngIf="scheduleTypeVal == 1 && !zoneSettings?.zoneSchedule?.id" >
                  <div class="no-buildingSchedule"> Zone Schedule entity does not exist</div>
                </ng-container>
                <puc-scheduler class="heatmap-fs-schedule"
                *ngIf="(scheduleTypeVal == 1 && zoneSettings?.zoneSchedule?.val) || (scheduleTypeVal == 0 && zoneSettings?.buildingSchedule?.val)"
                refType="roomRef" [sliderDisplayFlag]="displayRangeSlider"
                [scheduleInfo]="(scheduleTypeVal == 1 ? zoneSettings.zoneSchedule.val : zoneSettings.buildingSchedule.val)" [scheduleId]="zoneSettings?.zoneSchedule?.id"
                [scheduleType]="scheduleTypeVal" [buildingLimits]="buildingLimit" [sliderInput]="schSliderInput"
                [zoneName]="refs.zoneName" [refId]="refs.roomRef" [ccuRef]="refs.ccuRef" [siteId]="refs.siteRef" [siteTz]="siteTz" (schedulerEmitEvent)="schedulerEventEmit()"
                [buildingScheduleInfo]="zoneSettings?.buildingSchedule?.val" [defaultLimits]="defaultLimits" [readonly]="scheduleTypeVal==0" [isVofm] = "isVofm">
              </puc-scheduler>
              </ng-container>
              <puc-apply-named-schedule class="heatmap-fs-schedule"
                [roomObj]="roomObj"
                [multiModuleScheduleTypeList]="multiModuleScheduleTypeList"
                [scheduleType]="scheduleTypeVal" 
                [siteTz]="siteTz"
                [fromFacilisight]="true"
                [fromZonePage]="true"
                [zoneSettings]="zoneSettings"
                [buildingLimits]="buildingLimit"
                refType="roomRef"
                [orgName]="refs?.siteDetails?.organization"
                [readonly]="true"
                [multiModuleProfile]="multiModuleProfile"
                [buildingSettings]="zoneSettings"
                [refs]="refs"
                [defaultLimits]="defaultLimits"
                *ngIf="scheduleTypeVal == 2"
                [newSchedulerOnNamedSchedule]="showSchedulerOccupancy"
                [buildingToZoneDifferential]="zoneSettings?.buildingToZoneDifferential?.val">
              </puc-apply-named-schedule>   
            </div>
          </div>
          <div class="p-t-20" *ngIf="profileCheckFlag">
            <puc-special-schedules
              [specialSchedulesServerData]="zoneSettings?.zoneSpecialSchedule"
              [siteRef]="refs.siteRef"
              [isZoneVac]="true"
              [siteTimeZone] = "siteTz"
              [sliderDisplayFlag]="displayRangeSlider"
              [roomRef]="refs.roomRef"
              [ccuRef]="refs.ccuRef"
              [sliderInput]="schSliderInput"
              [buildingLimits]="buildingLimit"
              [defaultLimits]="defaultLimits"
              [zoneName]="refs.ccuName"
              [buildingOrZonename]="zoneName"
              (specialSchedulerEmitEvent)="schedulerEventEmit()"
              [refId]="refs.roomRef"
              [unoccupiedSetback]="zoneSettings?.zoneLevelunoccupiedZoneSetback?.val"
              [buildingToZoneDifferential]="zoneSettings?.buildingToZoneDifferential"
              [showRevampedSpecialScheduler]="showSchedulerOccupancy"
              [isVofm] = "isVofm">
            </puc-special-schedules>
          </div>

          <div class="forVacation">
            <puc-vacations *ngIf="profileCheckFlag" [vacationsServerData]="zoneVacations" [roomRef]="refs.roomRef" [ccuRef]="refs.ccuRef"
              [siteRef]="refs.siteRef" [siteTimeZone]="siteTz" [isZoneVac]=true [isVofm] = "isVofm">
            </puc-vacations>
          </div>

          <div class="zone-settings__section" *ngIf="checkCertificationAccess('remote-ccu-access')">
            <h3>Remote Access</h3>
            <puc-remote-access-portal [siteId]="siteRef" [ccuId]="ccuRef" appContext="zone-equip" [targetEntityId]="equipRef"></puc-remote-access-portal>
          </div>
        </div>
      </puc-widget>
    </div>
  </div>

  <div  class="showEquipPaired"  *ngIf="showEquipPaired">
    <h3> No zone's are paired</h3>
  </div>


  <!--Skeleton-->
  <ng-template class="zonepage-wrapper__content" #contentSkeleton>
    <div *ngIf="roomRef !== 'noRef'">
      <puc-widget class="zonepage-wrapper__content__widget" title="">
        <div class="zone-settings">
          <div class="zone-settings__section">
            <div>

            </div>
            <br />
          </div>

          <div class="zone-settings__section" style="margin-left: 20%">
            <ul [ngStyle]="{'padding-inline-start': '0px'}">
              <li>
                <span *ngIf="profileCheckFlag">
                  <ngx-skeleton-loader
                    [theme]="{ 'border-radius': '0','height': '157px', 'width': '157px', 'display': 'block', 'margin-left': '70%' }">
                  </ngx-skeleton-loader>
                </span>
                <div *ngIf="profiles && profiles.length && profiles[profiles.length-1].profileType == 'emr'"
                  style="margin-left: 70%; width: 100vh;">
                  <img src="../../../../../../../assets/images/emrskeleton.png" width=" 200px" alt="" />
                </div>
                <div *ngIf="profiles && profiles.length && profiles[profiles.length-1].profileType == 'pid'"
                  style="margin-left: 70%; width: 100vh">
                  <img src="../../../../../../../assets/images/skeleton_pi.png" width=" 200px" alt="" />
                </div>
                <ul *ngIf="profileCheckFlag">
                  <li>
                    <ngx-skeleton-loader
                      [theme]="{ 'border-radius': '0',  height: '10px',width: '145%', 'margin-left':'20%' }">
                    </ngx-skeleton-loader>
                  </li>
                </ul>
              </li>
              <li *ngIf="profileCheckFlag">
                <div class="forVacation" style="margin-left: 36%;">
                  <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader>
                  <div id="skeleton-vacation">
                    <div class="skeleton-vacation-value" *ngFor="let repeat of [0,1,2,3]">
                      <div class="skeleton-vacation-width" *ngFor="let repeat of [0,1]">
                        <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '10px'}"></ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>

                </div>
              </li>

            </ul>
          </div>
          <div class="zone-settings__section" style="margin-left: 36%;">
            <h3>
              <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader>
            </h3>
            <div [ngStyle]="{'margin':'auto', 'width':'91.5%'}">


              <ngx-skeleton-loader [theme]="{ 'border-radius': '0',height:'25px' ,width: '400px'}">
              </ngx-skeleton-loader>


            </div>
          </div>
          <div class="zone-settings__section" *ngIf="profileCheckFlag" style="margin-left: 36%;">
            <h3>
              <ngx-skeleton-loader [theme]="{ 'border-radius': '0', width: '150px'}"></ngx-skeleton-loader>
            </h3>
            <div [ngStyle]="{'margin':'auto', 'width':'91.5%'}">
              <ngx-skeleton-loader [theme]="{ 'border-radius': '0',height:'25px' ,width: '400px'}">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="forVacation" *ngIf="profileCheckFlag" style="margin-left: 36%;">
            <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '40px',width: '700px'}"></ngx-skeleton-loader>
            <div id="skeleton-vacation">
              <div class="skeleton-vacation-value" *ngFor="let repeat of [0,1,2,3]">
                <div class="skeleton-vacation-width" *ngFor="let repeat of [0,1,2,3]">
                  <ngx-skeleton-loader></ngx-skeleton-loader>
                </div>
              </div>
            </div>

          </div>
        </div>
      </puc-widget>
    </div>
  </ng-template>


  <!--- skeleton --->
  <div *ngIf="roomRef == 'noRef'" class="zonepage-wrapper__content">
    <div class="zonepage-wrapper__content__noZonePaired">
      <img src="../../../../../assets/images/NoDevicePaired.svg" alt="">
      <div>
        No Device Currently Paired
      </div>
      <div>
        Please go to the Floor Layout in the CCU to pair a new device
      </div>
    </div>
  </div>
</div>
<div class="zonepage-wrapper" *ngIf=" zoneInfo && zoneInfo.length == 0">
  <div class="zonepage-wrapper__content__noZonePaired">
    <img src="../../../../../assets/images/NoDevicePaired.svg" alt="">
    <div>
      No Device Currently Paired
    </div>
    <div>
      Please go to the Floor Layout in the CCU to pair a new device
    </div>
  </div>
</div>